import { React, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import {
    InputSearch,
    InputGroup,
    Textarea,
} from '@iso/components/uielements/input';
import { useContext } from 'react';
import { Spin, Tooltip, message, Input } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
// import closeIcon from '@iso/assets/images/icon/close-icon.svg';
import CloseIcon from '@iso/assets/images/icon/07-icon.svg';
import styled, { createGlobalStyle } from 'styled-components';
import { SelectOption } from '@iso/components/uielements/select';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import initialData from '../../redux/scrumBoard/data';
import { AppContext } from '../../context/ContextProvider';
import CreateTaskHeader from '../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
// import { borderRadius } from '../../../library/helpers/style_utils';
import Select from './AddEnvironmentVariable.style';
import Dropzone from '@iso/components/uielements/dropzone';
import DropzoneWrapper from '../AdvancedUI/Dropzone/Dropzone.styles';
import Alert from '@iso/components/Feedback/Alert';
import Scrollbars from 'react-custom-scrollbars-2';
import { AlertContext } from '../../context/alertContext';
import api from '../../api';
import { customFilterOption, customSortOptions, formatErrors, hasPermission } from '../../context/helper';
import UploadFiles from '../../components/utility/UploadFiles';
import { InfoCircleFilled } from '@ant-design/icons';
// import InputBox from '@iso/containers/Ecommerce/Checkout/InputBox.js';
import { CustomModal } from '../Dashboard/CustomComponents/PageLoader.style';


var ReactDOMServer = require('react-dom/server');

const Option = SelectOption;

const marginsBot = {
    position: 'fixed',
    opacity: '100%',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '1000',
    width: "25%",
    padding: '15px'
};

const fadeMarginBot = {
    position: 'fixed',
    opacity: '0',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '-1',
    width: "25%",
    padding: '15px'
};

let titleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontSize: "16px",
    fontWeight: "500"
}

let subTitleStyle = {
    fontSize: "14px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontType: "regular",
    fontWeight: "400"
}

const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }

  .isoHelperText {
    color: red;
    font-weight: 400;
    font-size: 12px;
    margin-top: 30%;
    display: flex;
    justify-content: center;
  }

  .header__center {
    font-size: 15px;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 1.2rem;
    align-items: center;
    color: #7C7C7C;
    margin: 50px;
    margin-inline: 49px;
  }

  .header__center::before, .header__center::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #F4F4F4;
  }
`;

const addButtonStyle = {
    marginRight: "10px",
    fontSize: "25px"
};

const buttonCss = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 46px"
};

const footerStyle = {
    width: "100%",
    position: "absolute",
    bottom: "0",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: "20px 10px",
    borderTop: "1px solid #B1B1B1",
    background: "#FFF",
}

export default ({
    // handleSubmit,
    data,
    valueFromDropdown,
    update,
    service,
    selectedAppId,
    values,
    submitCount,
    onCancel,
    onDelete,
    openDrawer,
    onEditCancel,
    tabSelected,
    currentProject,
    setCurrentProject,
    setHasChanges,
    fromEnvironment
}) => {

    const { openRightDrawer, setOpenRightDrawer } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const [importData, setImportData] = useState({
        repo: service ? (service.is_private ? service.repo_name : service.repo_url) : "",
        branch: "",
        importOption: "",
        file: "",
        secretFile: "",
        uploadContent: false,
    });

    const [fetching, setFetching] = useState(false);
    const [repoList, setRepoList] = useState([]);
    const [myDropdown, setMyDropdown] = useState([]);
    const [file, setFile] = useState(null);
    const [jsonData, setJsonData] = useState();
    const [filesList, setFilesList] = useState([]);
    const [branchLoad, setBranchLoad] = useState(true);
    const [importOptions, setImportOptions] = useState([]);
    const [secretList, setSecretList] = useState([]);
    const [error, setError] = useState({
        status: false,
        msg: "Success"
    });

    const navigate = useNavigate();
    const location = useLocation();
    const [searchValue, setSearchValue] = useState("");
    const [secretLoad, setSecretLoad] = useState(false);
    const [loadingSecrets, setLoadingSecrets] = useState(false); //adds loader when importing variables for secret maanger or repo

    const callGitlabPublicBranchesApi = (url) => {
        fetch(url)
            .then(async (res) => await res.json())
            .then((data) => {
                console.log('branches data :', data);
                if (data.message) {
                    console.log("Unable to connect to gitlab. Please check if repo is public");
                    setError({
                        status: true,
                        type: "branch",
                        msg: "Unable to connect to gitlab. Please check if repo is public"
                    });
                    // toggleAlert("error", "Error", "Unable to connect to github. Please check if repo is public");
                }
                else {
                    let branches = [];
                    data.map((temp, ind) => {
                        branches.push(
                            <Option key={ind} name="branch" value={temp.name}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                    <span>{temp.name}</span>
                                </div>
                            </Option>
                        );
                    });
                    setRepoList(branches);
                }
                setBranchLoad(false);
            })
            .catch((error) => {
                console.error('Error fetching branches:', error);
                setBranchLoad(false);
            });
    }

    useEffect(() => {
        console.log("Executing this useEffect");
        let options = !fromEnvironment ? ["Repo", "Upload .env file", "Secrets"] : ["Repo", "Upload .env file"];
        options.forEach((val) => {
            if (!fromEnvironment) {
                if (currentProject?.accountDetails?.account_id && val === "Secrets") {
                    importOptions.push(<Option key={val} name="importOption" value={val} disabled={!hasPermission("Can create secret")}>
                        {val}
                    </Option>);
                }
                else if (currentProject.tasks[selectedAppId].app_source_type === 1 && val === "Repo") {
                    importOptions.push(<Option key={val} name="importOption" value={val}>
                        {val}
                    </Option>);
                }
                else if (val !== "Secrets" && val !== "Repo") {
                    importOptions.push(<Option key={val} name="importOption" value={val}>
                        {val}
                    </Option>);
                }
            }
            else {
                importOptions.push(<Option key={val} name="importOption" value={val}>
                    {val}
                </Option>);
            }
        });
        setImportOptions([...importOptions]);
    }, []);

    useEffect(() => {
        console.log("Import data.importOption: ", importData.importOption);
        if (importData.importOption === "Secrets") {
            setSecretLoad(true);
            api.get(process.env.REACT_APP_API_URI + `/api/integration/secret-manager/list/secrets/${currentProject.accountDetails?.region}/${currentProject.accountDetails?.account_id}`)
                .then((res) => {
                    let data = res.data;
                    if (data.status) {
                        let secrets = [];
                        data.data.forEach((val) => {
                            secrets.push(<Option name="secretFile" key={val} value={val}>
                                {val}
                            </Option>);
                        });
                        setSecretList([...secrets]);
                        //setSecretList(data.data);
                    }
                    else {
                        console.log("Error");
                        setError({
                            msg: data.error_details.error,
                            status: true
                        });
                    }
                    setSecretLoad(false);
                }).catch((err) => {
                    console.log("Error");
                    setSecretLoad(false);
                });
        }
    }, [importData.importOption]);

    useEffect(() => {
        console.log("data in importEnvironmentVariables :", data);
        console.log("service in importEnvironmentVariables :", service);
        console.log("currentProject in importEnvironmentVariables :", currentProject);
        console.log("My import data: ", importData.importOption === "Repo");

        if (importData.importOption === "Repo") {
            if (service && !service.is_private && service.app_source_type === 1) {
                let rawData = service?.repo_url?.split("/");
                console.log("rawData :", rawData);

                if (service && !service.is_private) {
                    let rawData = service.repo_url.split("/");
                    console.log("rawData :", rawData);
                    let owner = rawData[3];
                    let repoName = rawData[4];

                    if (repoName) {
                        repoName = repoName.split(".git")[0];
                    }

                    if (service?.repo_url?.includes("https://github.com")) {
                        fetch(`https://api.github.com/repos/${owner}/${repoName}/branches`)
                            .then(async (res) => await res.json())
                            .then((data) => {
                                console.log('branches data :', data);
                                if (data.message) {
                                    console.log("Unable to connect to github. Please check if repo is public.");
                                    setError({
                                        status: true,
                                        type: "branch",
                                        msg: "Unable to connect to github. Please check if repo is public."
                                    });
                                    // toggleAlert("error", "Error", "Unable to connect to github. Please check if repo is public");
                                }
                                else {
                                    let branches = [];
                                    data.map((temp, ind) => {
                                        branches.push(
                                            <Option key={ind} name="branch" value={temp.name}>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                    <span>{temp.name}</span>
                                                </div>
                                            </Option>
                                        );
                                    });
                                    setRepoList(branches);
                                }
                                setBranchLoad(false);
                            })
                            .catch((error) => {
                                console.error('Error fetching branches:', error);
                                setBranchLoad(false);
                            });
                    }
                    else if (service && service.repo_id) {
                        // GitLab instance details
                        let gitlabInstance = `https://${rawData[2]}`;
                        let projectPath = `${owner}%2F${repoName}`;

                        let url = `${gitlabInstance}/api/v4/projects/${projectPath}/repository/branches`;
                        callGitlabPublicBranchesApi(url);
                    }
                    else if (service?.repo_url?.includes("bitbucket")) {
                        fetch(`https://api.bitbucket.org/2.0/repositories/${owner}/${repoName}/refs/branches`)
                            .then(async (res) => await res.json())
                            .then((data) => {
                                console.log('branches data :', data);
                                if (data.message) {
                                    console.log("Unable to connect to bitbucket. Please check if repo is public.");
                                    setError({
                                        status: true,
                                        type: "branch",
                                        msg: "Unable to connect to bitbucket. Please check if repo is public."
                                    });
                                }
                                else {
                                    let branches = [];
                                    data.map((temp, ind) => {
                                        branches.push(
                                            <Option key={ind} name="branch" value={temp.name}>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                                    <span>{temp.name}</span>
                                                </div>
                                            </Option>
                                        );
                                    });
                                    setRepoList(branches);
                                }
                                setBranchLoad(false);
                            })
                            .catch((error) => {
                                console.error('Error fetching branches:', error);
                                setBranchLoad(false);
                                // toggleAlert("error", "Error", "Something went wrong. Please try again.");
                            });
                    }
                    else {
                        // GitLab instance details
                        let gitlabInstance = `https://${rawData[2]}`;
                        let projectPath = `${owner}%2F${repoName}`;

                        let url = `${gitlabInstance}/api/v4/projects/${projectPath}/repository/branches`;
                        callGitlabPublicBranchesApi(url);
                    }
                }
            }
            else if (service.app_source_type === 1) {
                api.post(process.env.REACT_APP_API_URI + '/api/integration/vcs/branch/', {
                    account_id: service.account_id,
                    repo_name: service.repo_name
                }).then((res) => {
                    let data = res.data;
                    if (!data.status) {
                        let err = formatErrors(data.error_details);
                        console.log("Error :", err);
                        // toggleAlert("error", "Error", err);
                    }
                    else {
                        let branches = [];
                        data.data.map((temp, ind) => {
                            branches.push(
                                <Option key={ind} name="branch" value={temp}>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "Center" }}>
                                        <span>{temp}</span>
                                    </div>
                                </Option>
                            );
                        });
                        setRepoList(branches);
                    }
                    setBranchLoad(false);
                })
                    .catch((err) => {
                        console.log("Error :", err);
                        setBranchLoad(false);
                        // toggleAlert("error", "Error", "Something went wrong. Please try again.");
                    });
            }
        }

        // get .env Files from github
        // getFilesFromRepo();
    }, [importData.importOption]);


    useEffect(() => {
        if (importData.importOption === "Repo") {
            setFetching(true);
            getFilesFromRepo();
        }
    }, [importData.branch])



    const handelClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
                columnId: ""
            },
        });
    }

    const getFilesFromRepo = () => {
        api.get(process.env.REACT_APP_API_URI + `/api/project_env/get_env_file/${service.serviceId}?branch=${importData.branch}`)
            .then((res) => {
                let data = res.data;
                if (!data.status) {
                    let err = formatErrors(data.error_details);
                    console.log("Error :", err);
                    setFetching(false);
                }
                else if (!data.data) {
                    setFetching(false);
                    console.log("File not found in given repo. Please try changing you branch.");
                    // toggleAlert("error", "Error", "File not found in given repo. Please try changing you branch.");
                }
                else if (data.data) {
                    let envFile = [];
                    data.data.map((temp, ind) => {
                        envFile.push(
                            <Option key={ind} name="file" value={temp.file_path}>
                                <div style={{ display: "flex", justifyContent: "start", alignItems: "Center" }}>
                                    <span>{temp.file_name}</span>
                                </div>
                            </Option>
                        );
                    });

                    setFilesList(envFile);
                    setFetching(false);
                }
            })
            .catch((err) => {
                setFetching(false);
                console.log("Error :", err);
                // toggleAlert("error", "Error", "Something went wrong. Please try again.");
            });
    }

    const handleReadFile = (file) => {
        const reader = new FileReader();
        console.log("Files :", file);

        reader.onload = (e) => {
            try {
                // console.log("e.target :", e.target);
                // console.log("e.target.result :", e.target.result);
                setJsonData(e.target.result);
                setImportData({ ...importData, uploadContent: true });
                message.success(`${file.name} uploaded successfully. Please proceed.`);
            } catch (error) {
                console.error('Error parsing .env file:', error);
            }
        };

        if (file) {
            setFile({
                ...file,
                uid: file.uid,
                name: file.name,
                status: 'done',
            });
            reader.readAsText(file);
        }
    };

    const handelRemoveFile = () => {
        console.log("handelRemoveFile :", file);
        eventHandlers.removedfile();
    }

    const componentConfig = {
        iconFiletypes: ['.env', '.env.sample'],   // ['.jpg', '.png', '.gif']
        method: true,
        showFiletypeIcon: false,
        uploadMultiple: false,
        maxFiles: 1,
        maxFilesize: 2, // MB
        dictMaxFilesExceeded: 'You can only upload upto 1 image',
        dictRemoveFile: 'Delete',
        dictCancelUploadConfirmation: 'Are you sure to cancel upload?',
        postUrl: 'no-url',
    };

    const djsConfig = {
        acceptedFiles: ['.env', '.env.sample'],
        autoProcessQueue: false,
        maxFiles: 1,
        uploadMultiple: true,
        // previewTemplate: (
        //     <div>
        //         <p>Selected File</p>
        //         {/* <button onClick={() => setFile(null)}>Remove File</button> */}
        //         </div>
        //   )
        // previewTemplate: ReactDOMServer.renderToStaticMarkup(
        //     <div className="dz-preview dz-file-preview">
        //       <div className="dz-details">
        //         <div className="dz-filename"><span data-dz-name="true"></span></div>
        //         <img data-dz-thumbnail="true" />
        //       </div>
        //       <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
        //       <div className="dz-success-mark"><span>✔</span></div>
        //       <div className="dz-error-mark"><span>✘</span></div>
        //       <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
        //     </div>
        // )
    };

    const eventHandlers = {
        addedfile: (newFile) => {
            setFile(newFile);
            console.log("file in addedfile :", newFile);
            handleReadFile(newFile);
        },
        success: (newFile) => {
            console.log("newFile in success :", newFile);
            toggleAlert("success", "Success", `${newFile.name} successfully uploaded`);
        },
        error: (error) => {
            console.log("error :", error);
            toggleAlert("error", "Error", "Error in uploading File");
        },
        removedfile: (newFile) => {
            console.log("newFile in removedfile :", newFile);
            setFile(null);
        }
    };

    const handleSelectChange = (value, event) => {
        console.log("inside handleSelectChange");
        setImportData({ ...importData, [event.name]: value });
        setError({ ...error, status: false });
    }

    const handelCreate = (e, addAnother) => {
        console.log("inside handelCreate importData:", importData);
        console.log("inside handelCreate currentProject:", currentProject);

        // Already exist env varName to check for duplication in future
        let allEnvNames = [];
        service.envs.map((temp, idx) => {
            allEnvNames.push(temp.varName);
        });

        console.log("allEnvNames :", allEnvNames);
        if (importData.importOption === "Upload .env file") {
            if (importData.uploadContent) {
                console.log("jsonData :", jsonData);
                if (jsonData) {
                    let rawData = jsonData.split("\r\n");
                    if (rawData[0].includes("\n")) {
                        console.log("Includes \n insted or \r\n ");
                        rawData = jsonData.split("\n");
                    }

                    console.log("rawData :", rawData);
                    let tempEnvs = [];
                    if (rawData.length > 0) {
                        rawData.map((temp, idx) => {
                            if (!temp.trim().startsWith("#") && !temp.trim().startsWith("//") && temp.includes("=")) {
                                let rawText = temp.split("=");
                                let key = rawText[0].trim();
                                let value = rawText[1];

                                // if (allEnvNames.includes(key)) {
                                //     console.log("Yes :", temp);
                                //     service.envs.map((obj, idx) => {
                                //         if (obj.varName === key) {
                                //             service.envs[idx].value = value;
                                //             service.envs[idx].valueFrom = "Value";
                                //             service.envs[idx].isSecret = false;
                                //             service.envs[idx]["dependOn"] = null;
                                //             service.envs[idx].error = value ? false : true;
                                //         }
                                //     });
                                // }
                                // else {
                                    tempEnvs.push({
                                        varName: key,
                                        valueFrom: "Value", 
                                        value: value,
                                        isSecret: false,
                                        dependOn: null,
                                        error: !value || allEnvNames.includes(key) ? true : false || key.startsWith(" ") || key.endsWith(" "),
                                        id: idx,
                                        isHide: false,
                                        key: idx,
                                        keys: key,
                                        syncProj: false,
                                    });
                                // }

                            }
                        });

                        console.log("tempEnvs :", tempEnvs);

                        currentProject.importDetails = {
                            ...importData
                        };

                        currentProject.tasks.map((temp, idx) => {
                            if (temp.id === data.id) {
                                currentProject.tasks[idx].importDetails = {
                                    ...importData
                                };
                                // currentProject.tasks[idx].accountDetails = {
                                //     ...currentProject.tasks[idx].accountDetails,
                                //     secret_name: importData.secretFile
                                // };
                                currentProject.tasks[idx].envs = service.envs.concat(tempEnvs);
                            }
                        });

                        setCurrentProject({ ...currentProject, tasks: [...currentProject.tasks] });
                        localStorage.setItem(!fromEnvironment ? "Project" : "ProjectDep", JSON.stringify({ ...currentProject, tasks: [...currentProject.tasks] }));
                        setHasChanges(true);
                        handelClick();
                    }
                }
                else {
                    setError({
                        status: true,
                        msg: "No variables found."
                    });
                }
            }
        }
        else if (importData.importOption === "Repo") {
            if (!importData.repo) {
                setError({
                    status: true,
                    msg: "Repo name not found. Please try again"
                });
            }
            else if (!importData.repo || !importData.branch || !importData.file) {
                setError({
                    status: true,
                    msg: "Please Fill all fields marked with *"
                });
            }
            else {
                setError({ ...error, status: false });
                console.log("current project in add...", currentProject, data);
                setLoadingSecrets(true);
                // api.get(process.env.REACT_APP_API_URI + `/api/project_env/get_env_var/${service.serviceId}${importData.branch ? `/${importData.branch}/` : "/"}${importData.file ? `?file_path=${importData.file}` : ""}`)
                api.get(process.env.REACT_APP_API_URI + `/api/project_env/get_env_var/${service.serviceId}?branch=${importData.branch}&file_path=${importData.file}`)
                    .then((res) => {
                        let data = res.data;
                        console.log("data in get_env_file api :", data);
                        if (!data.status) {
                            let err = formatErrors(data.error_details);
                            toggleAlert("error", "Error", err);
                        }
                        else {
                            if (data.data) {
                                let tempEnvs = [];

                                data.data.map((temp, idx) => {
                                    let key = Object.keys(temp)[0].trim();
                                    let value = Object.values(temp)[0];

                                    // if (allEnvNames.includes(key)) {
                                    //     service.envs.map((obj, idx) => {
                                    //         if (obj.varName === key) {
                                    //             service.envs[idx].value = value;
                                    //             service.envs[idx].valueFrom = "Value";
                                    //             service.envs[idx].isSecret = false;
                                    //             service.envs[idx]["dependOn"] = null;
                                    //         }
                                    //     });
                                    // }
                                    // else {
                                        tempEnvs.push({
                                            varName: key,
                                            valueFrom: "Value",
                                            value: value,
                                            isSecret: false,
                                            isHide: false,
                                            dependOn: null,
                                            error: !value || allEnvNames.includes(key) ? true : false,
                                            id: idx,
                                        isHide: false,
                                        key: idx,
                                        keys: key,
                                        syncProj: false,
                                            // isSecretManager
                                        });
                                    // }
                                });

                                // console.log("tempEnvs :", tempEnvs);

                                currentProject.importDetails = {
                                    ...importData
                                };

                                // currentProject.accountDetails = {
                                //     ...currentProject.accountDetails,
                                //     // secret_name: importData.secretFile
                                // };

                                currentProject.tasks.map((temp, idx) => {
                                    if (temp.id === service.id) {
                                        currentProject.tasks[idx].envs = service.envs.concat(tempEnvs);
                                        currentProject.tasks[idx].importDetails = {
                                            ...importData
                                        };
                                    }
                                });

                                setCurrentProject({ ...currentProject, tasks: [...currentProject.tasks] });
                                localStorage.setItem(!fromEnvironment ? "Project" : "ProjectDep", JSON.stringify({ ...currentProject, tasks: [...currentProject.tasks] }));
                                setHasChanges(true);
                                handelClick();
                            }
                            else {
                                toggleAlert("error", "Error", ".env file not found. Please try different branch.");
                            }
                        }
                        setLoadingSecrets(false);
                    })
                    .catch((err) => {
                        console.log("Error :", err);
                        toggleAlert("error", "Error", "Something went wrong. Please try again.");
                        setLoadingSecrets(false);
                    });
            }
        }
        else if (importData.importOption === "Secrets") {
            if (importData.secretFile === "") {
                setError({
                    status: true,
                    msg: "Please Fill all fields marked with *"
                });
            }
            else {
                setLoadingSecrets(true);
                api.get(process.env.REACT_APP_API_URI + `/api/project_env/secrets?region=${currentProject.accountDetails?.region}&account_id=${currentProject?.accountDetails?.account_id}&secret_name=${importData.secretFile}`)
                    .then((res) => {
                        let data = res.data;
                        console.log("data in get_env_file api :", data);
                        if (!data.status) {
                            let err = formatErrors(data.error_details);
                            setError({
                                msg: err,
                                status: true
                            });
                            //toggleAlert("error", "Error", err);
                        }
                        else {
                            if (data.data && Object.keys(data.data).length > 0) {
                                let tempEnvs = [];

                                let convertEnvs = [];

                                Object.keys(data.data).forEach((val) => {
                                    convertEnvs.push({
                                        [val]: data.data[val].toString()
                                    });
                                });

                                convertEnvs.map((temp, idx) => {
                                    let key = Object.keys(temp)[0];
                                    let value = Object.values(temp)[0];

                                    // if (allEnvNames.includes(key)) {
                                    //     service.envs.map((obj, idx) => {
                                    //         if (obj.varName === key) {
                                    //             service.envs[idx].value = value;
                                    //             service.envs[idx].valueFrom = "Value";
                                    //             service.envs[idx].isSecret = true;
                                    //             service.envs[idx]["dependOn"] = null;
                                    //             service.envs[idx]["importFrom"] = "Secret Manager";
                                    //         }
                                    //     });
                                    // }
                                    // else {
                                    tempEnvs.push({
                                        varName: key,
                                        valueFrom: "Value",
                                        value: value,
                                        isSecret: true,
                                        isHide: true,
                                        dependOn: null,
                                        importFrom: "Secret Manager",
                                        error: !value || allEnvNames.includes(key) ? true : false,
                                    });
                                    // }
                                });

                                console.log("tempEnvs :", tempEnvs);

                                currentProject.importDetails = {
                                    ...importData
                                };

                                currentProject.accountDetails = {
                                    ...currentProject.accountDetails,
                                    // secret_name: importData.secretFile
                                };

                                currentProject.tasks.map((temp, idx) => {
                                    if (temp.id === service.id) {
                                        // currentProject.tasks[idx].importDetails = {
                                        //     ...importData
                                        // };
                                        // currentProject.tasks[idx].accountDetails = {
                                        //     ...currentProject.tasks[idx].accountDetails,
                                        //     secret_name: importData.secretFile
                                        // };
                                        currentProject.tasks[idx].secret_name = importData.secretFile;
                                        currentProject.tasks[idx].envs = service.envs.concat(tempEnvs);
                                        currentProject.tasks[idx].importDetails = {
                                            ...importData
                                        };
                                    }
                                });

                                setCurrentProject({ ...currentProject, tasks: [...currentProject.tasks] });
                                localStorage.setItem(!fromEnvironment ? "Project" : "ProjectDep", JSON.stringify({ ...currentProject, tasks: [...currentProject.tasks] }));
                                setHasChanges(true);
                                handelClick();
                            }
                            else {
                                //toggleAlert("error", "Error", "No secrets found.");
                                setError({
                                    status: true,
                                    msg: "No secrets found."
                                });
                            }
                        }
                        setLoadingSecrets(false);
                    })
                    .catch((err) => {
                        console.log("Error :", err);
                        toggleAlert("error", "Error", "Something went wrong. Please try again.");
                        setLoadingSecrets(false);
                    });
            }
        }
    }

    function toggleAlert(type, message, description, hide = true, time = 5) {
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        if (hide) {
            // opacity=0 after 10sec
            setTimeout(() => {
                set_alertOptions(alertOptions => ({
                    status: false,
                    type: type,
                    message: message,
                    description: description,
                }));
            }, time * 1000);
        }
    }

    const filterOption = (input, option) => {
        if (option.key) {
            return (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());
        }
    }


    return (
        <>
            {/* <Alert
                message={alertOptions.message}
                description={alertOptions.description}
                type={alertOptions.type}
                showIcon
                style={alertOptions.status ? marginsBot : fadeMarginBot}
            /> */}

            <CustomModal
                visible={loadingSecrets}
                //onCancel={hideModal}
                footer={null}
                closable={false}
                centered
                maskClosable={false}
                style={{ border: "none", boxShadow: "none" }}
            >
                <div style={{ textAlign: 'center', background: "transparent", border: "none" }}>
                    <Spin size="large" />
                </div>
            </CustomModal>

            <ProdContentHold className='addEnvVar' style={{}}>
                <Box
                    title="Select to import*"
                    subtitle=""
                    titleStyle={titleStyle}
                    subtitleStyle={subTitleStyle}
                    style={{ paddingInline: "49px", marginTop: "105px" }}
                >
                    {console.log("Import options: ", importOptions)}
                    <Select
                        value={importData.importOption}
                        onChange={handleSelectChange}
                        className="custom-ant-selector"
                        placeholder="Select"
                    //filterOption={filterOption}
                    //notFoundContent={branchLoad ? <Spin size="small" /> : null}
                    >
                        {importOptions}
                    </Select>
                </Box>
                {/* Header */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", backgroundColor: "rgb(19, 47, 68)" }}>
                    <Box
                        title={
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize: "14px"
                            }}>
                                <span className="header-text">Import Variable</span>
                                <img src={CloseIcon} style={{ cursor: "pointer", width: "20px", height: "20px", transform: "translateY(15px)" }} alt="close Icon" onClick={handelClick} />
                            </div>
                        }
                        subtitle="Enter details to import new variable"
                        titleStyle={{
                            fontWeight: '500',
                            fontFamily: "Nunito Sans",
                            fontSize: '14px',
                            color: "#ffffff"
                        }}
                        subTitleStyle={{
                            fontWeight: '400',
                            fontFamily: "Nunito Sans",
                            fontSize: '13px',
                            color: "#ffffff"
                        }}
                        style={{
                            // paddingLeft: "20px",
                            position: "absolute",
                            zIndex: "1",
                            width: "100%",
                            top: "0px",
                            height: "83px",
                            backgroundColor: "rgb(19, 47, 68)"
                        }}
                    />
                </div>
                {/* Content */}
                {importData.importOption === "Repo" && <>
                    <div style={{ opacity: file ? "0.7" : "1", pointerEvents: file && "none" }}>
                        <Box
                            title="Repo*"
                            subtitle="Repo Details"
                            titleStyle={titleStyle}
                            subtitleStyle={subTitleStyle}
                            style={{ paddingInline: "49px" }}
                        >
                            <Input
                                name="repo"
                                value={importData.repo}
                                placeholder="Repo name"
                                disabled={true}
                                style={{
                                    width: "100%",
                                    height: "44px",
                                    border: `1px solid ${error.status && error.type && error.type === "branch" ? `#f64744` : `#D0D5DD`}`,
                                    borderRadius: "8px",
                                    cursor: "not-allowed",
                                    color: "rgba(0, 0, 0, 0.25)",
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                }}
                            />
                            {error.status && error.type && error.type === "branch" && <p className="isoHelperText" style={{ marginTop: "5px", justifyContent: "start" }}>
                                <span>{error.msg}</span>
                            </p>}
                        </Box>

                        <Box
                            title="Branch*"
                            subtitle="Branch details"
                            titleStyle={titleStyle}
                            subtitleStyle={subTitleStyle}
                            style={{ paddingInline: "49px" }}
                        >
                            <Select
                                showSearch
                                filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                onSearch={(value) => {
                                    console.log("Enters search", value);
                                    setSearchValue(value);
                                }}
                                value={importData.branch}
                                onChange={handleSelectChange}
                                className="custom-ant-selector"
                                placeholder="Select"
                                style={{}}
                                //filterOption={filterOption}
                                notFoundContent={branchLoad ? <Spin size="small" /> : null}
                            >
                                {repoList}
                            </Select>
                        </Box>

                        {importData.branch && <Box
                            title={<Tooltip title={"File needs to be on the root directory."} placement="bottomLeft">
                                <div style={{ display: "flex" }}><p style={{ marginRight: "10px" }}>File*</p> <InfoCircleFilled style={{ color: "#8896A1" }} /></div>
                            </Tooltip>}
                            titleStyle={titleStyle}
                            style={{ paddingInline: "49px" }}
                        >
                            <Select
                                value={importData.file}
                                onChange={handleSelectChange}
                                placeholder="Select"
                                className="custom-ant-selector"
                            >
                                {fetching ?
                                    <Option>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                            <Spin size="small" />
                                        </div>
                                    </Option>
                                    :
                                    filesList
                                }
                            </Select>
                        </Box>}
                    </div>
                </>}
                {/* <div className='header__center'> or </div> */}


                {importData.importOption === "Secrets" && <>
                    <Box title="Provider*"
                        subtitle="Select Provider from here"
                        titleStyle={titleStyle}
                        subtitleStyle={subTitleStyle}
                        style={{ paddingInline: "49px" }}
                    >
                        <Input style={{ height: "44px" }} disabled value={currentProject.accountDetails?.account_id ? currentProject.accountDetails?.account_id + " | Secret Manager" : currentProject.accountDetails} />
                    </Box>
                    <Box title="Select Secret*"
                        subtitle="Select Secret from here"
                        titleStyle={titleStyle}
                        subtitleStyle={subTitleStyle}
                        style={{ paddingInline: "49px" }}
                    >
                        {console.log("secrets fetched: ", secretList)}
                        <Select value={importData.secretFile}
                            onChange={handleSelectChange}
                            placeholder="Select"
                            className="custom-ant-selector"
                            notFoundContent={secretLoad ? <Spin size='small' /> : null}
                        >
                            {!secretLoad && secretList}
                        </Select>
                    </Box>
                </>}

                {importData.importOption === "Upload .env file" && <Box
                    title="Upload .env file here*"
                    subtitle="File Details"
                    // subTitle="Only .env files are allowed. Please check your file type properly"
                    subtitleStyle={subTitleStyle}
                    titleStyle={titleStyle}
                    style={{ paddingInline: "49px", opacity: importData.branch && importData.branch !== "" && "0.7", pointerEvents: importData.branch && importData.branch !== "" && "none" }}
                >
                    <div style={{ padding: "10px 0" }}>
                        <UploadFiles types={[".env", ".ENV"]} files={file ? [file] : []} setFiles={setFile} handleReadFile={handleReadFile} />
                        {/* <DropzoneWrapper>
                            <Dropzone
                                config={componentConfig}
                                eventHandlers={eventHandlers}
                                djsConfig={djsConfig}
                            />
                        </DropzoneWrapper> */}
                    </div>
                </Box>}

                {console.log("Errors: ", error)}

                {error.status && !error.type && <p className="isoHelperText" style={{ marginTop: "0" }}>
                    <span>{error.msg}</span>
                </p>}

                <div style={{ marginBottom: "100px" }}></div>


                {/* Footer */}
                <div style={footerStyle}>
                    {!update ?
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button className="customButton" style={{ ...buttonCss, marginRight: "30px" }} onClick={() => {
                                setImportData({
                                    ...importData,
                                    branch: "",
                                    file: "",
                                    uploadContent: false,
                                });
                                handelClick();
                                console.log("Clicked Cancel");
                            }}>
                                Cancel
                            </Button>
                            <Button className="customButtonActive" style={{ ...buttonCss, background: "#132F44", borderRadius: "8px", color: "#FFFFFF" }} onClick={(e) => handelCreate(e, true)}>
                                Proceed
                            </Button>
                        </div>
                        :
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Button className="customButton" style={{ ...buttonCss, marginRight: "30px" }} onClick={() => {  }}>
                                Cancel
                            </Button>
                            <Button className="customButtonActive" style={{ ...buttonCss, background: "#132F44", borderRadius: "8px", color: "#FFFFFF" }} onClick={() => { }}>
                                Update
                            </Button>
                        </div>
                    }
                </div>

            </ProdContentHold>
        </>
    );
};