import cloudStep3 from '../../../assets/images/cloudStep3.png';
import cloudStep4 from '../../../assets/images/cloudStep4.png';
import cloudStep5 from '../../../assets/images/cloudStep5.png';
import cloudStep6 from '../../../assets/images/cloudStep6.png';
import cloudStep9 from '../../../assets/images/cloudStep9.png';
import cloudStep10 from '../../../assets/images/cloudStep10.png';

export const cloudflareContent = [
    {
        boxTitle: "Step 1",
        boxSubtitle: "Log in to your Cloudflare Account, Navigate to Cloudflare's login page and log in.",
        //image: "Image",
        isOpen: false,
    },
    {
        boxTitle: "Step 2",
        boxSubtitle: "On the top-right, you'll see a dropdown button, please click on that as shown in image",
        //image: "Image",
        isOpen: false,
    },
    {
        boxTitle: "Step 3",
        boxSubtitle: "Click on My Profile, and on the left-hand side, you'll see API Tokens, click on it",
        image: cloudStep3,
        isOpen: false
    },
    {
        boxTitle: "Step 4",
        boxSubtitle: `On API Tokens Page: Click on Create Token you will see a section: "API token templates", under that click on the Use template button beside "Edit zone DNS"`,
        image: cloudStep4,
        isOpen: false
    },
    {
        boxTitle: "Step 5",
        boxSubtitle: "Now, the Permissions section will be auto-filled. Leave it and move to another section",
        image: cloudStep5,
        isOpen: false
    },
    {
        boxTitle: "Step 6",
        boxSubtitle: "Now, select the zone from the third dropdown box in the Zone Resources section.",
        image: cloudStep6,
        isOpen: false
    },
    {
        boxTitle: "Step 7",
        boxSubtitle: "Leave the Client IP Address Filtering section, completely blank and move to the next section.",
        //image: "Image",
        isOpen: false
    },
    {
        boxTitle: "Step 8",
        boxSubtitle: "You may specify the Time To Live(TTL) for the token to be created in the last box under TTL section and move on.",
        //image: "Image",
        isOpen: false
    },
    {
        boxTitle: "Step 9",
        boxSubtitle: "Now, click on the Continue to summary button, and then click on Create Token",
        image: cloudStep9,
        isOpen: false
    },
    {
        boxTitle: "Step 10",
        boxSubtitle: "Copy the Token: Once the token is created, it will be displayed to you exactly once. Make sure you copy it securely somewhere, as you won't be able to see it again for security reasons.",
        image: cloudStep10,
        isOpen: false
    }
];