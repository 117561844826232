import React, { lazy, Suspense, useContext, useEffect } from 'react';
import {
  Route,
  Navigate,
  BrowserRouter as Router,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';
// import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from './components/utility/loader';
import * as Sentry from "@sentry/react";
import NoBoardFounds from './containers/ScrumBoard/Board/BoardNotFound/BoardNotFound';
import { Spin } from 'antd';
import { CustomModal } from './containers/Dashboard/CustomComponents/PageLoader.style';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
// const Dashboard = lazy(() => import('./containers/Pages/TestDashBoard/Test'));

const publicRoutes = [
  // {
  //   path: PUBLIC_ROUTE.LANDING,
  //   exact: true,
  //   component: lazy(() => import('./containers/Dashboard/Dashboard')),
  // },
  // {
  //   path: PUBLIC_ROUTE.PAGE_404,
  //   component: lazy(() => import('./containers/Pages/404/404')),
  // },
  // {
  //   path: PUBLIC_ROUTE.PAGE_500,
  //   component: lazy(() => import('./shared/containers/Pages/500/500')),
  // },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('./containers/Pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import('./containers/Pages/SignUp/SignUp')),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import('./containers/Pages/ForgotPassword/ForgotPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    exact: false,
    component: lazy(() =>
      import('./containers/Pages/ResetPassword/ResetPassword')
    ),
  },
  {
    path: PUBLIC_ROUTE.EMAIL_VERIFICATION,
    component: lazy(() =>
      import('./containers/Pages/EmailVerification/EmailVerification')
    ),
  },
  {
    path: PUBLIC_ROUTE.UNSUBSCRIBE,
    component: lazy(() => 
      import('./containers/Pages/EmailVerification/Unsubscribe')
    )
  },
  {
    path: PUBLIC_ROUTE.TRY_WITHOUT_LOGIN,
    component: lazy(() =>
      import('./containers/Pages/TryWithoutLogin/TryWithoutLogin')
    ),
  },
  // {
  //   path: PUBLIC_ROUTE.AUTH0_CALLBACK,
  //   component: lazy(() =>
  //     import('./containers/Authentication/Auth0/Auth0Callback')
  //   ),
  // },
];

function PrivateRoute({ children }) {
  const storedAuth = localStorage.getItem("isAuth");
  return storedAuth ? children :
    <Navigate to='/signin' />;
}

// function PublicRoute({ children }) {
//   console.log('children :', children.displayName);
//   const storedAuth = localStorage.getItem("isAuth");
//   return storedAuth ? <Navigate to='/dashboard' /> : children;
// }
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default function MyRoutes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={
        <CustomModal
          visible={true}
          //onCancel={hideModal}
          footer={null}
          closable={false}
          centered
          maskClosable={false}
          style={{ border: "none", boxShadow: "none" }}
          maskStyle={{ backgroundColor: "white" }}>
          <div style={{ textAlign: 'center', background: "transparent", border: "none" }}>
            <Spin size="large" />
          </div>
        </CustomModal>}>
        <SentryRoutes>
          {publicRoutes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact} element={<route.component />} />
          ))}
          {/* <PrivateRoute path="/dashboard" element={<Dashboard />} /> */}
          <Route path='/*' element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          } />
        </SentryRoutes>
      </Suspense>
    </ErrorBoundary>
  );
}
