import React, { useState } from 'react';
const ProfileContext = React.createContext();

const ProfileProvider = ({ children }) => {
    const [profileData, set_profileData] = useState({});
    const [clustersData, setClustersData] = useState([]);
    const [projectsData, setProjectsData] = useState([]);

    return (
        <ProfileContext.Provider value={{ profileData, set_profileData, clustersData, setClustersData, projectsData, setProjectsData }}>
            {children}
        </ProfileContext.Provider>
    );
}

export { ProfileContext, ProfileProvider };