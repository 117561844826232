import React, { useContext } from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from './assets/styles/globalStyle';
import { ContextProvider } from './context/ContextProvider';
import { store } from './redux/store';
// import Boot from './redux/boot';
import MyRoutes from './MyRoutes';
import AppProvider from './AppProvider';
import { SideBarProvider } from './context/sidebarContext';
import { AlertProvider } from './context/alertContext';
import "./App.css";
import { ProfileProvider } from './context/profileContext';
import { BreadcrumbProvider } from './context/breadcrumbContext';
import { DeploymentProvider } from './context/deploymentContext';
import { GuardrailsProvider } from './context/guardrailsContext';

const App = () => (
  <>
    {console.log('rendring in app.js...')}
    <ContextProvider>
      <Provider store={store}>
        <AppProvider>
          <SideBarProvider>
            <AlertProvider>
              <ProfileProvider>
                <DeploymentProvider>
                  <GuardrailsProvider>
                    <BreadcrumbProvider>
                      <GlobalStyles />
                      <MyRoutes />
                    </BreadcrumbProvider>
                  </GuardrailsProvider>
                </DeploymentProvider>
              </ProfileProvider>
            </AlertProvider>
          </SideBarProvider>
        </AppProvider>
      </Provider>
    </ContextProvider>
  </>
);
// Boot()
//   .then(() => App())
//   .catch(error => console.error(error));

export default App;
