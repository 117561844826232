import React, { useEffect } from 'react';
import { Form, Field } from 'formik';
import { Row, Col, Tooltip, message } from 'antd';
import {
    AntDatePicker,
    AntSelect,
    AntTextArea,
    AntInput,
} from '@iso/components/ScrumBoard/AntFields';
import Input, {
    InputSearch,
    InputGroup,
    Textarea,
} from '@iso/components/uielements/input';
import Select, { SelectOption } from '@iso/components/uielements/select';
// import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
// import ContentHolder from '@iso/components/utility/contentHolder';
// import { dateFormat } from '@iso/components/ScrumBoard/FieldFormats';
import HeadingWithIcon from '@iso/components/ScrumBoard/HeadingWithIcon/HeadingWithIcon';
import mySqlIcon from '@iso/assets/images/icon/mysql-icon.svg';
import mongoDbIcon from '@iso/assets/images/icon/mongodb-icon-small.png';
import redisIcon from '@iso/assets/images/icon/redis-icon.png';
import s3Icon from '@iso/assets/images/icon/s3-icon.png';
import elasticSearchIcon from '@iso/assets/images/icon/elasticsearch-icon.png';
import CreateTaskHeader from '../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import Dropzone from '@iso/components/uielements/dropzone';
import DropzoneWrapper from '../AdvancedUI/Dropzone/Dropzone.styles';
import Box from '../../components/utility/box';
import { useState } from 'react';
import { AppContext } from '../../context/ContextProvider';
import { useContext } from 'react';
import { useLocation } from 'react-router';
import api from '../../api';
import { formatErrors } from '../../context/helper';
import { AlertContext } from '../../context/alertContext';
import Alert from '@iso/components/Feedback/Alert';
import CreateTaskFooter from '../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import styled from 'styled-components';
import { useRef } from 'react';
import UploadFiles from '../../components/utility/UploadFiles';
import { DeploymentContext } from '../../context/deploymentContext';

const Option = SelectOption;

const marginsBot = {
    position: 'fixed',
    opacity: '100%',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '1000',
    width: "25%",
    padding: '15px'
};

const fadeMarginBot = {
    position: 'fixed',
    opacity: '0',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '-1',
    width: "25%",
    padding: '15px'
};

let boxStyle = {
    border: '0',
    padding: "0",
    width: "100%",
}

let titleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontSize: "16px",
    fontWeight: "500"
}

let subTitleStyle = {
    fontSize: "14px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontType: "regular",
    fontWeight: "400"
}

let inputStyle = {
    padding: '4px 11px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontSize: '14px',
    borderRadius: '6px',
    height: "40px"
}

let textAreaStyle = {
    padding: '4px 11px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontSize: '14px',
    borderRadius: '6px',
    height: "100px"
}

let imgStyle = {
    width: '16px',
    height: '20px',
    marginRight: '10px'
}

let imgs = {
    "MySQL": mySqlIcon,
    "Redis": redisIcon,
    "MongoDB": mongoDbIcon,
    "AWS S3": s3Icon,
    // "RabbitMQ": ,
    // "Kafka": ,
    // "AmazonMQ": ,
}

const HorizontalOr = styled.div`
    &.header__center {
        font-size: 15px;
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        grid-column-gap: 1.2rem;
        align-items: center;
        color: #7C7C7C;
        margin: 50px;
        // margin-inline: 49px;
        margin-inline: 0px;
        margin-right: 100px;
    }

    &.header__center::before, &.header__center::after {
        content: "";
        display: block;
        height: 1px;
        background-color: #F4F4F4;
    }
`

export default ({
    //   handleSubmit,
    data,
    editing,
    columnId,
    values,
    submitCount,
    onCancel,
    onDelete,
    onSave,
    onEditCancel,
}) => {

    let { convertToFrontend, convertToBackend, currentProject, setCurrentProject, openRightDrawer, setOpenRightDrawer, currentColumns, setCurrentColumns, currentTasks, setCurrentTasks } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    let location = useLocation();
    const targetRef = useRef(null);

    const serviceNameRegex = /^[a-z0-9]([a-z0-9-]*[a-z0-9])?(?:\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/;

    const [dataSourceTasks, setDataSourceTasks] = useState(editing ? data : {
        id: "",
        serviceId: "",
        serviceCategory: "",
        columnId: columnId,
        name: "",
        source: "",
        dumpUrl: "",
        version: "",
        editing: false,
        file: "",
        presignedUrl: ""
    });

    const [allDataSources, setAllDataSources] = useState();
    const [file, setFile] = useState(editing ? data.file : null);
    const [jsonData, setJsonData] = useState();
    const [filesList, setFilesList] = useState([]);
    const [fileChanged, setFileChanged] = useState(false);
    const [dataSourceVersions, setDataSourceVersions] = useState();
    const [importData, setImportData] = useState({
        //repo: service ? (service.is_private ? service.repo_name : service.repo_url) : "",
        branch: "",
        file: "",
        uploadContent: false,
    });
    const [errMsg, setErrMsg] = useState("");
    const [fileStatus, setFileStatus] = useState("");
    const { deploymentData, setDeploymentData } = useContext(DeploymentContext);
    const [isNewUpload, setIsNewUpload] = useState(false);
    const [isFileChanged, setIsFileChanged] = useState(false);
    const [isFileDeleted, setIsFileDeleted] = useState(false);
    const shouldGetFileStatus = useRef(true);
    const fileRef = useRef(false);

    const { setSelectedProject } = useContext(DeploymentContext);

    const [error, setError] = useState({
        status: false,
        msg: "Success"
    });

    console.log("Data: ", data);

    // Version list
    // useEffect(() => {
    //     let tempData = [];
    //     console.log("currentProject when selecting dataSource :", currentProject);
    //     console.log("dataSourceTasks when selecting dataSource :", dataSourceTasks);
    //     currentProject.serviceCategories.map((temp, idx) => {
    //         if (temp.name === dataSourceTasks.source) {
    //             temp.version.map((item, id) => {
    //                 tempData.push(<Option key={id} style={{ height: "40px" }} name="version" value={item} >
    //                     <span style={{ display: "flex", justifyContent: "start", alignItems: "center", height: "40px" }}>{item}</span>
    //                 </Option>);
    //             })
    //         }
    //     });
    //     setDataSourceVersions(tempData);
    // }, [dataSourceTasks.source]);


    // DataSources List
    useEffect(() => {
        console.log("currentProject :", currentProject);
        console.log("Data:... ", data);
        let tempData = [];
        currentProject.serviceCategories.map((temp, idx) => {
            if (temp.type === "data_source") {
                console.log("Temp.name: ", temp.name);
                if (temp.name !== "AWS S3") {
                    tempData.push(<Option key={idx} name="source" value={temp.name} data-key={temp.id}>
                        <div style={{ display: "flex", justifyContent: "start", alignItems: "center", height: "40px" }}>
                            <img src={temp.logo} style={{ width: "18px", height: "18px", marginRight: "10px" }} alt="img" />
                            <span>{temp.name}</span>
                        </div>
                    </Option>);
                }
            }
        });

        setAllDataSources(tempData);
    }, []);

    // Auto focus error
    useEffect(() => {
        if (error.status) {
            scrollToElement();
        }
    }, [error.status]);

    useEffect(() => {
        if (editing) {
            if (fileRef.current) {
                setDataSourceTasks({ ...dataSourceTasks, file: file });
                if (location.pathname.includes("/all-projects/project/") && editing) {
                    if ((!data.file && !file) || (data.file && file && data.file === file)) {
                        console.log("useEffect executes: ", isNewUpload, isFileChanged, isFileDeleted);
                        setIsNewUpload(false);
                        setIsFileChanged(false);
                        setIsFileDeleted(false);
                    }
                    else if (!data.file && file) {
                        console.log("useEffect executes: ", isNewUpload, isFileChanged, isFileDeleted);
                        setIsNewUpload(true);
                        setIsFileChanged(false);
                        setIsFileDeleted(false);
                    }
                    else if (file) {
                        console.log("useEffect executes: ", isNewUpload, isFileChanged, isFileDeleted);
                        setIsFileChanged(true);
                        setIsFileDeleted(false);
                        setIsNewUpload(false);
                    }
                    else {
                        console.log("useEffect executes: ", isNewUpload, isFileChanged, isFileDeleted);
                        setIsFileDeleted(true);
                        setIsNewUpload(false);
                        setIsFileChanged(false);
                    }
                }
                //this case is for updating a file in a service when the project has not been created yet. This will generate new s3 url every time even if it was generated previously.
                else {
                    if ((!data.file && !file) || (data.file && file && data.file === file)) {
                        console.log("useEffect executes: ", isNewUpload, isFileChanged, isFileDeleted);
                        setIsNewUpload(false);
                        setIsFileChanged(false);
                        setIsFileDeleted(false);
                    }
                    else {
                        setIsNewUpload(true);
                        setIsFileChanged(false);
                        setIsFileDeleted(false);
                    }
                }
            }
            else {
                fileRef.current = true;
            }
        }
        else {
            if (!data.file && !file) {
                setIsNewUpload(false);
            }
            else {
                setIsNewUpload(true);
            }
        }
    }, [file]);

    useEffect(() => {
        let timeout;
        console.log("Datasource tasks: ", dataSourceTasks);
        function getFileStatus() {
            //if(shouldGetFileStatus.current){
            //if(dataSourceTasks.serviceId && dataSourceTasks.serviceId !== ""){
            api.get(process.env.REACT_APP_API_URI + `/api/project_env/services/${dataSourceTasks.serviceId}/`).then((res) => {
                let data = res.data;
                setDataSourceTasks({
                    ...dataSourceTasks,
                    file: data.data.configuration.s3_key ? {
                        name: data.data.configuration.file_name,
                        uid: data.data.configuration.s3_key,
                        status: 'done',
                        url: data.data.configuration.presigned_url,
                    } : null,
                    presignedUrl: data.data.configuration.presigned_url,
                    s3Key: data.data.configuration.s3_key,
                    dumpUrl: data.data.configuration.dump_url,
                });
                setFile(data.data.configuration?.s3_key ? {
                    name: data.data.configuration.file_name,
                    uid: data.data.configuration.s3_key,
                    status: 'done',
                    url: data.data.configuration.presigned_url
                } : null);
                console.log("Add data: ", data.data.configuration);
                // if(data.data.configuration && data.data.configuration.dump_action){
                //     if(data.data.configuration.dump_action[0].includes("upload_dump_file")){
                //         if(data.data.configuration.dump_action[1] === "fail"){
                //             setErrMsg(`The upload request for the previous file named ${data.data.configuration.dump_action[2]} has failed. Please try uploading another file.`);
                //         }
                //         else if(data.data.configuration.dump_action[1] === "in_progress"){
                //             setErrMsg(`The upload request for the previous file named ${data.data.configuration.dump_action[2]} is in progress.`);
                //         }
                //     }
                //     else if(data.data.configuration.dump_action[0].includes("delete_dump_file")){
                //         if(data.data.configuration.dump_action[1] === "fail"){
                //             setErrMsg(`The deletion request for the previous file named ${data.data.configuration.dump_action[2]} has failed. Please try again.`);
                //         }
                //         else if(data.data.configuration.dump_action[1] === "in_progress"){
                //             setErrMsg(`The deletion request for the previous file named ${data.data.configuration.dump_action[2]} is in progress.`);
                //         }
                //     }
                // }
                // if(data.data.configuration.dump_action[1] !== "in_progress"){
                //     shouldGetFileStatus.current = false;
                // }
                //timeout = setTimeout(getFileStatus, 5000);
            });
            //}
            //}
        }
        //in case of updating existing service of the existing project
        if (location.pathname.includes("/all-projects/project/") && editing) {
            getFileStatus();
        }
        // return (() => {
        //     clearTimeout(timeout);
        // });
    }, []);

    const handleReadFile = (file) => {
        const reader = new FileReader();
        console.log("Files... :", file);

        reader.onload = (e) => {
            try {
                // console.log("e.target :", e.target);
                console.log("e.target.result :", e.target.result);
                setJsonData(e.target.result);
                setImportData({ ...importData, uploadContent: true });
                message.success(`${file.name} uploaded successfully. Please proceed.`);
            } catch (error) {
                console.error('Error parsing .env file:', error);
            }
        };

        if (file) {
            console.log("File path: ", file);
            setFile(file);
            // setFile({
            //     ...file,
            //     uid: file.uid,
            //     name: file.name,
            //     status: 'done',
            // });
            //setDataSourceTasks({...dataSourceTasks, file: file});
            setError({
                status: false,
                msg: ""
            });
            reader.readAsText(file);
        }
    };

    const handelRemoveFile = () => {
        console.log("handelRemoveFile :", file);
        eventHandlers.removedfile();
    }

    const componentConfig = {
        iconFiletypes: ['.env', '.env.sample'],   // ['.jpg', '.png', '.gif']
        method: true,
        showFiletypeIcon: false,
        uploadMultiple: false,
        maxFiles: 1,
        maxFilesize: 2, // MB
        dictMaxFilesExceeded: 'You can only upload upto 1 image',
        dictRemoveFile: 'Delete',
        dictCancelUploadConfirmation: 'Are you sure to cancel upload?',
        postUrl: 'no-url',
    };

    const djsConfig = {
        acceptedFiles: ['.env', '.env.sample'],
        autoProcessQueue: false,
        maxFiles: 1,
        uploadMultiple: true,
        // previewTemplate: (
        //     <div>
        //         <p>Selected File</p>
        //         {/* <button onClick={() => setFile(null)}>Remove File</button> */}
        //         </div>
        //   )
        // previewTemplate: ReactDOMServer.renderToStaticMarkup(
        //     <div className="dz-preview dz-file-preview">
        //       <div className="dz-details">
        //         <div className="dz-filename"><span data-dz-name="true"></span></div>
        //         <img data-dz-thumbnail="true" />
        //       </div>
        //       <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
        //       <div className="dz-success-mark"><span>✔</span></div>
        //       <div className="dz-error-mark"><span>✘</span></div>
        //       <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
        //     </div>
        // )
    };

    const eventHandlers = {
        onload: (newFile) => {
            console.log("File load: ", newFile);
        },
        addedfile: (newFile) => {
            setFile(newFile);
            console.log("file in addedfile :", newFile);
            handleReadFile(newFile);
        },
        success: (newFile) => {
            console.log("newFile in success :", newFile);
            toggleAlert("success", "Success", `${newFile.name} successfully uploaded`);
        },
        error: (error) => {
            console.log("error :", error);
            toggleAlert("error", "Error", "Error in uploading File");
        },
        removedfile: (newFile) => {
            console.log("newFile in removedfile :", newFile);
            setFile(null);
        }
    };


    const handelClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
                setData: "",
                columnId: "",
                tabSelected: "",
            },
        });
    }

    const getServiceCategoryId = (name) => {
        let serviceCategoryId = null;
        currentProject.serviceCategories.map((temp, idx) => {
            if (temp.name.toLowerCase() === name.toLowerCase()) {
                serviceCategoryId = temp.id
            }
        });

        console.log('serviceCategoryId :', serviceCategoryId);
        return serviceCategoryId;
    }

    const saveService = (presignedUrlInfo) => {
        let conf = { ...dataSourceTasks };
        conf["is_file"] = file ? true : false;
        conf["file_name"] = file ? /*conf.name + '-' +*/ file.name : "";
        console.log("Filessss: ", file);
        //conf["dump_url"] = conf.dumpUrl;
        conf["s3_key"] = presignedUrlInfo.fields ? presignedUrlInfo.fields.key : null; // Will be handled later
        conf["dump_url"] = conf.dumpUrl ? conf.dumpUrl : null;
        let myFile = file ? {
            file: file,
            serviceName: conf.name
        } : {};
        delete conf.file;
        delete conf.dumpUrl;
        delete conf.columnId;
        delete conf.editing;
        delete conf.is_file;
        delete conf.source;
        delete conf.id;
        let payload = {
            "name": dataSourceTasks.name,
            "description": null,
            // "service_type": convertToBackend[columnId],
            "service_category": getServiceCategoryId(dataSourceTasks.source),
            "service_icon": null,
            "config": { ...conf },
            "project": currentProject.id
        }

        delete payload.config.name;
        delete payload.config.serviceId;
        delete payload.config.icon;
        delete payload.config.title;
        delete payload.config.serviceCategory;
        delete payload.config.version;
        delete payload.config.created_at;
        delete payload.config.updated_at;
        console.log('payload in updateService :', payload);
        // const formData = new FormData();
        // formData.append('data', JSON.stringify(payload));
        // if (myFile.file) {
        //     formData.append(/*myFile.serviceName + '-' +*/ myFile.file.name, new File([myFile.file], myFile.file.name, { type: 'application/sql,application/gzip' }));
        // }
        api.post(`${process.env.REACT_APP_API_URI}/api/project_env/services/`, payload, /*{
            'Content-Type': 'multipart/form-data',
        }*/)
            .then((res) => {
                let data = res.data;
                console.log("create dataSource data :", data);
                if (data.status) {
                    // currentProject.tasks.forEach((temp, idx) => {
                    //     if (temp && (temp.serviceId === dataSourceTasks.serviceId)) {
                    //         currentProject.tasks[idx].file = {...currentProject.tasks[idx].file, url: data.data.configuration.dumps[0].presigned_url};
                    //     }
                    // });
                    // update column
                    currentProject.columns[columnId].task_orders.push(currentProject.tasks.length);
                    // update tasks
                    currentProject.tasks.push({
                        id: currentProject.tasks.length,
                        name: data.data.name,
                        title: data.data.name,
                        columnId: convertToFrontend[data.data.type],
                        serviceCategory: data.data.service_category,
                        source: currentProject.serviceCategories.find((val) => {
                            return val.id === data.data.service_category;
                        }).name,
                        file: file,
                        s3Key: presignedUrlInfo.fields.key,
                        presignedUrl: presignedUrlInfo.url,
                        serviceId: data.data.id,
                        ...data.data.configuration
                    });

                    setCurrentProject({ ...currentProject, columns: currentProject.columns, tasks: [...currentProject.tasks] });
                    localStorage.setItem("Project", JSON.stringify({ ...currentProject, columns: currentProject.columns, tasks: [...currentProject.tasks] }));
                    let depData = JSON.parse(localStorage.getItem("deploymentData"));
                    if (depData && depData.projectName === currentProject.name) {
                        setDeploymentData({ ...depData, projectId: 0, projectName: "" });
                        setSelectedProject({});
                        localStorage.removeItem("selectedProject");
                        localStorage.setItem("deploymentData", JSON.stringify({ ...depData, projectId: 0, projectName: "" }));
                    }
                    handelClick();
                    toggleAlert("success", "Success", "Successfully saved service");
                }
                else {
                    let err = formatErrors(data.error_details);
                    console.log("Error :", err);
                    toggleAlert("error", "Error", err);
                }
            })
            .catch((err) => {
                console.log("err :", err);
                toggleAlert("error", "Error", "Something went wrong. Please try again.");
            });
    }

    const saveTasks = (presignedUrlInfo) => {
        console.log("Presigned url: ", presignedUrlInfo, file);
        let myFile = file ? {
            file: file,
            //serviceName: conf.name
        } : {};
        let id = currentProject.tasks.length;
        dataSourceTasks.id = id;

        // update column
        currentProject.columns[columnId].task_orders.push(id);
        // update tasks
        currentProject.tasks.push({ ...dataSourceTasks, presignedUrl: presignedUrlInfo?.url, s3Key: presignedUrlInfo?.fields?.key, file: file });

        setCurrentProject({ ...currentProject, columns: { ...currentProject.columns }, tasks: currentProject.tasks });
        localStorage.setItem("Project", JSON.stringify({ ...currentProject, columns: { ...currentProject.columns }, tasks: currentProject.tasks }));
        handelClick();

    }

    const updateTasks = (presignedUrlInfo) => {
        let myFile = file ? {
            file: file,
            //serviceName: conf.name
        } : {};
        currentProject.tasks.forEach((temp, idx) => {
            if (temp && (temp.id === dataSourceTasks.id)) {
                currentProject.tasks[idx] = { ...dataSourceTasks, presignedUrl: presignedUrlInfo?.url, s3Key: presignedUrlInfo?.fields?.key, file: file };
            }
        });
        console.log('currentProject :', currentProject);
        setCurrentProject({ ...currentProject });
        localStorage.setItem("Project", JSON.stringify({ ...currentProject }));
        handelClick();
    }

    const generateS3Url = () => {
        console.log("File status: ", isFileChanged, isFileDeleted, isNewUpload);
        //initial case if the service was updated without any file.
        if (!isFileChanged && !isFileDeleted && !isNewUpload) {
            let presignedUrlInfo = {
                url: dataSourceTasks.presignedUrl,
                fields: {
                    key: dataSourceTasks.s3Key ? dataSourceTasks.s3Key : null
                }
            };
            console.log("Presigned info: ", presignedUrlInfo);
            if (editing) {
                if (location.pathname.includes("/all-projects/project/")) {
                    updateService(presignedUrlInfo);
                }
                else {
                    updateTasks(presignedUrlInfo);
                }
            }
            else {
                if (location.pathname.includes("/all-projects/project/")) {
                    saveService(presignedUrlInfo);
                }
                else {
                    saveTasks(presignedUrlInfo);
                }
            }
            // if (editing) {
            //     console.log("Datasource task: ", dataSourceTasks);
            //     handelUpdateTask(presignedUrlInfo); //will modify it later
            // }
            // else {
            //     handelSaveTasks(presignedUrlInfo); //will modify it later
            // }
        }
        else {
            //case when the existing file was replaced
            if (isFileChanged) {
                api.get(process.env.REACT_APP_API_URI + `/api/project_env/generate-pre-signed-url/${dataSourceTasks.serviceId}/replace`).then((res) => {
                    let data = res.data;
                    let url = data.data.pre_signed_url.url;
                    console.log("URL: ", url);
                    setDataSourceTasks({ ...dataSourceTasks, presignedUrl: url, file: file });
                    uploadFileToUrl(data.data.pre_signed_url);
                }).catch((err) => {
                    console.log("Upload failed", err);
                });
            }
            //case when the new file gets uploaded
            else if (isNewUpload) {
                let url = new URL(process.env.REACT_APP_API_URI + `/api/project_env/generate-pre-signed-url/`);
                let payload = {
                    file_type: dataSourceTasks.source === "MongoDB" ? ".gzip" : ".sql"
                }
                Object.keys(payload).forEach(key => url.searchParams.append(key, payload[key]));
                api.get(url, {
                    headers: {
                        'Content-Type': 'application/sql,application/gzip'
                    }
                }).then((res) => {
                    let data = res.data;
                    let url = data.data.pre_signed_url.url;
                    console.log("New upload: ", url);
                    setDataSourceTasks({ ...dataSourceTasks, presignedUrl: url, file: file });
                    uploadFileToUrl(data.data.pre_signed_url);
                }).catch((err) => {
                    console.log("Upload failed", err);
                });
            }
            //case when we delete a particular file
            else if (isFileDeleted) {
                api.get(process.env.REACT_APP_API_URI + `/api/project_env/generate-pre-signed-url/${dataSourceTasks.serviceId}/delete`).then((res) => {
                    let data = res.data;
                    let url = data.data.pre_signed_url;
                    console.log("URL: ", url);
                    setDataSourceTasks({ ...dataSourceTasks, presignedUrl: url, file: file });
                    uploadFileToUrl(url);
                }).catch((err) => {
                    console.log("Upload failed");
                });
            }
        }
    }

    const uploadFileToUrl = (presignedUrlInfo) => {
        if (!isFileDeleted) {
            console.log("Presigned info: ", presignedUrlInfo);
            let conf = { ...dataSourceTasks };
            let myFile = file ? {
                file: file,
                serviceName: conf.name
            } : {};
            let formData = new FormData();
            let files = new FormData();
            const data = presignedUrlInfo.fields;
            files.append('file', myFile.file/*new File([myFile.file], myFile.file.name, { type: 'application/sql,application/gzip' })*/);

            // const fileReader = new FileReader();
            // console.log("Before accessing file: ", myFile.file);
            // fileReader.onload = function (event) {
            //     console.log("myfile res: ", event.target.result); // Log the file content
            // };
            // fileReader.readAsText(myFile.file);
            for (const key of files.keys()) {
                data[key] = files.get(key);
            }
            for (const key in data) {
                formData.append(key, data[key]);
            }
            fetch(presignedUrlInfo.url, {
                method: 'POST',
                body: formData,
                // headers: {
                //     'Content-Type': 'multipart/form-data',
                // }
            })
                .then(response => {
                    console.log("Response: ", response);
                    if (response.status === 204) {
                        console.log("File uploaded successfully.");
                        if (editing) {
                            if (location.pathname.includes("/all-projects/project/")) {
                                updateService(presignedUrlInfo);
                            }
                            else {
                                updateTasks(presignedUrlInfo);
                            }
                        }
                        else {
                            if (location.pathname.includes("/all-projects/project/")) {
                                saveService(presignedUrlInfo);
                            }
                            else {
                                saveTasks(presignedUrlInfo);
                            }
                        }
                    } else {
                        console.log(`Failed to upload file. Status code: ${response.status}`);
                        return response.text();
                    }
                })
                .then(data => {
                    if (data) {
                        console.log("Response:", data);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else {
            if (editing) {
                if (location.pathname.includes("/all-projects/project/")) {
                    updateService(presignedUrlInfo);
                }
                else {
                    updateTasks(presignedUrlInfo);
                }
            }
            else {
                if (location.pathname.includes("/all-projects/project/")) {
                    saveService(presignedUrlInfo);
                }
                else {
                    handelSaveTasks(presignedUrlInfo);
                }
            }
        }
    }

    const updateService = (presignedUrlInfo) => {
        let conf = { ...dataSourceTasks };
        console.log("Data source tasks: ", dataSourceTasks);
        console.log("Dump url: ", conf);
        //conf["is_file"] = conf.file ? true : false;
        conf["file_name"] = file ? /*conf.name + '-' +*/ file.name : "";
        //conf["is_file_change"] = fileChanged;
        conf["s3_key"] = presignedUrlInfo?.fields?.key ? presignedUrlInfo.fields.key : null; // Will be handled later
        conf["dump_url"] = conf.dumpUrl ? conf.dumpUrl : null;
        //conf["presigned_url"] = conf.presignedUrl ? conf.presignedUrl : null;
        // let myFile = conf.file ? {
        //     file: conf.file,
        //     serviceName: conf.name
        // } : {};
        delete conf.file;
        delete conf.dumpUrl;
        delete conf.dumps;
        delete conf.dump_action;
        delete conf.source;
        delete conf.s3Key;
        let payload = {
            "name": dataSourceTasks.name,
            // "service_type": convertToBackend[dataSourceTasks.columnId],
            "service_category": getServiceCategoryId(dataSourceTasks.source),
            "description": dataSourceTasks.description,
            "config": { ...conf }
        }

        delete payload.config.id;
        delete payload.config.name;
        delete payload.config.columnId;
        delete payload.config.description;
        delete payload.config.serviceId;
        delete payload.config.serviceCategory;
        delete payload.config.service_category;
        delete payload.config.created_at;
        delete payload.config.updated_at;
        delete payload.config.presigned_url;

        if (isFileDeleted) {
            payload.config.s3_key = null;
        }

        console.log('payload in updateService :', payload);

        // const formData = new FormData();
        // formData.append('data', JSON.stringify(payload));
        // if(myFile.file){
        //     formData.append(myFile.serviceName + '-' + myFile.file.name, new File([myFile.file], myFile.file.name, { type: 'application/sql,application/json,application/bson' }));
        // }
        api.patch(`${process.env.REACT_APP_API_URI}/api/project_env/services/${dataSourceTasks.serviceId}/`, payload,/* {
            'Content-Type': 'multipart/form-data',
        }*/)
            .then((res) => {
                let data = res.data;
                console.log("update in app data :", data);
                if (data.status) {
                    currentProject.tasks.forEach((temp, idx) => {
                        if (temp && (temp.serviceId === dataSourceTasks.serviceId)) {
                            currentProject.tasks[idx] = { ...dataSourceTasks, presignedUrl: presignedUrlInfo.url, s3Key: presignedUrlInfo?.fields?.key };
                            //currentProject.tasks[idx].file = {...currentProject.tasks[idx].file, url: data.data.configuration.dumps[0].presigned_url};
                        }
                    });
                    console.log('currentProject :', currentProject);
                    setCurrentProject({ ...currentProject });
                    localStorage.setItem("Project", JSON.stringify({ ...currentProject }));
                    let depData = JSON.parse(localStorage.getItem("deploymentData"));
                    if (depData && depData.projectName === currentProject.name) {
                        setDeploymentData({ ...depData, projectId: 0, projectName: "" });
                        setSelectedProject({});
                        localStorage.removeItem("selectedProject");
                        localStorage.setItem("deploymentData", JSON.stringify({ ...depData, projectId: 0, projectName: "" }));
                    }
                    handelClick();
                    toggleAlert("success", "Success", "Successfully updated service.");
                }
                else {
                    let err = formatErrors(data.error_details);
                    console.log("Error :", err);
                    toggleAlert("error", "Error", err);
                }
            })
            .catch((err) => {
                console.log("err :", err);
                toggleAlert("error", "Error", "Something went wrong. Please try again.");
            });
    }

    const handelSaveTasks = () => {
        console.log('save dataSourceTasks :', dataSourceTasks);
        let listOfName = [];
        currentProject.tasks.map((temp, idx) => {
            temp && listOfName.push(temp.name);
        });

        if (!dataSourceTasks.name || !dataSourceTasks.source) {
            setError({
                status: true,
                msg: "Please fill all required field marked with *"
            });
        }
        else if (!serviceNameRegex.test(dataSourceTasks.name)) {
            console.log("Data Source Name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character")
            setError({
                status: true,
                msg: "Data Source Name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character"
            });
        }
        else if (listOfName.includes(dataSourceTasks.name)) {
            setError({
                status: true,
                msg: `Name already exists. Please try with different name.`
            });
        }
        else if (dataSourceTasks.name.startsWith(" ") || dataSourceTasks.name.endsWith(" ")) {
            setError({
                status: true,
                msg: `Please remove any leading or trailing spaces from Data Source Name.`
            });
        }
        else if (location.pathname.includes("/all-projects/project/")) {
            if (location.pathname.includes("/all-projects/project/")) {
                generateS3Url();
            }
            else {
                generateS3Url();
            }
        }
        else {
            if (location.pathname.includes("/all-projects/project/")) {
                generateS3Url();
            }
            else {
                generateS3Url();
            }
        }
    }

    const handelUpdateTask = () => {
        console.log('save dataSourceTasks :', dataSourceTasks, dataSourceTasks.file);
        let listOfName = [];
        currentProject.tasks.map((temp, idx) => {
            if (temp && temp.id != dataSourceTasks.id) {
                listOfName.push(temp.name);
            }
        });

        if (!dataSourceTasks.name || !dataSourceTasks.source) {
            setError({
                status: true,
                msg: "Please fill all required field marked with *"
            });
        }
        else if (!serviceNameRegex.test(dataSourceTasks.name)) {
            console.log("Data Source Name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character")
            setError({
                status: true,
                msg: "Data Source Name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character"
            });
        }
        else if (listOfName.includes(dataSourceTasks.name)) {
            setError({
                status: true,
                msg: `Name already exists. Please try with different name.`
            });
        }
        else if (dataSourceTasks.name.startsWith(" ") || dataSourceTasks.name.endsWith(" ")) {
            setError({
                status: true,
                msg: `Please remove any leading or trailing spaces from Data Source Name.`
            });
        }
        else if (location.pathname.includes("/all-projects/project/")) {
            generateS3Url();
            //updateService(presignedUrl);
        }
        else {
            generateS3Url();
            //updateTasks(presignedUrl);
        }
    }

    let myTitle = (title, required = true) => (
        <div>
            {required && <span style={{ marginRight: "2px", color: "red" }}>*</span>}
            {/* <img src={TitleIcon} style={imgStyle} alt="image" /> */}
            <span>{title}</span>
        </div>
    );

    const handelChange = (e) => {
        // console.log('e.target.value :', e.target.value);
        // https://github.com/Gautam-SquareOps/test.git
        setDataSourceTasks({ ...dataSourceTasks, [e.target.name]: e.target.value });
        setError({
            status: false,
            msg: ""
        });
    }

    const handleSelectChange = (value, event) => {
        // myClusterData.prod[event.name] = value;
        console.log("event :", event);
        console.log("value :", value);

        setDataSourceTasks({ ...dataSourceTasks, [event.name]: value });
        setError({
            status: false,
            msg: ""
        });
    }


    function toggleAlert(type, message, description) {
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        // opacity=0 after 10sec
        setTimeout(() => {
            set_alertOptions(alertOptions => ({
                status: false,
                type: type,
                message: message,
                description: description,
            }));
        }, 5 * 1000);
    }

    const scrollToElement = () => {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            {/* <Alert
                message={alertOptions.message}
                description={alertOptions.description}
                type={alertOptions.type}
                showIcon
                style={alertOptions.status ? marginsBot : fadeMarginBot}
            /> */}

            <Form style={{ marginTop: "100px" }}>
                <CreateTaskHeader
                    buttonContent="Data Sources"
                    editing={editing}
                    values={values}
                    onCancel={onCancel}
                    onDelete={onDelete}
                    onEditCancel={onEditCancel}
                    onSave={handelSaveTasks}
                    onUpdate={handelUpdateTask}
                />

                <div style={{ padding: "0 20px" }}>
                    <Box
                        title={"Data Source*"}
                        subtitle={"Select the data source required for the application service"}
                        style={boxStyle}
                        titleStyle={titleStyle}
                        subTitleStyle={subTitleStyle}
                        imgStyle={imgStyle}
                    >
                        <Select
                            style={{ width: '100%', height: "40px" }}
                            defaultValue={dataSourceTasks.source}
                            onChange={handleSelectChange}
                            className="customSelectDataSource"
                            placeholder="Select"
                        >
                            {allDataSources}
                        </Select>
                    </Box>

                    {dataSourceTasks.source && <Box
                        title={"Data Source Name*"}
                        subtitle={"Provide a name for the selected data source"}
                        style={boxStyle}
                        titleStyle={titleStyle}
                        subTitleStyle={subTitleStyle}
                        imgStyle={imgStyle}
                    >
                        <Input
                            name="name"
                            value={dataSourceTasks.name}
                            onChange={handelChange}
                            placeholder="Enter"
                            style={inputStyle}
                        />
                    </Box>}

                    {["MySQL", "MongoDB", "Postgres"].includes(dataSourceTasks.source) && <>
                        <Box
                            title={"S3 URL"}
                            subtitle={"Enter the S3 URL where the database dump is stored. This dump will be restored when creating the environment."}
                            style={{ ...boxStyle, opacity: file && Object.keys(file).length === 0 ? "0.7" : "1", pointerEvents: file && Object.keys(file).length === 0 ? "none" : "" }}
                            titleStyle={titleStyle}
                            subTitleStyle={subTitleStyle}
                            imgStyle={imgStyle}
                        >
                            <Input
                                name="dumpUrl"
                                value={dataSourceTasks.dumpUrl}
                                onChange={handelChange}
                                placeholder="Write here"
                                style={inputStyle}
                            />
                        </Box>

                        <HorizontalOr className='header__center'> or </HorizontalOr>
                    </>}

                    {["MySQL", "MongoDB", "Postgres"].includes(dataSourceTasks.source) && <>
                        <Box
                            title="Upload File here"
                            // subTitle="Only .env files are allowed. Please check your file type properly"
                            titleStyle={titleStyle}
                            style={{ ...boxStyle, opacity: dataSourceTasks.dumpUrl && dataSourceTasks.dumpUrl !== "" ? "0.7" : "1", pointerEvents: dataSourceTasks.dumpUrl && dataSourceTasks.dumpUrl !== "" ? "none" : "" }}
                        >
                            {/* {dataSourceTasks.dump_action && dataSourceTasks.dump_action.length > 0 && (dataSourceTasks.dump_action[1] === "fail" || dataSourceTasks.dump_action[1] === "in_progress") && (dataSourceTasks.dump_action[0].includes("upload_dump_file") || dataSourceTasks.dump_action[0].includes("delete_dump_file")) && <Alert message={errMsg} type={dataSourceTasks.dump_action[1] === "fail" ? "error" : "info"} showIcon style={{margin: "20px 0"}}/>} */}
                            <div style={{ padding: "10px 0",/* opacity: (dataSourceTasks.dump_action && dataSourceTasks.dump_action.length > 0 && dataSourceTasks.dump_action[1] === "in_progress" && (dataSourceTasks.dump_action[0].includes("upload_dump_file") || dataSourceTasks.dump_action[0].includes("delete_dump_file"))) ? "0.5" : "1", pointerEvents: (dataSourceTasks.dump_action && dataSourceTasks.dump_action.length > 0 && dataSourceTasks.dump_action[1] === "in_progress" && (dataSourceTasks.dump_action[0].includes("upload_dump_file") || dataSourceTasks.dump_action[0].includes("delete_dump_file"))) ? "none" : ""*/ }}>
                                <UploadFiles editing={editing} types={dataSourceTasks.source === "MySQL" ? ['.sql'] : dataSourceTasks.source === "MongoDB" ? ['.gzip'] : ['.sql']} setFileChanged={setFileChanged} files={file ? [file] : []} setFiles={setFile} handleReadFile={handleReadFile} />
                            </div>
                        </Box>
                    </>}

                    {/* {dataSourceTasks.source && !["AWS S3"].includes(dataSourceTasks.source) && <Box
                        title={"Version*"}
                        style={boxStyle}
                        titleStyle={titleStyle}
                        imgStyle={imgStyle}
                    >
                        <Select
                            style={{ width: '100%', height: "40px" }}
                            defaultValue={dataSourceTasks.version}
                            onChange={handleSelectChange}
                            className="customSelectDataSource"
                            placeholder="Please select a version"
                        >
                            {dataSourceVersions}
                        </Select>
                    </Box>} */}

                    <Box
                        style={{ ...boxStyle, marginBottom: "140px" }}
                    >
                        {error.status && <p ref={targetRef} className="isoHelperText">
                            <span>{error.msg}</span>
                        </p>}
                    </Box>

                </div>

                <CreateTaskFooter buttonContent={"Data Sources"} onCancel={onCancel} editing={editing} onSave={handelSaveTasks} onUpdate={handelUpdateTask} onDelete={onDelete} />
            </Form>
        </>
    )
};
