import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip, Form, Input, Switch } from 'antd';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import parse from "html-react-parser";
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
import blueTriangleWorkflow from '@iso/assets/images/blueTriangleWorkflow.svg';
import awsLogo from '../../../assets/images/aws.svg';
import { marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import dragIcon from '@iso/assets/images/drag-icon.svg';
import deleteIcon from '@iso/assets/images/deleteIcon.svg';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import ScrollableFeed from 'react-scrollable-feed';
import CodeViewGeneral from '../../Clusters/Task/CodeViewGeneral';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';


let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let innerSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9B9B9B",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
}

const blueTriangleStyle = {
    position: "absolute",
    right: "2.5%",
    top: "40%",
}

const widgetStyle = {
    margin: "40px 20px",
    width: "inherit",
};

const inputStyle = {
    height: "44px"
}

let boxTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    marginBottom: "1px",
};

let boxSubtitleStyle = {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

let failureButtonsStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 0 9px",
    padding: "10px 57px",
    borderRadius: "8px",
    border: "solid 1px #132f44",
}


export default ({
    data,
    openDrawer,
    columnId,
    selectedIndex,
}) => {

    const { openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, workflowNodeData, setWorkflowNodeData, workflowEdgesData, setWorkflowEdgesData, currentWorkflowData, setCurrentWorkflowData } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const navigate = useNavigate();
    const location = useLocation();
    const [form] = Form.useForm();

    const handelClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    const [toolsData, setToolsData] = useState(data);
    const [toolSelected, setToolSelected] = useState(selectedIndex);
    const [allTools, setAllTools] = useState([
        { 
            toolName: "Secret Detection",
            img: "",
            description: "This tool scans your source code and configuration files for sensitive information such as API keys, passwords, and other secrets that may have been inadvertently committed. By identifying and flagging these secrets early in the workflow, it helps to prevent security breaches and ensures that sensitive data is not exposed.",
            secretDetectionTrigger: false,
            onTop: false,
            order: 0
        },
        // { toolName: "Trivy", img: "", description: "Click to configure", maxCriticalVulnerability: "", maxHighVulnerability: "", onTop: false, order: 1 },
        // {name: "Tool 3", img: "", description: "Click to configure", onTop: false, order: 2}
    ]);

    useEffect(() => {
        console.log("selectedIndex :", selectedIndex);
        console.log("currentWorkflowData :", currentWorkflowData);
        // allTools[selectedIndex].onTop = true;
        // setAllTools([...allTools]);
        
        if(currentWorkflowData && currentWorkflowData.sourceConfig.tools.length > 0 && currentWorkflowData.sourceConfig.tools.length < 2){
            let tempAllTools = [];
            currentWorkflowData.sourceConfig.tools.map((temp, ind) => {
                allTools.map((item, idx) => {
                    if(selectedIndex === idx){
                        if(item.toolName === temp.toolName){
                            tempAllTools.push(temp);
                        }
                        else{
                            item.onTop = true;
                            tempAllTools.push(item);
                        }
                    }
                    else{
                        if(item.toolName === temp.toolName){
                            tempAllTools.push(temp);
                        }
                        else{
                            tempAllTools.push(item);
                        }
                    }

                    // if(item.toolName != temp.toolName){
                    //     if(selectedIndex === idx){
                    //         item.onTop = true;
                    //         tempAllTools.push(item);
                    //     }
                    // }
                    // else{
                    //     tempAllTools.push(temp);
                    // }
                });
            });
            console.log("tempAllTools :", tempAllTools);
            setAllTools([...tempAllTools]);
        }
        else if(currentWorkflowData && currentWorkflowData.sourceConfig.tools.length === 0 ){
            allTools[selectedIndex].onTop = true;
            // currentWorkflowData.sourceConfig.tools = allTools;
            setAllTools([...allTools]);
        }
        else{
            setAllTools([...currentWorkflowData?.sourceConfig.tools]);
        }
    }, []);

    function toggleAlert(type, message, description, hide = true, time = 5) {
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        if (hide) {
            // opacity=0 after 10sec
            setTimeout(() => {
                set_alertOptions(alertOptions => ({
                    status: false,
                    type: type,
                    message: message,
                    description: description,
                }));
            }, time * 1000);
        }
    }

    const handleSaveDetails = (e) => {
        console.log("clicked handleSaveDetails :", toolSelected, toolsData);
        if(toolsData.preBuildToolsData.length > 0 && toolsData.preBuildToolsData[toolSelected].secretDetectionTrigger){
            let tempSelectedTools = allTools.filter((temp) => temp.onTop);
            console.log("tempSelectedTools :", tempSelectedTools);

            setWorkflowData((prevData) => ({
                ...prevData,
                preBuildToolsData: tempSelectedTools,
            }));
    
            currentWorkflowData.sourceConfig.tools = [...tempSelectedTools];
            setCurrentWorkflowData({...currentWorkflowData});
            localStorage.setItem("Workflow", JSON.stringify({...currentWorkflowData}));
        }
        else{
            setWorkflowData((prevData) => ({
                ...prevData,
                preBuildToolsData: [],
            }));
    
            currentWorkflowData.sourceConfig.tools = [];
            setCurrentWorkflowData({...currentWorkflowData});
            localStorage.setItem("Workflow", JSON.stringify({...currentWorkflowData}));
        }

        handelClick();
    }

    const handleChecked = (value) => {
        // toolsData?.preBuildToolsData?.[toolSelected]?.secretDetectionTrigger
        console.log("inside handleChecked value :", value);
        // set value
        allTools[toolSelected].secretDetectionTrigger = value;
        console.log("inside handleChecked allTools :", allTools);

        let tempSelectedTools = allTools.filter((temp) => temp.onTop);
        console.log("inside handleChecked tempSelectedTools :", tempSelectedTools);

        setToolsData((prev) => ({
            ...prev,
            preBuildToolsData: tempSelectedTools,
        }));

        setAllTools([...allTools]);
        // currentWorkflowData.sourceConfig.tools = [...tempSelectedTools];
        // setCurrentWorkflowData([...allTools]);
    }


    const handleCancel = (e) => {
        console.log("clicked handleCancel...");
        handelClick();
    }

    const handelInputChange = (e) => {
        console.log("clicked handelInputChange e.target :", e.target);
        // set value
        allTools[toolSelected][e.target.name] = e.target.value;
        console.log("inside handleChecked allTools :", allTools);

        let tempSelectedTools = allTools.filter((temp) => temp.onTop);
        console.log("inside handleChecked tempSelectedTools :", tempSelectedTools);

        setToolsData((prev) => ({
            ...prev,
            preBuildToolsData: tempSelectedTools,
        }));
        setAllTools([...allTools]);
    }

    const handleUnselectedTools = (e) => {
        console.log("clicked handleUnselectedTools :", e.target);
        console.log("clicked handleUnselectedTools e.target.id :", e.target.id);

        if (e.target?.id) {
            allTools[e.target.id].onTop = true;
            setToolSelected(parseInt(e.target.id));
            setAllTools([...allTools]);
        }
    }

    const handleSelectedTools = (e) => {
        e.stopPropagation();
        console.log("clicked handleSelectedTools :", e.target);
        console.log("clicked handleSelectedTools e.target.id :", e.target.id);

        if (e.target?.id) {
            allTools[e.target.id].onTop = false;
            allTools.sort((a, b) => a.onTop ? -1 : 1);
            setAllTools([...allTools]);
            setToolSelected(allTools.length >= 1 ? 0 : null);
        }
    }

    const showSelectTool = (e) => {
        console.log("clicked showSelectTool :", e.target);
        console.log("clicked showSelectTool e.target.id :", e.target.id);

        if (e.target?.id) {
            setToolSelected(parseInt(e.target.id));
            setAllTools([...allTools]);
        }
    }

    const handleDragTools = (e) => {
        console.log("clicked handleDragTools :", e.target);
        console.log("clicked handleDragTools e.target.id :", e.target.id);

        if (e.target?.id) {
            // setToolSelected(parseInt(e.target.id));
            // setAllTools([...allTools]);
        }
    }



    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0", height: "100%" }}>
            <CreateTaskHeader buttonContent={columnId} onCancel={handelClick} />

            <div style={{ marginTop: "84px", width: "100%" }} />

            {/* Left Column */}
            <Col lg={8} md={8} sm={8} xs={8} style={{ borderRight: "1px solid #ebebeb", height: "inherit" }}>
                {allTools.filter((item) => item.onTop).length > 0 && <Box
                    title="Configure tools for pipeline"
                    subtitle="Drag and drop to add tools in the flow"
                    titleStyle={{ ...innerTitleStyle, lineHeight: "20px" }}
                    subTitleStyle={innerSubtitleStyle}
                    style={{ border: 0, padding: "0px 20px", margin: 0, marginTop: "40px", height: "fit-content" }}
                />}
                {/* {console.log("toolSelected :", toolSelected)} */}
                {allTools.map((temp, idx) => (temp.onTop &&
                    <div key={idx} id={idx} style={{ position: "relative" }} onClick={showSelectTool}>
                        <StickerWidgetWrapper id={idx} className="isoStickerWidget" style={{ marginTop: "16px", marginLeft: "22px", width: "250px", position: "relative" }}>
                            <Box id={idx} style={{ borderRadius: "10px", cursor: "pointer", padding: "0", position: "relative" }}>
                                <ContentHolder
                                    id={idx}
                                    style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px" }}
                                >
                                    <Box
                                        title={
                                            <div id={idx} style={{ display: "flex", justifyContent: "start", alignItems: "center", position: "relative", paddingRight: "55px" }}>
                                                <span id={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)" }}>
                                                    <img id={idx} src={customScriptIcon} />
                                                </span>
                                                <div>
                                                    <p id={idx} style={{ color: "#1F1F1F", marginLeft: "10px", fontSize: "12px", lineHeight: "20px", fontFamily: "Poppins" }}>{temp.toolName}</p>
                                                    <p id={idx} style={{ marginLeft: "10px", ...innerSubtitleStyle }}>Click to configure</p>
                                                </div>
                                            </div>
                                        }
                                        // subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Click to configuration</p>}
                                        style={{ border: "transparent", padding: "0", marginBottom: "0", position: "relative" }}
                                        titleStyle={{ ...innerTitleStyle, marginBottom: "0", position: "relative" }}
                                        subTitleStyle={innerSubtitleStyle}
                                        id={idx}
                                    />
                                </ContentHolder>
                            </Box>
                        </StickerWidgetWrapper>
                        {idx === toolSelected && <img src={blueTriangleWorkflow} style={blueTriangleStyle} />}
                        {/* <img id={idx} src={deleteIcon} alt="delete" onClick={handleSelectedTools} style={{ position: "absolute", top: "6px", right: "33px", width: "18px", height: "22px", objectFit: "cover", cursor: "pointer" }} /> */}
                        <img id={idx} src={dragIcon} alt="drag" onClick={handleDragTools} style={{ position: "absolute", top: "29px", left: "13px", width: "18px", height: "22px", objectFit: "cover", cursor: "pointer" }} />
                    </div>
                ))}

                {/* next section */}
                {allTools.filter((item) => !item.onTop).length > 0 && <Box
                    title="Select tools for pipeline"
                    subtitle="Click plus icon to add more tools in the flow."
                    titleStyle={{ ...innerTitleStyle, lineHeight: "20px" }}
                    subTitleStyle={innerSubtitleStyle}
                    style={{ border: 0, padding: "0px 20px", margin: 0, marginTop: "40px", height: "fit-content" }}
                />}

                {allTools.map((temp, idx) => (
                    (!temp.onTop && <div key={idx} style={{ position: "relative", marginTop: "20px" }} id={idx} onClick={handleUnselectedTools}>
                        <StickerWidgetWrapper id={idx} className="isoStickerWidget" style={{ marginLeft: "22px", width: "250px", position: "relative" }}>
                            <Box id={idx} style={{ borderRadius: "10px", cursor: "pointer", padding: "0", position: "relative" }}>
                                <ContentHolder
                                    id={idx}
                                    style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "18px" }}
                                >
                                    <Box
                                        id={idx}
                                        title={
                                            <div id={idx} style={{ display: "flex", justifyContent: "start", alignItems: "center", position: "relative", paddingRight: "55px" }}>
                                                <span id={idx} style={{ display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "100%", width: "36px", height: "36px", background: "white", padding: "3px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)" }}>
                                                    <img id={idx} src={customScriptIcon} />
                                                </span>
                                                <div>
                                                    <p id={idx} style={{ color: "#1F1F1F", marginLeft: "10px", fontSize: "12px", lineHeight: "20px", fontFamily: "Poppins" }}>{temp.toolName}</p>
                                                    <p id={idx} style={{ marginLeft: "10px", ...innerSubtitleStyle }}>Click to configure</p>
                                                </div>
                                            </div>
                                        }
                                        // subtitle={<p style={{ lineHeight: "24px", color: "#9B9B9B" }}>Click to configuration</p>}
                                        style={{ border: "transparent", padding: "0", marginBottom: "0", position: "relative" }}
                                        titleStyle={{ ...innerTitleStyle, marginBottom: "0", position: "relative" }}
                                        subTitleStyle={innerSubtitleStyle}
                                    />
                                </ContentHolder>
                            </Box>
                        </StickerWidgetWrapper>
                        {/* <img src={blueTriangleWorkflow} style={blueTriangleStyle} /> */}
                        <img src={plusIcon} alt="add" id={idx} onClick={handleUnselectedTools} style={{ position: "absolute", top: "10px", right: "35px", width: "16px", height: "16px", objectFit: "cover", cursor: "pointer" }} />
                    </div>)
                ))}

            </Col>

            {/* Right Column */}
            {console.log("toolsData :", toolsData)}
            {console.log("allTools :", allTools)}
            <Col lg={16} md={16} sm={16} xs={16} style={{ marginBottom: "100px" }}>
                <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle }}>
                    <Form
                        form={form}
                        initialValues={{ ...toolsData }}
                        preserve={false}
                        name="dynamic_rule"
                        style={{
                            width: "100%",
                        }}
                    >
                        {allTools?.[toolSelected]?.toolName === "Secret Detection" && <>
                            <Box
                                title={"Enable Secret Detection*"}
                                subtitle={"Check the box to enable Secret Detection."}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                            >
                                <Switch style={toolsData?.preBuildToolsData?.[toolSelected]?.secretDetectionTrigger ? { backgroundColor: "#132f44" } : {}} checked={toolsData?.preBuildToolsData?.[toolSelected]?.secretDetectionTrigger} onChange={handleChecked} />
                            </Box>
                        </>}

                        {allTools?.[toolSelected]?.toolName === "Trivy" && <>
                            <Box
                                title={"Max critical vulnerability*"}
                                subtitle={"Mention your max critical vulnerability."}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="maxCriticalVulnerability"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide max critical vulnerability.',
                                            },
                                        ]}
                                    >
                                        <Input
                                            name="maxCriticalVulnerability"
                                            value={toolsData?.preBuildToolsData?.[toolSelected]?.maxCriticalVulnerability}
                                            onChange={handelInputChange}
                                            placeholder="2"
                                            style={inputStyle}
                                        // onBlur={getPublicBranches}
                                        />
                                    </Form.Item>
                                </ContentHolder>
                            </Box>
                            
                            <Box
                                title={"Max high vulnerability*"}
                                subtitle={"Mention your max high vulnerability."}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0", height: "fit-content" }}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="maxHighVulnerability"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide max high vulnerability.',
                                            },
                                        ]}
                                    >
                                        <Input
                                            name="maxCriticalVulnerability"
                                            value={toolsData?.preBuildToolsData?.[toolSelected]?.maxHighVulnerability}
                                            onChange={handelInputChange}
                                            placeholder="5"
                                            style={inputStyle}
                                        // onBlur={getPublicBranches}
                                        />
                                    </Form.Item>
                                </ContentHolder>
                            </Box>
                        </>}

                        {allTools?.[toolSelected]?.toolName === "Tool 3" && <>
                            <Box
                                title={"Tool3 Name*"}
                                subtitle={"Provide tool name here"}
                                titleStyle={boxTitleStyle}
                                subTitleStyle={boxSubtitleStyle}
                                style={{ width: "100%", marginTop: "0", border: "0", padding: "0" }}
                            >
                                <ContentHolder style={{ width: "100%", marginTop: "6px" }}>
                                    <Form.Item
                                        name="toolName"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please provide App Name.',
                                            },
                                        ]}
                                    >
                                        <Input
                                            name="toolName"
                                            value={toolsData?.preBuildToolsData?.[toolSelected]?.toolName}
                                            style={{ ...inputStyle }}
                                            onChange={handelInputChange}
                                            placeholder="Name"
                                        />
                                    </Form.Item>
                                </ContentHolder>
                            </Box>
                        </>}
                    </Form>

                    {/* <Box
                        title={"Failure Strategy"}
                        subtitle={"Please define a failure strategy if the tool fails to run or encounters an error while running."}
                        titleStyle={boxTitleStyle}
                        subTitleStyle={boxSubtitleStyle}
                        style={{ width: "100%", marginTop: "0", border: "0", padding: "0" }}
                    >
                        <ContentHolder style={{width: "100%", marginTop: "6px"}}>
                            <button style={failureButtonsStyle}>
                                <img src={} alt="abort" />
                            </button>
                        </ContentHolder>
                    </Box> */}
                </StickerWidgetWrapper>
            </Col>

            <CreateTaskFooter buttonContent={"Custom Script"} givenButtonFunction1={handleSaveDetails} givenButtonFunction2={handleCancel} openDrawer={openDrawer} />

        </LayoutWrapper>
    );
};