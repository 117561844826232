import {React, useState, useEffect} from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import Input, {
  InputSearch,
  InputGroup,
  Textarea,
} from '@iso/components/uielements/input';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled from 'styled-components';
import Select, { SelectOption } from '@iso/components/uielements/select';

import initialData from '../../../redux/scrumBoard/data';
import { useNavigate } from 'react-router-dom';

import {
  PlusOutlined
} from '@ant-design/icons';
import plusIcon from '../../../assets/images/plusIcon.svg';
import { Link } from 'react-router-dom';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import api from '../../../api';

const Option = SelectOption;

const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

const addButtonStyle = { 
  marginRight: "10px", 
  fontSize: "25px"
};


const serverInsTypes = ["t3.small", "m4.large", "c5.large", "r3.large"];
serverInsTypes.map((child, ind)=> {
  serverInsTypes.push(<Option key={child} name="serverInsType" value={child}>{child}</Option>);
});

var optionAzs = [];


export default function ClusterDev({
  // handleSubmit,
  data,
  setData,
  values,
  submitCount,
  onCancel,
  onDelete,
  onEditCancel,
  tabSelected,
  openAddOns,
  openDrawer
}) {
  const {openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters, currentCluster, setCurrentCluster, clusterTabSelected} = useContext(AppContext);

  const [myClusterData, setMyClusterData] = useState(data);
  const [error, setError] = useState({
    status: false,
    msg: "Success"
  });
  const [myVersions, setMyVersions] = useState([]);
  const navigate = useNavigate();
  // const [myClusterData, setMyClusterData] = useState(data);
  useEffect(() => {
    console.log("Current cluster in useefect: ", currentCluster.dev.versionsAvailable);
    let arr = [];
    currentCluster.dev.versionsAvailable.map((child, ind)=> {
      arr.push(<Option key={child.version} name="eks" value={child.version}>{child.version}</Option>);
    });
    setMyVersions(arr);
    setMyClusterData(currentCluster);
  }, [currentCluster]);
  

  let azs = currentCluster.availability_zones ? currentCluster.availability_zones : [];
  optionAzs = [];
  azs.map((child, ind)=> {
    optionAzs.push(<Option key={child+"-"+ind} name="naz" value={child}>{child}</Option>);
  });
  
  const handleChange = value => {
    // console.log('allClusters: ', allClusters);
    myClusterData.dev[value.target.name] = value.target.checked;
    setData({...myClusterData, dev: myClusterData.dev});
    setMyClusterData({...myClusterData, dev: myClusterData.dev});
    setError({
      status: false,
      msg: ""
    });
  };

  const handleSelectChange = (value, event) => {
    console.log('Data :', currentCluster["dev"].versionsAvailable);
    let result = currentCluster["dev"].versionsAvailable.find((val) => {
      console.log(currentCluster["dev"].eks);
      if (val.version === value) {
        return val;
      }
    })
    api.get(process.env.REACT_APP_API_URI + `/api/cluster/eks-addon/${currentCluster.integration}/${result.id}`)
    .then((res) => {
      let data = res.data;
      myClusterData.dev["addonsData"] = data;
      setMyClusterData({...myClusterData, dev: myClusterData.dev});
      console.log("This is the addons data: ", data);
    });
    myClusterData.dev[event.name] = value;
    setData({...myClusterData, dev: myClusterData.dev});
    setMyClusterData({...myClusterData, dev: myClusterData.dev});
    setCurrentCluster({...myClusterData, dev: myClusterData.dev});
    setError({
      status: false,
      msg: ""
    });
  };
  
  const handleMultiSelectChange = (value, event) => {
    console.log('event :', event);
    // if (value.length <= 1){
    //   setError({
    //     status: true,
    //     msg: "Please provide 2 or more AZ's"
    //   });
    //   // event.value;
    //   return false;
    // }
    console.log('value :', value);
    console.log('myClusterData.dev.naz :', myClusterData.dev.naz);
    if (value.length > myClusterData.prerequisiteInfo.available_eip_quota) {
      setError({
          status: true,
          msg: `You can select upto ${myClusterData.prerequisiteInfo.available_eip_quota} availability zones.`
      });
  }
    else{
      console.log('ok');
      setError({
        status: false,
        msg: ""
      })
    }
    myClusterData.dev.naz = value;
    setData({...myClusterData, dev: myClusterData.dev});
    setMyClusterData({...myClusterData, dev: myClusterData.dev});
    setCurrentCluster({...myClusterData, dev: myClusterData.dev});
  };
  
  const handelInputChange = (e) => {
    if(e.target.name === "name" || e.target.name === "value"){
      // console
      myClusterData.dev.tags[e.target.id][e.target.name] = e.target.value;
    }
    else if(["logRetention", "clusterLogRetention", 'flowLogMax'].includes(e.target.name)){
      const logRetentionValue = e.target.value.replace(/\D/g, '');
      myClusterData.dev[e.target.name] = logRetentionValue;
    }
    else{
      myClusterData.dev[e.target.name] = e.target.value;
    }
    console.log('updated myClusterData :', myClusterData);
    setData({...myClusterData, dev: myClusterData.dev});
    setMyClusterData({...myClusterData, dev: myClusterData.dev});
    setError({
      status: false,
      msg: ""
    });
  };

  const handelBlurEvent = (e) => {
    if(e.target.name === "vpcCidr"){
      // console
      if(e.target.value.match(/\b(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([0-9]|[1-2][0-9]|3[0-2])\b/)){
        console.log('True');
        setError({
          status: false,
          msg: ""
        });
        
      }
      else{
        console.log('False');
        setError({
          status: true,
          msg: "Please provide correct vpc cidr(example: 10.0.0.0/16)"
        });
      }
      myClusterData.dev[e.target.name] = e.target.value;
    }
    // else if(e.target.name === "naz"){
    //   if(myClusterData.dev.naz.length < 2){
    //     console.log('naz length error');
    //   }
    //   else{
    //     console.log('naz length ok');
    //   }
    // }
  }


  return (
    <ProdContentHold>
      <CreateTaskHeader
        buttonContent="Cluster"
        data={myClusterData}
        // values={{editing: true}}
        onCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              myClusterData: myClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        onDelete={onDelete}
        onEditCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              myClusterData: myClusterData,
              tabSelected: tabSelected,
            },
          });
        }}
        openBasicView={() => {
          setOpenRightDrawer({
            drawerType: "dev",
            drawerProps: {
                data: myClusterData,
                setData: setMyClusterData,
                tabSelected: tabSelected,
            },
          });
        }}
        openCodeView={() => {
          setOpenRightDrawer({
            drawerType: "prodCodeView",
            drawerProps: {
                data: myClusterData,
                setData: setMyClusterData,
                tabSelected: tabSelected,
            },
          });
        }}
      />

      {/* Tags */}

      <Box
        title={<div style={{display: "flex"}}><span style={{marginRight: "5px", color: "red"}}>*</span><span><IntlMessages id="cluster.noofavailablityzones.title" /></span></div>}
        subtitle={<IntlMessages id="cluster.noofavailablityzones.subTitle" />}
        style={{marginTop: "80px"}}
      >
        <ContentHolder>
          <Select
            mode="multiple"
            name="naz"
            style={{ width: '100%' }}
            placeholder="No. of availablity zones"
            defaultValue={myClusterData.dev.naz}
            onChange={handleMultiSelectChange}
          >
            {optionAzs}
          </Select>
        </ContentHolder>
        {error.status && <p className="isoHelperText">
          <span>{error.msg}</span>
        </p>}
      </Box>

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} />

      <Box
        title={<IntlMessages id="cluster.flowlogmaxaggregationinterval.title" />}
        subtitle={<IntlMessages id="cluster.flowlogmaxaggregationinterval.subTitle" />}
      >
        <ContentHolder>
          <Input
            name="flowLogMax"
            value={myClusterData.dev.flowLogMax}
            onChange={handelInputChange}
            placeholder="Flow Log aggregation interval"
            maxLength="4"
          />
        </ContentHolder>
      </Box> */}

      <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} />
      
      <Box
        title={<div style={{display: "flex"}}><span style={{marginRight: "5px", color: "red"}}>*</span><span><IntlMessages id="cluster.vpccidr.title" /></span></div>}
        subtitle={<IntlMessages id="cluster.vpccidr.subTitle" />}
      >
        <ContentHolder>
          <Input
            name="vpcCidr"
            value={myClusterData.dev.vpcCidr}
            onChange={handelInputChange}
            onBlur={handelBlurEvent}
            placeholder="10.0.0.0/16"
            maxLength="19"
          />
        </ContentHolder>
      </Box>

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} /> */}

      {/* <Box
        title={<IntlMessages id="cluster.logretentionperiod.title" />}
        subtitle={<IntlMessages id="cluster.logretentionperiod.subTitle" />}
      >
        <ContentHolder>
          <Input
            name="logRetention"
            value={myClusterData.dev.logRetention}
            onChange={handelInputChange}
            placeholder="Log retention period"
            maxLength="4"
          />
        </ContentHolder>
      </Box> */}

      <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} />

      <Box
        title={<div style={{display: "flex"}}><span style={{marginRight: "5px", color: "red"}}>*</span><span><IntlMessages id="cluster.kubernetiveslogretentionperiod.title" /></span></div>}
        subtitle={<IntlMessages id="cluster.kubernetiveslogretentionperiod.subTitle" />}
      >
        <ContentHolder>
          <Input
            name="clusterLogRetention"
            value={myClusterData.dev.clusterLogRetention}
            onChange={handelInputChange}
            placeholder="Kubernetives log retention period"
            maxLength="3"
          />
        </ContentHolder>
      </Box>
      
      <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} />

      <Box
        title={<div style={{display: "flex"}}><span style={{marginRight: "5px", color: "red"}}>*</span><span><IntlMessages id="cluster.eksversion.title" /></span></div>}
        subtitle={<IntlMessages id="cluster.eksversion.subTitle" />}
      >
        <ContentHolder>
          <Select
            style={{ width: '400px' }}
            defaultValue={myClusterData.dev.eks}
            onChange={handleSelectChange}
          >
            {myVersions}
          </Select>
        </ContentHolder>
      </Box>
      
      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} /> */}

      {/* <Box
        title={<IntlMessages id="cluster.serverinstancetype.title" />}
        subtitle={<IntlMessages id="cluster.serverinstancetype.subTitle" />}
      >
        <ContentHolder>
          <Select
            style={{ width: '400px' }}
            defaultValue={myClusterData.dev.serverInsType}
            onChange={handleSelectChange}
          >
            {serverInsTypes}
          </Select>
        </ContentHolder>
      </Box> */}

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} />

      <Box
        title={<IntlMessages id="cluster.backups.title" />}
        subtitle={<IntlMessages id="cluster.backups.subTitle" />}
      >
        <ContentHolder>
          <Checkbox name="backups" checked={myClusterData.dev.backups} onChange={handleChange}>Backups</Checkbox>
        </ContentHolder>
      </Box> */}

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} /> */}

      {/* <Box
        title={<IntlMessages id="cluster.natgatewayperAZ.title" />}
        subtitle={<IntlMessages id="cluster.natgatewayperAZ.subTitle" />}
      >
        <ContentHolder>
          <Checkbox name="natGatway" checked={myClusterData.dev.natGatway}  onChange={handleChange}>Nat Gateway per AZ</Checkbox>
        </ContentHolder>
      </Box> */}

      {/* <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} /> */}

      {/* <Box
        title={<IntlMessages id="cluster.vpnserver.title" />}
        subtitle={<IntlMessages id="cluster.vpnserver.subTitle" />}
      >
        <ContentHolder>
          <Checkbox name="vpns" checked={myClusterData.dev.vpns} onChange={handleChange}>Vpn Server</Checkbox>
        </ContentHolder>
      </Box> */}

      <hr style={{width: "92%", borderTop: "1px solid #88898b24", marginTop: "15px", marginBottom: "15px"}} />

      <Box
        title={<IntlMessages id="cluster.tags.title" />}
      >
      {myClusterData.dev.tags.map((tag, ind) => (
        <ContentHolder key={"tag-"+ind} style={{display: "flex", justifyContent: 'space-between', alignItems: 'center'}}>
          {ind === myClusterData["dev"].tags.length - 1 && <Link onClick={(e) => {
          console.log('updated myClusterData :', myClusterData);
          myClusterData.dev.tags.push({
            name: "",
            value: ""
          });
          setData({...myClusterData, dev: myClusterData.dev});
          setMyClusterData({...myClusterData, dev: myClusterData.dev});
        }}
        style={{marginRight: "10px"}}
        > <img src={plusIcon}/> </Link>}
          <Input
            name="name"
            id={ind}
            value={myClusterData.dev.tags[ind] && myClusterData.dev.tags[ind]['name']}
            onChange={handelInputChange}
            placeholder="Tag Name"
            style={ind !== myClusterData.dev.tags.length - 1 ? {marginLeft: "48px"} : {}}
          />
          <Input
            name="value"
            id={ind}
            value={myClusterData.dev.tags[ind] && myClusterData.dev.tags[ind]['value']}
            onChange={handelInputChange}
            placeholder="Tag Value"
            style={{margin: "0 10px"}}
          />
          {myClusterData.dev.tags.length - 1 !== 0 && <Button type="danger" id={ind} onClick={(e) => {
            console.log('Id to be deleted :', e.currentTarget.id);
            myClusterData.dev.tags.splice(e.currentTarget.id, 1)
            setData({...myClusterData, dev: myClusterData.dev});
            setMyClusterData({...myClusterData, dev: myClusterData.dev});
          }}>
            Remove Tag
          </Button>}
        </ContentHolder>
      ))}
      </Box>

      <Box style={{height: "100px"}}>

      <Box>
        {/* <ContentHolder style={{
          position: "relative"
        }}>
          <Button type="primary" onClick={() => {
            openAddOns(currentCluster, clusterTabSelected);
            openDrawer();
            }} style={{position: "absolute", right: "0", marginTop: "-20px"}}>
              Save & Next
          </Button>
          {error.status && <p className="isoHelperText">
              <span>{error.msg}</span>
          </p>}
        </ContentHolder> */}
      </Box>
      </Box>
      <CreateTaskFooter buttonContent={"Cluster"} givenButtonFunction1={openAddOns} openDrawer={openDrawer}/>


    {/* <Tooltip title="Please Implements Your Own Attachment Methods">
      <AttachmentWrapper>
        <HeadingWithIcon heading="Attachments" iconSrc={AttachmentIcon} />
        <HeadingWithIcon
          heading="Add an Attachment...."
          iconSrc={AttachmentIcon}
        />
      </AttachmentWrapper>
    </Tooltip> */}
    </ProdContentHold>
  );
};
