import React from 'react';
// import { connect } from 'react-redux';
import { Formik } from 'formik';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
// import RenderTaskForm from './RenderCreateTaskForm/RenderCreateTaskForm';
import { dateFormat } from '@iso/components/ScrumBoard/FieldFormats';
// import scrumBoardActions from '@iso/redux/scrumBoard/actions';
import { CreateTaskWrapper } from './TaskCreateOrUpdate.style';
import RenderCreateAppForm from '../../../Projects/RenderCreateAppForm';
import RenderCreateDataSourceForm from '../../../Projects/RenderCreateDataSourceForm';
import RenderCreateQueuesForm from '../../../Projects/RenderCreateQueuesForm';
import RenderCreateThirdPartyForm from '../../../Projects/RenderCreateThirdPartyForm';

const initialValues = {
  id: '',
  title: '',
  description: '',
  labels: [],
  assignees: [],
  attachments: [],
  comments: [],
  todos: [],
  columnId: '',
  editing: false,
  created_at: moment(Date.now()),
  updated_at: moment(Date.now()),
  due_date: moment(Date.now()),
  selectOptions: ['success', 'error', 'processing', 'warning', 'default'],
  selectAssignees: ['Mark', 'Bob', 'Anthony'],
};

const DRAWER_COMPONENTS = {
  app: RenderCreateAppForm,
  dataSource: RenderCreateDataSourceForm,
  queues: RenderCreateQueuesForm,
  thirdParties: RenderCreateThirdPartyForm,
  // thirdParties: RenderCreateThirdPartyForm,
  /* other modals */
};

const TaskForm = props => {
  const initials = {
    ...initialValues,
    ...props.initials,
    due_date: moment(
      props.initials && props.initials.due_date
        ? props.initials.due_date
        : Date.now()
    ),
    updated_at: moment(Date.now()),
  };

  const handleSubmit = formProps => {
    if (!formProps.editing) {
      formProps.id = uuid();
      formProps.columnId = props.columnId;
      formProps.editing = false;
      formProps.created_at = moment(formProps.created_at)
        .format(dateFormat)
        .toString();
    }

    props.createOrUpdateTaskWatcher({
      ...formProps,
      due_date: moment(formProps.due_date)
        .format(dateFormat)
        .toString(),
      updated_at: moment(formProps.updated_at)
        .format(dateFormat)
        .toString(),
    });
    props.closeDrawer();
  };

  console.log('props.columnId :', props.columnId);
  const SpecificRender = DRAWER_COMPONENTS[props.columnId];

  return (
    <CreateTaskWrapper>
      <Formik
        initialValues={initials}
        onSubmit={handleSubmit}
        render={formikProps => (
          <SpecificRender
            {...{...formikProps, data:props.data, columnId:props.columnId, editing: props.editing}}
            onCancel={props.closeDrawer}
            onSave={props.onSave}
            onDelete={() => {
              props.deleteTask({
                taskId: props.initials.id,
                columnId: props.columnId,
              });
              props.closeDrawer();
            }}
            onEditCancel={() => {
              props.cancelEditTask(props.initials);
              props.setOpenRightDrawer({
                drawerType: 'CARD_DETAILS',
                drawerProps: {
                  data: { ...props.initials },
                  columnId: props.columnId,
                },
              });
            }}
          />
        )}
      />
    </CreateTaskWrapper>
  );
};

export default TaskForm;

// export default connect(
//   null,
//   {
//     ...scrumBoardActions,
//   }
// )(TaskForm);
