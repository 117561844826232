import api from '../api';


export const fetchPublicBranches = async (integrationType, owner, repoName) => {
    if(integrationType === "github"){
        const response = await api.get(`https://api.github.com/repos/${owner}/${repoName}/branches`);
        console.log("Data in fetchPublicBranches for github :", response.data);
        return response.data;
    }
    else if(integrationType === "gitlab"){
        const response = await api.get(`https://gitlab.com/api/v4/projects/${owner}%2F${repoName}/repository/branches`);
        console.log("Data in fetchPublicBranches for gitlab :", response.data);
        return response.data;
    }
    else if(integrationType === "bitbucket"){
        // const response = await api.get(`https://gitlab.com/api/v4/projects/${owner}%2F${repoName}/repository/branches`);
        // console.log("Data in fetchBranches for gitlab :", response.data);
        // return response.data;
    }
};

export const fetchBranches = async (accountId, repoName) => {
    const response = await api.post(process.env.REACT_APP_API_URI + '/api/integration/vcs/branch/', {
        account_id: accountId,
        repo_name: repoName,
    });
    console.log("Data in fetchBranches :", response.data);
    return response.data;
};

export const fetchRepoRelatedDetails = async (accountId, repoName, type) => {
    const response = await api.post(process.env.REACT_APP_API_URI + `/api/integration/vcs/repos/details?${type}=True`, {
        account_id: accountId,
        repo_name: repoName,
    });
    console.log("Data in fetchTags :", response.data);
    return response.data;
};