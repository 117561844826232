import { useContext, useRef, useState } from "react";
import { GuardrailsContext } from "../../../context/guardrailsContext";
import ActionBox from "./ActionBox";


let nodeStyle = {
    width: "433px",
    height: "93px",
    padding: "21px 3px 20px 8px",
    borderRadius: "10px",
    backgroundColor: "rgba(236, 244, 251, 0.7)",
  };

export const useGuardrailNode = () => {
    const { guardrailNodesData, setGuardrailNodesData, guardrailEdgesData, setGuardrailEdgesData, selectedGuardrailInfo, setSelectedGuardrailInfo } = useContext(GuardrailsContext);
    
    const nextAvailableNode = (myNodes, myEdges) => {
        for (let i = 2; i < myNodes.length; i++) {
            //if(guardrailNodesData[i])
            let connectedCount = myEdges.filter((val) => val.source === myNodes[i].id);
            console.log("My connected count: ", connectedCount);
            if (i === 2 && connectedCount.length < 2) {
                return myNodes[i];
            }
            else if (connectedCount.length < 1) {
                return myNodes[i];
            }
        }
        return null;
    }

    const handleAddNode = (myData, myNodes, myEdges, conditionNo) => {
        console.log("My data: ", myData);
        let availableNode = nextAvailableNode(myNodes, myEdges);
        console.log("Next available node: ", availableNode);
        let myNodeObj = {};
        if (availableNode.id === "3") {
            let connectedCount = myEdges.filter((val) => val.source === availableNode.id);
            if (connectedCount.length === 0) {
                myNodeObj = {
                    id: (myNodes.length + 1).toString(),
                    type: "if",
                    data: { label: <ActionBox conditionField={myData.info?.selectedFilters[conditionNo - 1].param} conditionOperation={myData.info?.selectedFilters[conditionNo - 1].filter} conditionValue={myData.info?.selectedFilters[conditionNo - 1].value} conditionNo={conditionNo.toString()} /> },
                    position: { x: 50, y: 600 },
                    style: nodeStyle,
                }
            }
            else if (connectedCount.length === 1) {
                myNodeObj = {
                    id: (myNodes.length + 1).toString(),
                    type: "if",
                    data: { label: <ActionBox conditionField={myData.info?.selectedFilters[conditionNo - 1].param} conditionOperation={myData.info?.selectedFilters[conditionNo - 1].filter} conditionValue={myData.info?.selectedFilters[conditionNo - 1].value} conditionNo={conditionNo.toString()} /> },
                    position: { x: 323 + (myNodes[myNodes.length - 1].position.x), y: 600 },
                    style: nodeStyle,
                }
                let prevNode = myNodes[myNodes.length - 1];
                prevNode = {
                    ...prevNode,
                    position: { x: prevNode.position.x - 323, y: 600 }
                };
                myNodes[myNodes.length - 1] = prevNode;
            }
            else {
                myNodeObj = {
                    id: (myNodes.length + 1).toString(),
                    type: "if",
                    data: { label: <ActionBox conditionField={myData.info?.selectedFilters[conditionNo - 1].param} conditionOperation={myData.info?.selectedFilters[conditionNo - 1].filter} conditionValue={myData.info?.selectedFilters[conditionNo - 1].value} conditionNo={conditionNo.toString()} /> },
                    position: { x: 473, y: 600 },
                    style: nodeStyle,
                }
            }
        }
        else {
            console.log("mydata: ", myData);
            myNodeObj = {
                id: (myNodes.length + 1).toString(),
                type: "if",
                data: { label: <ActionBox conditionField={myData.info?.selectedFilters[conditionNo - 1].param} conditionOperation={myData.info?.selectedFilters[conditionNo - 1].filter} conditionValue={myData.info?.selectedFilters[conditionNo - 1].value} conditionNo={conditionNo.toString()} /> },
                position: { x: myNodes[myNodes.length - 2].position.x, y: myNodes[myNodes.length - 2].position.y + 200 },
                style: nodeStyle,
            };
        }
        let myEdgeObj = {
            id: "e" + (availableNode.id) + "-" + (myNodes.length + 1),
            source: (availableNode.id),
            target: (myNodes.length + 1).toString(),
            type: "customEdge"//!selectedGuardrailInfo.view && "customEdge"
        }
        console.log("New node and edge: ", myNodeObj, myEdgeObj);
        myNodes.push(myNodeObj);
        myEdges.push(myEdgeObj);
        //fitView();
        // setGuardrailNodesData([...guardrailNodesData, myNodeObj]);
        // setGuardrailEdgesData([...guardrailEdgesData, myEdgeObj]);
    };

    const updateCondition = async (myData, conditionNo) => {
        setSelectedGuardrailInfo(myData);
        guardrailNodesData[conditionNo + 2] = {
            id: (conditionNo + 3).toString(),
            type: "if",
            data: {
                label: <ActionBox conditionField={myData.info?.selectedFilters[conditionNo - 1].param} conditionOperation={myData.info?.selectedFilters[conditionNo - 1].filter} conditionValue={myData.info?.selectedFilters[conditionNo - 1].value} conditionNo={conditionNo.toString()} />
            },
            position: {...guardrailNodesData[conditionNo + 2].position},
            style: nodeStyle,
        };
        setGuardrailNodesData([...guardrailNodesData]);
        //fitView();
    }

    const deleteNode = (conditionNo) => {
        // guardrailNodesData.forEach((val) => {
        //   if(val.id === toString(parseInt(conditionNo) + 3)){

        //   }
        // });
        console.log("My condition no: ", conditionNo);
        guardrailNodesData[parseInt(conditionNo) + 2] = {
            ...guardrailNodesData[guardrailNodesData.length - 1],
            data: { label: <ActionBox conditionField={selectedGuardrailInfo.info.selectedFilters[selectedGuardrailInfo.info.selectedFilters.length - 1].param} conditionOperation={selectedGuardrailInfo.info.selectedFilters[selectedGuardrailInfo.info.selectedFilters.length - 1].filter} conditionValue={selectedGuardrailInfo.info.selectedFilters[selectedGuardrailInfo.info.selectedFilters.length - 1].value} conditionNo={conditionNo.toString()} /> },
            id: guardrailNodesData[parseInt(conditionNo) + 2].id,
            position: guardrailNodesData[parseInt(conditionNo) + 2].position,
        };
        guardrailNodesData.pop();
        if (guardrailNodesData.length === 4) {
            guardrailNodesData[3] = {
                ...guardrailNodesData[3],
                //data: { label: <ActionBox conditionField={selectedGuardrailInfo.info.selectedFilters[0].param} conditionOperation={selectedGuardrailInfo.info.selectedFilters[0].filter} conditionValue={selectedGuardrailInfo.info.selectedFilters[0].value} conditionNo={"1"}/> },
                position: { x: 50, y: 600 },
            };
        }
        let sourceNodeIdx = guardrailEdgesData.findIndex((val) => val.source === toString(parseInt(conditionNo) + 2));
        let targetNodeIdx = guardrailEdgesData.findIndex((val) => val.target === toString(parseInt(conditionNo) + 2));

        // let sourceNodeEdge = guardrailEdgesData[sourceNodeIdx].id;
        if (sourceNodeIdx >= 0) {
            guardrailEdgesData[sourceNodeIdx] = {
                id: `e${guardrailEdgesData[guardrailEdgesData.length - 1].target}-${guardrailEdgesData[sourceNodeIdx].target}`,
                source: guardrailEdgesData[guardrailEdgesData.length - 1].target,
                target: guardrailEdgesData[sourceNodeIdx].target,
                type: "customEdge"
            };
        }
        if (targetNodeIdx >= 0) {
            guardrailEdgesData[targetNodeIdx] = {
                id: `e${guardrailEdgesData[targetNodeIdx].source}-${guardrailEdgesData[guardrailEdgesData.length - 1].target}`,
                source: guardrailEdgesData[targetNodeIdx].source,
                target: guardrailEdgesData[guardrailEdgesData.length - 1].target,
                type: "customEdge"
            };
        }
        guardrailEdgesData.pop();
        selectedGuardrailInfo.info.selectedFilters[parseInt(conditionNo) - 1] = selectedGuardrailInfo.info.selectedFilters[selectedGuardrailInfo.info.selectedFilters.length - 1];
        selectedGuardrailInfo.info.selectedFilters.pop();
        setSelectedGuardrailInfo({ ...selectedGuardrailInfo });
        localStorage.setItem('selectedGuardrailInfo', JSON.stringify({ ...selectedGuardrailInfo }));
        console.log("GuardrailnodesData, guardrailEdges: ", guardrailNodesData, guardrailEdgesData);
        setGuardrailNodesData([...guardrailNodesData]);
        setGuardrailEdgesData([...guardrailEdgesData]);
        //fitView();
    };

    return { updateCondition, deleteNode, handleAddNode };
}