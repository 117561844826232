import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

// Add request interceptor
api.interceptors.request.use(
  (config) => {
    console.log('inside interceptors use...');
    // Modify the request config, such as adding headers or authentication tokens
    const accessToken = localStorage.getItem('isAuth');
    const profileData = localStorage.getItem('profile-data');
    console.log("profileData in interceptor :", profileData);
    if (accessToken) {
      // for try without login
      if(accessToken === "true" && profileData && JSON.parse(profileData).roles && JSON.parse(profileData).roles.length > 0 && JSON.parse(profileData).roles[0] === "guest"){
        console.log("config :", config);
        return Promise.reject("Try without login");
      }

      // for else case
      console.log('accessToken found in interceptors use...', accessToken);
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    else{
      let myVersion = localStorage.getItem("appVersion");
      localStorage.clear();
      localStorage.setItem("appVersion", myVersion);
      window.open("/signin", "_self");
      return Promise.reject("Authorization Failed");
    }
    return config;
  },
  (error) => {
    console.log('Found error in interceptors.request...', error);
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add response interceptor
api.interceptors.response.use(
  (response) => {
    // Process successful responses
    console.log('SUCCESS in interceptors.response...');
    return response;
  },
  async (error) => {
    // Handle response errors
    const originalRequest = error.config;
    console.log('originalRequest in interceptors.response...');

    // Check if the response status is 401 (Unauthorized)
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      console.log('response status is 401 in interceptors.response...');

      // Perform a request to refresh the access token
      const refreshToken = localStorage.getItem('refreshToken');
      const rememberMe = localStorage.getItem('iam');
      if (refreshToken) {
        try {
          const response = await axios.post(process.env.REACT_APP_API_URI+'/login/refresh/', { refresh: refreshToken });
          // console.log('calling /login/refresh/ in interceptors.response...:', response.data);
          console.log('calling /login/refresh/ in interceptors.response...');
          // Update the access token in storage
          const newAccessToken = response.data.data.access;
          // const newRefreshToken = response.data.data.refresh;
          // console.log('newAccessToken Found in interceptors.response...', newAccessToken);
          console.log('newAccessToken Found in interceptors.response...');
          localStorage.setItem('isAuth', newAccessToken);
          // localStorage.setItem('refreshToken', newRefreshToken);

          // Update the request configuration with the new access token
          originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

          // Retry the original request
          return api(originalRequest);
        } catch (refreshError) {
            // Handle the refresh token request error
            console.log('Refresh Token Failed in interceptors.response :', refreshError);
            // redirect to login after clearing localStorage if refresh token failed
            let myVersion = localStorage.getItem("appVersion");
            localStorage.clear();
            localStorage.setItem("appVersion", myVersion);
            window.open("/signin", "_self");
        }
      }
    }
    else if(error.response && error.response.data.code){
      return Promise.resolve(error.response);
    }

    // Handle other response errors
    return Promise.reject(error);
  }
);

export default api;
