export let deploymentHelpChecks = {
    deployment: {
        clusterNamespace: "Specify the Kubernetes namespace where the application will be deployed. Namespaces isolate groups of resources within a single cluster.",
        dnsZone: "Enter the DNS zone for your application's domain. This will be used to route traffic to your application."
    },
    application: {
        branch: "Select the repository branch from which to deploy. Typically, 'main' or 'master' for production deployments.",
        containerRegistryId: "Provide the URL of the container registry where your application's Docker image is stored (e.g., Docker Hub, ECR).",
        enableAutoTrigger: "Toggle to enable Continuous Integration/Continuous Deployment. Automates the deployment of your application upon code changes.",
        dockerFilePath: "Specify the path to the Dockerfile within your repository. This file contains the instructions to build your application's Docker image.",
        port: "Enter the port number your application listens on. This port will be used to route internal traffic to your app.",
        healthCheckPath: "Define the URL path for health checks (e.g., '/health'). Used to ensure your application is running correctly.",
        endpointType: "Choose the type of endpoint for your service: 'Public' for internet-facing or 'Internal' for private network access.",
        requestCpu: "Set the minimum CPU allocation for your application (e.g., '500m' for 0.5 CPUs). Ensures baseline performance.",
        limitCpu: "Define the maximum CPU limit for your application (e.g., '2' for 2 CPUs). Prevents overuse of resources.",
        requestMem: "Define the maximum CPU limit for your application (e.g., '2' for 2 CPUs). Prevents overuse of resources.",
        limitMem: "Set the maximum memory limit for your application (e.g., '1Gi' for 1 GB). Helps in resource management.",
        minimumReplica: "Enter the minimum number of replicas for your application, ensuring availability and load distribution.",
        autoScaling: "Toggle to enable automatic scaling of your application based on load. Adjusts replicas within set minimum and maximum limits.",
        nodegroupName: "Specify the name of the node group where your application will be deployed. Node groups organize clusters into subgroups.",
        maximumReplica: "Define the maximum number of replicas for your application to handle peak loads effectively.",
        autoScalingParam: "Set the parameters for auto-scaling (e.g., CPU utilization percentage) to automatically adjust the number of replicas.",
    },
    dataSources: {
        type: "Select whether the datasource is 'Self Managed' for databases managed by your own team or 'AWS Managed' for databases managed by AWS services. This choice determines the level of control and responsibility you have over the database.",
        version: "Specify the version of the database software (e.g., PostgreSQL 13.3). Ensure compatibility with your application.",
        storageType: "Choose 'EBS' for block storage optimized for database workloads that require consistent performance, or 'EFS' for file storage that provides a simple, scalable, elastic file system for use with AWS Cloud services and on-premises resources.",
        noOfReplica: "Enter the number of replicas for high availability. More replicas can provide better fault tolerance and load distribution.",
        storageSize: "Define the storage size for your database (e.g., '10Gi'). Consider future growth and data retention needs.",
        backupDetails: "Provide backup configuration details, including frequency and retention period, to ensure data durability.",
        userAccessDetails: "Specify access control settings, such as usernames and permissions, for managing database access.",
        dbType: "Select 'Multi AZ Instance' for high availability with automatic failover to a standby in another Availability Zone or 'Single AZ Instance' for a cost-effective option suited for non-critical applications or development environments",
        logsDetails: "Enter details for log management, including log retention policies and access to log data for troubleshooting.",
        maintenanceWindow: "Specify the preferred time window for performing database maintenance tasks. This minimizes impact on application availability."
    }
};