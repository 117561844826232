import { useContext, useState } from "react";
import Box from "../../../components/utility/box";
import CreateTaskHeader from "../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader";
import { AppContext } from "../../../context/ContextProvider";
import copyIcon from "@iso/assets/images/copyImg.svg";
import { copyText } from "../../../context/helper";
import { Tooltip } from "antd";

import eyeIcon from '@iso/assets/images/icon/eye-icon.svg';
import eyeCloseIcon from '@iso/assets/images/icon/eye-close-icon.svg';


let titleStyle = {
    margin: '10px 0',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: '600',
    color: "#1f1f1f",

}

let subTitleStyle = {
    margin: '10px 0',
    fontSize: '13px',
    color: "#a6a6a6",
    fontFamily: 'Nunito Sans',
    fontWeight: '600',
    wordWrap: "break-word",
}

let boxStyle = {
    border: '0',
    padding: "0",
    width: "100%",
    //marginTop: "50px",
}

export default function AddonsOverview({ data }) {
    const [addonsData, setAddonsData] = useState(data);
    const { openRightDrawer, setOpenRightDrawer, checkTryWithoutLogin } = useContext(AppContext);
    const [tooltipTxt, setTooltipTxt] = useState({
        // username: "",
        // password: ""
    });
    const [showMore, setShowMore] = useState({
        admin: true,
        readOnly: true
    });
    const [isSecret, setIsSecret] = useState({
        admin: true,
        readOnly: true,
        password: true
    });

    const hideString = (isSecret, str) => {
        return isSecret ? Array.apply(null, { length: str.length }).map((temp, idx) => "*") : str;
    }


    const copyFunc = async (e, text) => {
        let res = await copyText(text);
        console.log("Tooltip: ", e.target.name, res);
        setTooltipTxt({
            [e.target.name]: "Copied"
        });
        setTimeout(() => {
            setTooltipTxt({
                [e.target.name]: ""
            });
        }, 2000);
    }

    return (
        <div>
            <CreateTaskHeader
                data={addonsData}
                buttonContent={addonsData.name}
                // values={{editing: true}}
                onCancel={() => {
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            data: addonsData.name
                        },
                    });
                }}
            />
            {addonsData.name === "PGL stack" ? <>
                <div style={{ padding: "110px 20px 0 20px" }}>
                    <Box title="Username"
                        subtitle={<div style={{ display: "flex" }}>
                            <p style={{ ...subTitleStyle, marginRight: "10px" }}>{addonsData.value.username ? addonsData.value.username : "-"}</p>
                        </div>}
                        titleStyle={titleStyle}
                        subTitleStyle={subTitleStyle}
                        style={boxStyle}
                    />
                </div>
                <div style={{ padding: "20px 20px 0 20px" }}>
                    <Box title="Password"
                        subtitle={addonsData.value.password ?
                            <div>
                                <span style={{ ...subTitleStyle, marginRight: "6px" }}>{!showMore.password ? isSecret.password ? "********" : addonsData.value.password : isSecret.password ? "********" : addonsData.value.password.substring(0, 60) + "..."}</span>
                                <img src={isSecret.password ? eyeIcon : eyeCloseIcon} style={{ cursor: "pointer" }} onClick={() => {
                                    setIsSecret({
                                        ...isSecret,
                                        password: !isSecret.password
                                    });
                                }} />
                                {addonsData.value && addonsData.value.password && addonsData.value.password !== "" && <Tooltip title={tooltipTxt.password} placement="bottomLeft" open={tooltipTxt.password && tooltipTxt.password !== ""}>
                                    <img name="password" src={copyIcon} style={{ cursor: "pointer" }} onClick={(e) => {
                                        copyFunc(e, addonsData.value.password);
                                    }} />
                                </Tooltip>}
                            </div>
                            : "-"
                        }
                        titleStyle={titleStyle}
                        subTitleStyle={subTitleStyle}
                        style={boxStyle}
                    />
                </div>
                <div style={{ padding: "20px 20px 0 20px" }}>
                    <Box title="Endpoint"
                        subtitle={addonsData.value.url ? (checkTryWithoutLogin ? addonsData.value.url : <a href={'https://' + addonsData.value.url} target="_blank">{addonsData.value.url}</a>) : "-"}
                        titleStyle={titleStyle}
                        subTitleStyle={subTitleStyle}
                        style={boxStyle}
                    />
                </div>
                <div style={{ padding: "20px 20px 0 20px" }}>
                    <Box title="Cloud Watch Collector"
                        subtitle={addonsData.value.cloudwatch_collector ? addonsData.value.cloudwatch_collector : "-"}
                        titleStyle={titleStyle}
                        subTitleStyle={subTitleStyle}
                        style={boxStyle}
                    />
                </div>
                <div style={{ padding: "20px 20px 0 20px" }}>
                    <Box title="Loki"
                        subtitle={addonsData.value.loki ? addonsData.value.loki : "-"}
                        titleStyle={titleStyle}
                        subTitleStyle={subTitleStyle}
                        style={boxStyle}
                    />
                </div>
                <div style={{ padding: "20px 20px 0 20px" }}>
                    <Box title="Object Expiration"
                        subtitle={addonsData.value.s3_object_expiration ? addonsData.value.s3_object_expiration : "-"}
                        titleStyle={titleStyle}
                        subTitleStyle={subTitleStyle}
                        style={boxStyle}
                    />
                </div>
                <div style={{ padding: "20px 20px 0 20px" }}>
                    <Box title="Dashboard refresh interval"
                        subtitle={addonsData.value.dashboard_refresh_interval ? addonsData.value.dashboard_refresh_interval : "-"}
                        titleStyle={titleStyle}
                        subTitleStyle={subTitleStyle}
                        style={boxStyle}
                    />
                </div>
            </>
                :
                addonsData.name === "K8s Dashboard" ? <>
                    <div style={{ padding: "110px 20px 0 20px" }}>
                        <Box title="Hostname"
                            subtitle={addonsData.value.k8s_dashboard_hostname ? <a href={'https://' + addonsData.value.k8s_dashboard_hostname} target="_blank">{addonsData.value.k8s_dashboard_hostname}</a> : "-"}
                            titleStyle={titleStyle}
                            subTitleStyle={subTitleStyle}
                            style={boxStyle}
                        />
                    </div>
                    <div style={{ padding: "20px 20px 0 20px" }}>
                        <Box title="Admin Token"
                            subtitle={addonsData.value.admin_token ?
                                <>
                                    <div>
                                        <span style={{ ...subTitleStyle, marginRight: "6px" }}>{!showMore.admin ? isSecret.admin ? "********" : addonsData.value.admin_token : isSecret.admin ? "********" : addonsData.value.admin_token.substring(0, 60) + "..."}</span>
                                        <img src={isSecret.admin ? eyeIcon : eyeCloseIcon} style={{ cursor: "pointer" }} onClick={() => {
                                            setIsSecret({
                                                ...isSecret,
                                                admin: !isSecret.admin
                                            });
                                            setShowMore({
                                                ...showMore,
                                                admin: !showMore.admin
                                            });
                                        }} />
                                        {!isSecret.admin && addonsData.value?.admin_token && addonsData.value.admin_token !== "" && <Tooltip title={tooltipTxt.admin} placement="bottomLeft" open={tooltipTxt.admin && tooltipTxt.admin !== ""}>

                                            <img name="admin" src={copyIcon} style={{ cursor: "pointer", marginLeft: "10px" }} onClick={(e) => {
                                                copyFunc(e, addonsData.value.admin_token);
                                            }} />
                                        </Tooltip>}
                                    </div>
                                    {/* {!isSecret.admin && <a onClick={() => {
                                        setShowMore({
                                            ...showMore,
                                            admin: !showMore.admin
                                        });
                                    }}>{showMore.admin ? "See more" : "See less"}</a>} */}
                                </>
                                : "-"} titleStyle={titleStyle}
                            subTitleStyle={subTitleStyle}
                            style={boxStyle}
                        />
                    </div>
                    <div style={{ padding: "20px 20px 0 20px", marginBottom: "50px" }}>
                        <Box title="Read Only Token"
                            subtitle={addonsData.value.read_only_token ?
                                <>
                                    {/* <p style={subTitleStyle}>{!showMore.readOnly ? addonsData.value.read_only_token : addonsData.value.read_only_token.substring(0, 100) + '...'}</p> */}

                                    <div>
                                        <span style={{ ...subTitleStyle, marginRight: "6px" }}>{!showMore.readOnly ? isSecret.readOnly ? "********" : addonsData.value.read_only_token : isSecret.readOnly ? "********" : addonsData.value.read_only_token.substring(0, 60) + "..."}</span>
                                        <img src={isSecret.readOnly ? eyeIcon : eyeCloseIcon} style={{ cursor: "pointer" }} onClick={() => {
                                            setIsSecret({
                                                ...isSecret,
                                                readOnly: !isSecret.readOnly
                                            });
                                            setShowMore({
                                                ...showMore,
                                                readOnly: !showMore.readOnly
                                            });
                                        }} />
                                        {!isSecret.readOnly && addonsData.value?.read_only_token && addonsData.value.read_only_token !== "" && <Tooltip title={tooltipTxt.readOnly} placement="bottomLeft" open={tooltipTxt.readOnly && tooltipTxt.readOnly !== ""}>
                                            <img name="readOnly" src={copyIcon} style={{ cursor: "pointer", marginLeft: "10px" }} onClick={(e) => {
                                                copyFunc(e, addonsData.value.read_only_token);
                                            }} />
                                        </Tooltip>}
                                    </div>
                                    {/* {!isSecret.readOnly && <a onClick={() => {
                                        setShowMore({
                                            ...showMore,
                                            readOnly: !showMore.readOnly
                                        });
                                    }}>{showMore.readOnly ? "See more" : "See less"}</a>} */}
                                </>
                                : "-"}
                            titleStyle={titleStyle}
                            subTitleStyle={subTitleStyle}
                            style={boxStyle}
                        />
                    </div>
                </>
                    : addonsData.name === "ArgoFlow" ? <>
                        <div style={{ padding: "110px 20px 0 20px" }}>
                            <Box title="Host"
                                subtitle={addonsData.value && Object.keys(addonsData.value).length > 0 ? addonsData.value.argoworkflow_host : "-"}
                                // subtitle={addonsData.value ? <a href={'https://' + addonsData.value} target="_blank">{addonsData.value}</a> : "-"}
                                titleStyle={titleStyle}
                                subTitleStyle={subTitleStyle}
                                style={boxStyle}
                            />
                        </div>
                    </>
                        : <>
                            <div style={{ padding: "110px 20px 0 20px" }}>
                                <Box title="Domain"
                                    subtitle={addonsData.value ? addonsData.value : "-"}
                                    // subtitle={addonsData.value ? <a href={'https://' + addonsData.value} target="_blank">{addonsData.value}</a> : "-"}
                                    titleStyle={titleStyle}
                                    subTitleStyle={subTitleStyle}
                                    style={boxStyle}
                                />
                            </div>
                        </>}
        </div>
    );
}