import React from 'react';
import Input from '@iso/components/uielements/input';
import { InputBoxWrapper } from './Checkout.styles';

export default function({ label, placeholder, important, name, value, onChange, disabled, style }) {
  return (
    <InputBoxWrapper className="isoInputBox">
      <label>
        {label}
        {important ? <span className="asterisk">*</span> : null}
      </label>
      <Input size="large" placeholder={placeholder} name={name} value={value} onChange={onChange} disabled={disabled} style={{cursor: disabled ? "not-allowed" : "auto", width: (style && style.width) ? style.width : "auto"}} />
    </InputBoxWrapper>
  );
}
