import { React, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import Input, {
    InputSearch,
    InputGroup,
    Textarea,
} from '@iso/components/uielements/input';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled, { createGlobalStyle } from 'styled-components';
import Select, { SelectOption } from '@iso/components/uielements/select';
import initialData from '../../../redux/scrumBoard/data';
import { useEffect } from 'react';
import { Link, useFetcher, useNavigate } from 'react-router-dom';
import Dropzone from '@iso/components/uielements/dropzone';
import DropzoneWrapper from '../../AdvancedUI/Dropzone/Dropzone.styles';
import { AlertContext } from '../../../context/alertContext';
import api from '../../../api';
import { gcpContent } from './gcpStepContents';
import Scrollbars from 'react-custom-scrollbars-2';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import { formatErrors } from '../../../context/helper';

const Option = SelectOption;

const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;


const serverInsTypes = ["t3.small", "m4.large", "c5.large", "r3.large"];
serverInsTypes.map((child, ind) => {
    serverInsTypes.push(<Option key={child} name="serverInsType" value={child}>{child}</Option>);
});

var optionAzs = [];

const innerTitleStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "0.035px"
};

const innerSubtitleStyle = {
    color: "#888888",
    fontFamily: "Nunito Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "300",
    letterSpacing: "0.028px"
};

const titleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
};

const subTitleStyle = {
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400"
};

export default function GcpIntgration({
    // handleSubmit,
    data,
    connected,
    setConnected,
    load,
    setLoad,
    onCancel,
    onDelete,
    openDrawer,
    onEditCancel,
    tabSelected,
    openAddOns
}) {

    const { logout, setOpenRightDrawer } = useContext(AppContext);
    const { alertOptions, set_alertOptions, handleAlerts } = useContext(AlertContext);
    const alertContext = useContext(AlertContext);
    const navigate = useNavigate();

    const [jsonData, setJsonData] = useState();
    const [file, setFile] = useState();
    const [error, setError] = useState({
        status: false,
        msg: "Success"
    });
    const [showAll, setShowAll] = useState(false);

    const [stepContent, setStepContent] = useState([]);
    const [subStepContent, setSubStepContent] = useState([]);

    useEffect(() => {
        setStepContent(gcpContent);
        setSubStepContent(gcpContent[2].boxSubtitleDetails);
    }, []);

    const handleClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: ""
            },
        });
    }

    const convertToRoman = (num) => {
        let numberArr = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
        let RomanArr = [
            "m",
            "cm",
            "d",
            "cd",
            "c",
            "xc",
            "l",
            "xl",
            "x",
            "ix",
            "v",
            "iv",
            "i"
        ];
        let result = [];

        const findElement = e => {
            return e <= num;
        };

        while (num > 0) {
            let nextHighest = numberArr.find(findElement);

            result.push(RomanArr[numberArr.indexOf(nextHighest)]);
            num -= nextHighest;
        }
        let newResult = result.join("");

        return newResult;
    }

    const handleReadFile = (file) => {
        const reader = new FileReader();

        reader.onload = () => {
            try {
                const jsonContent = JSON.parse(reader.result);
                // Use the parsed JSON content as needed
                setJsonData(jsonContent);
            } catch (error) {
                console.error('Error parsing JSON file:', error);
            }
        };

        if (file) {
            reader.readAsText(file);
        }
    };

    const handleGcpIntegration = () => {
        setLoad({ ...load, gcp: true });
        handleClick();
        console.log("inside handleGcpIntegration :", jsonData);
        // after 10sec show Successfully Connected GCP
        // let myTimeout = setTimeout(() => {
        //     set_alertOptions(newVal => ({
        //         ...newVal,
        //         status: true,
        //         type: "info",
        //         message: "Info",
        //         description: "GCP Account Connected Successfully! Please wait we are enabling services",
        //     }));

        //     setTimeout(() => {
        //         set_alertOptions(newVal => ({
        //             ...newVal,
        //             status: false,
        //             type: "info",
        //             message: "Info",
        //             description: "GCP Account Connected Successfully! Please wait we are enabling services",
        //         }));
        //     }, 10 * 1000);
        // }, 10 * 1000);


        api.post(process.env.REACT_APP_API_URI + '/api/integration/gcp/connect/', jsonData)
            .then((res) => {
                let data = res.data;
                console.log('data in gcp integration :', data);
                // console.log('handleAlerts :', handleAlerts);
                // console.log('set_alertOptions :', set_alertOptions);
                if (data.status) {
                    // clearTimeout(myTimeout);
                    // handleClick();
                    set_alertOptions(newVal => ({
                        ...newVal,
                        status: true,
                        type: "info",
                        message: "Info",
                        description: "GCP Account Connected Successfully! Please wait we are enabling services",
                    }));

                    // Call GCP Service enable api
                    api.post(process.env.REACT_APP_API_URI + '/api/integration/gcp/services/' + data.data.id)
                        .then((res) => {
                            let data = res.data;

                            if (data.status) {
                                setConnected(!connected);
                                set_alertOptions(newVal => ({
                                    ...newVal,
                                    status: true,
                                    type: "success",
                                    message: "Success",
                                    description: "Enabled Services successfully.",
                                }));
                                setTimeout(() => {
                                    set_alertOptions(newVal => ({
                                        ...newVal,
                                        status: false,
                                        type: "success",
                                        message: "Success",
                                        description: "Enabled Services successfully.",
                                    }));
                                }, 5 * 1000);
                                setLoad({ ...load, gcp: false });
                            }
                            else {
                                let err = formatErrors(data.error_details);
                                console.log("err in /gcp/integration :", err);

                                set_alertOptions(newVal => ({
                                    ...newVal,
                                    status: true,
                                    type: "error",
                                    message: "Error",
                                    description: "Failed to enabled GCP services.",
                                }));
                                setTimeout(() => {
                                    set_alertOptions(newVal => ({
                                        ...newVal,
                                        status: false,
                                        type: "error",
                                        message: "Error",
                                        description: "Failed to enabled GCP services.",
                                    }));
                                }, 5 * 1000);
                                setLoad({ ...load, gcp: false });
                            }
                        })
                        .catch((err) => {
                            console.log("Error :", err);
                            setLoad({ ...load, gcp: false });
                        })

                }
                else {
                    // handleClick();
                    // clearTimeout(myTimeout);
                    let err = formatErrors(data.error_details);
                    console.log("err in /gcp/integration :", err);

                    set_alertOptions(newVal => ({
                        ...newVal,
                        status: true,
                        type: "error",
                        message: "Failed",
                        description: err,
                    }));

                    setTimeout(() => {
                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: false,
                            type: "error",
                            message: "Failed",
                            description: err,
                        }));
                    }, 5 * 1000);
                    // handleAlerts("error", "Failed", "Error Connecting.");
                }
                setLoad({ ...load, gcp: false });
            })
            .catch((err) => {
                // handleClick();
                // clearTimeout(myTimeout);
                set_alertOptions(newVal => ({
                    ...newVal,
                    status: true,
                    type: "error",
                    message: "Failed",
                    description: "Error Connecting.",
                }));

                setTimeout(() => {
                    set_alertOptions(newVal => ({
                        ...newVal,
                        status: false,
                        type: "error",
                        message: "Failed",
                        description: "Error Connecting.",
                    }));
                }, 5 * 1000);
                // handleAlerts("error", "Failed", "Something Went Wrong!");
                setLoad({ ...load, gcp: false });
                console.log('Error in GcpIntegration :', err);
            })
    }

    const handelRemoveFile = () => {
        console.log("handelRemoveFile :", file);
        eventHandlers.removedfile();
    }

    const componentConfig = {
        iconFiletypes: ['.json'],   // ['.jpg', '.png', '.gif']
        method: true,
        showFiletypeIcon: false,
        uploadMultiple: false,
        maxFilesize: 2, // MB
        maxFiles: 1,
        dictMaxFilesExceeded: 'You can only upload upto 1 image',
        dictRemoveFile: 'Delete',
        dictCancelUploadConfirmation: 'Are you sure to cancel upload?',
        postUrl: 'no-url',
    };

    const djsConfig = {
        acceptedFiles: 'application/json',
        autoProcessQueue: false,
        maxFiles: 1,
        // previewTemplate: (
        //     <div>
        //         <p>Selected File</p>
        //         {/* <button onClick={() => setFile(null)}>Remove File</button> */}
        //         </div>
        //   )
    };

    const eventHandlers = {
        addedfile: (file) => {
            setFile(file);
            console.log("file in addedfile :", file);
            handleReadFile(file);
        },
        success: (file) => {
            console.log("File in success :", file);
            set_alertOptions(newVal => ({
                ...newVal,
                status: true,
                type: "success",
                message: "Success",
                description: `${file.name} successfully uploaded`,
            }));

            setTimeout(() => {
                set_alertOptions(newVal => ({
                    ...newVal,
                    status: false,
                    type: "success",
                    message: "Success",
                    description: `${file.name} successfully uploaded`,
                }));
            }, 5 * 1000);
        },
        error: (error) => {
            console.log("error :", error);
            // alert('error', 'Server is not set in the demo');
            set_alertOptions(newVal => ({
                ...newVal,
                status: true,
                type: "error",
                message: "Success",
                description: `${file.name} successfully uploaded`,
            }));

            setTimeout(() => {
                set_alertOptions(newVal => ({
                    ...newVal,
                    status: false,
                    type: "error",
                    message: "Error",
                    description: `Error in uploading File`,
                }));
            }, 5 * 1000);
        },
        removedfile: (file) => {
            console.log("file in removedfile :", file);
            setFile(null);
        },
    };

    return (
        <ProdContentHold>
            <CreateTaskHeader
                buttonContent="GCP"
                data={"Yes"}
                // values={{editing: true}}
                onCancel={() => {
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            myClusterData: "",
                            tabSelected: "",
                        },
                    });
                }}
                onDelete={onDelete}
                onEditCancel={() => {
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            myClusterData: "",
                            tabSelected: "",
                        },
                    });
                }}
            />
            <Scrollbars style={{ height: "55vh", width: "100%" }}>
                <div style={{ marginTop: "100px", padding: "20px" }}>
                    {stepContent && stepContent.map((val, ind) => {
                        return (<div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "95%" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ border: "2px solid", marginLeft: "10px", padding: "5px", marginRight: "12px" }}>{ind + 1}</div>
                                    <div style={{ display: "flex", alignItems: "center" }}><p style={{ fontFamily: "Poppins", fontSize: "14px", fontStyle: "normal", fontWeight: "400", letterSpacing: "0.035px" }}> {val.boxTitle1} </p></div>
                                </div>
                                {ind === 0 && <div style={{ display: "flex", alignItems: 'center' }}>
                                    <Link onClick={() => {
                                        stepContent.map((val, ind) => {
                                            val.isOpen = !showAll ? true : false;
                                        });
                                        subStepContent.map((val, ind) => {
                                            val.isOpen = !showAll ? true : false;
                                        });
                                        setStepContent([...stepContent]);
                                        setSubStepContent([...subStepContent]);
                                        setShowAll(showAll => !showAll);
                                    }} style={{
                                        color: "#132f44",
                                        fontFamily: "Nunito Sans",
                                        fontSize: "11px",
                                        fontWeight: "400",
                                        letterSpacing: "0.028px"
                                    }}> {!showAll ? "Show all Images" : "Hide all Images"} </Link>
                                </div>}
                            </div>
                            <div style={{ marginLeft: "20px" }}>
                                <div style={!val.isOpen ? { borderLeft: "2px solid" } : { borderLeft: "2px dashed" }}>
                                    <Box
                                        title={val.boxTitle2}
                                        subtitle={<p>{val.boxSubtitle} {!val.boxSubtitleDetails && val.image && <Link onClick={() => {
                                            //setStepContent()
                                            val.isOpen = !val.isOpen;
                                            setStepContent([...stepContent]);
                                        }} style={{
                                            color: "#132f44",
                                            fontFamily: "Nunito Sans",
                                            fontSize: "11px",
                                            fontWeight: "400",
                                            letterSpacing: "0.028px"
                                        }}> {val.isOpen ? "Hide Image" : "Show Image"} </Link>}</p>}
                                        titleStyle={innerTitleStyle}
                                        subTitleStyle={innerSubtitleStyle}
                                        style={{ border: "0" }}
                                    />
                                </div>
                                {val.boxSubtitleDetails && val.boxSubtitleDetails.map((ele, idx) => {
                                    return (
                                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "40px" }}>
                                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div style={{ border: "2px solid", marginLeft: "10px", padding: "5px", marginRight: "12px", height: "fit-content", alignItems: "center" }}>{convertToRoman(idx + 1)}</div>
                                                <Box
                                                    subtitle={<p>{ele.title} <Link onClick={() => {
                                                        //setStepContent()
                                                        ele.isOpen = !ele.isOpen;
                                                        setSubStepContent([...subStepContent]);
                                                    }} style={{
                                                        color: "#132f44",
                                                        fontFamily: "Nunito Sans",
                                                        fontSize: "11px",
                                                        fontWeight: "400",
                                                        letterSpacing: "0.028px"
                                                    }}> {ele.isOpen ? "Hide Image" : "Show Image"} </Link></p>}
                                                    subTitleStyle={innerSubtitleStyle}
                                                    style={{ padding: "10px 0" }}
                                                />
                                            </div>
                                            {ele.isOpen && <div style={{ padding: "10px 40px" }}>
                                                <img src={ele.image} style={{ width: "90%" }} />
                                            </div>}
                                        </div>
                                    );
                                })}
                                {val.isOpen && !val.boxSubtitleDetails && val.image && <div style={{ padding: "10px 40px" }}>
                                    <img src={val.image} style={{ width: "90%" }} />
                                </div>}
                                {val.isOpen && !val.boxSubtitleDetails && val.image2 && <div style={{ padding: "10px 40px" }}>
                                    <img src={val.image2} style={{ width: "90%" }} />
                                </div>}
                            </div>
                        </div>);
                    })}
                </div>
            </Scrollbars>

            <div style={{ borderTop: "2px solid rgba(0, 0, 0, 0.25)", padding: "20px" }}>
                {/* <DropzoneWrapper style={{marginTop: "60px"}}> */}

                <Box
                    title={"Please upload JSON file *"}
                    subtitle={"upload the JSON file."}
                    titleStyle={titleStyle}
                    subTitleStyle={subTitleStyle}
                />
                <div style={{ padding: "0 20px", marginBottom: "90px" }}>
                    <DropzoneWrapper>
                        <Dropzone
                            config={componentConfig}
                            eventHandlers={eventHandlers}
                            djsConfig={djsConfig}
                        />
                    </DropzoneWrapper>
                </div>
                {/* </DropzoneWrapper> */}
            </div>
            <CreateTaskFooter buttonContent={"GCPIntegration"} givenButtonFunction1={handleGcpIntegration} givenButtonFunction2={handelRemoveFile} />
        </ProdContentHold>
    );
};
