import styled from 'styled-components';

export const CreateTaskWrapper = styled.div`
  // padding: 0 30px;
`;
export const AttachmentWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
