import styled from 'styled-components';
import { palette } from 'styled-theme';

const AntTab = ComponentName => styled(ComponentName)`
  &.ant-tabs {
    border: 1px solid ${palette('border', 0)};
    &:not(.ant-tabs-vertical) {
      > .ant-tabs-content-animated {
        display: ${props => (props['data-rtl'] === 'rtl' ? 'block' : 'flex')};
      }
    }

    .ant-tabs-nav {
      .ant-tabs-tab {
        margin: ${props => props['data-rtl'] === 'rtl' ? '0 0 0 24px' : '0 24px 0 0'};

        .anticon:not(.anticon-close) {
          margin: ${props =>
    props['data-rtl'] === 'rtl' ? '0 0 0 8px' : '0 8px 0 0'};

          &.anticon-close{
            right: ${props =>
    props['data-rtl'] === 'rtl' ? 'inherit' : '2px'};;
            left: ${props =>
    props['data-rtl'] === 'rtl' ? '2px' : 'inherit'};;
          }
        }
      }
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      background: white !important;
   }

   .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #1f1f1f;
    font-family: Poppins;
   }

    .ant-tabs-ink-bar {
      position: absolute;
      background: #000000;
      pointer-events: none;
    }

    .ant-tabs-tab-prev {
      left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
      right ${props => (props['data-rtl'] === 'rtl' ? '2px' : 'inherit')};
      transform: ${props =>
    props['data-rtl'] === 'rtl' ? 'rotate(180deg)' : 'rotate(0)'};

    }

    .ant-tabs-tab-next {
      left: ${props => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
      right ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '2px')};
      transform: ${props =>
    props['data-rtl'] === 'rtl' ? 'rotate(180deg)' : 'rotate(0)'};
    }

    &.ant-tabs.ant-tabs-vertical{
      .ant-tabs-tab-prev,
      .ant-tabs-tab-next{
        transform: rotate(0);
      }
    }
  }

  &.my-tab-navigator{
    .ant-tabs-nav {
      .ant-tabs-tab:first-child {
        border-top-left-radius: 20px;
        border-bottom-right-radius: 0;
      }
      .ant-tabs-tab {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: 10px;
        color: #1f1f1f !important;
        background: #E4E4E4;
        font-family: Poppins;
        border: transparent !important;
        width: auto;
        align-items: center;
        justify-content: center;
        margin: ${props => props['data-rtl'] === 'rtl' ? '0 0 0 0' : '0 0 0 0'};
      }
      
      .ant-tabs-tab-active {
        background: white !important;
        border: none !important;
      }
      
      .ant-tabs-tab.ant-tabs-tab-active + .ant-tabs-tab {
        border-radius: 0 0 0 9px;
      }
    }


    .ant-tabs-nav::before {
      border-bottom: transparent;
    }
  }
  
  &.roles-tab-navigator{
    .ant-tabs-nav {
      .ant-tabs-tab:first-child {
        border-top-left-radius: 20px;
        border-bottom-right-radius: 0;
      }
      .ant-tabs-tab {
        color: #1F1F1F !important;
        background: #EDEDED;
        font-family: Poppins;
        width: auto;
        align-items: center;
        justify-content: center;
        margin: ${props => props['data-rtl'] === 'rtl' ? '0 0 0 0' : '0 0 0 0'};
        border-radius: 0px 0px 0px 0px;
        padding: 12px 14px;
        width: 113px;
      }
      
      .ant-tabs-nav-list{
        .ant-tabs-tab {
          margin-left: 0;
          // border: 1px solid grey !important;
          border-left: 1px solid #E4E4E4;
        }

        .ant-tabs-tab-active > div, .ant-tabs-tab > div{
          color: #1F1F1F;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .ant-tabs-nav::before {
      border-bottom: transparent;
    }
  }
`;

export default AntTab;
