import { useContext, useState } from "react";
import { AppContext } from "../../../context/ContextProvider";
import removeIcon from '../../../assets/images/crossIcon.svg';
import { GuardrailsContext } from "../../../context/guardrailsContext";
import { useGuardrailNode } from "./useGuardrailNode";
import GuardrailInfoDrawer from "../GuardrailModals/GuardrailInfoDrawer";

let conditionContentStyle = {
  width: "68px",
  height: "52px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 27px 0 0",
  padding: "6px 8px",
  borderRadius: "10px",
  backgroundColor: "#4d99ef",
};
let actionContentStyle = {
  opacity: "0.6",
  width: "90px",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#4d99ef",
  margin: "0 27px 0 0",
  width: "98px"
};
let operationContentStyle = {
  height: "19px",
  width: "68%",
  flexGrow: "0",
  opacity: "0.6",
  fontFamily: "Nunito Sans",
  paddingRight: "8px",
  fontSize: "14px",
  fontWeight: "600",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#1f1f1f",
};

const labelStyle = {
  flexGrow: "0",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  color: "#fff",
};

export default function ActionBox({ condition = "if Environment Name is All", conditionField = null, conditionOperation = null, conditionValue = "", conditionNo = "1" }) {
  const { setOpenRightDrawer } = useContext(AppContext);
  const { guardrailNodesData, setGuardrailNodesData, guardrailEdgesData, setGuardrailEdgesData, selectedGuardrailInfo, setSelectedGuardrailInfo } = useContext(GuardrailsContext);
  const { deleteNode } = useGuardrailNode();
  const [ openGuardrailInfo, setOpenGuardrailInfo ] = useState(false);
  const [modalLoaded, setModalLoaded] = useState(false);
  // const deleteNode = () => {
  //   // guardrailNodesData.forEach((val) => {
  //   //   if(val.id === toString(parseInt(conditionNo) + 3)){

  //   //   }
  //   // });
  //   console.log("My condition no: ", conditionNo);
  //   guardrailNodesData[parseInt(conditionNo) + 2] = {
  //     ...guardrailNodesData[guardrailNodesData.length - 1],
  //     data: { label: <ActionBox conditionField={selectedGuardrailInfo.info.selectedFilters[selectedGuardrailInfo.info.selectedFilters.length - 1].param} conditionOperation={selectedGuardrailInfo.info.selectedFilters[selectedGuardrailInfo.info.selectedFilters.length - 1].filter} conditionValue={selectedGuardrailInfo.info.selectedFilters[selectedGuardrailInfo.info.selectedFilters.length - 1].value} conditionNo={conditionNo.toString()}/> },
  //     id: guardrailNodesData[parseInt(conditionNo) + 2].id,
  //     position: guardrailNodesData[parseInt(conditionNo) + 2].position,
  //   };
  //   guardrailNodesData.pop();
  //   if (guardrailNodesData.length === 4) {
  //     guardrailNodesData[3] = {
  //       ...guardrailNodesData[3],
  //       //data: { label: <ActionBox conditionField={selectedGuardrailInfo.info.selectedFilters[0].param} conditionOperation={selectedGuardrailInfo.info.selectedFilters[0].filter} conditionValue={selectedGuardrailInfo.info.selectedFilters[0].value} conditionNo={"1"}/> },
  //       position: { x: 50, y: 600 },
  //     };
  //   }
  //   let sourceNodeIdx = guardrailEdgesData.findIndex((val) => val.source === toString(parseInt(conditionNo) + 2));
  //   let targetNodeIdx = guardrailEdgesData.findIndex((val) => val.target === toString(parseInt(conditionNo) + 2));

  //
  //   if (sourceNodeIdx >= 0) {
  //     guardrailEdgesData[sourceNodeIdx] = {
  //       id: `e${guardrailEdgesData[guardrailEdgesData.length - 1].target}-${guardrailEdgesData[sourceNodeIdx].target}`,
  //       source: guardrailEdgesData[guardrailEdgesData.length - 1].target,
  //       target: guardrailEdgesData[sourceNodeIdx].target,
  //       type: "customEdge"
  //     };
  //   }
  //   if (targetNodeIdx >= 0) {
  //     guardrailEdgesData[targetNodeIdx] = {
  //       id: `e${guardrailEdgesData[targetNodeIdx].source}-${guardrailEdgesData[guardrailEdgesData.length - 1].target}`,
  //       source: guardrailEdgesData[targetNodeIdx].source,
  //       target: guardrailEdgesData[guardrailEdgesData.length - 1].target,
  //       type: "customEdge"
  //     };
  //   }
  //   guardrailEdgesData.pop();
  //   selectedGuardrailInfo.info.selectedFilters[parseInt(conditionNo) - 1] = selectedGuardrailInfo.info.selectedFilters[selectedGuardrailInfo.info.selectedFilters.length - 1];
  //   selectedGuardrailInfo.info.selectedFilters.pop();
  //   setSelectedGuardrailInfo({...selectedGuardrailInfo});
  //   localStorage.setItem('selectedGuardrailInfo', JSON.stringify({...selectedGuardrailInfo}));
  //   console.log("GuardrailnodesData, guardrailEdges: ", guardrailNodesData, guardrailEdgesData);
  //   setGuardrailNodesData([...guardrailNodesData]);
  //   setGuardrailEdgesData([...guardrailEdgesData]);
  // };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {modalLoaded && <GuardrailInfoDrawer openGuardrailInfo={openGuardrailInfo} setOpenGuardrailInfo={setOpenGuardrailInfo} data={{
        heading: "Configure Condition",
        conditionNo: parseInt(conditionNo)
      }} modalLoaded={modalLoaded} setModalLoaded={setModalLoaded}/>}
      {guardrailNodesData.length > 4 && !selectedGuardrailInfo.view && <img src={removeIcon} style={{ height: "12px", width: "12px", position: "absolute", top: "8px", right: "8px", cursor: "pointer" }} onClick={() => {
        deleteNode(conditionNo);
      }} />}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} onClick={() => {
        if(!selectedGuardrailInfo.view){
          setOpenGuardrailInfo(true);
          setModalLoaded(true);
          // setOpenRightDrawer({
          //   drawerType: "guardrailNodeInfo",
          //   drawerProps: {
          //     data: {
          //       heading: "Configure Condition",
          //       conditionNo: parseInt(conditionNo),
          //     },
          //     /*setData: setMyClusterData,
          //     columnId: null,
          //     tabSelected: e.target.name,
          //     openAddOns: openAddOns*/
          //   },
          // });
        }
      }}>
        <div style={conditionContentStyle}><p style={labelStyle}>If</p></div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
          <span style={actionContentStyle}>Condition {conditionNo}</span>
          <span style={operationContentStyle}>{"if " + conditionField + " " + conditionOperation + " " + conditionValue}</span>
        </div>
      </div>
    </div>
  );
}