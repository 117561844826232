import styled from 'styled-components';
import { borderRadius } from '../../../library/helpers/style_utils';
import { palette } from 'styled-theme';

const StickerWidgetWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  ${borderRadius('5px')};

  .isoIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    flex-shrink: 0;
    background-color: rgba(0, 0, 0, 0.1);

    i {
      font-size: 30px;
    }
  }

  .isoHelperText {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    color: ${palette('error', 0)};
    padding-left: ${props =>
    props['data-rtl'] === 'rtl' ? 'inherit' : '13px'};
    padding-right: ${props =>
    props['data-rtl'] === 'rtl' ? '13px' : 'inherit'};
    margin: 15px 0;
    position: relative;
    display: flex;
    align-items: center;

    &:before {
      content: '*';
      color: ${palette('error', 0)};
      padding-right: 3px;
      font-size: 14px;
      line-height: 1;
      position: absolute;
      top: 2px;
      left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
      right: ${props => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
    }
  }

  &.otherApps{
    width: 24%;
    border-radius: 10px;
    border: 0px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 3px 0px;
    margin-bottom: 34px;
    margin-right: 35px;

    @media screen and (max-width: 1280px) {
      width: 384px;
    }
    
    // @media screen and (max-width: 799px) {
    //   width: 300px;
    // }
  }

  .otherAppBoxWrapper .isoExampleWrapper{
    margin-top: 0;
    
    .header-card{
      display: flex;
      justify-content: start;
      align-items: center;

      .title-img{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 1px solid #00000029;
      }

      .header-title{
        display: flex;
        flex-direction: column;
        justify-content: start;
        line-height: 1.4;
        margin-left: 10px;

        .header-text{
          color: #1F1F1F;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
        }

        .header-subText{
          color: #1F1F1F;
          font-family: Nunito Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
        }
      }
    }
    
    .header-status{
      border-radius: 17px;
      border: 1px solid #A1DBBF;
      background: #DDF1DD;
      padding: 3px 14px;

      img{
        margin-right: 6px;
      }
      
      .status-text{
        color: #2CAF32;
        font-family: Nunito Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .disconnected{
      border: 1px solid #E98A8A;
      background: #FFCACA;

      .status-text{
        color: #B50000;
      }
    }

    .card-description{
      color: #292929;
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
    }

    .middle-content{
      padding-left: 10px;
      padding-bottom: 50px;
    }
    
    .footer-title{

      img{
        margin-right: 10px;
      }

      .title-text{
        color: #292929;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
    }

    .footer-action{

      button{
        width: 20px;
        height: 15px;
        min-width: 35px;
        
        .ant-switch-handle{
          height: 13px;
          width: 13px;
          top: 1px;
        }
  
        .ant-switch-inner{
          width: 20px;
          height: 15px;
        }
      }

      button.ant-switch-checked{
        .ant-switch-handle{
          inset-inline-start: 20px;
        }
      }
    }

    .top-content{

    }
  
    .middle-content{
      
    }
    
    .footer-content{
      position: absolute;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      bottom: 0px;
      margin: 10px 20px;

      @media screen and (max-width: 1280px) {
        width: 80%;
      }
      
      @media screen and (max-width: 799px) {
        width: 70%;
      }
    }
  }

  .isoContentWrapper {
    width: 100%;
    padding: 20px 15px 20px 20px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    .isoStatNumber {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.1;
      margin: 0 0 5px;
    }

    .isoLabel {
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      line-height: 1.2;
    }
  }
`;

export { StickerWidgetWrapper };
