import { React, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import Input, {
    InputSearch,
    InputGroup,
    Textarea,
} from '@iso/components/uielements/input';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled, { createGlobalStyle } from 'styled-components';
import Select, { SelectOption } from '@iso/components/uielements/select';
import initialData from '../../../redux/scrumBoard/data';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AlertContext } from '../../../context/alertContext';
import api from '../../../api';
import { cloudflareContent } from './cloudflareStepContents';
import Scrollbars from 'react-custom-scrollbars-2';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import { formatErrors } from '../../../context/helper';
import { Form } from 'antd';

const Option = SelectOption;

const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

const serverInsTypes = ["t3.small", "m4.large", "c5.large", "r3.large"];
serverInsTypes.map((child, ind) => {
    serverInsTypes.push(<Option key={child} name="serverInsType" value={child}>{child}</Option>);
});

var optionAzs = [];

const innerTitleStyle = {
    fontFamily: "Poppins",
    color: "#474747",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "0.035px"
};

const innerSubtitleStyle = {
    color: "#888888",
    fontFamily: "Nunito Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "300",
    letterSpacing: "0.028px"
};


export default function CloudFlareIntgration({
    // handleSubmit,
    data,
    load,
    setLoad,
    connected,
    setConnected,
    onCancel,
    onDelete,
    openDrawer,
    onEditCancel,
    tabSelected,
    openAddOns
}) {

    const { setOpenRightDrawer } = useContext(AppContext);
    const { set_alertOptions, handleAlerts } = useContext(AlertContext);
    const [stepContent, setStepContent] = useState([]);
    const navigate = useNavigate();

    const [token, setToken] = useState();

    const [error, setError] = useState({
        status: false,
        msg: "Success"
    });

    const [form] = Form.useForm();

    useEffect(() => {
        setStepContent(cloudflareContent);
    }, []);


    const handleClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: ""
            },
        });
    }

    const handelInputChange = (e) => {
        setToken(e.target.value);
    }


    const handleIntegration = async (e) => {
        try {
            await form.validateFields();
            console.log("inside handleIntegration :", token);
            handleClick();
            setLoad({ ...load, cloudflare: true });
            let payload = {
                token: token
            }

            api.post(process.env.REACT_APP_API_URI + '/api/integration/cloudflare/', payload)
                .then((res) => {
                    let data = res.data;
                    console.log('data in cloudFlare integration :', data);
                    if (data.status) {
                        setConnected(!connected);
                        // handleClick();
                        // handleAlerts("success", "Success", "GCP Account Connected Successfully.");
                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: true,
                            type: "success",
                            message: "Success",
                            description: "CloudFlare Account Connected Successfully.",
                        }));

                        setTimeout(() => {
                            set_alertOptions(newVal => ({
                                ...newVal,
                                status: false,
                                type: "success",
                                message: "Success",
                                description: "CloudFlare Account Connected Successfully.",
                            }));
                        }, 5 * 1000);
                    }
                    else {
                        let err = formatErrors(data.error_details);
                        console.log("err in /cloudFlare/integration :", err);

                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: true,
                            type: "error",
                            message: "Failed",
                            description: err,
                        }));

                        setTimeout(() => {
                            set_alertOptions(newVal => ({
                                ...newVal,
                                status: false,
                                type: "error",
                                message: "Failed",
                                description: err,
                            }));
                        }, 5 * 1000);
                        // handleAlerts("error", "Failed", "Error Connecting.");
                    }
                    setLoad({ ...load, cloudflare: false });
                })
                .catch((err) => {
                    set_alertOptions(newVal => ({
                        ...newVal,
                        status: true,
                        type: "error",
                        message: "Failed",
                        description: "Something went wrong",
                    }));

                    setTimeout(() => {
                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: false,
                            type: "error",
                            message: "Failed",
                            description: "Something went wrong",
                        }));
                    }, 5 * 1000);
                    // handleAlerts("error", "Failed", "Something Went Wrong!");
                    setLoad({ ...load, cloudflare: false });
                    console.log('Error :', err);
                })
        }
        catch (error) {
            console.log("Error");
        }
    }

    return (
        <ProdContentHold>
            <Form form={form} name="cloudflare">
            <CreateTaskHeader
                buttonContent="Cloudflare"
                data={"Yes"}
                // values={{editing: true}}
                onCancel={() => {
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            myClusterData: "",
                            tabSelected: "",
                        },
                    });
                }}
                onDelete={onDelete}
                onEditCancel={() => {
                    setOpenRightDrawer({
                        drawerType: false,
                        drawerProps: {
                            myClusterData: "",
                            tabSelected: "",
                        },
                    });
                }}
            />

            <Scrollbars style={{ height: "70vh", width: "100%" }}>
                <div style={{ marginTop: "100px", padding: "20px" }}>
                    {stepContent && stepContent.map((val, ind) => {
                        return (<div style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "95%" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{ border: "2px solid", marginLeft: "10px", padding: "5px", marginRight: "12px" }}>{ind + 1}</div>
                                    <div style={{ display: "flex", alignItems: "center" }}><p style={{ fontFamily: "Poppins", fontSize: "14px", fontStyle: "normal", fontWeight: "600", letterSpacing: "0.035px" }}> {val.boxTitle} </p></div>
                                </div>
                                {/* {ind === 0 && <div style={{display: "flex", alignItems: 'center'}}>
                                    <Link onClick={() => {
                                        stepContent.map((val, ind) => {
                                            val.isOpen = !showAll ? true : false;
                                        });
                                        setStepContent([...stepContent]);
                                        setShowAll(showAll => !showAll);
                                    }} style={{
                                        color: "#132f44",
                                        fontFamily: "Nunito Sans",
                                        fontSize: "11px",
                                        fontWeight: "400",
                                        letterSpacing: "0.028px"
                                    }}> {!showAll ? "Show all Images" : "Hide all Images"} </Link>
                                </div>} */}
                            </div>
                            <div style={{ marginLeft: "20px" }}>
                                <div style={!val.isOpen ? { borderLeft: "2px solid" } : { borderLeft: "2px dashed" }}>
                                    <Box
                                        title={<>{val.boxSubtitle} <Link onClick={() => {
                                            //setStepContent()
                                            val.isOpen = !val.isOpen;
                                            setStepContent([...stepContent]);
                                        }} style={{
                                            color: "#afafaf",
                                            fontFamily: "Nunito Sans",
                                            fontSize: "11px",
                                            fontWeight: "400",
                                            letterSpacing: "0.028px"
                                        }}> {val.image ? val.isOpen ? "Hide Image" : "Show Image" : ""} </Link></>}
                                        titleStyle={innerTitleStyle}
                                        style={{ border: "0" }}
                                    />
                                </div>
                                <div style={{ padding: "10px 40px" }}>
                                    {val.isOpen && <img src={val.image} style={{ width: "100%", height: "550px" }} />}
                                </div>
                            </div>
                        </div>);
                    })}
                </div>
            </Scrollbars>

            <div style={{ borderTop: "2px solid rgba(0, 0, 0, 0.25)", padding: "20px" }}>

                <Box
                    title={<div style={{ display: "flex" }}><span style={{
                        color: "#1f1f1f",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                    }}>CloudFlare Api Token<span style={{ marginRight: "5px", color: "red" }}> * </span> </span></div>
                    }
                    style={{ marginBottom: "84px" }}
                >
                    <ContentHolder>
                        <Form.Item
                            name={"token"}
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter token."
                                }
                            ]}
                        >
                            <Input
                                name="token"
                                value={token}
                                onChange={handelInputChange}
                                placeholder="Enter Cloudflare Api Token"
                                style={{ height: "59px" }}
                            />
                        </Form.Item>
                    </ContentHolder>
                </Box>
            </div>
            <CreateTaskFooter buttonContent={"CloudflareIntegration"} givenButtonFunction1={handleIntegration} />

            {/* <Box>
                <ContentHolder style={{
                    position: "relative"
                }}>
                    <Button type="primary" onClick={handleIntegration} style={{ position: "absolute", right: "40%", marginTop: "-20px" }}>
                        Connect
                    </Button>
                </ContentHolder>
            </Box> */}
            </Form>
        </ProdContentHold>
    );
};
