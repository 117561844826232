import { Button } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Label } from "../containers/UsersCRUD/UsersCRUD.styles";
import { AppContext } from "./ContextProvider";
import SituationBox from "../containers/Guardrails/GuardrailFlow/SituationBox";
import OperationBox from "../containers/Guardrails/GuardrailFlow/OperationBox";
import ModuleBox from "../containers/Guardrails/GuardrailFlow/ModuleBox";
import ActionBox from "../containers/Guardrails/GuardrailFlow/ActionBox";
import api from "../api";
import { useNavigate } from "react-router-dom";
import { useGuardrailNode } from "../containers/Guardrails/GuardrailFlow/useGuardrailNode";
import { AlertContext } from "./alertContext";

const GuardrailsContext = React.createContext();

const GuardrailsProvider = ({ children }) => {
  let nodeStyle = {
    width: "433px",
    height: "93px",
    padding: "21px 3px 20px 8px",
    borderRadius: "10px",
    backgroundColor: "rgba(236, 244, 251, 0.7)",
  };
  let tempNodeData = [
    {
      id: "1",
      type: "what",
      data: {
        label: <SituationBox />
      },
      position: { x: 50, y: 100 },
      style: nodeStyle,      //sourcePosition: "right",
    },
    {
      id: "2",
      type: "when",
      data: {
        label: <OperationBox />
      },
      position: { x: 50, y: 243 },
      style: nodeStyle,      // targetPosition: "right",
    },
    {
      id: "3",
      type: "where",
      data: {
        label: <ModuleBox />
      },
      position: { x: 50, y: 386 },
      style: {...nodeStyle, backgroundColor: "rgba(244, 244, 244, 0.5)"},
      // sourcePosition: "left",
    },
    // {
    //   id: "4",
    //   type: "if",
    //   data: {
    //     label: <ActionBox />
    //   },
    //   position: { x: 50, y: 600 },
    //   style: nodeStyle,
    //   // sourcePosition: "left",
    // },
  ];


  let tempEdgesData = [
    {
      id: "e1-2",
      source: "1",
      target: "2",
      //animated: true,
      //style: { stroke: "#fff" },
    },
    {
      id: "e2-3",
      source: "2",
      target: "3",
      //animated: true,
      //style: { stroke: "#fff" },
    },
    // {
    //   id: "e3-4",
    //   source: "3",
    //   target: "4",
    //   type: "customEdge"
    //   //label: "Add",
    //   //animated: true,
    //   //style: { stroke: "#fff" }
    // }
  ];

  let navigate = useNavigate();

  const handleCreateGuardrail = () => {
    console.log("Selected filters: ", selectedGuardrailInfo.info);
    let phases = ["Creating", "Created", "Addons Initialising", "Active", "Addons Destroying", "Destroying", "Failed", "Updating", "Pending", "Destroyed", "Out Of Sync", "Destroy Failed", "Cluster Updating", "Update Failed"];
    let appliedFilters = selectedGuardrailInfo.info.selectedFilters.map((val) => {
      return {
        "param": val.param,
        "operation": val.filter,
        "value": val.param !== "cluster phase" ? val.value : phases.indexOf(val.value)
      }
    });
    let payload = {
      "name": selectedGuardrailInfo.guardrailName,
      "description": selectedGuardrailInfo.description ? selectedGuardrailInfo.description : "",
      "action_configuration": {
        "scheduled_at": selectedGuardrailInfo.info.scheduledDate === "" ? new Date() : (new Date(new Date(selectedGuardrailInfo.info.scheduledDate).toLocaleString("en-US", { month: 'numeric', day: 'numeric', year: 'numeric' }) + " " + selectedGuardrailInfo.info.scheduledTime)).toISOString(),
        "repeat_frequency": selectedGuardrailInfo.info.repeatFrequency,
        "repeat_occurrence": selectedGuardrailInfo.info.repeatOccurrence === "" ? null : selectedGuardrailInfo.info.repeatOccurrence,
        "repeat_on_days": selectedGuardrailInfo.info.repeatOn
      },
      "action": selectedGuardrailInfo.info.id,
      "owner": selectedGuardrailInfo.owners,
      "filters_to_apply": appliedFilters
    };
    console.log("Final payload: ", payload);
    if (selectedGuardrailInfo.editing) {
      api.put(`/api/guardrail/edit/${selectedGuardrailInfo.guardrailId}`, payload).then((res) => {
        let data = res.data;
        if (data.status) {
          set_alertOptions(alertOptions => ({
            status: true,
            type: "success",
            message: "Success",
            description: !selectedGuardrailInfo.editing ? "Guardrail created successfully." : "Guardrail updated successfully.",
          }));

          // opacity=0 after 10sec
          setTimeout(() => {
            set_alertOptions(alertOptions => ({
              tatus: false,
              type: "success",
              message: "Success",
              description: !selectedGuardrailInfo.editing ? "Guardrail created successfully." : "Guardrail updated successfully.",
            }));
          }, 5 * 1000);
          navigate('/guardrail');
        }
        else {
          set_alertOptions(alertOptions => ({
            status: true,
            type: "error",
            message: !selectedGuardrailInfo.editing ? "Guardrail Creation Failed" : "Guardrail Update Failed",
            description: data.error_details.name[0],
          }));

          // opacity=0 after 10sec
          setTimeout(() => {
            set_alertOptions(alertOptions => ({
              tatus: false,
              type: "error",
              message: !selectedGuardrailInfo.editing ? "Guardrail Creation Failed" : "Guardrail Update Failed",
              description: data.error_details.name[0],
            }));
          }, 5 * 1000);
        }
        //setValidateGuardrail(false);
      }).catch((err) => {
        //setValidateGuardrail(false);
        set_alertOptions(alertOptions => ({
          status: true,
          type: "error",
          message: !selectedGuardrailInfo.editing ? "Guardrail Creation failed" : "Guardrail Update Failed",
          description: "Something went wrong. Please try again.",
        }));

        // opacity=0 after 10sec
        setTimeout(() => {
          set_alertOptions(alertOptions => ({
            tatus: false,
            type: "error",
            message: !selectedGuardrailInfo.editing ? "Guardrail Creation failed" : "Guardrail Update Failed",
            description: "Something went wrong. Please try again.",
          }));
        }, 5 * 1000);
        console.log("error");
      });
    }
    else {
      payload["status"] = selectedGuardrailInfo.status;
      api.post('/api/guardrail/', payload).then((res) => {
        let data = res.data;
        if (data.status) {
          set_alertOptions(alertOptions => ({
            status: true,
            type: "success",
            message: "Success",
            description: "Guardrail created successfully.",
          }));

          // opacity=0 after 10sec
          setTimeout(() => {
            set_alertOptions(alertOptions => ({
              tatus: false,
              type: "success",
              message: "Success",
              description: !selectedGuardrailInfo.editing ? "Guardrail created successfully." : "Guardrail edited successfully.",
            }));
          }, 5 * 1000);
          navigate('/guardrail');
        }
        else {
          console.log("error");
          set_alertOptions(alertOptions => ({
            status: true,
            type: "error",
            message: !selectedGuardrailInfo.editing ? "Guardrail Creation failed" : "Guardrail Update Failed",
            description: data.error_details.name[0],
          }));

          // opacity=0 after 10sec
          setTimeout(() => {
            set_alertOptions(alertOptions => ({
              tatus: false,
              type: "error",
              message: !selectedGuardrailInfo.editing ? "Guardrail Creation failed" : "Guardrail Update Failed",
              description: data.error_details.name[0],
            }));
          }, 5 * 1000);
        }
        //setValidateGuardrail(false);
      }).catch((err) => {
        console.log("error");
        set_alertOptions(alertOptions => ({
          status: true,
          type: "error",
          message: !selectedGuardrailInfo.editing ? "Guardrail Creation failed" : "Guardrail Update Failed",
          description: "Something went wrong.",
        }));

        // opacity=0 after 10sec
        setTimeout(() => {
          set_alertOptions(alertOptions => ({
            tatus: false,
            type: "error",
            message: !selectedGuardrailInfo.editing ? "Guardrail Creation failed" : "Guardrail Update Failed",
            description: "Something went wrong.",
          }));
        }, 5 * 1000);
        //setValidateGuardrail(false);
      });
    }
  }

  const [guardrailNodesData, setGuardrailNodesData] = useState(tempNodeData);
  const [guardrailEdgesData, setGuardrailEdgesData] = useState(tempEdgesData);
  const [selectedGuardrailInfo, setSelectedGuardrailInfo] = useState({});
  const [guardrailLoading, setGuardrailLoading] = useState(false);
  const { set_alertOptions } = useContext(AlertContext);
  //const [openGuardrailInfo, setOpenGuardrailInfo] = useState(false);
  const { setOpenRightDrawer } = useContext(AppContext);

  useEffect(() => {
    let guardrailData = JSON.parse(localStorage.getItem('selectedGuardrailInfo'));
    if (guardrailData) {
      setSelectedGuardrailInfo(guardrailData);
    }
  }, []);
  return (
    <GuardrailsContext.Provider value={{
      guardrailNodesData, setGuardrailNodesData,
      guardrailEdgesData, setGuardrailEdgesData,
      selectedGuardrailInfo, setSelectedGuardrailInfo,
      guardrailLoading, setGuardrailLoading,
      tempEdgesData, tempNodeData,
      //openGuardrailInfo, setOpenGuardrailInfo,
      handleCreateGuardrail
    }}>
      {children}
    </GuardrailsContext.Provider>
  );
}

export { GuardrailsContext, GuardrailsProvider };