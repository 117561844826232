import { React, useState, useEffect, useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Row, Col, Tooltip, Form, Switch, Spin } from 'antd';
import Box from '@iso/components/utility/box';
import { Link } from 'react-router-dom';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import { SelectOption } from '@iso/components/uielements/select';
import { useLocation, useNavigate } from 'react-router-dom';
import ContentHolder from '@iso/components/utility/contentHolder';
import ExecutionHistoryIcon from '@iso/assets/images/execution-history-icon.svg';
import expandIcon from '@iso/assets/images/expandIcon.svg';
import rightArrow from '@iso/assets/images/templateArrowBlueRight.svg';
import downArrow from '@iso/assets/images/templateArrowBlueDown.svg';
import Input from '@iso/containers/Deployments/ApplicationBasicDetails.styles';
// import Input, {
//     InputSearch,
//     InputGroup,
//     Textarea,
// } from '@iso/components/uielements/input';
import api from '../../../api';
import { AppContext } from '../../../context/ContextProvider';
import { AlertContext } from '../../../context/alertContext';
import awsLogo from '../../../assets/images/aws.svg';
import customScriptIcon from '@iso/assets/images/custom-script-icon.svg';
import secretDetectionIcon from '@iso/assets/images/secret-detection-icon.svg';
import sonarcubeIcon from '@iso/assets/images/sonarcube-icon.svg';
import checkmarkIcon from '@iso/assets/images/checkmark-icon.svg';
import { customFilterOption, customSortOptions, formatErrors, marketplaceStatuses, timeSince } from '../../../context/helper';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { StickerWidgetWrapper } from '../../Widgets/SmallCards/SmallCards.styles';
import noDataPlusIcon from '@iso/assets/images/nodata-plus-icon.svg';
import noDataImg from '@iso/assets/images/no-data-found.svg';
import deleteIcon from '@iso/assets/images/tagsDelete.svg';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import Select from '@iso/containers/Deployments/Deployment.styles';
import CreateTaskFooter from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import { fetchBranches } from '../../../apis/Integrations';


const Option = SelectOption;

let itemContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: '14px',
    marginBottom: "15px"
}

const widgetStyle = {
    margin: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
};

const itemHeader = {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    color: "#1f1f1f",
};

export default ({
    data,
    columnId,
}) => {

    const { openRightDrawer, setOpenRightDrawer, workflowData, setWorkflowData, currentWorkflowData, setCurrentWorkflowData } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);

    const navigate = useNavigate();
    // const location = useLocation();

    const [form] = Form.useForm();

    const [templates, setTemplates] = useState([
        // {
        //     name: "Template-1",
        //     img: "",
        //     open: true,
        // },
        // {
        //     name: "Template-2",
        //     img: "",
        //     open: false,
        // },
        // {
        //     name: "Template-3",
        //     img: "",
        //     open: false,
        // },
        // {
        //     name: "Template-4",
        //     img: "",
        //     open: false,
        // },
        // {
        //     name: "Template-5",
        //     img: "",
        //     open: false,
        // },
    ]);

    const [fetching, setFetching] = useState(true);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        console.log("data in workflowTemplatesRightDrawer :", data);
        let url = `${process.env.REACT_APP_API_URI}/api/pipelines/workflows/templates/${currentWorkflowData.serviceId}/`;

        api.get(url)
            .then((res) => {
                let data = res.data;
                
                console.log("workflows templates data :", data);
                // if (data.status) {
                    let dataList = [];
                    data.map((temp, idx) => {
                        let myObj = {
                            id: temp.id,
                            envUniqueId: temp.environment_unique_id ? temp.environment_unique_id : "",
                            name: temp.name ? temp.name : "",
                            serviceId: temp.service,
                            image: temp.s3_url,
                            open: idx === 0 ? true : false,
                            triggeredAt: temp.created_at,
                            calculatedTriggeredAt: `${timeSince(new Date(temp.created_at))} ago`,
                            triggeredBy: temp.created_by,
                        };

                        dataList.push(myObj);
                    });

                    dataList = dataList.sort((a, b) => (a.triggeredAt.localeCompare(b.triggeredAt)));
                    console.log("dataList :", dataList);
                    setTemplates(dataList);
                    // toggleAlert("success", "Success", "Job for workflow execution submitted successfully.");
                // }
                // else {
                //     let err = formatErrors(data.error_details);
                //     console.log("Error in workflows execute api :", err);
                //     // toggleAlert("error", "Error", err);
                // }
            })
            .catch((err) => {
                console.log("Err in catch :", err);
                // toggleAlert("error", "Error", "Something went wrong. Please try again.");
            });
    }, []);

    const handelCancel = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
            },
        });
    }

    // const handelInputChange = (e) => {
    //     console.log("inside handelInputChange event :", e);
    //     setBuildData({ ...buildData, [e.target.name]: e.target.value });
    // }

    // const handleSelectChanges = (value, event) => {
    //     // integration-redirect
    //     console.log("inside handleSelectChanges event :", event);
    //     console.log("inside handleSelectChanges value :", value);
    //     if (event.name === "containerRegistry") {
    //         setBuildData({
    //             ...buildData,
    //             [event.name]: value,
    //             containerRegistryId: event["data-registryId"],
    //             region: event["data-region"],
    //             accountId: event["data-accountId"],
    //             alias: event["data-alias"],
    //             integrationType: event["data-integrationType"],
    //             logo: event["data-logo"],
    //         });
    //     }
    //     // else if (event.name === "appSource" && event["data-key"]) {
    //     //     setBuildData({ ...buildData, [event.name]: event.type, dockerRepoUrl: "", repoUrl: "", accountId: event["data-key"], accountName: event["data-alias"], isPrivate: false });
    //     // }
    //     // else if ((event.name === "appSource") && (event.type === "public")) {
    //     //     setBuildData({ ...buildData, [event.name]: event.type, dockerRepoUrl: "", repoUrl: "", dockerFilePath: "", accountId: "", accountName: "", branch: "", isPrivate: false });
    //     //     return;
    //     // }
    //     // else if (event.name === "dockerRepoUrl") {
    //     //     console.log('event["data-url"] :', event["data-url"]);
    //     //     console.log('event["data-isprivate"] :', event["data-isprivate"]);
    //     //     setBuildData({ ...buildData, [event.name]: event.type, repoUrl: event["data-url"], isPrivate: event["data-isprivate"], isOwner: event["is-owner"] });
    //     // }
    //     // else if (event.type === "integration") {
    //     //     handleCancel();
    //     //     navigate("/integrations");
    //     //     return;
    //     // }
    //     else {
    //         setBuildData({ ...buildData, [event.name]: event.type });
    //     }
    // }

    const handleOpenTemplate = (e) => {
        console.log("clicked handleOpenTemplate...",);

    }

    const handleCancel = (e) => {
        console.log("clicked handleCancel...");
        handelCancel();
    }
    
    const handleArrowClick = (e, ind) => {
        console.log("clicked handleArrowClick :", ind);
        let tempData = [];
        templates.map((item, idx) => {
            if(ind === idx){
                item.open = !item.open;
            }

            tempData.push(item);
        });

        setTemplates([...tempData]);
    }
    
    const handleImgPreview = (e, ind) => {
        console.log("clicked handleImgPreview :", ind);
    }


    return (
        <LayoutWrapper className="clusterInfo" style={{ padding: "0" }}>
            <CreateTaskHeader buttonContent={columnId} onCancel={handelCancel} />

            <div style={{ marginTop: "84px", width: "100%" }} />

            <StickerWidgetWrapper className="isoStickerWidget" style={{ ...widgetStyle, marginTop: "34px", marginBottom: "150px" }}>
                {templates.map((temp, idx) => (
                    <>
                        <div className='template-item-container' style={itemContainer}>
                            <div className='template-header' style={itemHeader}>
                                <img src={ExecutionHistoryIcon} alt="" />
                                <span style={{ marginLeft: "23px" }}>{temp.name}</span>
                            </div>
                            <div className='template-dropdown' style={{  }}>
                                <span className='template-img-container'>
                                    <img
                                        src={temp.open ? downArrow : rightArrow}
                                        alt=""
                                        style={{cursor: "pointer"}}
                                        onClick={(e) => {
                                            console.log("I am inside img :", idx);
                                            handleArrowClick(e, idx);
                                        }}
                                    />
                                </span>
                            </div>
                        </div>

                        <hr className="" style={{ border: "1px solid #F2F2F2", width: "100%", marginBottom: "14px" }} />
                        
                        {/* Workflow preview img */}
                        {temp.open && 
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height :"250px", marginBottom: "31px", background: "#f2f2f2", padding: "28px 95px", position: "relative" }}>
                                <img src={temp.img} style={{ width: "100%", height: "100%" }} />
                                <img src={expandIcon} style={{ position:"absolute", top: "12px", right: "12px", cursor: "pointer" }} onClick={(e) => {
                                    handleImgPreview(e, idx);
                                }} />
                            </div>
                        }
                    </>
                ))}
            </StickerWidgetWrapper>

            <CreateTaskFooter buttonContent={"Source Config"} givenButtonFunction1={handleOpenTemplate} givenButtonFunction2={handleCancel} />
        </LayoutWrapper>
    );
};