import { Row } from "antd";
import { useState, useContext } from "react";
import styled from 'styled-components';
import Box from "../../../components/utility/box";
import CreateTaskHeader from "../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader";
import { AppContext } from "../../../context/ContextProvider";

const SecurityContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

let myTitle = (title) => (
    <div style={{display: "flex"}}>
        {/* <img src={TitleIcon} style={imgStyle} alt="image" /> */}
        <span>{title}</span>
    </div>
);

let mySubTitle = (subtitle) => (
    <div>
        <span> {subtitle} </span>
    </div>
);

let boxStyle = {
    border: '0',
    padding: "0",
    width: "100%",
    marginTop: "50px",
}

let titleStyle = {
    margin: '10px 0',
    fontSize: '16px',
    fontFamily: 'Poppins',
    fontWeight: '600',
    color: "#1f1f1f",

}

let subTitleStyle = {
    margin: '10px 0',
    fontSize: '13px',
    color: "#a6a6a6",
    fontFamily: 'Nunito Sans',
    fontWeight: '600',
}

export default function SecurityDrawerData({ data }) {
    const [securityData, setSecurityData] = useState(data);
    const { openRightDrawer, setOpenRightDrawer } = useContext(AppContext);
    let regExp = /\[([^)]+)\]/;

    return (
        <SecurityContentHold>

    <CreateTaskHeader
        data={securityData}
        buttonContent="Scan Outcome"
        // values={{editing: true}}
        onCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              data: securityData
            },
          });
        }}
      />
        <div style={{padding: "20px"}}>
            <Box title="Title"
                subtitle={securityData.Title.split(']')[1]}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={{
                    border: '0',
                    padding: "0",
                    width: "100%",
                    marginTop: "100px"
            }}
            >
            </Box>
            <Box title="Description"
                subtitle={securityData.Description}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box>
            <Box title="Region"
                subtitle={securityData.Resources[0].Region}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box>
            <Box title="Status"
                subtitle={securityData.Compliance.Status}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box>
            <Box title="Check Title"
                subtitle={regExp.exec(securityData.Title)[1]}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box>
            {/* <Box title="Resource ID"
                subtitle={securityData.Resources[0].Id}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box>
            <Box title="Check ID"
                subtitle={securityData.Compliance.Status}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box>
            <Box title="Status Extended"
                subtitle={securityData.Compliance.Status}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box>
            <Box title="Risk"
                subtitle={securityData.Compliance.Status}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box>
            <Box title="Recommendation"
                subtitle={securityData.Compliance.Status}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box>
            <Box title="Recommendation URL"
                subtitle={securityData.Compliance.Status}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box>
            <Box title="Check Description"
                subtitle={securityData.Compliance.Status}
                titleStyle={titleStyle}
                subTitleStyle={subTitleStyle}
                style={boxStyle}
            >
            </Box> */}
            </div>
        </SecurityContentHold>
    );
}