import { React, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import Input, {
    InputSearch,
    InputGroup,
    Textarea,
} from '@iso/components/uielements/input';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled, { createGlobalStyle } from 'styled-components';
import { SelectOption } from '@iso/components/uielements/select';
import Select from '../../Deployments/Deployment.styles';
import initialData from '../../../redux/scrumBoard/data';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AlertContext } from '../../../context/alertContext';
import awsIcon from '@iso/assets/images/icon/aws-logo.svg';
import api from '../../../api';
import { formatErrors } from '../../../context/helper';
import { Form } from 'antd';

const Option = SelectOption;

let boxStyle = {
    border: '0',
    padding: "20px",
    width: "100%"
}

let titleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontSize: "16px",
    fontWeight: "500"
}

let subtitleStyle = {
    fontSize: "14px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontType: "regular",
    fontWeight: "400"
}

let inputStyle = {
    padding: '4px 11px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontSize: '14px',
    borderRadius: '6px',
    height: "40px"
}

let imgStyle = {
    width: '16px',
    height: '20px',
    marginRight: '10px'
}

const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

const serverInsTypes = ["t3.small", "m4.large", "c5.large", "r3.large"];
serverInsTypes.map((child, ind) => {
    serverInsTypes.push(<Option key={child} name="serverInsType" value={child}>{child}</Option>);
});

var optionAzs = [];

export default function GitlabIntegration({
    // handleSubmit,
    data,
    load,
    setLoad,
    connected,
    setConnected,
    onCancel,
    onDelete,
    handelGitLabConnections,
}) {

    const { setOpenRightDrawer } = useContext(AppContext);
    const { set_alertOptions, handleAlerts } = useContext(AlertContext);
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [gitlabData, setGitlabData] = useState({
        source: undefined,
        url: "",
        pat: "",
    });

    
    useEffect(() => {
        form.setFieldsValue({
            source: undefined,
            url: "",
            pat: "",
        });
    }, []);


    const handleClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: ""
            },
        });
    }

    const handleSelectChange = (value, event) => {
        console.log("event :", event);
        console.log("value :", value);

        setGitlabData({ ...gitlabData, [event.name]: value });

        form.setFieldsValue({
            ...gitlabData,
            [event.name]: value
        });
    }

    const handelInputChanges = (e) => {
        console.log("inside handelInputChanges :", gitlabData);
        console.log("e.target.name :", e.target.name);
        console.log("e.target.value :", e.target.value);

        setGitlabData({
            ...gitlabData,
            [e.target.name]: e.target.value
        });
        
        form.setFieldsValue({
            ...gitlabData,
            [e.target.name]: e.target.value
        });
    }

    const handleIntegration = async(e) => {
        console.log("inside handleIntegration :", gitlabData);
        
        // setLoad({ ...load, gitlab: true });

        try {
            const values = await form.validateFields();
            setLoad({ ...load, gitlab: true });
            console.log('Success:', values);

            // remove trailing /
            if(gitlabData.url && gitlabData.url.endsWith("/")){
                gitlabData.url = gitlabData.url.slice(0,-1);
            }
            
            let payload = {
                gitlab_account_type: 2,
                self_hosted_account_url: gitlabData.url,
                personal_access_token: gitlabData.pat,
                code: null,
                redirect_uri: null
            }

            api.post(process.env.REACT_APP_API_URI + '/api/integration/gitlab/access_token/', payload)
                .then((res) => {
                    let data = res.data;
                    console.log('data in gitlab self hosted integration :', data);
                    if (data.status) {
                        setConnected(!connected);
                        handleClick();

                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: true,
                            type: "success",
                            message: "Success",
                            description: "Gitlab Account Connected Successfully.",
                        }));

                        setTimeout(() => {
                            set_alertOptions(newVal => ({
                                ...newVal,
                                status: false,
                                type: "success",
                                message: "Success",
                                description: "Gitlab Account Connected Successfully.",
                            }));
                        }, 5 * 1000);

                        setLoad({ ...load, gitlab: false });
                    }
                    else {
                        let err = formatErrors(data.error_details);
                        console.log("err in gitlab self-hosted integration :", err);

                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: true,
                            type: "error",
                            message: "Failed",
                            description: err,
                        }));

                        setTimeout(() => {
                            set_alertOptions(newVal => ({
                                ...newVal,
                                status: false,
                                type: "error",
                                message: "Failed",
                                description: err,
                            }));
                        }, 5 * 1000);
                        // handleAlerts("error", "Failed", "Error Connecting.");
                        setLoad({ ...load, gitlab: false });
                    }

                    handleClick();
                })
                .catch((err) => {
                    set_alertOptions(newVal => ({
                        ...newVal,
                        status: true,
                        type: "error",
                        message: "Failed",
                        description: "Something went wrong. Please try again.",
                    }));

                    setTimeout(() => {
                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: false,
                            type: "error",
                            message: "Failed",
                            description: "Something went wrong. Please try again.",
                        }));
                    }, 5 * 1000);
                    // handleAlerts("error", "Failed", "Something Went Wrong!");
                    setLoad({ ...load, gitlab: false });
                    handleClick();
                    console.log('Error in gitlab self-hosted integration :', err);
                })
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
            setLoad({ ...load, gitlab: false });
        }
    }

    const handleCancel = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                myClusterData: "",
                tabSelected: "",
            },
        });
    }

    const handleGitLabConnect = async () => {
        try {
            await form.validateFields();
            if (gitlabData.source === "Gitlab Cloud") {
                handelGitLabConnections();
            }
            else if (gitlabData.source === "Gitlab self-hosted") {
                handleIntegration();
            }
        }
        catch(error) {
            console.log("Error");
        }
    }

    return (
        <ProdContentHold>
            <CreateTaskHeader
                buttonContent="Gitlab"
                data={"Yes"}
                // values={{editing: true}}
                onCancel={() => {
                    handleCancel();
                }}
                onDelete={onDelete}
                onEditCancel={() => {
                    handleCancel();
                }}
            />

            <Form
                form={form}
                initialValues={{ ...gitlabData }}
                preserve={false}
                name="dynamic_rule"
            >
                <Box
                    title={"Source*"}
                    subtitle={"Select source from the following."}
                    style={{ ...boxStyle, marginTop: "100px", marginBottom: gitlabData.source === "Gitlab self-hosted" ? "0px" : "100px" }}
                    titleStyle={titleStyle}
                    subtitleStyle={subtitleStyle}
                    imgStyle={imgStyle}
                >
                    <Form.Item
                        name="source"
                        rules={[
                            {
                                required: true,
                                message: 'Please select source',
                            },
                        ]}
                    >
                        <Select
                            mode="single"
                            name="source"
                            style={{ width: '100%', marginTop: "10px" }}
                            placeholder="Please select"
                            value={gitlabData.source}
                            onChange={handleSelectChange}
                            getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        >
                            {["Gitlab Cloud", "Gitlab self-hosted"].map((val, ind) => (
                                <Option key={ind} name="source" value={val}>{val}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Box>

                {gitlabData.source === "Gitlab self-hosted" && <>
                    <Box
                        title={"Base URL*"}
                        subtitle={"Provide base url here."}
                        style={boxStyle}
                        titleStyle={titleStyle}
                        subtitleStyle={subtitleStyle}
                        imgStyle={imgStyle}
                    >
                        <Form.Item
                            name="url"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please provide Base Url.',
                                },
                                {
                                    // Custom validator for only integers
                                    validator: (_, value) => {
                                        console.log("value.split('/').length !== 4 :", value.split('/').length !== 4);
                                        if(value.length > 0){
                                            if (!value.startsWith("https://")) {
                                                return Promise.reject(new Error(`Please provide a valid Base URL.`));
                                            }
                                            else if (value.endsWith("/") && value.split("/").length !== 4) {     // https://gitlab-test.atmosly.in/ 
                                                return Promise.reject(new Error(`Please provide a valid Base URL.`));
                                            }
                                            else if (!value.endsWith("/") && value.split("/").length !== 3) {      // https://gitlab-test.atmosly.in
                                                return Promise.reject(new Error(`Please provide a valid Base URL.`));
                                            }
                                            else{
                                                return Promise.resolve();
                                            }
                                        }
                                        else{
                                            return Promise.resolve();
                                        }
                                    }
                                },
                            ]}
                        >
                            <Input
                                name="url"
                                value={gitlabData.url}
                                placeholder="https://gitlab-instance.com"
                                style={{ marginTop: "10px", height: "44px" }}
                                onChange={handelInputChanges}
                            />
                        </Form.Item>
                    </Box>

                    <Box
                        title={"Personal Access Token*"}
                        subtitle={"To setup gitlab self-managed, provide your personal access token(PAT) from Gitlab. The personal access token is required to have the following scopes -> api."}
                        style={{...boxStyle, marginBottom: "100px"}}
                        titleStyle={titleStyle}
                        subtitleStyle={subtitleStyle}
                        imgStyle={imgStyle}
                    >
                        <Form.Item
                            name="pat"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please provide PAT',
                                },
                            ]}
                        >
                            <Input
                                name="pat"
                                value={gitlabData.pat}
                                style={{ marginTop: "10px", height: "44px" }}
                                onChange={handelInputChanges}
                                placeholder="Token"
                            />
                        </Form.Item>
                    </Box>
                </>}

                <div style={{ width: "100%", alignContent: "center", position: "absolute", bottom: "0", borderTop: "1px solid #b1b1b1", padding: "17px", background: "white" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "right", alignItems: "right" }}>
                            <Button
                                name="cancel"
                                size="large"
                                onClick={() => {
                                    handleCancel();
                                }}
                                style={{ marginRight: "10px", borderRadius: "10px", border: "1px solid #132f44", color: "#12f44", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", cursor: "pointer" }}
                            >
                                Cancel
                            </Button>
                            <Button name="proceed" size="large" onClick={handleGitLabConnect} style={{ backgroundColor: "#132f44", borderRadius: "10px", color: "white", border: "1px solid #132f44", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", cursor: "pointer" }}>
                                Connect
                            </Button>
                        </div>
                    </div>
                </div>
            </Form>
        </ProdContentHold>
    );
};
