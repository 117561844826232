import React from 'react';
import { BoxTitle, BoxSubTitle } from './boxTitle.style';

export default props => {
  return (
    <div id={props.id && props.id}>
      {props.title ? (
        <BoxTitle style={props.titleStyle}> {props.title} </BoxTitle>
      ) : (
        ''
      )}
      {props.subtitle ? (
        <>
          {!props.tootipText && <BoxSubTitle className={props.tootipText} style={props.subTitleStyle}> {props.subtitle} </BoxSubTitle>}

          {props.tootipText && <div className={props.className}>
            <BoxSubTitle style={props.subTitleStyle}> {props.subtitle} </BoxSubTitle>
            <BoxSubTitle className="tooltiptext"> {props.subtitle} </BoxSubTitle>
          </div>}
        </>
      ) : (
        ''
      )}
       {props.subtitle2 ? (
        <BoxSubTitle className="isoBoxSubTitle" style={props.subTitle2Style}> {props.subtitle2} </BoxSubTitle>
      ) : (
        ''
      )}
    </div>
  );
};
