import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/ContextProvider";
import { GuardrailsContext } from "../../../context/guardrailsContext";
import GuardrailInfoDrawer from "../GuardrailModals/GuardrailInfoDrawer";
import api from "../../../api";

let conditionContentStyle = {
  width: "68px",
  height: "52px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 27px 0 0",
  padding: "6px 8px",
  borderRadius: "10px",
  backgroundColor: "#4d99ef",
};
let actionContentStyle = {
  opacity: "0.6",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#4d99ef",
  margin: "0 27px 0 0",
  width: "98px"
};
let operationContentStyle = {
  height: "19px",
  width: "68%",
  flexGrow: "0",
  opacity: "0.6",
  fontFamily: "Nunito Sans",
  paddingRight: "8px",
  fontSize: "14px",
  fontWeight: "600",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#1f1f1f",
};

const labelStyle = {
  flexGrow: "0",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  color: "#fff",
};

export default function SituationBox({ operation }) {
  const { setOpenRightDrawer } = useContext(AppContext);
  const { selectedGuardrailInfo, setSelectedGuardrailInfo } = useContext(GuardrailsContext);
  const [openGuardrailInfo, setOpenGuardrailInfo] = useState(false);
  const [modalLoaded, setModalLoaded] = useState(false);

  useEffect(() => {
    if (!selectedGuardrailInfo.editing && selectedGuardrailInfo.id) {
      api.get(process.env.REACT_APP_API_URI + `/api/guardrail/action/${selectedGuardrailInfo.id}`).then((res) => {
        let data = res.data;
        if (data.status) {
          console.log("Calling guardrail api....");
          setSelectedGuardrailInfo({
            ...selectedGuardrailInfo,
            info: {
              ...selectedGuardrailInfo.info,
              actionsList: data.data
            }
          });
          localStorage.setItem('selectedGuardrailInfo', JSON.stringify({
            ...selectedGuardrailInfo,
            info: {
              ...selectedGuardrailInfo.info,
              actionsList: data.data
            }
          }));
        }
        else {

        }
      }).catch((err) => {
        console.log("error");
      });
    }
  }, []);

  return (
    <>
      {modalLoaded && <GuardrailInfoDrawer openGuardrailInfo={openGuardrailInfo} setOpenGuardrailInfo={setOpenGuardrailInfo} data={{
        heading: "Configure Action"
      }} modalLoaded={modalLoaded} setModalLoaded={setModalLoaded}/>}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} onClick={() => {
        if(!selectedGuardrailInfo.view){
          setOpenGuardrailInfo(true);
          setModalLoaded(true);
        }
        // setOpenRightDrawer({
        //   drawerType: "guardrailNodeInfo",
        //   drawerProps: {
        //     data: {
        //       heading: "Configure Action",
        //     },
        //     /*setData: setMyClusterData,
        //     columnId: null,
        //     tabSelected: e.target.name,
        //     openAddOns: openAddOns*/
        //   },
        // });
      }}>
        <div style={conditionContentStyle}><p style={labelStyle}>What</p></div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
          <span style={actionContentStyle}>Action</span>
          <span style={operationContentStyle}>{operation ? operation : "Select Action"}</span>
        </div>
      </div>
    </>
  );
}