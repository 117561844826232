import { React, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import Input, {
    InputSearch,
    InputGroup,
    Textarea,
} from '@iso/components/uielements/input';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled, { createGlobalStyle } from 'styled-components';
import Select, { SelectOption } from '@iso/components/uielements/select';
import { useNavigate } from 'react-router-dom';
import { AlertContext } from '../../../context/alertContext';
import api from '../../../api';
import { formatErrors } from '../../../context/helper';
import { Form } from 'antd';
const Option = SelectOption;

const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

const serverInsTypes = ["t3.small", "m4.large", "c5.large", "r3.large"];
serverInsTypes.map((child, ind) => {
    serverInsTypes.push(<Option key={child} name="serverInsType" value={child}>{child}</Option>);
});

var optionAzs = [];

export default function DockerHubIntgration({
    // handleSubmit,
    data,
    load,
    setLoad,
    connected,
    setConnected,
    onCancel,
    onDelete,
    openDrawer,
    onEditCancel,
    tabSelected,
    openAddOns
}) {

    const { setOpenRightDrawer } = useContext(AppContext);
    const { set_alertOptions, handleAlerts } = useContext(AlertContext);
    const navigate = useNavigate();

    const [dockerhubData, setDockerhubData] = useState({
        username: "",
        token: "",
        registryUrl: "",
    });

    const [error, setError] = useState({
        status: false,
        msg: "Success"
    });

    const [form] = Form.useForm();

    const handleClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: ""
            },
        });
    }


    const handelInputChanges = (e) => {
        setDockerhubData({ ...dockerhubData, [e.target.name]: e.target.value });
    }


    const handleIntegration = async (e) => {
        let errors = [];
        try {
            await form.validateFields();
        }
        catch (err) {
            console.log("Error");
            errors = [...errors, {
                error: err
            }];
        }
        if (errors.length > 0) {
            console.log("Error");
        }
        else {

            console.log("inside handleIntegration :", dockerhubData);
            handleClick();
            setLoad({ ...load, dockerhub: true });
            let payload = {
                username: dockerhubData.username.trim(),
                token: dockerhubData.token.trim(),
                registry_url: dockerhubData.registryUrl.trim(),
            }

            api.post(process.env.REACT_APP_API_URI + '/api/integration/docker-registry-credentials/', payload)
                .then((res) => {
                    let data = res.data;
                    console.log('data in dockerhub integration :', data);
                    if (data.status) {
                        setConnected(!connected);
                        // handleAlerts("success", "Success", "GCP Account Connected Successfully.");
                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: true,
                            type: "success",
                            message: "Success",
                            description: "DockerHub Connected Successfully.",
                        }));

                        setTimeout(() => {
                            set_alertOptions(newVal => ({
                                ...newVal,
                                status: false,
                                type: "success",
                                message: "Success",
                                description: "DockerHub Connected Successfully.",
                            }));
                        }, 5 * 1000);
                        // handleClick();
                    }
                    else {
                        // handleAlerts("error", "Failed", "Error Connecting.");
                        let err = formatErrors(data.error_details);
                        console.log("err in /dockerhub/integration :", err);

                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: true,
                            type: "error",
                            message: "Failed",
                            description: err,
                        }));

                        setTimeout(() => {
                            set_alertOptions(newVal => ({
                                ...newVal,
                                status: false,
                                type: "error",
                                message: "Failed",
                                description: err,
                            }));
                        }, 5 * 1000);
                    }
                    setLoad({ ...load, dockerhub: false });
                })
                .catch((err) => {
                    set_alertOptions(newVal => ({
                        ...newVal,
                        status: true,
                        type: "error",
                        message: "Failed",
                        description: "Something went wrong",
                    }));

                    setTimeout(() => {
                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: false,
                            type: "error",
                            message: "Failed",
                            description: "Something went wrong",
                        }));
                    }, 5 * 1000);
                    // handleAlerts("error", "Failed", "Something Went Wrong!");
                    setLoad({ ...load, dockerhub: false });
                    console.log('Error in resend-emailverification :', err);
                })
        }
    }

    return (
        <ProdContentHold>
            <Form name='dockerHub' form={form}>
                <CreateTaskHeader
                    buttonContent="DockerHub"
                    data={"Yes"}
                    // values={{editing: true}}
                    onCancel={() => {
                        setOpenRightDrawer({
                            drawerType: false,
                            drawerProps: {
                                myClusterData: "",
                                tabSelected: "",
                            },
                        });
                    }}
                    onDelete={onDelete}
                    onEditCancel={() => {
                        setOpenRightDrawer({
                            drawerType: false,
                            drawerProps: {
                                myClusterData: "",
                                tabSelected: "",
                            },
                        });
                    }}
                />

                <Box
                    title={<div style={{ display: "flex", marginTop: "100px" }}><span style={{ marginRight: "5px", color: "red" }}>*</span><span>Username :</span></div>}
                >
                    <ContentHolder>
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter username."
                                }
                            ]}
                        >
                            <Input
                                name="username"
                                value={dockerhubData.username}
                                onChange={handelInputChanges}
                                placeholder="Username"
                            />
                        </Form.Item>
                    </ContentHolder>
                </Box>

                <Box
                    title={<div style={{ display: "flex" }}><span style={{ marginRight: "5px", color: "red" }}>*</span><span>Token/Password :</span></div>}
                >
                    <ContentHolder>
                        <Form.Item
                            name="token"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter token."
                                }
                            ]}
                        >
                            <Input
                                name="token"
                                value={dockerhubData.token}
                                onChange={handelInputChanges}
                                placeholder="Enter your Token or Password"
                            />
                        </Form.Item>
                    </ContentHolder>
                </Box>

                <Box
                    title={<div style={{ display: "flex" }}><span style={{ marginRight: "5px", color: "red" }}>*</span><span>Registry Url :</span></div>}
                >
                    <ContentHolder>
                        <Form.Item
                            name="registryUrl"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter the registry url."
                                }
                            ]}
                        >
                            <Input
                                name="registryUrl"
                                value={dockerhubData.registryUrl}
                                onChange={handelInputChanges}
                                placeholder="Registry Url"
                            />
                        </Form.Item>
                    </ContentHolder>
                </Box>

                <Box>
                    <ContentHolder style={{
                        position: "relative"
                    }}>
                        <Button type="primary" onClick={handleIntegration} style={{ position: "absolute", right: "40%", marginTop: "-20px" }}>
                            Connect
                        </Button>
                    </ContentHolder>
                </Box>
            </Form>
        </ProdContentHold>
    );
};
