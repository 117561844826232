import styled from 'styled-components';
import Select from '@iso/components/uielements/select';
import WithDirection from '@iso/lib/helpers/rtl';

const WDSelect = styled(Select)`
    &.ant-select {
        .ant-select-selector{
            width: 347px;
            height: 44px;
            // position: relative;
            // cursor: pointer;
            border: 1px solid #D0D5DD;
            border-radius: 8px;
            // &.ant-select-selection--single {
            //     width: 347px;
            //     height: 44px;
            //     // position: relative;
            //     // cursor: pointer;
            //     border: 1px solid #D0D5DD;
            //     border-radius: 8px;
            // }
        }

        .ant-select-selection-placeholder, .ant-select-selection-item{
            display: flex;
            align-items: center;
        }

        .ant-select-selection-search .ant-select-selection-search-input{
            height: 42px;
        }

    }
    &.custom-ant-selector{
        width: 100%;

        .ant-select-selector{
            width: 100%;
        }
    }
`;

const isoSelect = WithDirection(WDSelect);
const SelectOption = Select.Option;

export default isoSelect;
export {
    SelectOption,
};