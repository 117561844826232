import { useContext, useState } from "react";
import { AppContext } from "../../../context/ContextProvider";
import { GuardrailsContext } from "../../../context/guardrailsContext";
import GuardrailInfoDrawer from "../GuardrailModals/GuardrailInfoDrawer";
import { AlertContext } from "../../../context/alertContext";

let conditionContentStyle = {
  width: "68px",
  height: "52px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 27px 0 0",
  padding: "6px 8px",
  borderRadius: "10px",
  backgroundColor: "#4d99ef",
};
let actionContentStyle = {
  opacity: "0.6",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#4d99ef",
  display: "flex",
  flexDirection: "column",
  margin: "0 27px 0 0",
  width: "98px"
};
let operationContentStyle = {
  height: "19px",
  flexGrow: "0",
  opacity: "0.6",
  fontFamily: "Nunito Sans",
  paddingRight: "8px",
  fontSize: "14px",
  fontWeight: "600",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#1f1f1f",
};

const labelStyle = {
  flexGrow: "0",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  color: "#fff",
};

export default function OperationBox({ operationContent1, operationContent2, operationValue1, operationValue2 }) {
  const { setOpenRightDrawer } = useContext(AppContext);
  const [openGuardrailInfo, setOpenGuardrailInfo] = useState(false);
  const { set_alertOptions } = useContext(AlertContext);
  const { selectedGuardrailInfo } = useContext(GuardrailsContext);
  const [modalLoaded, setModalLoaded] = useState(false)
  function toggleAlert(type, message, description) {
    console.log("Toggle alert called: ");
    set_alertOptions(alertOptions => ({
      status: true,
      type: type,
      message: message,
      description: description,
    }));

    // opacity=0 after 10sec
    setTimeout(() => {
      set_alertOptions(alertOptions => ({
        status: false,
        type: type,
        message: message,
        description: description,
      }));
    }, 5 * 1000);
  }
  return (
    <>
    {console.log("OperationContent: ", selectedGuardrailInfo, operationContent1, operationContent2, operationValue1, operationValue2)}
      {modalLoaded && <GuardrailInfoDrawer openGuardrailInfo={openGuardrailInfo} setOpenGuardrailInfo={setOpenGuardrailInfo} data={{
        heading: "Configure Frequency"
      }} modalLoaded={modalLoaded} setModalLoaded={setModalLoaded} />}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} onClick={() => {
        if (!selectedGuardrailInfo.view) {
          if (operationContent1 && operationContent2 && operationValue1 && operationValue2) {
            setOpenGuardrailInfo(true);
            setModalLoaded(true);
          }
          else {
            toggleAlert("error", "Error", "Please select an action in order to proceed.");
          }
        }
        // setOpenRightDrawer({
        //   drawerType: "guardrailNodeInfo",
        //   drawerProps: {
        //     data: {
        //       heading: "Configure Frequency"
        //     },
        //     /*setData: setMyClusterData,
        //     columnId: null,
        //     tabSelected: e.target.name,
        //     openAddOns: openAddOns*/
        //   },
        // });
      }}>
        <div style={conditionContentStyle}><p style={labelStyle}>When</p></div>
        <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
          {(operationContent1 && operationContent2 && operationValue1 && operationValue2) ? <>
            <div style={actionContentStyle}>{operationContent1}{operationContent2}</div>
            <div style={{ display: "flex", flexDirection: "column", width: "68%" }}>
              <p style={operationContentStyle}>{operationValue1}</p>
              <p style={operationContentStyle}>{operationValue2}</p>
            </div>
          </> : <>
            <div style={actionContentStyle}>
              Frequency
            </div>
            <div style={{...operationContentStyle, width: "68%"}}>
              Select Frequency
            </div>
          </>}
        </div>
      </div>
    </>
  );
}