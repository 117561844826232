import React from 'react';
import { WidgetBox } from './Widgets.styles';

export default function({ children, className, style, height, padding }) {
  return (
    <WidgetBox
      className={`isoWidgetBox ${className ? className : ''}`}
      height={height}
      padding={padding}
      style={style}
    >
      {children}
    </WidgetBox>
  );
}
