import styled from 'styled-components';

const LayoutContentWrapper = styled.div`
  width: 100%;
  padding: 40px 20px;
  display: flex;
  flex-flow: row wrap;
  overflow: hidden;

  .box-with-large-font > div > h3 {
      font-size: 20px;
  }
  
  .cluster-status-active > div > p {
    color: #ffbf00;
  }

  .cluster-status-failed > div > p {
    color: red;
  }

  .cluster-status-completed > div > p {
    color: green;
  }

  .my-loger{
    .cm-scroller{
      background-color: #151515;
      color: #e0e0e0;
    }
  }

  .isoWidgetsWrapper{

    .dashboard-card{

      .dashboard-card-header{
        width: 100%;
        display: flex;
        flex-flow: wrap;
        font-size: 20px;
        font-family: Poppins;
        color: rgb(31, 31, 31);
        font-weight: 600;
        margin-bottom: 20px;
        padding-left: 3px;

        @media screen and (max-width: 1280px) {
          font-size: 16px;
        }
        
        @media screen and (max-width: 799px) {
          font-size: 16px;
        }

      }
      
      .dashboard-card-text{
        font-size: 16px;
        font-family: "Nunito Sans Regular";
        color: rgb(41, 41, 41);
        padding-top: 5px;

        @media screen and (max-width: 1280px) {
          font-size: 14px;
          padding-top: 0px;
          padding-left: 10px;
        }
        
        @media screen and (max-width: 799px) {
          font-size: 14px;
          padding-top: 0px;
          padding-left: 10px;
        }
      }
      
      .dashboard-card-text-2{
        font-size: 16px;
        font-family: "Nunito Sans Regular";
        color: rgb(41, 41, 41);

        @media screen and (max-width: 1280px) {
          font-size: 15px;
        }
        
        @media screen and (max-width: 799px) {
          font-size: 15px;
        }
      }

      .security-pie{
        display: flex;
        height: fit-content;
        margin-bottom: 100px;

        @media screen and (max-width: 1280px) {
          margin-bottom: 80px;
        }
        
        @media screen and (max-width: 799px) {
          margin-bottom: 75px;
        }

        .pie-pass{
          width: 12vw;
          height: 12vw;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transform: translate(40px, 40px);
          background-color: rgb(67, 190, 24);
        }

        .pie-fail{
          width: 10vw;
          height: 10vw;
          border: 5px solid white;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          transform: translate(-10px, -5px);
          background-color: rgb(206, 0, 0);
        }

        .pie-pass, .pie-fail{
          .pie-pass-count, .pie-fail-count{
            font-size: 24px;
            font-weight: 700;
            font-family: "Nunito Sans";
            color: white;

            @media screen and (max-width: 1280px) {
              font-size: 17px;
            }
            
            @media screen and (max-width: 799px) {
              font-size: 16px;
            }
          }
  
          .pie-pass-text, .pie-fail-text{
            font-size: 16px;
            font-weight: 400;
            font-family: "Nunito Sans";
            color: white;

            @media screen and (max-width: 1280px) {
              font-size: 12px;
            }
            
            @media screen and (max-width: 799px) {
              font-size: 12px;
            }
          }
        }
      }

      .failed-findings{
        width: 100%;
        display: flex;
        flex-flow: wrap;
        font-size: 16px;
        font-family: "Nunito Sans Regular";
        color: gray;
        font-weight: bold;
        margin-bottom: 20px;
        padding-left: 3px;

        @media screen and (max-width: 1280px) {
          font-size: 12px;
        }
        
        @media screen and (max-width: 799px) {
          font-size: 12px;
        }
      }

      .customBarChartContainer .recharts-wrapper{
          g.recharts-layer{
            text > tspan{
              @media screen and (max-width: 1280px) {
                font-size: 10px;
              }
              
              @media screen and (max-width: 799px) {
                font-size: 10px;
              }
            }
          }
      }

      .security-footer-msg{
        width: 100%;
        display: flex;
        flex-flow: wrap;

        span{
          color: rgba(94, 93, 93, 0.55);
          font-size: 15px;
          margin-top: 10px;

          @media screen and (max-width: 1280px) {
            font-size: 12px;
          }
          
          @media screen and (max-width: 799px) {
            font-size: 12px;
          }
        }
      }
    }

  }
  
  @media only screen and (max-width: 767px) {
    padding: 50px 20px;
  }

  @media (max-width: 580px) {
    padding: 15px;
  }
`;

export { LayoutContentWrapper };
