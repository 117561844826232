import Box from "../../../components/utility/box";
import CreateTaskFooter from "../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter";
import CreateTaskHeader from "../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader";
import {
    SelectOption as Option,
} from '@iso/components/uielements/select';
import Select from '../../Deployments/Deployment.styles';
import { useContext, useEffect, useState } from "react";
import api from "../../../api";
import { Button, Calendar, TimePicker, Spin } from 'antd';
import { setDay } from "date-fns";
import { AppContext } from "../../../context/ContextProvider";
import scanNow from '../../../assets/images/scanNow.svg';
import scheduleLater from '../../../assets/images/scheduleLater.svg';
import { AlertContext } from "../../../context/alertContext";
import { formatErrors } from "../../../context/helper";
import { CustomModal } from "../CustomComponents/PageLoader.style";
import noDataPlusIcon from '@iso/assets/images/nodata-plus-icon.svg';
import noDataImg from '@iso/assets/images/no-data-found.svg';
import { useNavigate } from "react-router";


const noDataTitle = {
    color: "#A6A6A6",
    textAlign: "center",
    fontFamily: "Nunito Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "300",
};

const noDataSubTitle = {
    color: "#323232",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    marginLeft: "5px"
};

const titleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500"
};

const subTitleStyle = {
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontSize: "12px",
    fontWeight: "400"
};
export default function SecurityTypeDrawerData() {

    const navigate = useNavigate();

    const [fetching, setFetching] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [freqs, setFreqs] = useState([]);
    const [daysList, setDaysList] = useState([]);
    //const [freqSelected, setFreqSelected] = useState("");
    const [selectedData, setSelectedData] = useState({
        accountNo: "",
        type: "",
        frequency: "",
        dailyTime: "",
        weeklyTime: "",
        weeklyDay: "",
        monthlyPeriod: "",
        monthlyTime: ""
    });
    const { openRightDrawer, setOpenRightDrawer, refreshBoardList, setRefreshBoardList } = useContext(AppContext);
    const [scanType, setScanType] = useState("Scan Now");
    const { alertOptions, set_alertOptions } = useContext(AlertContext);
    const [error, setError] = useState({
        status: false,
        msg: ""
    });
    console.log("Scan types: ", scanType);

    console.log("This component executed");
    useEffect(() => {
        api.post(process.env.REACT_APP_API_URI + '/api/integration/account/list/', {
            "category_id": 1
        })
            .then((res) => {
                let data = res.data;
                let options = [];
                if (data.status) {
                    //setAccountList(data.data);
                    data.data.map((child) => {
                        if (child.status && child.integration === 1) {
                            options.push(<Option key={child.account_id} name="accountNo" value={child.account_id}>
                                <div style={{ display: "flex", justifyContent: "start", alignItems: "center", width: "100%" }}>
                                    <img src={child.integration_logo} style={{ width: "18px", height: "18px", marginRight: "10px" }} alt="provider" />
                                    <span>{`${child.account_id}${child.alias ? ` (${child.alias})` : ""}`}</span>
                                </div>
                            </Option>);
                        }
                    });
                    setAccountList(options);
                    console.log("Accounts: ", data.data);
                }
            })
            .catch((err) => console.log('Error :', err))
    }, []);

    useEffect(() => {
        const types = ["cislevel1", "cislevel2", "pci", "soc2", "hipaa"];
        let data = [];

        types.map((val) => {
            data.push(<Option key={val} name="type" value={val}>{val}</Option>);
        });

        setTypeList(data);
    }, []);

    useEffect(() => {
        const freqTypes = ["Monthly", "Weekly", "Daily"];
        const freqOptions = [];
        freqTypes.map((val) => {
            freqOptions.push(<Option key={val} name="frequency" value={val}>{val}</Option>)
        });
        setFreqs(freqOptions);

        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        setDaysList(days);
    }, []);

    function toggleAlert(type, message, description, hide = true, time = 5) {
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        console.log("Hide :", hide);
        console.log("time :", time);
        if (hide) {
            // opacity=0 after 10sec
            setTimeout(() => {
                set_alertOptions(alertOptions => ({
                    status: false,
                    type: type,
                    message: message,
                    description: description,
                }));
            }, time * 1000);
        }
    }

    const handleScan = () => {
        if (scanType === "Scan Now") {
            setFetching(true);

            if (selectedData.accountNo !== "" && selectedData.type !== "") {
                api.post(process.env.REACT_APP_API_URI + '/api/cluster/prowler/scan/', {
                    account_id: selectedData.accountNo,
                    scan_type: selectedData.type
                }).then((res) => {
                    let data = res.data;
                    if (data.status) {
                        toggleAlert("success", "Success", "Scan request has been initiated.");
                        setOpenRightDrawer({
                            drawerType: false,
                        });
                        setRefreshBoardList(!refreshBoardList);
                    }
                    else if (!data.status) {
                        let err = formatErrors(data.error_details);
                        console.log("err in security scan :", err);
                        setOpenRightDrawer({
                            drawerType: false,
                        });
                        toggleAlert("error", "Error", err);
                    }

                    setFetching(false);
                }).catch((err) => {
                    console.log("Error: ", err);
                    setFetching(false);
                    toggleAlert("error", "Error", "An error occurred.");
                });
            }
            else {
                setFetching(false);
                setError({
                    status: true,
                    msg: "Please select an Account and Type in order to proceed."
                });
            }
        }
        else {

        }
    };
    const handleClear = () => {
        console.log("Clearing");
        setSelectedData({
            accountNo: "",
            type: "",
            frequency: "",
            dailyTime: "",
            weeklyTime: "",
            weeklyDay: "",
            monthlyPeriod: "",
            monthlyTime: ""
        });
    };

    const handleSelectChange = (value, event) => {
        if(event.name === "redirect"){
            setOpenRightDrawer({
                drawerType: false,
            });
            navigate("/integrations");
        }

        console.log("Enters");
        setError({
            status: false,
            msg: ""
        })
        setSelectedData(selectedData => ({
            ...selectedData,
            [event.name]: value
        }));
    };

    const onPanelChange = (value, mode) => {

    };
    const handleDayClick = (val) => {
        setSelectedData(selectedData => ({
            ...selectedData,
            weeklyDay: val
        }));
    };
    return (
        <>
            <CustomModal
                visible={fetching}
                //onCancel={hideModal}
                footer={null}
                closable={false}
                centered
                maskClosable={false}
                style={{ border: "none", boxShadow: "none" }}
            >
                <div style={{ textAlign: 'center', background: "transparent", border: "none" }}>
                    <Spin size="large" />
                </div>
            </CustomModal>

            <CreateTaskHeader buttonContent={"Scan"} onCancel={() => {
                setOpenRightDrawer({
                    drawerType: false,
                });
            }} />
            
            <Box style={{ display: "flex", height: "fit-content", margin: "83px 0 0 0", border: "0", padding: "0" }}>
                <Box
                    title={
                        <div style={{ display: "flex" }}>
                            <img src={scanNow} style={{ marginRight: "10px" }} />
                            <p> Scan Now </p>
                        </div>
                    }
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "fit-content", backgroundColor: scanType === "Scan Now" ? "#f4fbff" : "", cursor: "pointer", marginBottom: "0" }}
                    onClick={() => {
                        setScanType("Scan Now");
                    }}
                />
                <Box
                    title={
                        <div style={{ display: "flex" }}>
                            <img src={scheduleLater} style={{ marginRight: "10px" }} />
                            <p> Schedule Later </p>
                        </div>
                    }
                    style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "fit-content", backgroundColor: scanType === "Schedule Later" ? "#f4fbff" : "", cursor: "pointer", marginBottom: "0", cursor: "not-allowed" }}
                    onClick={() => {
                        // setScanType("Schedule Later");
                    }}
                />
            </Box>
            <Box title="Account*" subtitle="Please select an account" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ border: "0", height: "fit-content", padding: "40px 40px 0 40px" }}>
                <Select placeholder="Select account number" style={{ width: "100%" }} value={selectedData.accountNo === "" ? null : selectedData.accountNo} onChange={handleSelectChange}>
                    {accountList.length > 0 ? accountList :
                        <Option key="0" name="redirect" data-alias="" type="integration" style={{background: "white"}}>
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center", height: "fit-content", padding: "3px 0" }}>
                                <img src={noDataImg} />
                                <span style={noDataTitle}>No Account available.</span>
                                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <img src={noDataPlusIcon} />
                                    <span style={noDataSubTitle}>Create one</span>
                                </div>
                            </div>
                        </Option>
                    }
                </Select>
            </Box>
            <Box title="Type*" subtitle="Please select scan framework type" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ border: "0", height: "fit-content", padding: "40px 40px 0 40px" }}>
                <Select placeholder="Select" style={{ width: "100%" }} value={selectedData.type === "" ? null : selectedData.type} onChange={handleSelectChange}>{typeList}</Select>

                {error.status && !Array.isArray(error.msg) && <p className="isoHelperText">
                    <span>{error.msg}</span>
                </p>}
            </Box>
            {scanType === "Scan Now" ? (
                <>
                    {/* <Box title="Specify time period*" subtitle="Please choose specific time period" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ border: "0", height: "fit-content", padding: "40px 40px 0 40px" }}>
                        <TimePicker style={{ height: "44px", width: "100%" }} />
                    </Box> */}
                </>) : scanType === "" ? <></> : (<>
                    {/* <Box title="Account*" subtitle="Please select an account" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ marginTop: "80px", border: "0", height: "fit-content", padding: "40px 40px 0 40px" }}>
                        <Select placeholder="Select" style={{ width: "100%" }}>{accountList}</Select>
                    </Box>
                    <Box title="Type" subtitle="Please select scan framework type" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ border: "0", height: "fit-content", padding: "40px 40px 0 40px" }}>
                        <Select placeholder="Select" style={{ width: "100%" }}></Select>
                    </Box> */}
                    <Box title="Frequency*" subtitle="Please select frequency" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ border: "0", height: "fit-content", padding: "40px 40px 0 40px" }}>
                        <Select placeholder="Select" style={{ width: "100%" }} onChange={handleSelectChange}>{freqs}</Select>
                    </Box>

                    {selectedData.frequency === "Daily" && <>
                        <Box title="Specify time period*" subtitle="Please choose specific time period" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ border: "0", height: "fit-content", padding: "40px 40px 0 40px" }}>
                            <TimePicker style={{ height: "44px", width: "100%" }} />
                        </Box>
                    </>}
                    {selectedData.frequency === "Weekly" && <>
                        <Box title="Choose specific day*" subtitle="Please choose specific day" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ border: "0", height: "fit-content", padding: "40px 40px 0 40px" }}>
                            <div style={{ border: "1px solid #d0d5dd", borderRadius: "2px", width: "fit-content" }}>
                                {daysList.map((val, idx) => {
                                    return (<Button style={{ border: "0", borderRight: idx < 6 ? "1px solid #d0d5dd" : "0", borderRadius: "0" }} onClick={() => {
                                        handleDayClick(val);
                                    }}>{val}</Button>);
                                })}
                            </div>
                        </Box>
                        <Box title="Choose specific period*" subtitle="Please choose specific time period" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ border: "0", height: "fit-content", padding: "40px 40px 0 40px" }}>
                            <TimePicker style={{ height: "44px", width: "100%" }} />
                        </Box>
                    </>}
                    {selectedData.frequency === "Monthly" && <>
                        <Box title="Choose specific period*" subtitle="Please choose specific period" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ border: "0", height: "fit-content", padding: "40px 40px 0 40px" }}>
                            <Calendar fullscreen={false} onPanelChange={onPanelChange} bordered={true} style={{ border: "1px solid lightgray" }} />
                        </Box>
                        <Box title="Specify time period*" subtitle="Please choose specific time period" titleStyle={titleStyle} subTitleStyle={subTitleStyle} style={{ border: "0", height: "fit-content", padding: "40px 40px 0 40px", marginBottom: "180px" }}>
                            <TimePicker style={{ height: "44px", width: "100%" }} />
                        </Box>
                    </>}
                </>)}
            <CreateTaskFooter buttonContent={scanType} openDrawer={() => { }} givenButtonFunction1={handleScan} givenButtonFunction2={handleClear} />
        </>
    );
}