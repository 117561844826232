import React, { useEffect, useState } from 'react';
import { Form, Field } from 'formik';
import { Row, Col, Tooltip } from 'antd';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Select, { SelectOption } from '@iso/components/uielements/select';
import {
  AntDatePicker,
  AntSelect,
  AntTextArea,
  AntInput,
} from '@iso/components/ScrumBoard/AntFields';
import Input, {
  InputSearch,
  InputGroup,
  Textarea,
} from '@iso/components/uielements/input';
import { dateFormat } from '@iso/components/ScrumBoard/FieldFormats';
// import CreateTaskHeader from '../CreateTaskHeader/CreateTaskHeader';
import HeadingWithIcon from '@iso/components/ScrumBoard/HeadingWithIcon/HeadingWithIcon';
import TitleIcon from '@iso/assets/images/icon/05-icon.svg';
import DescriptionIcon from '@iso/assets/images/icon/06-icon.svg';
import AttachmentIcon from '@iso/assets/images/icon/01-icon.svg';
import CreateTaskHeader from '../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { AppContext } from '../../context/ContextProvider';
import { useContext } from 'react';
import { useLocation } from 'react-router';

const Option = SelectOption;

let boxStyle = {
  border: '0',
  padding: "0",
  width: "84%"
}

let titleStyle = {
  margin: '10px 0',
  fontSize: '16px',
  color: '#788195',
  fontFamily: 'Poppins',
  fontWeight: '500',
}

let inputStyle = {
  padding: '4px 11px',
  color: 'rgba(0, 0, 0, 0.88)',
  fontSize: '14px',
  borderRadius: '6px',
  height: "40px"
}

let textAreaStyle = {
  padding: '4px 11px',
  color: 'rgba(0, 0, 0, 0.88)',
  fontSize: '14px',
  borderRadius: '6px',
  height: "100px"
}

let imgStyle = {
  width: '16px',
  height: '20px',
  marginRight: '10px'
}


export default ({
  // handleSubmit,
  data,
  editing,
  columnId,
  values,
  submitCount,
  onCancel,
  onDelete,
  onSave,
  onEditCancel,
}) => {

  const { convertToBackend, convertToFrontend, currentProject, setCurrentProject, currentColumns, setCurrentColumns, currentTasks, setCurrentTasks, openRightDrawer, setOpenRightDrawer } = useContext(AppContext);

  const location = useLocation();
  // const [sources, setSources] = useState([]);
  // const [accountsConnected, setAccountsConnected] = useState();

  const [myThirdPartyData, setMyThirdPartyData] = useState(editing ? data : {
    id: "",
    name: "",
    url: "",
    description: "",
    columnId: columnId,
    serviceId: "",
    editing: false,
    created_at: new Date(),
    updated_at: new Date(),
  });

  const [error, setError] = useState({
    status: false,
    msg: "Success"
  });

  const handelClick = () => {
    setOpenRightDrawer({
      drawerType: false,
      drawerProps: {
        data: "",
        setData: "",
        columnId: "",
        tabSelected: "",
      },
    });
  }

  let myTitle = (title) => (
    <div style={{ display: "flex", alignItems: "flex-end" }}>
      <span style={{ marginRight: "2px", color: "red" }}>*</span>
      <img src={TitleIcon} style={imgStyle} alt="image" />
      <span>{title}</span>
    </div>
  );

  // const saveService = () => {
  //   console.log('myThirdPartyData in addService :', myThirdPartyData);
  //   console.log('currentProject in addService :', currentProject);

  //   let payload = {
  //     "name": myThirdPartyData.name,
  //     "description": myThirdPartyData.description,
  //     "service_type": convertToBackend[myThirdPartyData.columnId],
  //     "service_icon": null,
  //     "config": {
  //         ...myThirdPartyData
  //     },
  //     "project": currentProject.id
  //   }
    
  //   delete payload.config.id;
  //   delete payload.config.name;
  //   delete payload.config.columnId;
  //   delete payload.config.description;
  //   delete payload.config.serviceId;
  //   console.log('payload in updateService :', payload);

  //   fetch(`${process.env.REACT_APP_API_URI}/api/project_env/services/`, {
  //     method: 'POST',
  //     headers: {
  //     "Authorization": `Bearer ${localStorage.getItem("isAuth")}`,
  //     'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(payload)
  //   })
  //   .then(async (res) => {
  //     let data = await res.json();
  //     console.log("create thirdparty data :", data);
  //     if (data.status) {
  //         // update column
  //         currentProject.columns[columnId].task_orders.push(currentProject.tasks.length);
  //         // update tasks
  //         currentProject.tasks.push({
  //             id: currentProject.tasks.length,
  //             name: data.data.name,
  //             title: data.data.name,
  //             description: data.data.description,
  //             columnId: convertToFrontend[data.data.type],
  //             serviceId: data.data.id,
  //             ...data.data.configuration
  //         });

  //         setCurrentProject({ ...currentProject, columns: currentProject.columns, tasks: [...currentProject.tasks] });
  //         // localStorage.setItem("Project", currentProject);
  //         handelClick();
  //     }
  //     else{
  //         if(typeof data.msg === 'object'){
  //             data.msg = data.msg[Object.keys(data.msg)[0]][0];
  //             console.log('data.msg :', data.msg);
  //         }
  //         alert(`Error : ${data.msg}`);
  //     }
  //   })
  //   .catch((err) => {
  //       console.log("err :", err);
  //       alert(`Something Went wrong. Please try again!`);
  //   });
  // }

  // const updateService = () => {
  //   console.log('myThirdPartyData in updateService :', myThirdPartyData);
  //   let payload = {
  //       "name": myThirdPartyData.name,
  //       "service_type": convertToBackend[myThirdPartyData.columnId],
  //       "description": myThirdPartyData.description,
  //       "config": {
  //           ...myThirdPartyData
  //       }
  //   }
    
  //   delete payload.config.id;
  //   delete payload.config.name;
  //   delete payload.config.columnId;
  //   delete payload.config.description;
  //   delete payload.config.serviceId;
  //   console.log('payload in updateService :', payload);

  //   fetch(`${process.env.REACT_APP_API_URI}/api/project_env/services/${myThirdPartyData.serviceId}/`, {
  //       method: 'PATCH',
  //       headers: {
  //       "Authorization": `Bearer ${localStorage.getItem("isAuth")}`,
  //       'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(payload)
  //   })
  //   .then(async (res) => {
  //       let data = await res.json();
  //       console.log("account/list data :", data);
  //       if (data.status) {
  //         currentProject.tasks.forEach((temp, idx) => {
  //           if (temp && temp.serviceId === myThirdPartyData.serviceId) {
  //               currentProject.tasks[idx] = myThirdPartyData;
  //           }
  //         });
  //         console.log('currentProject :', currentProject);
  //         setCurrentProject({ ...currentProject });
  //         handelClick();
  //       }
  //       else{
  //           if(typeof data.msg === 'object'){
  //               data.msg = data.msg[Object.keys(data.msg)[0]][0];
  //               console.log('data.msg :', data.msg);
  //           }
  //           alert(`Error : ${data.msg}`);
  //       }
  //   })
  //   .catch((err) => {
  //       console.log("err :", err);
  //       alert("Something went wrong. Please try again!");
  //   });
  // }

  // const saveTasks = () => {
  //   console.log('myThirdPartyData :', myThirdPartyData);
  //   let id = currentProject.tasks.length;
  //   myThirdPartyData.id = id;
  //   console.log('id :', id);

  //   // update column
  //   currentProject.columns[columnId].task_orders.push(id);
  //   setCurrentProject({ ...currentProject, columns: currentProject.columns });
  //   // update tasks
  //   currentProject.tasks.push(myThirdPartyData);
  //   setCurrentProject({...currentProject,  tasks: [...currentProject.tasks]});
  //   // localStorage.setItem("Project", currentProject);
  //   handelClick();
  // }

  // const updateTasks = () => {
  //   console.log('currentProject :', currentProject);
  //   console.log('myThirdPartyData :', myThirdPartyData);
  //   currentProject.tasks.forEach((temp, idx) => {
  //     if(temp && (temp.id === myThirdPartyData.id)){
  //       currentProject.tasks[idx] = myThirdPartyData;
  //     }
  //   });
  //   console.log('currentProject :', currentProject);
  //   setCurrentProject({...currentProject});
  //   // localStorage.setItem("Project", currentProject);
  //   handelClick();
  // }

  const handelChange = (e) => {
    setMyThirdPartyData({ ...myThirdPartyData, [e.target.name]: e.target.value });
  }

  const handelSaveTask = () => {
    // if (location.pathname.includes("/all-projects/project/")) {
    //     saveService();
    // }
    // else {
    //     saveTasks();
    // }
  }

  const handelUpdateTask = () => {
    // if (location.pathname.includes("/all-projects/project/")) {
    //     updateService();
    // }
    // else {
    //     updateTasks();
    // }
  }


  return (
    <Form style={{ marginTop: "100px" }}>
      <CreateTaskHeader
        buttonContent="thirdParties"
        editing={editing}
        values={values}
        onCancel={onCancel}
        onDelete={onDelete}
        onEditCancel={onEditCancel}
        onSave={handelSaveTask}
        onUpdate={handelUpdateTask}
      />
      <div style={{ padding: "0 20px" }}>

        <Box
          title={myTitle("Third Party Name")}
          style={boxStyle}
          titleStyle={titleStyle}
          imgStyle={imgStyle}
        >
          <Input
            name="name"
            value={myThirdPartyData.name}
            onChange={handelChange}
            placeholder="Add Third Party Name"
            style={inputStyle}
          />
        </Box>

        <Box
          title={myTitle("Url")}
          style={boxStyle}
          titleStyle={titleStyle}
          imgStyle={imgStyle}
        >
          <Input
            name="url"
            value={myThirdPartyData.url}
            onChange={handelChange}
            placeholder="Add Third Party Url"
            style={inputStyle}
          />
        </Box>

        <Box
            title={myTitle("Description")}
            style={boxStyle}
            titleStyle={titleStyle}
            imgStyle={imgStyle}
        >
            <Textarea
                name="description"
                value={myThirdPartyData.description}
                onChange={handelChange}
                placeholder="Add a more detailed description..."
                style={textAreaStyle}
            />
        </Box>
      </div>
    </Form>
  )
};
