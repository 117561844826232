import { React, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import Input, {
    InputSearch,
    InputGroup,
    Textarea,
} from '@iso/components/uielements/input';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import styled, { createGlobalStyle } from 'styled-components';
import Select, { SelectOption } from '@iso/components/uielements/select';
import initialData from '../../../redux/scrumBoard/data';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AlertContext } from '../../../context/alertContext';
import awsIcon from '@iso/assets/images/icon/aws-logo.svg';
import api from '../../../api';
import { formatErrors } from '../../../context/helper';
import { Form } from 'antd';

const Option = SelectOption;

const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }
`;

const serverInsTypes = ["t3.small", "m4.large", "c5.large", "r3.large"];
serverInsTypes.map((child, ind) => {
    serverInsTypes.push(<Option key={child} name="serverInsType" value={child}>{child}</Option>);
});

var optionAzs = [];

export default function EcrIntgration({
    // handleSubmit,
    data,
    load,
    setLoad,
    connected,
    setConnected,
    onCancel,
    onDelete,
    openDrawer,
    onEditCancel,
    tabSelected,
    openAddOns
}) {

    const { setOpenRightDrawer } = useContext(AppContext);
    const { set_alertOptions, handleAlerts } = useContext(AlertContext);
    const navigate = useNavigate();

    const [ecrData, setEcrData] = useState({
        accountId: [],
        region: []
    });

    const [allAccountIds, setAllAccountIds] = useState([]);
    const [allRegions, setAllRegions] = useState([]);

    const [error, setError] = useState({
        status: false,
        msg: "Success"
    });

    const [form] = Form.useForm();

    useEffect(() => {
        console.log("data :", data);
        console.log("initialData :", initialData);
        let accountIds = [];
        let regions = [];
        // accountIds
        data.map((temp, ind) => {
            if (temp.integration_type === "aws" && temp.status) {
                accountIds.push(
                    <Option key={temp.account_id} name="accountId" data-alias={temp.alias} type={temp.integration_type} value={temp.account_id}>
                        <div name="accountId" style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                            <img src={awsIcon} style={{ width: "18px", height: "18px", marginRight: "10px" }} alt="provider" />
                            <span name="accountId">{`${temp.account_id}${temp.alias ? ` (${temp.alias})` : ""}`}</span>
                        </div>
                    </Option>
                );
            }
        });

        // Integrate New Account on no aws account found
        // if (accountIds.length >= 0) {
        //     accountIds.push(
        //         <Option key={data.length} name="accountId" data-alias="" type="integration" style={{ color: "white", background: "#1677ff" }}>
        //             <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
        //                 <span>Integrate New Account</span>
        //             </div>
        //         </Option>
        //     );
        // }

        // regions
        Object.keys(initialData.regions["aws"]).map((child, ind) => {
            regions.push(<Option key={child + "-" + ind} name="region" value={child}>
                <div style={{ display: "flex", justifyContent: "start", alignItems: "center", width: "100%" }}>
                    <span>{`${initialData.regions["aws"][child].name} / ${child}`}</span>
                </div>
            </Option>);
        });

        setAllAccountIds([...accountIds]);
        setAllRegions([...regions]);
    }, []);


    const handleClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: ""
            },
        });
    }

    const handleSelectChange = (value, event) => {
        console.log("event :", event);
        // console.log("value :", value);
        if (event.name === "redirect") {
            // handleClick();
            return;
        }

        setEcrData({ ...ecrData, [event[0].name]: value });
    }


    const handleIntegration = async (e) => {
        try {
            await form.validateFields();
            console.log("inside handleIntegration :", ecrData);
            handleClick();
            setLoad({ ...load, ecr: true });
            let payload = {
                account_ids: ecrData.accountId,
                regions: ecrData.region,
            }

            api.post(process.env.REACT_APP_API_URI + '/api/integration/ecr-credentials/', payload)
                .then((res) => {
                    let data = res.data;
                    console.log('data in dockerhub integration :', data);
                    if (data.status) {
                        setConnected(!connected);
                        // handleClick();
                        // handleAlerts("success", "Success", "GCP Account Connected Successfully.");
                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: true,
                            type: "success",
                            message: "Success",
                            description: "ECR Account Connected Successfully.",
                        }));

                        setTimeout(() => {
                            set_alertOptions(newVal => ({
                                ...newVal,
                                status: false,
                                type: "success",
                                message: "Success",
                                description: "ECR Account Connected Successfully.",
                            }));
                        }, 5 * 1000);
                    }
                    else {
                        let err = formatErrors(data.error_details);
                        console.log("err in /ecr/integration :", err);

                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: true,
                            type: "error",
                            message: "Failed",
                            description: err,
                        }));

                        setTimeout(() => {
                            set_alertOptions(newVal => ({
                                ...newVal,
                                status: false,
                                type: "error",
                                message: "Failed",
                                description: err,
                            }));
                        }, 5 * 1000);
                        // handleAlerts("error", "Failed", "Error Connecting.");
                    }
                    setLoad({ ...load, ecr: false });
                })
                .catch((err) => {
                    set_alertOptions(newVal => ({
                        ...newVal,
                        status: true,
                        type: "error",
                        message: "Failed",
                        description: "Something went wrong",
                    }));

                    setTimeout(() => {
                        set_alertOptions(newVal => ({
                            ...newVal,
                            status: false,
                            type: "error",
                            message: "Failed",
                            description: "Something went wrong",
                        }));
                    }, 5 * 1000);
                    // handleAlerts("error", "Failed", "Something Went Wrong!");
                    setLoad({ ...load, ecr: false });
                    console.log('Error in resend-emailverification :', err);
                })
        }
        catch (err) {
            console.log("Error", err);
        }
    }

    return (
        <ProdContentHold>
            <Form form={form} name="ecrForms">
                <CreateTaskHeader
                    buttonContent="ECR"
                    data={"Yes"}
                    // values={{editing: true}}
                    onCancel={() => {
                        setOpenRightDrawer({
                            drawerType: false,
                            drawerProps: {
                                myClusterData: "",
                                tabSelected: "",
                            },
                        });
                    }}
                    onDelete={onDelete}
                    onEditCancel={() => {
                        setOpenRightDrawer({
                            drawerType: false,
                            drawerProps: {
                                myClusterData: "",
                                tabSelected: "",
                            },
                        });
                    }}
                />

                <Box
                    title={<div style={{ display: "flex", marginTop: "100px" }}><span style={{ marginRight: "5px", color: "red" }}>*</span><span>Account ID :</span></div>}
                >
                    <ContentHolder>
                        {console.log("allAccountIds :", allAccountIds)}
                        <Form.Item
                            name="accountId"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select Account ID."
                                }
                            ]}
                        >
                            <Select
                                mode={allAccountIds.length > 0 ? "multiple" : "single"}
                                name="accountId"
                                style={{ width: '100%' }}
                                placeholder="Select account Ids"
                                defaultValue={ecrData.accountId}
                                onChange={handleSelectChange}
                            >
                                {allAccountIds.length > 0 ? allAccountIds :
                                    <Option key="0" name="redirect" data-alias="" type="integration" style={{ color: "white", background: "#1677ff" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
                                            <span>No AWS account integration found. Integrate a new account.</span>
                                        </div>
                                    </Option>
                                }
                            </Select>
                        </Form.Item>

                        {/* <Select
                        style={{ width: '400px' }}
                        defaultValue={ecrData.accountId}
                        onChange={handleSelectChange}
                    >
                        {allAccountIds}
                    </Select> */}
                    </ContentHolder>
                </Box>

                <Box
                    title={<div style={{ display: "flex" }}><span style={{ marginRight: "5px", color: "red" }}>*</span><span>Region :</span></div>}
                >
                    <ContentHolder>
                        <Form.Item
                            name="region"
                            rules={[
                                {
                                    required: true,
                                    message: "Please select region."
                                }
                            ]}
                        >
                            <Select
                                mode="multiple"
                                name="region"
                                style={{ width: '100%' }}
                                placeholder="Select regions"
                                defaultValue={ecrData.region}
                                onChange={handleSelectChange}
                            >
                                {allRegions}
                            </Select>
                        </Form.Item>
                        {/* <Select
                        style={{ width: '400px' }}
                        defaultValue={ecrData.region}
                        onChange={handleSelectChange}
                    >
                        {allRegions}
                    </Select> */}
                    </ContentHolder>
                </Box>

                <Box>
                    <ContentHolder style={{
                        position: "relative"
                    }}>
                        <Button type="primary" onClick={handleIntegration} style={{ position: "absolute", right: "40%", marginTop: "-20px" }}>
                            Connect
                        </Button>
                    </ContentHolder>
                </Box>
            </Form>
        </ProdContentHold>
    );
};
