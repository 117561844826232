import React, { useState } from 'react';
const SideBarContext = React.createContext();

const SideBarProvider = ({ children }) => {
    const [current, setCurrent] = useState(["dashboard"]);
    return (
        <SideBarContext.Provider value={{current, setCurrent}}>
            {children}
        </SideBarContext.Provider>
    );
}

export { SideBarContext, SideBarProvider };