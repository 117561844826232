import styled from 'styled-components';
import Table from '../Tables/AntTables/AntTables.styles';
import { Radio } from 'antd';
const TableWrapper = styled(Table)`
    .ant-table-bordered .ant-table-thead > tr > th{
        background-color: #132F44;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
    
    .ant-table-bordered .ant-table-tbody > tr > td {
        color: #292929;
        font-weight: 400;
        font-size: 12px;
    }
    
    .ant-spin-nested-loading, .ant-spin-container, .ant-table-bordered, .ant-table-container{
        height: 100%;
    }

    .ant-table-header{
        height: 48px;
    }

    .ant-table-tbody .ant-table-row .ant-table-cell{
        padding: 14px 22px;
        font-family: "Nunito Sans";
    }    
    
    .ant-table-tbody .ant-table-row .ant-table-cell.custom-error-handel-col{
        padding: 0;
        font-family: "Nunito Sans";
    }    

    &.roleAndPermissionTable{
        .ant-table-tbody .ant-table-row .ant-table-cell{
            padding: 8.5px 15px;
            font-family: "Nunito Sans";
        }

        // .ant-table-tbody > tr > td > label > span.ant-checkbox .ant-checkbox-inner{
        //     width: 13px;
        //     height: 13px;
        // }

        .ant-table-bordered .ant-table-thead > tr > th{
            font-family: "Poppins";
            font-size: 16px;
            padding: 12.5px 16px;
        }

        span{
            font-family: "Nunito Sans";
            font-size: 12px;
        }

        &.ant-table-thead > &.ant-table-cell{
            font-family: "Poppins";
            font-size: 16px;
        }
    }
    
    &.permissionsTable{
        .ant-table:not(.ant-table-bordered) .ant-table-thead > tr > th{
            background-color: #FFFFFF;
            color: #1F1F1F;
            font-weight: 500;
            font-family: Poppins;
            font-size: 16px;
            border-top: 1px solid #e9e9e9;
            border-bottom: 1px solid #e9e9e9;
            padding: 10.5px;
        }
        
        .ant-table:not(.ant-table-bordered) .ant-table-thead > tr{
            th:first-child {
                border-left: 1px solid #e9e9e9;
            }
            
            th:last-child {
                border-right: 1px solid #e9e9e9;
            }
        }
        
        .ant-table:not(.ant-table-bordered) .ant-table-tbody>tr:hover{
            td.ant-table-cell-row-hover{
                border-start-start-radius: 0;
                border-end-start-radius: 0;
            }
        }
        
        // .ant-table-tbody tr:hover > td {
        //     background-color: white;
        //     border-start-start-radius: 0;
        //     border-end-start-radius: 0;
        // }
        
        .ant-table-tbody .ant-table-row{
            td:first-child{
                border-left: 1px solid #e9e9e9;
            }
            
            td:last-child{
                border-right: 1px solid #e9e9e9;
            }
        }
        
        .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
            display: none;
        }
        
        .ant-table-tbody .ant-table-row .ant-table-cell{
            padding: 10.5px 10.5px;
            color: #1F1F1F;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        span{
            font-family: "Nunito Sans";
            font-size: 12px;
        }
    }
`;

const SpanHeading = styled.span`
    font-weight: 600;
    padding: 2px;
    color: #1F1F1F;
`;

export const MyRadioButton = styled(Radio)`
    .ant-radio-inner {
        border: 1px solid #132f44 !important;
    }
  .ant-radio-checked .ant-radio-inner:after{
    background-color: white !important;
  }
  .ant-radio-checked .ant-radio-inner {
    background-color: #132f44 !important;
    border: 1px solid #132f44;
  }
`;


export {
    TableWrapper,
    SpanHeading
};