import { React, useState } from 'react';
import IntlMessages from '@iso/components/utility/intlMessages';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Button from '@iso/components/uielements/button';
import { useContext } from 'react';
import { Form, Input, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
// import closeIcon from '@iso/assets/images/icon/close-icon.svg';
import closeIcon from '@iso/assets/images/icon/07-icon.svg';
import styled, { createGlobalStyle } from 'styled-components';
import { SelectOption } from '@iso/components/uielements/select';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import initialData from '../../redux/scrumBoard/data';
import { AppContext } from '../../context/ContextProvider';
import CreateTaskHeader from '../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
// import { borderRadius } from '../../../library/helpers/style_utils';
import Select from '../Deployments/Deployment.styles';
import { customFilterOption, customSortOptions, hasPermission } from '../../context/helper';
import CustomInfoTooltip from '../Dashboard/CustomComponents/CustomInfoTooltip';
import api from '../../api';
import { AlertContext } from '../../context/alertContext';
import { formatErrors } from '../../context/helper';
import useProject from '../Dashboard/CustomHooks/useProject';


const Option = SelectOption;
const { TextArea } = Input

const ProdContentHold = styled.div`
  .isoBoxWrapper {
    height: auto;
    border: none;
    margin: 0;
  }

  .isoHelperText {
    color: red;
    font-weight: 400;
    font-size: 12px;
    margin-top: 10%;
    display: flex;
    justify-content: start;
  }

  .header-text{
    font-size: 14px;
  }
`;

const addButtonStyle = {
  marginRight: "10px",
  fontSize: "25px"
};

let titleStyle = {
  color: "#1f1f1f",
  fontFamily: "Poppins",
  fontType: "medium",
  fontSize: "16px",
  fontWeight: "500"
}

let subTitleStyle = {
  fontSize: "14px",
  color: "#9b9b9b",
  fontFamily: "Nunito Sans",
  fontType: "regular",
  fontWeight: "400"
}

let boxStyle = {
  border: '0',
  padding: "0",
  width: "100%",
}

const valueFroms = ["Value", "Value From"];
let myValueFroms = [];
valueFroms.map((child, ind) => {
  myValueFroms.push(<Option key={child} name="valueFrom" value={child}>{child}</Option>);
});

export default ({
  // handleSubmit,
  data,
  valueFromDropdown,
  update,
  service,
  selectedAppId,
  // form,
  values,
  submitCount,
  onCancel,
  onDelete,
  openDrawer,
  onEditCancel,
  tabSelected,
  currentProject,
  setCurrentProject,
  hasChanges,
  setHasChanges,
  fromEnvironment,
  serviceSelected
}) => {


  const { openRightDrawer, setOpenRightDrawer } = useContext(AppContext);
  const [form] = Form.useForm();

  const [envData, setEnvData] = useState(update ?
    { ...data, value: hasPermission("Can view secret") && data.isSecret ? data.value : !data.isSecret ? data.value : "********" } : {
      varName: "",
      valueFrom: "",
      valueShown: "",
      value: "",
      isSecret: (!hasPermission("Can create secret") && currentProject.accountDetails.account_id) ? false : true,
      isHide: (!hasPermission("Can create secret") && currentProject.accountDetails.account_id) ? false : true,
      dependOn: "",
      value_depend_on: "",
      error: false,
      importFrom: "",
      syncProj: (!hasPermission("Can create secret") && currentProject.accountDetails.account_id) ? false : true,
    });
  const [myDropdown, setMyDropdown] = useState([]);
  const [error, setError] = useState({
    status: false,
    msg: "Success"
  });
  const [searchValue, setSearchValue] = useState("");
  const [savingVars, setSavingVars] = useState(false);
  const { set_alertOptions } = useContext(AlertContext);
  const { encryptSecrets, decryptSecrets } = useProject();

  //console.log("Data: ", data);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // const rawValues = ["DB.HOST", "DB.USER", "DB.PASSWORD"];
    console.log("service :", valueFromDropdown, location);
    let myValues = [];
    valueFromDropdown.map((temp, ind) => {
      // if (temp.service_category_id !== 8) {
      //   temp.connection_parameters.map((child) => {
      //     myValues.push(<Option key={child} name="value" data-alias={temp.id} my-value={child} value={child}>{child}</Option>);
      //   });
      // }
      // else {
      console.log("values: ", temp.connection_parameters);
      Object.keys(temp.connection_parameters).map((child) => {
        console.log("temp val: ", temp.id, child);
        myValues.push(<Option key={child + "_" + temp.name} name="value" data-alias={temp.id} my-value={child} value={child + "_" + temp.name}>
          <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
            <p style={{ color: "#1f1f1f", fontWeight: "600", fontFamily: "Nunito Sans" }}>{child + "_" + temp.name}</p>
            <div style={{ marginTop: "10px", whiteSpace: "normal" }}>
              <p style={{ marginRight: "10px", fontFamily: "Nunito Sans", fontSize: "12px" }}>{temp.connection_parameters[child].Description}</p>
              <p style={{ color: "#4D99EF", fontFamily: "semibold", fontFamily: "Nunito Sans" }}>{temp.connection_parameters[child].Example}</p>
            </div>
          </div>
        </Option>)
      });
      //}
    });
    setMyDropdown(myValues);
  }, []);

  useEffect(() => {
    if (form) {
      form.setFieldsValue({ ...envData });
    }
  }, [envData]);

  useEffect(() => {
    if (update && form) {
      try {
        const values = form.validateFields();
        console.log('Success:', values);
      }
      catch (err) {
        console.log('Error :', err);
      }
    }
  }, [update]);


  const handelClick = () => {
    console.log("location.state: ", location.state);
    setOpenRightDrawer({
      drawerType: false,
      drawerProps: {
        data: "",
        columnId: ""
      },
    });
  }

  const handelInputChange = (e) => {
    console.log("inside handelInputChange");
    if (e.target.name === "value") {
      console.log("Env data: ", envData);
      setEnvData({
        ...envData,
        [e.target.name]: e.target.value,
        dependOn: null,
        error: e.target.value ? false : true
      });

    }
    else {
      setEnvData({ ...envData, [e.target.name]: e.target.value, error: e.target.value ? false : true });
    }
    setError({ ...error, status: false });
  }

  const handleSelectChange = (value, event) => {
    console.log("value inside handleSelectChange :", value);
    console.log("event inside handleSelectChange :", event);
    if (event.name === "value") {
      console.log("event alias: ", event);
      setEnvData({
        ...envData,
        [event.name]: event["my-value"],
        valueShown: value,
        dependOn: event["data-alias"],
        error: value ? false : true,
      });
    }
    else {
      setEnvData({
        ...envData,
        [event.name]: value,
        value: "",
        isSecret: value === "Value From" ? false : envData.isSecret,
        isHide: value === "Value From" ? false : envData.isSecret,
        error: value ? false : true,
      });
    }
    setError({ ...error, status: false });
  }

  const handelCreate = async (e, addAnother) => {
    console.log("inside handelCreate envData:", envData, location.state);
    console.log("inside handelCreate currentProject:", currentProject);
    try {
      if (form) {
        const values = await form.validateFields();
        console.log('Success:', values);
      }
      let allNames = [];
      if (service) {
        service.envs.map((temp) => {
          allNames.push(temp.varName);
        });
      }

      if (allNames.includes(envData.varName)) {
        setError({
          status: true,
          msg: "Variable name already exist. Please provide unique variable name."
        });
      }
      else if (envData.varName.startsWith(" ") || envData.varName.endsWith(" ")) {
        setError({
          status: true,
          msg: "Please remove any leading or trailing spaces from variable name."
        });
      }
      else {
        setError({ ...error, status: false });
        console.log("current project in add...", currentProject, data);
        currentProject.tasks.map((temp, idx) => {
          if (temp.id === data.id) {
            // let tempEnvData = {...envData}

            currentProject.tasks[idx].envs.push(envData);
          }
        });
        if (!fromEnvironment) {
          currentProject.tasks[selectedAppId].appError = false
        };
        setCurrentProject({ ...currentProject, tasks: [...currentProject.tasks] });
        localStorage.setItem(!fromEnvironment ? "Project" : "ProjectDep", JSON.stringify({ ...currentProject, tasks: [...currentProject.tasks] }));
        if (!hasChanges) {
          if (!fromEnvironment) {
            if (envData.isSecret && currentProject.accountDetails.account_id) {
              console.log("getting env vars....");
              handelSaveEnvVar(true);
            }
            else {
              handelSaveEnvVar(false);
            }
          }
          else {
            if (envData.syncProj) {
              if (envData.isSecret && currentProject.accountDetails.account_id) {
                console.log("getting env vars....");
                handelSaveEnvVar(true);
              }
              else {
                handelSaveEnvVar(false);
              }
            }
          }
        }
        // setHasChanges(true);

        if (addAnother) {
          setEnvData({
            varName: "",
            valueFrom: "",
            value: "",
            isSecret: true,
            isHide: true,
            dependOn: "",
            value_depend_on: "",
            error: false,
          });
        }
        else {
          console.log("my click");
          handelClick();
        }
      }
    }
    catch (err) {
      console.log("Inside handelCreate Error :", err);
    };
  }

  const handelUpdate = async (e) => {
    console.log("inside handelUpdate envData:", service, envData, data, location.state);
    console.log("inside handelUpdate currentProject:", currentProject);
    try {
      // const values = await form.validateFields();
      // console.log('Success:', values);
      if (form) {
        const values = await form.validateFields();
        console.log('Success:', values);
      }      
      let allNames = [];
      let ctr = 0; //counts how many times the variables get repeated
      // if (fromEnvironment) {
        console.log("Environment data: ", service);
        service.envs.map((temp) => {
          if (temp.varName != data.varName) {
            allNames.push(temp.varName);
          }
          else {
            allNames.push(temp.varName);
            ctr++;
          }
        });
      // }
      // else {
      //   if (service) {
      //     service.envs.map((temp) => {
      //       if (temp.varName != data.varName) {
      //         allNames.push(temp.varName);
      //       }
      //     });
      //   } 
      // }

      console.log("ALL NAMES: ", allNames);

      if (allNames.includes(envData.varName) && ctr > 1) {
        setError({
          status: true,
          msg: "Variable name already exist. Please provide unique variable name."
        });
      }
      else if (envData.varName.startsWith(" ") || envData.varName.endsWith(" ")) {
        setError({
          status: true,
          msg: "Please remove any leading or trailing spaces from variable name."
        });
      }
      else {
        setError({ ...error, status: false });
        console.log("Data.tempId: ", data);
        currentProject.tasks.map((temp, idx) => {
          if (!fromEnvironment ? (temp.id === data.serviceId || idx === data.tempId) : temp.id === data.tempId) {
            currentProject.tasks[idx].envs[data.id] = envData;
          }
        });

        if (!fromEnvironment) {
          currentProject.tasks[selectedAppId].appError = false;
        }
        console.log("My new currentProject: ", currentProject);
        setCurrentProject({ ...currentProject, tasks: [...currentProject.tasks] });
        localStorage.setItem(!fromEnvironment ? "Project" : "ProjectDep", JSON.stringify({ ...currentProject, tasks: [...currentProject.tasks] }));
        console.log("Entering api call.....");
        if (!hasChanges) {
          if (!fromEnvironment) {
            if (envData.isSecret && (currentProject.tasks[selectedAppId].is_secret_manager || currentProject.accountDetails.account_id)) {
              console.log("getting env vars....");
              handelSaveEnvVar(true);
            }
            else {
              handelSaveEnvVar(false);
            }
          }
          else {
            if (envData.syncProj) {
              if (envData.isSecret && currentProject.accountDetails.account_id) {
                console.log("getting env vars....");
                handelSaveEnvVar(true);
              }
              else {
                handelSaveEnvVar(false);
              }
            }
          }
        }
        // setHasChanges(true);
        handelClick();
      }
    }
    catch (err) {
      console.log("Inside handelCreate Error :", err);
    }
  }

  const filterOption = (input, option) => {
    if (option.key) {
      return (option?.value.toLowerCase() ?? '').includes(input.toLowerCase());
    }
  }

  function toggleAlert(type, message, description) {

    set_alertOptions(alertOptions => ({
      status: true,
      type: type,
      message: message,
      description: description,
    }));

    // opacity=0 after 10sec
    setTimeout(() => {
      set_alertOptions(alertOptions => ({
        status: false,
        type: type,
        message: message,
        description: description,
      }));
    }, 5 * 1000);
  }

  const handelSaveEnvVar = (callSecretApi) => {
    let payload = [];
    // let myAppIdx = [];
    let foundEmpty = false;
    let injectFilled = true;

    // console.log("hasChanges in handelSaveEnvVar :", hasChanges);
    // console.log("dataSource in handelSaveEnvVar :", dataSource);

    currentProject.tasks.every((temp, idx) => {
      // if ((!temp.injectValue || temp.injectValue === "") && temp.envs?.length > 0) {
      //   injectFilled = false;
      //   currentProject.tasks[idx].appError = true;
      //   // return false;
      // }
      // if (foundEmpty) {
      //     injectFilled = true;
      //     currentProject.tasks[idx].appError = true;
      //     return false;   // helps in breaking every() loop
      // }
      if (temp.columnId === "app") {
        // myAppIdx.push(temp.id);
        console.log("temp in handleCreate :", temp);

        temp.envs.every((env) => {
          let found = false;

          if (env.value === "" || env.value === undefined) {
            foundEmpty = true;
            currentProject.tasks[idx].appError = true;
            return false;   // helps in breaking every() loop
          }
          // check if already exist in envs list
          payload.map((pay, i) => {
            if (pay.id === temp.serviceId) {
              found = true;
              pay["env_var"][env.varName] = {
                "type": env.valueFrom,
                "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                "is_secret": env.isSecret,
                "depend_on": env.dependOn
              }
            }
          });

          console.log("found :", found);
          // if not
          if (!found) {
            console.log("temp: ", temp);
            let myObj = {
              "id": temp.serviceId,
              // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
              "is_secret_manager": callSecretApi ? currentProject.accountDetails?.account_id ? true : false : undefined,
              "secret_details": callSecretApi ? currentProject.accountDetails?.account_id ? {
                "account_id": currentProject.accountDetails?.account_id,
                "region": currentProject.accountDetails?.region,
                "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProject.name + '_' + temp.name),

              } : undefined : undefined,
              "env_var": {
                [env.varName]: {
                  "type": env.valueFrom,
                  "value": env.value,//env.isSecret ? encryptSecrets(env.value) : env.value,
                  "is_secret": env.isSecret,
                  "depend_on": env.dependOn
                }
              }
            }
            if (callSecretApi) {
              if (env.isSecret) {
                payload.push(myObj);
              }
            }
            else {
              if (!env.isSecret) {
                payload.push(myObj);
              }
            }
          }

          return true;  // helps in continue loop
        });

        return true;  // helps in continue loop
      }
      else {
        return true;
      }
    });

    // console.log("foundEmpty :", foundEmpty);
    // console.log("injectFilled :", injectFilled);
    // console.log("injectValue :", injectValue);
    // console.log("hasChanges :", hasChanges);
    // console.log("payload :", payload);

    // if (foundEmpty) {
    //   setCurrentProject({ ...currentProject, tasks: [...currentProject.tasks] });
    //   toggleAlert("error", "Error", "Some Values are empty. Please fill them first.");
    // }
    // else if (!injectValue || !injectFilled) {
    //   console.log("inside if injectValue :", injectValue);
    //   setCurrentProject({ ...currentProject, tasks: [...currentProject.tasks] });
    //   toggleAlert("error", "Error", "Please select one from Build Time and Run Time.");
    //   // setIsValidated(true);
    // }
    // else if (!hasChanges) {
    //   changeService();
    // }
    if (payload.length > 0) {
      // console.log("payload :", payload, data,  payload.find(val => !fromEnvironment ? val.id === currentProject.tasks[selectedAppId].serviceId : val.id === data.serviceId));
      setSavingVars(true);
      !callSecretApi && !fromEnvironment ? api.put(`${process.env.REACT_APP_API_URI}/api/project_env/projects/${currentProject.id}/services-envs/`, payload.filter((val) => !fromEnvironment ? val.id === currentProject.tasks[selectedAppId].serviceId : (val.id === data.serviceId || val.id === data.tempId)))
        .then((res) => {
          let data = res.data;
          console.log("repo/list data :", data);
          if (data.status) {
            toggleAlert("success", "Success", update ? "Environment variable updated successfully." : "Environment variable added successfully.");
            // changeService();
            setHasChanges(false);
            // setIsValidated(false);
            // localStorage.removeItem("Project");
            // navigate("/all-projects");
          }
          else {
            let err = formatErrors(data.msg);
            toggleAlert("error", "Error", err);
          }
          setSavingVars(false);
        })
        .catch((err) => {
          console.log("err :", err);
          toggleAlert("error", "Error", "Something went wrong. Please try again.");
          setSavingVars(false);
        }) :
        !update && callSecretApi ? api.post(`${process.env.REACT_APP_API_URI}/api/project_env/secrets`, payload.find(val => !fromEnvironment ? val.id === currentProject.tasks[selectedAppId].serviceId : (val.id === data.serviceId || val.id === data.tempId)))
          .then((res) => {
            let data = res.data;
            console.log("repo/list data :", data);
            if (data.status) {
              toggleAlert("success", "Success", update ? "Environment variable updated successfully." : "Environment variable added successfully.");
              // changeService();
              setHasChanges(false);
              // setIsValidated(false);
              // localStorage.removeItem("Project");
              // navigate("/all-projects");
            }
            else {
              let err = formatErrors(data.msg);
              toggleAlert("error", "Error", err);
            }
            setSavingVars(false);
          })
          .catch((err) => {
            console.log("err :", err);
            toggleAlert("error", "Error", "Something went wrong. Please try again.");
            setSavingVars(false);
          }) : callSecretApi && api.put(`${process.env.REACT_APP_API_URI}/api/project_env/secrets`, payload.find((val, idx) => !fromEnvironment ? val.id === currentProject.tasks[selectedAppId].serviceId || idx === data.tempId : (val.id === data.serviceId || val.id === data.tempId)))
            .then((res) => {
              let data = res.data;
              console.log("repo/list data :", data);
              if (data.status) {
                toggleAlert("success", "Success", update ? "Environment variable updated successfully" : "Environment variable added successfully.");
                // changeService();
                setHasChanges(false);
                // setIsValidated(false);
                // localStorage.removeItem("Project");
                // navigate("/all-projects");
              }
              else {
                let err = formatErrors(data.msg);
                toggleAlert("error", "Error", err);
              }
              setSavingVars(false);
            })
            .catch((err) => {
              console.log("err :", err);
              toggleAlert("error", "Error", "Something went wrong. Please try again.");
              setSavingVars(false);
            });
    }
  }


  return (
    <ProdContentHold className='addEnvVar' style={{}}>
      <Form
        form={form}
        initialValues={{ ...envData }}
        preserve={false}
        name="dynamic_rule"
        disabled={(!hasPermission("Can update secret") && update && envData.isSecret)}
      >
        <Box
          title={<div style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}>
            <span className="header-text">Add Variable</span>
            <img src={closeIcon} style={{ cursor: "pointer", width: "20px", height: "20px", transform: "translateY(15px)" }} alt="close Icon" onClick={handelClick} />
          </div>}
          subtitle="Enter details to add new variable"
          titleStyle={{
            fontWeight: '500',
            fontFamily: "Nunito Sans",
            fontSize: '14px',
            color: "white"
          }}
          subTitleStyle={{
            fontWeight: '400',
            fontFamily: "Nunito Sans",
            fontSize: '13px',
            color: "white"
          }}
          style={{
            position: "absolute",
            zIndex: "1",
            width: "100%",
            top: "0px",
            height: "83px",
            backgroundColor: "rgb(19, 47, 68)"
          }}
        />

        <Box
          title="Variable*"
          subtitle="Variable Name"
          titleStyle={titleStyle}
          subtitleStyle={subTitleStyle}
          style={{
            marginTop: "80px",
          }}
        >
          <Form.Item
            name="varName"
            rules={[
              {
                required: true,
                message: 'Please enter variable name',
              },
            ]}
          >
            <Input
              name="varName"
              value={envData.varName}
              onChange={handelInputChange}
              placeholder="Enter Variable here"
              className="envVar-varName"
              style={{height: "44px"}}
              disabled={(!hasPermission("Can update secret") && update && envData.isSecret)}
            />
          </Form.Item>
        </Box>

        <Box
          title="Value From*"
          subtitle="Value from details"
          subtitleStyle={subTitleStyle}
          titleStyle={titleStyle}
          style={{ width: "100%" }}
        >
          <div style={{ width: "100%" }}>
            <Form.Item
              name="valueFrom"
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
            >
              <Select
                value={envData.valueFrom}
                onChange={handleSelectChange}
                placeholder="Enter Value From here"
                style={{ width: '100%' }}
                disabled={((currentProject.importDetails?.secretFile && update) || (currentProject.accountDetails.account_id && envData.isSecret && update))}
              >
                {myValueFroms}
              </Select>
            </Form.Item>
          </div>
        </Box>

        <Box
          title="Select Value*"
          subtitle="Select dependent service value from dropdown. Values are displayed as <SERVICE_VARIABLE_name>"
          subtitleStyle={subTitleStyle}
          titleStyle={titleStyle}
        >
          {console.log("envData.valueShown :", envData.valueShown)}
          {envData.valueFrom === "Value From" ?
            <Form.Item
              name={envData.valueFrom === "Value From" ? "valueShown" : "value"}
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
            >
              <Select
                // defaultValue={envData.value}
                showSearch
                filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                onSearch={(value) => {
                  console.log("Enters search", value);
                  setSearchValue(value);
                }}
                value={envData.valueShown ? envData.valueShown : null}
                optionLabelProp={envData.valueShown}
                onChange={handleSelectChange}
                placeholder="Please Select Value"
                //filterOption={filterOption}
                style={{ width: "100%" }}
              >
                {myDropdown}
              </Select>
            </Form.Item>
            :
            <Form.Item
              name="value"
              rules={[
                {
                  required: true,
                  message: 'This field is required!',
                },
              ]}
            >
              <TextArea
                rows={4}
                name="value"
                value={envData.valueFrom === "Value From" ? envData.valueShown : envData.value}
                onChange={handelInputChange}
                placeholder="Enter value here"
                className="envVar-textarea"
                disabled={(!hasPermission("Can update secret") && update && envData.isSecret)}
              />
            </Form.Item>

          }
        </Box>

        <Box
          title={<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <span style={{ marginRight: "10px" }}>Is this a secret* </span>
              <CustomInfoTooltip title={"Note: any new secret or modification to existing secrets will sync back to the provider."} placement="bottomLeft" />
            </div>
            <Switch
              checked={envData.isSecret}
              onChange={(checked) => {
                setEnvData({ ...envData, syncProj: checked, isSecret: !envData.isSecret, isHide: !envData.isSecret });
                setError({ ...error, status: false });
              }}
              name="isSecret"
              disabled={((currentProject.accountDetails.account_id && data.isSecret && update) || envData.valueFrom === "Value From" || ((!hasPermission("Can create secret") || (!hasPermission("Can update secret") && update)) && currentProject.accountDetails.account_id) || hasChanges)}
              style={{ backgroundColor: envData.isSecret && "#132f44" }}
            /></div>}
          subtitle="Toggle the switch to enable secret variable."
          titleStyle={titleStyle}
          subtitleStyle={subTitleStyle}
        >
          <ContentHolder style={{ marginTop: "12px" }}>
            {error.status && <p className="isoHelperText">
              <span>{error.msg}</span>
            </p>}
          </ContentHolder>
        </Box>

        {fromEnvironment && <Box
          title={<div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <span style={{ marginRight: "10px" }}>Sync back to project* </span>
              <CustomInfoTooltip title={"Note: any new secret or modification to existing secrets will sync back to the provider."} placement="bottomLeft" />
            </div>
            <Switch
              checked={envData.syncProj}
              disabled={envData.isSecret}
              onChange={() => {
                setEnvData({ ...envData, syncProj: !envData.syncProj });
                setError({ ...error, status: false });
              }}
              name="syncProj"
              // disabled={envData.importFrom === "Secret Manager"}
              style={{ backgroundColor: envData.syncProj && "#132f44" }}
            /></div>}
          subtitle="Toggle the switch to sync these variables with the project."
          titleStyle={titleStyle}
          subtitleStyle={subTitleStyle}
          style={{ marginBottom: "130px" }}
        >
          {/* <ContentHolder style={{ marginTop: "12px" }}>
            {error.status && <p className="isoHelperText">
              <span>{error.msg}</span>
            </p>}
          </ContentHolder> */}
        </Box>}

        <div style={{height: "84px"}}></div>


        <div style={{
          position: "absolute",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "84px",
          bottom: "0",
          borderTop: "1px solid #e9e9e9",
          zIndex: "1000",
          background: "white",
        }}>
          {!update ?
            <Button style={{
              padding: '10px 50px',
              backgroundColor: "#132F44",
              width: "164px",
              height: "44px",
              border: '1px solid #D9D9D9',
              borderRadius: '8px',
              color: "white",
              marginRight: "15px"
            }}
              onClick={(e) => {
                handelCreate(e, false);
                // handelSaveEnvVar();
              }}
            >Create</Button>
            :
            <Button style={{
              padding: '10px 50px',
              backgroundColor: "#132F44",
              width: "164px",
              height: "44px",
              border: '1px solid #D9D9D9',
              borderRadius: '8px',
              color: "white",
            }}
              onClick={handelUpdate}
            >Update</Button>
          }

          {!update && <Button style={{
            padding: '10px 14px',
            backgroundColor: "transparent",
            width: "188px",
            height: "44px",
            border: '1px solid #132F44',
            borderRadius: '8px',
            color: "#132F44"
          }}
            onClick={(e) => {
              handelCreate(e, true);
            }}
          >Create & Add another</Button>}
        </div>
      </Form>
    </ProdContentHold >
  );
};