import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { 
  BrowserRouter, 
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes, } from "react-router-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import packageJson from '../package.json';
import withClearCache from './ClearCache';
import * as Sentry from "@sentry/react";
import { HttpClient, CaptureConsole } from "@sentry/integrations";
global.appVersion = packageJson.version;

const ClearCacheApp = withClearCache(App);
const sentryEnabled = process.env.REACT_APP_SENTRY_ENABLED || false;
const sentryDebugMode = process.env.REACT_APP_SENTRY_DEBUG_MODE == "true" || false;
const sentryDsn = process.env.REACT_APP_SENTRY_DSN || "";

if(sentryEnabled == "true" && sentryDsn !== ""){
  console.log("Sentry is enabled");
  Sentry.init({
    debug: sentryDebugMode,
    environment: process.env.REACT_APP_ENVIRONMENT || "local",
    dsn: sentryDsn,
    integrations: [
      new CaptureConsole({levels:['info', 'warn', 'error', 'debug', 'assert']}),
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [process.env.REACT_APP_API_URI, process.env.REACT_APP_GOOGLE_REDIRECT_URI],  // backend + frontend
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(), 
      new HttpClient({failedRequestStatusCodes:[[400,999]]})
    ],
    sendDefaultPii:true,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
else{
  console.log("Sentry is disabled");
}

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <BrowserRouter>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      {/* {console.log('rendring in index.js...')} */}
      {/* <App /> */}
      <ClearCacheApp />
    </GoogleOAuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


// cursor: !hasPermission("Can only create, update and delete marketplace repositories") ? "not-allowed" : "pointer"