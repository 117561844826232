import React, { useState } from 'react';
import { Row, Col } from 'antd';
import Switch from '@iso/components/uielements/switch';
import Select, { SelectOption } from '@iso/components/uielements/select';
import Form from '@iso/components/uielements/form';
import PageHeader from '@iso/components/utility/pageHeader';
import Box from '@iso/components/utility/box';
import LayoutWrapper from '@iso/components/utility/layoutWrapper';
import ContentHolder from '@iso/components/utility/contentHolder';
import basicStyle from '@iso/assets/styles/constants';
import { switchOptions, selectOptions, defaultValues } from '../../AdvancedUI/CodeMirror/config';
import CodeMirror from '@uiw/react-codemirror';
import CreateTaskHeader from '../../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import { useContext } from 'react';
import { AppContext } from '../../../context/ContextProvider';
import { javascript } from '@codemirror/lang-javascript';
import { androidstudio } from '@uiw/codemirror-theme-androidstudio';
// import 'codemirror/theme/monokai.css';
// import { javascript } from '@codemirror/legacy-modes/mode/javascript';
// import CodeMirror, { CodeMirrorToolbar } from '../../AdvancedUI/CodeMirror/CodeMirror.styles';
// import CodeMirror, { CodeMirrorToolbar } from './CodeMirror.styles';

const FormItem = Form.Item;
const Option = SelectOption;

let code = `%TAG ! tag:clarkevans.com,2002:
--- !shape
  # Use the ! handle for presenting
  # tag:clarkevans.com,2002:circle
- !circle
  center: &ORIGIN {x: 73, y: 129}
  radius: 7
- !line
  start: *ORIGIN
  finish: { x: 89, y: 102 }
- !label
  start: *ORIGIN
  color: 0xFFEEBB
  text: Pretty vector drawing.`;

export default function({
  data,
  setData,
  values,
  submitCount,
  onCancel,
  onDelete,
  onEditCancel,
  tabSelected,
}) {
  // const { rowStyle, colStyle, gutter } = basicStyle;
  const {openRightDrawer, setOpenRightDrawer, allClusters, setAllClusters} = useContext(AppContext);

  const [newYAMLCode, setNewYAMLCode] = useState(data ? data : code);

  return (
    <LayoutWrapper>
      <CreateTaskHeader
        buttonContent="Cluster"
        data={data}
        // values={{editing: true}}
        onCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              myClusterData: data,
              tabSelected: tabSelected,
            },
          });
        }}
        onDelete={onDelete}
        onEditCancel={() => {
          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              myClusterData: data,
              tabSelected: tabSelected,
            },
          });
        }}
        openBasicView={() => {
          setOpenRightDrawer({
            drawerType: tabSelected,
            drawerProps: {
                data: data,
                setData: setData,
                tabSelected: tabSelected,
            },
          });
        }}
      />
        <Box>
          <ContentHolder>
            {/* <CodeMirrorToolbar className="isoOptionWrapper">
              {toggleOptions()}
              {selctOptions()}
            </CodeMirrorToolbar> */}
            {/* <CodeMirror
              value={state.basic}
              onChange={value => updateCode('basic', value)}
              options={state.basicOptions}
            /> */}
            <CodeMirror
              value={newYAMLCode}
              options={{
                theme: 'androidstudio',
                keyMap: 'sublime',
                mode: 'jsx',
              }}
              onChange={(value)=>{
                console.log('value :', value);
                setNewYAMLCode(value);
              }}
            />
          </ContentHolder>
        </Box>
    </LayoutWrapper>
  );
}
