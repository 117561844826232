import styled from 'styled-components';

export const Wrapper = styled.div`
  font-size: 19px;
  font-family: 'Poppins';
  font-weight: 500;
  text-align: center;
  min-height: calc(100vh - 255px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 1280px) {
    // padding-top: 76px !important;
  }
  
  @media screen and (max-width: 799px) {
    // height: calc(100vh - 70px);
  }
`;

export const Title = styled.h2`
  color: #132F44;
font-family: Poppins;
font-size: 25px;
font-style: normal;
font-weight: 600;
line-height: normal;
`;
export const Text = styled.p`
color: #596268;
font-family: "Nunito Sans";
font-size: 18px;
font-style: normal;
font-weight: 300;
line-height: normal;
`;
export const Icon = styled.img`
width: 162px;
height: 85px;
flex-shrink: 0;
opacity: 0.4;
`;
