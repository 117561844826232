import { Tooltip } from "antd";
import { InfoCircleFilled } from '@ant-design/icons';

export default function CustomInfoTooltip({ icon, title, placement, style }) {
    return (
        <div style={style}>
        <Tooltip title={title} placement={placement}>
            {icon ? <img src={icon} /> : <InfoCircleFilled style={{ color: "#8896A1" }}/>}
        </Tooltip>
        </div>
    );
}