import styled from 'styled-components';
import { palette } from 'styled-theme';
import Buttons from '@iso/components/uielements/button';
import Table from '../Tables/AntTables/AntTables.styles';
import {
  transition,
  borderRadius,
  boxShadow,
} from '@iso/lib/helpers/style_utils';

const TableWrapper = styled(Table)`
  .ant-table-bordered .ant-table-thead > tr > th {
    background-color: #132f44 !important;
    color: white;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
  }

  .ant-table-body {
    height: auto;
  }

.ant-table-container {
  height: fit-content !important;
  position: relative;
}

  .even-rows {
    background-color: #fbfbfb;
  }

  .ant-table-bordered .ant-table-tbody > tr > td {
    color: #292929;
    font-family: Nunito Sans;
    font-size: 12px;
    font-weight: 500;
  }
  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    white-space: normal;
    &.noWrapCell {
      white-space: nowrap;
    }

    // @media only screen and (max-width: 920px) {
    //   white-space: nowrap;
    // }
  }

  .customHeaderCss {
    .ant-table-bordered .ant-table-thead > tr > th{
        background-color: green;
    }
  }

  .myTableWrapper {
    .ant-table-bordered .ant-table-thead > tr > th{
      background-color: transparent;
    }
  }  

  .isoImgWrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${palette('grayscale', 9)};
    ${borderRadius('50%')};

    img {
      height: 100%;
      object-fit: cover;
    }

    .userActivity {
      width: 10px;
      height: 10px;
      display: block;
      background-color: ${palette('color', 3)};
      position: absolute;
      bottom: 0;
      right: 3px;
      border: 1px solid #ffffff;
      ${borderRadius('50%')};
    }
  }

  &.ant-table-wrapper.isoBlueTable {
    .ant-table-bordered .ant-table-thead > tr > th {
      background-color: #132f44 !important;
      color: white;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
    }

    .ant-table-container table>thead>tr:first-child >*:last-child{
      border-bottom: 1px solid #f1f3f6 ;
    }

    .ant-table-bordered .ant-table-tbody > tr > td {
      color: #292929;
      font-family: Nunito Sans;
      font-size: 12px;
      font-weight: 500;
    }
    
    // .ant-table-bordered .ant-table-tbody > tr > td .ant-empty.ant-empty-normal {
    //   margin: 0;
    // }
    
    // .ant-table-bordered .ant-table-tbody > tr > td .ant-empty.ant-empty-normal .ant-empty-image {
    //   width: 20px;
    //   height: 20px;
    // }

    .ant-table-bordered .ant-table-thead > tr > th,
    .ant-table-bordered .ant-table-tbody > tr > td {
      white-space: normal;
      &.noWrapCell {
        white-space: nowrap;
      }

      // @media only screen and (max-width: 920px) {
      //   white-space: nowrap;
      // }
    }

    .even-rows {
      background-color: #fbfbfb;
    }

    .customHeaderCss {
      .ant-table-bordered .ant-table-thead > tr > th{
          background-color: green;
      }
    }

    .myTableWrapper {
      .ant-table-bordered .ant-table-thead > tr > th{
        background-color: transparent;
      }
    }  

    .isoImgWrapper {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: ${palette('grayscale', 9)};
      ${borderRadius('50%')};

      img {
        height: 100%;
        object-fit: cover;
      }

      .userActivity {
        width: 10px;
        height: 10px;
        display: block;
        background-color: ${palette('color', 3)};
        position: absolute;
        bottom: 0;
        right: 3px;
        border: 1px solid #ffffff;
        ${borderRadius('50%')};
      }
    }

    // &.isoBlueTableWrapper {
    //   .ant-table-container table > thead > tr:first-child > :first-child {
    //     background: #132f44;
    // }
    }
  }

  &.ant-table-wrapper.isoBlueTable.nodegroups-subTable{
    .ant-table-bordered .ant-table-tbody > tr > td .ant-empty.ant-empty-normal {
      margin: 0;
    }

    .ant-table-bordered .ant-table-tbody > tr > td .ant-empty.ant-empty-normal .ant-empty-image {
      height: 30px;
    }
  }
`;

const StatusTag = styled.span`
  padding: 0 5px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background-color: ${palette('primary', 0)};
  font-size: 12px;
  color: #ffffff;
  text-transform: capitalize;

  &.draft {
    background-color: ${palette('warning', 0)};
  }

  &.publish {
    background-color: ${palette('success', 0)};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;
`;

const ButtonHolders = styled.div``;

const ComponentTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
  color: ${palette('text', 0)};
  margin: 5px 0;
`;

const ActionBtn = styled(Buttons)`
  && {
    padding: 0 12px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }

    i {
      font-size: 17px;
      color: ${palette('text', 1)};
    }

    &:hover {
      i {
        color: inherit;
      }
    }
  }
`;

const Fieldset = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  font-size: 13px;
  color: ${palette('text', 1)};
  line-height: 1.5;
  font-weight: 500;
  padding: 0;
  margin: 0 0 8px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-content: center;

  a {
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }

    i {
      font-size: 18px;
      color: ${palette('primary', 0)};

      &:hover {
        color: ${palette('primary', 4)};
      }
    }

    &.deleteBtn {
      i {
        color: ${palette('error', 0)};

        &:hover {
          color: ${palette('error', 2)};
        }
      }
    }
  }
`;

const Form = styled.div``;

export {
  ActionBtn,
  Fieldset,
  Label,
  Form,
  TitleWrapper,
  ButtonHolders,
  ActionWrapper,
  ComponentTitle,
  TableWrapper,
  StatusTag,
};
