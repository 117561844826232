import React, { useEffect, useState } from 'react';
// import clone from 'clone';
import { Row, Col, Tooltip, Popover, Avatar } from 'antd';
import LayoutWrapper from '../../components/utility/layoutWrapper';
import basicStyle from '../../assets/styles/constants';
import IsoWidgetsWrapper from '@iso/containers/Widgets/WidgetsWrapper';
import IsoWidgetBox from '@iso/containers/Widgets/WidgetBox';
import Button from '@iso/components/uielements/atmoslyButton';
import Input, {
    InputSearch,
    InputGroup,
    Textarea,
} from '@iso/components/uielements/input';
import Alert from '@iso/components/Feedback/Alert';
import {
    TableViews,
    tableinfos,
    dataList,
} from '../Tables/AntTables/AntTables';
import IntlMessages from '../../components/utility/intlMessages';
import { AppContext } from '../../context/ContextProvider';
import { useContext } from 'react';
import Box from '../../components/utility/box';
import searchIcon from '@iso/assets/images/icon/search-icon.svg';
import eyeIcon from '@iso/assets/images/icon/eye-icon.svg';
import eyeCloseIcon from '@iso/assets/images/icon/eye-close-icon.svg';
import deleteIcon from '@iso/assets/images/icon/delete-icon.svg';
import addItemIcon from '@iso/assets/images/icon/add-item-icon.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SpanHeading, TableWrapper } from '../../containers/Projects/EnvironmentVariables.style';
import { formatErrors, hasPermission } from '../../context/helper';
import { BreadcrumbContext } from '../../context/breadcrumbContext';
import { DeploymentContext } from '../../context/deploymentContext';
import closeIcon from '@iso/assets/images/icon/close-search-icon.svg';
import errorIcon from '@iso/assets/images/errorIcon.svg';
import CustomInfoTooltip from '../Dashboard/CustomComponents/CustomInfoTooltip';
import { MyRadioButton as Radio } from '../../containers/Projects/EnvironmentVariables.style';
import { MoreActionWrapper } from '../ScrumBoard/Board/BoardList/BoardListCard/BoardListCard.style';
import editIcon from '@iso/assets/images/editImg.svg';
import exportVars from '../../assets/images/exportVars.svg';
import optionIcon from '@iso/assets/images/options.svg';
import api from '../../api';
import { AlertContext } from '../../context/alertContext';

const rowStyleNew = {
    width: '100%',
    display: 'flex',
    flexFlow: 'row wrap',
    fontSize: '20px',
    fontWeight: '500',
    marginBottom: '20px'
}

const variablesStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: "center",
    fontSize: '20px',
    fontWeight: '500',
    padding: '20px'
}

// const costWrapperStyle = {
//     width: '100%',
//     display: 'flex',
//     flexFlow: 'row wrap',
//     fontSize: '40px'
// }

const styles = {
    wisgetPageStyle: {
        display: 'flex',
        // flexFlow: 'row wrap',
        // alignItems: 'flex-start',
        overflow: 'hidden',
        width: "100%"
    },
};

// let SERVICES = [
//     "Service 1",
//     "Service 2",
//     "Service 3",
//     "Service 4"
// ]

const data = [
    {
        id: 0,
        key: 0,
        keys: "This is my first name",
        values: "###",
        action: ""
    },
    {
        id: 1,
        key: 1,
        keys: "Last Name",
        values: "Kumar",
        action: ""
    },
    {
        id: 2,
        key: 2,
        keys: "key 1",
        values: "hhsdbc",
        action: ""
    },
    // {
    //     id: 3,
    //     key: 3,
    //     keys: "First Name",
    //     values: "Gautam",
    //     action: ""
    // },
    // {
    //     id: 4,
    //     key: 4,
    //     keys: "Last Name",
    //     values: "Kumar",
    //     action: ""
    // },
    // {
    //     id: 5,
    //     key: 5,
    //     keys: "key 1",
    //     values: "hhsdbc",
    //     action: ""
    // },
    // {
    //     id: 6,
    //     key: 6,
    //     keys: "Last Name",
    //     values: "Kumar",
    //     action: ""
    // },
    // {
    //     id: 7,
    //     key: 7,
    //     keys: "key 1",
    //     values: "hhsdbc",
    //     action: ""
    // },
    // {
    //     id: 8,
    //     key: 8,
    //     keys: "First Name",
    //     values: "Gautam",
    //     action: ""
    // },
    // {
    //     id: 9,
    //     key: 9,
    //     keys: "Last Name",
    //     values: "Kumar",
    //     action: ""
    // },
    // {
    //     id: 10,
    //     key: 10,
    //     keys: "key 1",
    //     values: "hhsdbc",
    //     action: ""
    // },
    // {
    //     id: 11,
    //     key: 11,
    //     keys: "Last Name",
    //     values: "Kumar",
    //     action: ""
    // },
    // {
    //     id: 12,
    //     key: 12,
    //     keys: "key 1",
    //     values: "hhsdbc",
    //     action: ""
    // },
    // {
    //     id: 13,
    //     key: 13,
    //     keys: "key 1",
    //     values: "hhsdbc",
    //     action: ""
    // },
    // {
    //     id: 14,
    //     key: 14,
    //     keys: "key 1",
    //     values: "hhsdbc",
    //     action: ""
    // },
    {
        id: 15,
        key: 15,
        keys: "My second last name",
        values: "Gautam",
        action: ""
    },
    {
        id: 16,
        key: 16,
        keys: "this is my last name",
        values: "$$$",
        action: ""
    },
];

const valuesStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
}

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontSize: "16px",
    fontWeight: "500"
};

let innerSubtitleStyle = {
    fontSize: "14px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontType: "regular",
    fontWeight: "400"
}

export default function EnvironmentVariablesDep({ services, serviceSelected, appData, setAppData, setAppFromEnv, isEnabled, allOptions, executeEnvs, setExecuteEnvs }) {

    const { rowStyle, colStyle } = basicStyle;

    let { convertToFrontend, convertToBackend, allProjects, setAllProjects, initialProject, setOpenModel, setOpenRightDrawer } = useContext(AppContext);
    let { pageHeading, setPageHeading, pageHeadingsObj, setPageHeadingsObj, breadData, setBreadData, breadcrumbObj, setBreadcrumbObj } = useContext(BreadcrumbContext);
    let { selectedEnv, addDep, addUpdatedData, updatedData, updateDep, selectedProject, cloneSelectedProject, updateSelectedProject, applicationData, setApplicationData, cloneDep, initialApplicationData, cloneApplicationData, setCloneApplicationData, currentProjectDep, setCurrentProjectDep, mapIdsWithAppName, setMapIdsWithAppName, sampleEnvData, setSampleEnvData } = useContext(DeploymentContext);
    const { set_alertOptions } = useContext(AlertContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [dataSource, setDataSource] = useState([]);
    const [initialDataSource, setInitialDataSource] = useState([]);
    const [selectedAppId, setSelectedAppId] = useState(0);
    const [appList, setAppList] = useState([]);
    const [valueFromDropdown, setValueFromDropdown] = useState([]);
    const [serviceCategoriesData, setServiceCategoriesData] = useState({});
    const [allEnvironments, setAllEnvironments] = useState();
    const [projectDet, setProjectDet] = useState(addDep ? { ...addUpdatedData.selectedProject } : updateDep ? { ...updatedData.selectedProject } : (!cloneDep ? { ...selectedProject } : { ...cloneSelectedProject }));
    const [searchText, setSearchText] = useState("");
    const [hasChanges, setHasChanges] = useState(false); //not being used anywhere
    const [mutiSelectedKeys, setMutiSelectedKeys] = useState([]);
    const [mutiSelectedRows, setMutiSelectedRows] = useState([]);
    const [operation, setOperation] = useState("");
    const [injectValue, setInjectValue] = useState("");
    const [openPopup, setOpenPopup] = useState(false);

    console.log("First app data beg: ", appData.environments, projectDet);
    //const [appData, setAppData] = useState({ ...initialApplicationData });//!cloneDep ? (applicationData[serviceSelected.name] ? { ...applicationData[serviceSelected.name] } : { ...initialApplicationData }) :
    //     (cloneApplicationData[serviceSelected.name] ? { ...cloneApplicationData[serviceSelected.name] } : { ...initialApplicationData }));
    //const [canFetch, setCanFetch] = useState(false);

    // useEffect(() => {
    //     if (!cloneDep) {
    //         if (applicationData[serviceSelected.name]) {
    //             console.log("Executes the update");
    //             setAppData({ ...applicationData[serviceSelected.name] });
    //         }
    //         else {
    //             setAppData({ ...initialApplicationData });
    //         }
    //         setCanFetch(true);
    //     }
    //     else {
    //         if (cloneApplicationData[serviceSelected.name]) {
    //             setAppData({ ...cloneApplicationData[serviceSelected.name] });
    //         }
    //         else {
    //             setAppData({ ...initialApplicationData });
    //         }
    //     }
    // }, [serviceSelected]);

    useEffect(() => {
        let sampleEnvs = [];
        //console.log("Appdata envs: ", JSON.parse(JSON.stringify(appData.environments)));
        // console.log("mapIdsWithAppName : ", JSON.parse(JSON.stringify(appData.environments)));
        if (appData.environments.length > 0) {
            appData.environments.map((val, idx) => {
                let dummyData = {
                    id: idx,
                    key: idx,
                    keys: val.key,
                    varName: val.key,
                    value: val.value,
                    valueFrom: (val.value_from === "" || !val.value_depend_on) ? "Value" : "Value From",
                    isSecret: val.is_secret,
                    isHide: val.is_secret,
                    syncProj: val.is_synced,
                    dependOn: val.value_depend_on, //for environment env variables
                    valueShown: val.valueShown ? val.valueShown : "",
                    error: val.error,
                };
                sampleEnvs.push(dummyData);
            });
            console.log("Sample envs: ", sampleEnvs);
            setDataSource(sampleEnvs);
        }
    }, [appData]);

    useEffect(() => {
        //if (!cloneDep && !updateDep) {
        //console.log("service changed", appData, canFetchEnvs);
        //if (canFetch) {
        if (!JSON.parse(localStorage.getItem('stopFetch'))) {
            console.log("Executes");
            console.log("Project dets: ", projectDet.id);
            let myData = {};
            let tasks = [];
            let myColumns = {
                app: {
                    id: 'app',
                    task_orders: [],
                    title: 'APPLICATIONS',
                    editing: false,
                },
                dataSource: {
                    id: 'dataSource',
                    task_orders: [],
                    title: 'DATA SOURCES',
                    editing: false,
                },
                queues: {
                    id: 'queues',
                    task_orders: [],
                    title: 'QUEUES',
                    editing: false,
                },
                thirdParties: {
                    id: 'thirdParties',
                    task_orders: [],
                    title: 'THIRD PARTIES',
                    editing: false,
                }
            };

            myData.id = projectDet.id;
            myData.name = projectDet.name;
            myData.title = projectDet.name;
            myData.starred = projectDet.is_starred;
            myData.updatedAt = projectDet.updated_at;
            myData.accountDetails = {
                account_id: projectDet.secret_account_integration?.account_id,
                region: projectDet.secret_region
            };
            console.log("projectDet: ", projectDet);
            projectDet.services.map((service, idx) => {
                if (service.service_category === 8) {
                    service.configuration && delete service.configuration.serviceId;

                    let myServiceDet = updateDep ? updatedData.selectedEnv.services.find((val) => {
                        return val.service_id === service.service_id;
                    }) : cloneDep && selectedEnv.services.find((val) => {
                        return val.service_id === service.service_id;
                    });

                    console.log("===========Inside myserviceDet=========", myServiceDet.config);
                    let myObj = {
                        ...service.configuration,
                        id: idx,
                        serviceId: !cloneDep && !updateDep && !addDep ? service.id : service.service_id,
                        serviceCategory: service.service_category,
                        name: service.name,
                        description: service.description,
                        columnId: convertToFrontend[!cloneDep && !updateDep && !addDep ? service.type : service.service_type],
                        pass_env_vars_at_build_time: (updateDep || cloneDep) ? (myServiceDet.config.pass_env_vars_at_build_time === "true" ? true : false) : (service.configuration.pass_env_vars_at_build_time === "" || !service.configuration.pass_env_vars_at_build_time) ? false : true,
                    };

                    console.log("Myobj: ", myObj);

                    if (!cloneDep && !updateDep && !addDep ? convertToFrontend[service.type] === "app" : convertToFrontend[service.service_type] === "app") {
                        myObj.envs = [];
                    }

                    tasks.push(myObj);
                    myColumns[convertToFrontend[!cloneDep && !updateDep && !addDep ? service.type : service.service_type]].task_orders.push(idx);
                }
            });
            console.log("===tasks: ", tasks);
            myData.tasks = tasks;
            myData.columns = myColumns;
            getDropDownData(projectDet.id);
            console.log("Envs fetched", myData);
            getEnvVarData({ ...myData });
        }
        else {
            getDropDownData(projectDet.id);
        }
        //}
        // else {
        //     getDropDownData(projectDet.id);
        // }
    }, []);


    // When currentProject gets updated
    useEffect(() => {
        console.log("appId found", selectedAppId);
        let allApps = [];
        let myId;
        //  allApps
        console.log("currentProject in EnvironmentVariables:", currentProjectDep, appData);
        console.log("selectedAppId in EnvironmentVariables:", selectedAppId, serviceSelected);

        currentProjectDep.tasks && currentProjectDep.tasks.map((temp, ind) => {
            if (temp) {
                if (temp.columnId === "app") {
                    allApps.push(temp);
                }
                console.log("Temp.serviceId: ", temp, temp.serviceId, serviceSelected.id);
                if ((temp.serviceId === serviceSelected.id && !cloneDep && !updateDep) || (temp.serviceId === serviceSelected.service_id && (cloneDep || updateDep || addDep))) {
                    console.log("Setting selected app id");
                    setSelectedAppId(temp.id);
                    myId = temp.id;
                }
                //if(temp.)
            }
        });

        // if ((myId !== 0) && !myId && allApps.length > 0) {
        //     myId = allApps[0].id;
        // }

        console.log('allApps :', allApps, myId);
        setAppList([...allApps]);
        //setSelectedAppId(serviceSelected.id);
        if (allApps.length > 0) {
            console.log("Updating the state");
            updateState(myId, allApps);
        }
        setSearchText("");
    }, [currentProjectDep, serviceSelected, executeEnvs]);


    const getDropDownData = (id) => {
        // Get ValueFrom DropDown Data
        fetch(`${process.env.REACT_APP_API_URI}/api/project_env/${id}/service-categories-parameters/`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("isAuth")}`,
            }
        })
            .then(async (res) => {
                let data = await res.json();
                setServiceCategoriesData(data);
                console.log("service-categories-parameters data :", data, services);
                if (data.status) {
                    let myData = [];
                    let arr = data.data;
                    arr = arr.sort();
                    arr.map((val) => {
                        myData.push(val);
                        // if(!cloneDep && !updateDep && !addDep){
                        //     if (services.some(x => x.id === val.id)) {
                        //         myData.push(val);
                        //     }
                        // }
                        // else {
                        //     if (services.some(x => x.service_id === val.id)) {
                        //         myData.push(val);
                        //     }
                        // }
                    })
                    console.log("MyData: ", myData);
                    setValueFromDropdown([...myData]);
                    // setCurrentProjectDep({ ...currentProjectDep, "valueFromDropdown": [...data.data] });
                    // localStorage.setItem("ProjectDep", JSON.stringify({ ...currentProjectDep, "valueFromDropdown": [...data.data] }));
                    //getProjectData(id);
                }
                else if (!data.status && data.msg.code === "token_not_valid") {
                    navigate("/signin");
                }
                else {
                    let err = formatErrors(data.msg);
                }
            })
            .catch((err) => {
                console.log("err :", err);
            });
    }

    const getEnvVarData = (myData) => {
        //   get Env Var data

        if (!cloneDep && !updateDep) {
            fetch(`${process.env.REACT_APP_API_URI}/api/project_env/projects/${myData.id}/services-envs/`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("isAuth")}`
                }
            })
                .then(async (res) => {
                    let data = await res.json();
                    let myAppIdx = [];
                    let shown = {};
                    console.log("env-var data :", data);
                    console.log("myData in getEnvVarDataaa:", myData);
                    if (data.status) {
                        myData.tasks.map((temp, idx) => {
                            console.log("temp in getEnvVarData :", temp);
                            // maintain object for valueShown
                            shown[temp.serviceId] = temp.name;

                            if (temp.columnId === "app") {
                                myAppIdx.push(temp.id);
                                data.data.map((env) => {
                                    //fetching for a particular app
                                    if (env.name === temp.name) {
                                        console.log("Fetching envs for: ", temp.name);
                                        Object.keys(env.environment_variables).map((key, idx) => {
                                            temp.envs.push({
                                                varName: key,
                                                valueFrom: env.environment_variables[key].type,
                                                value: env.environment_variables[key].value,
                                                isSecret: env.environment_variables[key].is_secret,
                                                isHide: env.environment_variables[key].is_secret,
                                                syncProj: env.environment_variables[key].is_secret ? true : false,
                                                dependOn: env.environment_variables[key].depend_on,
                                                valueShown: "",
                                                error: false,
                                            });
                                        });
                                        temp.is_secret_manager = env.is_secret_manager;
                                        temp.secret_name = env.secret_details.secret_name;
                                    }
                                })
                            }
                        });
                        console.log("myData.tasks :", myAppIdx);
                        console.log("shown :", shown);

                        //myData["mapIdsWithAppName"] = shown;
                        //setMapIdsWithAppName({ ...shown });
                        setCurrentProjectDep({ ...myData, mapIdsWithAppName: mapIdsWithAppName, tasks: [...myData.tasks] });
                        localStorage.setItem("ProjectDep", JSON.stringify({ ...myData, mapIdsWithAppName: mapIdsWithAppName, tasks: [...myData.tasks] }));
                        //localStorage.setItem('canFetchEnvs', JSON.stringify(false));
                        localStorage.setItem('stopFetch', JSON.stringify(true));
                        //setCanFetchEnvs(false);
                        if (myData.tasks.length) {
                            myData.tasks.map((val, idx) => {
                                if (val.serviceId === serviceSelected.id) {
                                    console.log("Given value: ", val);
                                    openApp(val.id);
                                    return;
                                }
                            });
                        }
                        //openApp(myAppIdx[0]);
                    }
                    else if (!data.status && data.msg.code === "token_not_valid") {
                        navigate("/signin");
                    }
                    else {
                        let err = formatErrors(data.msg);
                    }
                })
                .catch((err) => {
                    console.log("err :", err);
                });
        }
        else {
            let myAppIdx = [];
            let shown = {};
            //let data = updateDep ? updatedData.selectedEnv.services.find((val) => val.service_id === serviceSelected.service_id) : selectedEnv.services.find((val) => val.service_id === serviceSelected.service_id);

            let data = updateDep ? updatedData.selectedEnv.services : selectedEnv.services;
            myData.tasks.map((temp, idx) => {
                console.log("temp in getEnvVarData :", temp);
                // maintain object for valueShown
                shown[temp.serviceId] = temp.name;

                if (temp.columnId === "app") {
                    console.log("My app data: ", temp, appData.environments, data);
                    myAppIdx.push(temp.id);
                    let myData = data.find((val) => val.service_name === temp.name);
                    myData.config.env_variables.map((env) => {
                        //fetching for a particular app
                        //if (temp.name === data.service_name) {
                        console.log("Fetching envs for: ", temp.name);
                        //Object.keys(env.environment_variables).map((key, idx) => {
                        temp.envs.push({
                            varName: env.key,
                            valueFrom: env.value_depend_on ? "Value From" : "Value", //For clone/update payload in env is different. So, we are checking valueFrom on the basis of value_depend_on
                            value: env.value,
                            isSecret: env.is_secret,
                            isHide: env.is_secret,
                            syncProj: env.is_synced,
                            dependOn: env.value_depend_on,
                            valueShown: "",
                        });
                        //});
                        //}
                    })
                }
            });

            console.log("myData.tasks :", myAppIdx, myData);
            console.log("shown :", shown);

            myData["mapIdsWithAppName"] = { ...mapIdsWithAppName, ...shown };
            // myData["valueFromDropdown"] = valueFromDropdown;
            setMapIdsWithAppName({ ...mapIdsWithAppName, ...shown });
            setCurrentProjectDep({ ...myData, tasks: [...myData.tasks] });
            localStorage.setItem("ProjectDep", JSON.stringify({ ...myData, tasks: [...myData.tasks] }));
            localStorage.setItem('stopFetch', JSON.stringify(true));
            //setCanFetchEnvs(false);
            if (myData.tasks.length) {
                myData.tasks.map((val, idx) => {
                    if (val.serviceId === serviceSelected.service_id) {
                        console.log("Given value: ", val);
                        openApp(val.id, true);
                        return;
                    }
                });
            }
        }
    }

    // useEffect(() => {
    //     // if(valueFromDropdown){
    //     //     setCurrentProjectDep({...currentProjectDep, valueFromDropdown: valueFromDropdown});
    //     // }
    //     console.log("valueFromDropdown :", valueFromDropdown);
    // }, [valueFromDropdown]);

    const updateState = (tempId, allApps, fromGetApi) => {
        let sources = [];
        let myEnvs = [];

        // dataSources
        console.log("All data sources: ", allApps, tempId);
        console.log("currentProjectDep: ", currentProjectDep);
        allApps.map((ele) => {
            console.log("Hit update...1", ele);
            if (ele.id === tempId) {
                console.log("Hit update...2", ele);
                ele.envs.map((temp, idx) => {
                    console.log("Hit update...3", ele, temp);

                    let dummyData = {
                        id: idx,
                        key: idx,
                        keys: temp.varName,
                        varName: temp.varName,
                        value: temp.value,
                        valueFrom: temp.valueFrom,
                        isSecret: temp.isSecret,
                        isHide: temp.is_secret,
                        dependOn: temp.dependOn,
                        valueShown: temp.dependOn ? `${temp.value}_${currentProjectDep.mapIdsWithAppName[temp.dependOn]}` : "",
                        syncProj: temp.syncProj,
                        error: temp.error,
                    }
                    sources.push({ ...dummyData });
                    console.log("dummyData: ", dummyData);
                    console.log("My key: ", dummyData.varName);
                    //for checking undeployed services

                    myEnvs.push({
                        "key": dummyData.varName,
                        "value": dummyData.value,
                        "value_from": dummyData.valueFrom === "Value" ? "" : dummyData.value ? dummyData.value.split('_')[0].toLowerCase() : "",//dummyData.value,
                        "is_synced": dummyData.syncProj,
                        "is_secret": dummyData.isSecret,
                        "value_depend_on": dummyData.dependOn,
                        "valueShown": dummyData.valueShown,
                        "is_synced": dummyData.syncProj,
                        "error": dummyData.error,
                        //"isServiceUndeployed": checkEnv || checkClone || checkUpdate || checkAdd
                    });
                    // if (!dummyData.isSecret) {
                    //     appData.envPlainVars.push(`${dummyData.varName}: ${dummyData.value}`);
                    //     setAppData({ ...appData });
                    // }
                    // else {
                    //     appData.envSecretVars.push(`${dummyData.varName}: ${dummyData.value}`);
                    //     setAppData({ ...appData });
                    // }
                });
                console.log("Hit update...4", ele);
            }
        });
        console.log('sources :', sources);
        console.log("Appdata with envs: ", appData, myEnvs);

        if (JSON.parse(localStorage.getItem("stopFetch")) === true) {
            setAppFromEnv(myEnvs);
            if (fromGetApi) {
                setSampleEnvData({
                    ...sampleEnvData,
                    environments: myEnvs
                });
                localStorage.setItem('sampleEnvData', JSON.stringify({
                    ...sampleEnvData,
                    environments: myEnvs
                }));
            }
            setDataSource([...sources]);
            setInitialDataSource([...sources]);
        }
        // if (!cloneDep && !updateDep && !addDep) {
        //     setAppFromEnv(myEnvs);
        //     setDataSource([...sources]);
        //     setInitialDataSource([...sources]);
        // }
        // else {
        //     if (operation !== 'delete') {
        //         if (myEnvs.length > 0 && sources.length > 0) {
        //             console.log("Sources: ", sources, myEnvs);
        //             setAppFromEnv(myEnvs);
        //             setDataSource([...sources]);
        //             setInitialDataSource([...sources]);
        //         }
        //     }
        //     else {
        //         console.log("Sources: ", sources, myEnvs);
        //         setAppFromEnv(myEnvs);
        //         setDataSource([...sources]);
        //         setInitialDataSource([...sources]);
        //     }
        //     //if (JSON.parse(localStorage.getItem("stopFetch")) === true) {
        //     //     setAppFromEnv(myEnvs);
        //     //     setDataSource([...sources]);
        //     //     setInitialDataSource([...sources]);
        //     // }
        //     // else {
        //     //     if (myEnvs.length > 0 && sources.length > 0) {
        //     //             setAppFromEnv(myEnvs);
        //     //             setDataSource([...sources]);
        //     //             setInitialDataSource([...sources]);
        //     //     }
        //     // }
        // }
    }

    const columns = [
        {
            title: 'Keys',
            dataIndex: 'keys',
            key: 'keys',
            width: '250px',
            height: "48px",
            className: "custom-error-handel-col",
            // onCell: (record) => {
            //     console.log("Cell record", record);
            //     return {
            //         onClick: (e) => {
            //             e.stopPropagation();
            //             handleRecord('update', record);
            //         }
            //     }
            // },
            render: (text, row) => {
                //console.log("Row: ", row);
                return (
                    row.error ?
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "14px 22px", height: "50px", borderLeft: "3px solid #D20A0A" }}>
                            <span style={{ cursor: "pointer", color: "#D20A0A" }}>{text}</span>
                            <img src={errorIcon} style={{}} />
                        </div>
                        :
                        <span style={{ cursor: "pointer", padding: "14px 22px" }}>{text}</span>
                );
            },
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            width: '250px',
            height: "48px",
            ellipsis: true,
            // onCell: (record) => {
            //     console.log("Cell record", record);
            //     return {
            //         onClick: (e) => {
            //             e.stopPropagation();
            //             handleRecord('update', record);
            //         }
            //     }
            // },
            render: (text, row) => {
                console.log("row :", row.valueFrom);
                return (
                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                        <img src={row.isHide ? eyeIcon : eyeCloseIcon} style={{ cursor: "pointer", marginRight: "20px", opacity: (((row.importFrom === "Secret Manager" || currentProjectDep.accountDetails.account_id) && row.isSecret) && !hasPermission("Can view secret") && row.isSecret) ? "0.7" : "1", pointerEvents: ((row.importFrom === "Secret Manager" || currentProjectDep?.tasks.find(val => val.id === selectedAppId)?.is_secret_manager) && !hasPermission("Can view secret") && row.isSecret) && "none" }} onClick={(e) => {
                            handleRecord('toggleEye', row);
                            e.stopPropagation();
                        }} />
                        <Tooltip
                            title={row.isHide ?
                                (row.valueFrom === "Value From" ? ((row.valueShown !== "") ? Array.apply(null, { length: row.valueShown.length }).map((temp, idx) => "*") : "") : (text ? Array.apply(null, { length: text.length }).map((temp, idx) => "*") : ""))
                                :
                                (row.valueFrom === "Value From" ? row.valueShown : text)
                            }
                            placement="top"
                        >
                            <span style={valuesStyle}>{row.isHide ?
                                (row.valueFrom === "Value From" ? Array.apply(null, { length: row.valueShown.length }).map((temp, idx) => "*") : Array.apply(null, { length: text.length }).map((temp, idx) => "*"))
                                :
                                (row.valueFrom === "Value From" ? row.valueShown : text)
                            }</span>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '50px',
            height: "48px",
            render: (text, row) => {
                return (
                    <div style={{ display: "flex", justifyContent: "start", alignItems: "center" }}>
                        <i className="ion-android-create" style={{ display: "flex", justifyContent: "center", fontSize: "20px", color: "#4482FF", marginRight: "12px", cursor: (mutiSelectedRows.length <= 0 && ((hasPermission("Can update secret") && row.isSecret) || !row.isSecret)) ? "pointer" : "not-allowed", opacity: (!hasPermission("Can update secret") && row.isSecret) && "0.7" }} onClick={(e) => {
                            if (mutiSelectedRows.length <= 0 && ((hasPermission("Can update secret") && row.isSecret) || !row.isSecret)) {
                                handleRecord('update', row);
                            }
                            e.stopPropagation();
                        }} />
                        <img src={deleteIcon} style={{ cursor: (mutiSelectedRows.length <= 0 && ((hasPermission("Can delete secret") && row.isSecret) || !row.isSecret)) ? "pointer" : "not-allowed", color: "red", fontSize: "18px", opacity: (!hasPermission("Can delete secret") && row.isSecret) && "0.7" }} onClick={(e) => {
                            if (mutiSelectedRows.length <= 0 && ((hasPermission("Can delete secret") && row.isSecret) || !row.isSecret)) {

                                if (row.isSecret && currentProjectDep.accountDetails.account_id) {
                                    handleRecord('delete', row, true);
                                }
                                else {
                                    handleRecord('delete', row, false);
                                }
                                setOperation('delete');
                            }
                            e.stopPropagation();
                        }} />
                    </div>
                );
            },
        }
    ]

    const handelClick = () => {
        setOpenModel({
            modalType: false,
            modalProps: {
                data: ""
            },
        });
    }

    const openApp = (tempId, fromGetApi) => {
        console.log("tempId :", tempId);
        setSelectedAppId(tempId);
        updateState(tempId, appList, fromGetApi);
    }

    function toggleAlert(type, message, description) {

        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        // opacity=0 after 10sec
        setTimeout(() => {
            set_alertOptions(alertOptions => ({
                status: false,
                type: type,
                message: message,
                description: description,
            }));
        }, 5 * 1000);
    }

    const deleteEnv = (tempData, callSecretApi) => {
        console.log("tempData :", tempData);
        let payload = [];
        let newData = dataSource.filter((temp, idx) => {
            console.log("temp inside datasource :", temp);
            return temp.id !== tempData.id
        });
        let newData2 = appData.environments.filter((temp) => {
            return temp["key"] !== tempData.keys
        });
        console.log("newData :", newData);

        // setCurrentProject
        currentProjectDep.tasks.map((temp, idx) => {
            if (temp.id === selectedAppId) {
                currentProjectDep.tasks[idx].envs = newData;
            }
        });
        setSearchText("");
        //setAppData({ ...appData, environments: newData2 });
        setAppFromEnv(newData2);
        setCurrentProjectDep({ ...currentProjectDep, tasks: [...currentProjectDep.tasks] });
        localStorage.setItem('ProjectDep', JSON.stringify({ ...currentProjectDep, tasks: [...currentProjectDep.tasks] }));
        if (callSecretApi) {
            currentProjectDep.tasks.every((temp, idx) => {
                if (temp.columnId === "app") {
                    // myAppIdx.push(temp.id);
                    console.log("temp in handleCreate :", temp);
                    let myObj = {};
                    if (temp.envs.length === 0 && temp.id === selectedAppId) {
                        myObj = {
                            "id": temp.serviceId,
                            // "pass_at_build_time": temp.injectValue === "Build Time" ? true : false,
                            "is_secret_manager": !callSecretApi ? undefined : currentProjectDep.accountDetails?.account_id ? true : false,
                            "secret_details": !callSecretApi ? undefined : currentProjectDep.accountDetails?.account_id ? {
                                "account_id": currentProjectDep.accountDetails?.account_id,
                                "region": currentProjectDep.accountDetails?.region,
                                "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProjectDep.name + '_' + temp.name),

                            } : undefined,
                            "env_var": {}
                        }
                        payload.push(myObj);
                    }

                    else if (temp.id === selectedAppId) {

                        if ((callSecretApi && temp.envs.filter(x => x.isSecret).length === 0) || (temp.envs.filter(x => !x.isSecret).length === 0)) {
                            myObj = {
                                "id": temp.serviceId,
                                // "pass_at_build_time": temp.injectValue === "Build Time" ? true : false,
                                "is_secret_manager": !callSecretApi ? undefined : currentProjectDep.accountDetails?.account_id ? true : false,
                                "secret_details": !callSecretApi ? undefined : currentProjectDep.accountDetails?.account_id ? {
                                    "account_id": currentProjectDep.accountDetails?.account_id,
                                    "region": currentProjectDep.accountDetails?.region,
                                    "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProjectDep.name + '_' + temp.name),

                                } : undefined,
                                "env_var": {}
                            }
                        }
                        else {
                            temp.envs.map((env) => {
                                console.log("temp: ", temp);
                                if (!callSecretApi) {
                                    if (!env.isSecret) {
                                        myObj = {
                                            "id": temp.serviceId,
                                            // "pass_at_build_time": temp.injectValue === "Build Time" ? true : false,
                                            "is_secret_manager": !callSecretApi ? undefined : currentProjectDep.accountDetails?.account_id ? true : false,
                                            "secret_details": !callSecretApi ? undefined : currentProjectDep.accountDetails?.account_id ? {
                                                "account_id": currentProjectDep.accountDetails?.account_id,
                                                "region": currentProjectDep.accountDetails?.region,
                                                "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProjectDep.name + '_' + temp.name),
    
                                            } : undefined,
                                            "env_var": {
                                                ...myObj.env_var,
                                                [env.varName]: {
                                                    "type": env.valueFrom,
                                                    "value": env.value,
                                                    "is_secret": env.isSecret,
                                                    "depend_on": env.dependOn
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    if (env.isSecret) {
                                        myObj = {
                                            "id": temp.serviceId,
                                            // "pass_at_build_time": temp.injectValue === "Build Time" ? true : false,
                                            "is_secret_manager": !callSecretApi ? undefined : currentProjectDep.accountDetails?.account_id ? true : false,
                                            "secret_details": !callSecretApi ? undefined : currentProjectDep.accountDetails?.account_id ? {
                                                "account_id": currentProjectDep.accountDetails?.account_id,
                                                "region": currentProjectDep.accountDetails?.region,
                                                "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProjectDep.name + '_' + temp.name),
    
                                            } : undefined,
                                            "env_var": {
                                                ...myObj.env_var,
                                                [env.varName]: {
                                                    "type": env.valueFrom,
                                                    "value": env.value,
                                                    "is_secret": env.isSecret,
                                                    "depend_on": env.dependOn
                                                }
                                            }
                                        }
                                    }
                                }
                                console.log("Pushing myObj: ", myObj);
                            });
                        }
                        payload.push(myObj);
                    }
                }
                else {
                    return true;
                }
            });
            console.log("my payload: ", payload);
            api.put(`${process.env.REACT_APP_API_URI}/api/project_env/secrets`, payload.find(val => val.id === currentProjectDep.tasks.find(val => val.id === selectedAppId)?.serviceId))
                .then((res) => {
                    let data = res.data;
                    console.log("repo/list data :", data);
                    if (data.status) {
                        toggleAlert("success", "Success", "Environment variable has been successfully deleted from the project as well as the environment.");
                        // changeService();
                        setHasChanges(false);
                        // setIsValidated(false);
                        // localStorage.removeItem("Project");
                        // navigate("/all-projects");
                    }
                    else {
                        let err = formatErrors(data.msg);
                        toggleAlert("error", "Error", err);
                    }
                    //setSavingVars(false);
                })
                .catch((err) => {
                    console.log("err :", err);
                    toggleAlert("error", "Error", "Something went wrong. Please try again.");
                    //setSavingVars(false);
                });
        }
        handelClick();
    }

    const handleRecord = (action, row, callSecretApi) => {
        console.log("Row inside handleRecord :", row);
        if (action === "delete") {
            deleteEnv(row, callSecretApi);
            // setOpenModel({
            //     modalType: 'DELETE_TASKS',
            //     modalProps: {
            //         data: { ...row, name: row.keys },
            //         title: "Environment",
            //         subTitle: `Please provide the name of the environment to be deleted`,
            //         onDelete: deleteEnv
            //     },
            // });
        }
        if (action === "update") {
            setOpenRightDrawer({
                drawerType: "addEnvironmentVariable",
                drawerProps: {
                    data: { ...row, tempId: selectedAppId },
                    currentProject: currentProjectDep,
                    setCurrentProject: setCurrentProjectDep,
                    setHasChanges: setHasChanges,
                    serviceSelected: serviceSelected,
                    fromEnvironment: true,
                    update: true,
                    valueFromDropdown: valueFromDropdown,
                    columnId: "null",
                    service: appList.filter((temp) => temp.id === selectedAppId)[0],
                },
            });
        }
        else if (action === "toggleEye") {
            console.log("clicked toggleEye :", row);
            dataSource.map((temp, idx) => {
                // if (!temp.isSecret) {
                //     appData.envPlainVars.map((val, idx) => {
                //         if (val.includes(temp.varName)) {
                //             appData.envPlainVars.splice(idx, 1);
                //             appData.envSecretVars.push(val);
                //         }
                //     });
                // }
                // else {
                //     appData.envSecretVars.map((val, idx) => {
                //         if (val.includes(temp.varName)) {
                //             appData.envSecretVars.splice(idx, 1);
                //             appData.envPlainVars.push(val);
                //         }
                //     });
                // }
                if (temp.id === row.id) {
                    console.log("Temp and row: ", temp, row);
                    temp.isHide = !temp.isHide;
                }
                //return temp;
            });
            setDataSource([...dataSource]);
            // appData.environments.map((val, idx) => {
            //     console.log("My env val: ", val);
            //     if (val["key"] === row.keys) {
            //         console.log("Updated");
            //         val.is_secret = !val.is_secret;
            //     }
            //     return val;
            // });
            //setAppFromEnv(appData.environments);

            // currentProjectDep.tasks.map((temp, idx) => {
            //     console.log("myTemp", temp, selectedAppId);
            //     if (temp.id === selectedAppId) {
            //         //currentProjectDep.tasks[idx].envs = dataSource;
            //         console.log("Updated");
            //         currentProjectDep.tasks[idx].envs.map((val) => {
            //             if (val.varName === row.varName) {
            //                 console.log("Updated", val, row);
            //                 val.isHide = !val.isHide;
            //             }
            //             return val;
            //         });
            //     }
            // });
            // setCurrentProjectDep({ ...currentProjectDep });
            // localStorage.setItem('ProjectDep', JSON.stringify({ ...currentProjectDep }));
        }
    }

    const handelPlusIcon = () => {
        setOpenPopup(false);
        console.log("Filtered apps: ", appList);
        setOpenRightDrawer({
            drawerType: "addEnvironmentVariable",
            drawerProps: {
                data: appList.filter((temp) => temp.id === selectedAppId)[0],
                service: appList.filter((temp) => temp.id === selectedAppId)[0],
                setHasChanges: setHasChanges,
                currentProject: currentProjectDep,
                setCurrentProject: setCurrentProjectDep,
                fromEnvironment: true,
                update: false,
                valueFromDropdown: valueFromDropdown,
                columnId: null
            },
        });
    }

    const handleImportEnvVars = () => {
        setOpenPopup(false);
        console.log("Filtered apps: ", appList);
        setOpenRightDrawer({
            drawerType: "importEnvironmentVariable",
            drawerProps: {
                data: appList.filter((temp) => temp.id === selectedAppId)[0],
                service: appList.filter((temp) => temp.id === selectedAppId)[0],
                setHasChanges: setHasChanges,
                currentProject: currentProjectDep,
                setCurrentProject: setCurrentProjectDep,
                fromEnvironment: true,
                update: false,
                valueFromDropdown: valueFromDropdown,
                columnId: null
            },
        });
    }

    const handelMultiSelection = (selectedRowKeys, selectedRows) => {
        console.log("selectedRowKeys :", selectedRowKeys);
        console.log("selectedRows :", selectedRows);

        setMutiSelectedKeys(selectedRowKeys);
        setMutiSelectedRows(selectedRows);
    }

    const handelDeleteButton = (e) => {
        console.log("mutiSelectedRows in handelDeleteButton :", mutiSelectedRows);
        let selectedIds = [];

        mutiSelectedRows.map((temp, idx) => {
            selectedIds.push(temp.id);
        });

        let newData = initialDataSource.filter((temp, idx) => {
            return !selectedIds.includes(temp.id);
        });

        console.log("newData :", newData);

        // setCurrentProject
        currentProjectDep.tasks.map((temp, idx) => {
            if (temp.id === selectedAppId) {
                currentProjectDep.tasks[idx].envs = newData;
            }
        });

        setSearchText("");
        setMutiSelectedRows([]);
        setMutiSelectedKeys([]);
        setHasChanges(true);
        setCurrentProjectDep({ ...currentProjectDep, tasks: [...currentProjectDep.tasks] });
        localStorage.setItem("ProjectDep", JSON.stringify({ ...currentProjectDep, tasks: [...currentProjectDep.tasks] }));
    }

    const rowSelection = {
        onChange: handelMultiSelection,
        selectedRowKeys: mutiSelectedKeys
    };

    const handelSearchBox = (e) => {
        console.log("initialDataSource in search :", initialDataSource);
        let newList = initialDataSource.filter((temp, idx) => {
            if (temp.value.includes(e.target.value)) {
                return 1;
            }
        });
        // setAppList(JSON.parse(JSON.stringify([...newList])));
        setDataSource([...newList]);
        setSearchText(e.target.value);
    }

    const handelDeleteSearchBox = (e) => {
        console.log("initialDataSource when delete search :", initialDataSource);
        setDataSource(JSON.parse(JSON.stringify([...initialDataSource])));
        setSearchText("");
    }

    const onRadioChange = (e) => {
        setInjectValue(e.target.value);
        currentProjectDep.tasks.map((temp, idx) => {
            if (temp.id === selectedAppId) {
                currentProjectDep.tasks[idx].pass_env_vars_at_build_time = e.target.value === "Build Time" ? true : false;
                currentProjectDep.tasks[idx].appError = false;
            }
        });
        setCurrentProjectDep({ ...currentProjectDep, tasks: [...currentProjectDep.tasks] });
        localStorage.setItem('ProjectDep', JSON.stringify({ ...currentProjectDep, tasks: [...currentProjectDep.tasks] }));
        setHasChanges(true);
    }

    const MoreActions = () => {
        let disableImport = false;
        // if (currentProject.importDetails?.importOption === "Secrets") {
        //     disableImport = true;
        // }
        return <MoreActionWrapper>
            {/* Edit */}
            {/* temporary change */}
            {<div
                onClick={() => {
                    handelPlusIcon();
                }}
                style={{ display: "flex", alignItems: "center", borderBottom: "1px solid lightgrey" }}
            >
                <div style={{ display: "flex", padding: "10px", alignItems: "center" }}>
                <Avatar src={editIcon} style={{ width: "20px", height: "20px", background: "white", borderRadius: "100%", padding: "4px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)", marginRight: "20px" }} />
                {/* <img src={cloneIcon} style={{ maxWidth: "30px", marginRight: "20px", border: "2px solid lightgrey", borderRadius: "100%", cursor: "pointer" }} /> */}
                    <p> Add </p>
                </div>
            </div>}

            {/* Clone */}
            <div
                onClick={() => {
                    handleImportEnvVars();
                }}
                style={{ display: "flex", padding: "10px", alignItems: "center", opacity: disableImport ? "0.5" : "1", pointerEvents: disableImport && "none" }}
            >
                <Avatar src={exportVars} style={{ width: "20px", height: "20px", background: "white", borderRadius: "100%", padding: "4px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)", marginRight: "20px", transform: "rotate(180deg)" }} />
                {/* <img src={cloneIcon} style={{ maxWidth: "30px", marginRight: "20px", border: "2px solid lightgrey", borderRadius: "100%", cursor: "pointer" }} /> */}
                <p> Import </p>
            </div>

            {/* Delete */}
            {/* <div
                onClick={() => {
                    handleExportEnvVars();
                }}
                style={{ display: "flex", padding: "10px" }}
            >
                <Avatar src={exportVars} style={{ width: "20px", height: "20px", background: "white", borderRadius: "100%", padding: "4px", boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.25)", marginRight: "20px" }} />
                <p> Export </p>
            </div> */}
            {/* <div
            onClick={() => {
            }}
            style={{ display: "flex", padding: "10px"}}
          >
            <p>Refresh</p>
          </div> */}
        </MoreActionWrapper>
    };

    const handleOpenChange = (newOpen) => {
        setOpenPopup(openPopup => !openPopup);
    }

    const handelSaveEnvVar = (callSecretApi) => {
        let payload = [];
        // let myAppIdx = [];
        let foundEmpty = false;
        let injectFilled = true;

        // console.log("hasChanges in handelSaveEnvVar :", hasChanges);
        // console.log("dataSource in handelSaveEnvVar :", dataSource);

        currentProjectDep.tasks.every((temp, idx) => {
            // if ((!temp.injectValue || temp.injectValue === "") && temp.envs?.length > 0) {
            //     injectFilled = false;
            //     currentProject.tasks[idx].appError = true;
            //     // return false;
            // }
            // if (foundEmpty) {
            //     injectFilled = true;
            //     currentProject.tasks[idx].appError = true;
            //     return false;   // helps in breaking every() loop
            // }
            if (temp.columnId === "app") {
                // myAppIdx.push(temp.id);
                console.log("temp in handleCreate :", temp);

                temp.envs.every((env) => {
                    let found = false;

                    if (env.value === "" || env.value === undefined) {
                        foundEmpty = true;
                        currentProjectDep.tasks[idx].appError = true;
                        return false;   // helps in breaking every() loop
                    }
                    // check if already exist in envs list
                    payload.map((pay, i) => {
                        if (pay.id === temp.serviceId) {
                            found = true;
                            pay["env_var"][env.varName] = {
                                "type": env.valueFrom,
                                "value": env.value,
                                "is_secret": env.isSecret,
                                "depend_on": env.dependOn
                            }
                        }
                    });

                    console.log("found :", found);
                    // if not
                    if (!found) {
                        console.log("temp: ", temp);
                        let myObj = {
                            "id": temp.serviceId,
                            // "pass_at_build_time": currentProject.accountDetails.account_id && temp.isSecret ? undefined : temp.injectValue === "Build Time" ? true : false,
                            "is_secret_manager": callSecretApi ? currentProjectDep.accountDetails?.account_id ? true : false : undefined,
                            "secret_details": callSecretApi ? currentProjectDep.accountDetails?.account_id ? {
                                "account_id": currentProjectDep.accountDetails?.account_id,
                                "region": currentProjectDep.accountDetails?.region,
                                "secret_name": (temp.secret_name && temp.secret_name !== "" ? temp.secret_name : currentProjectDep.name + '_' + temp.name),

                            } : undefined : undefined,
                            "env_var": {
                                [env.varName]: {
                                    "type": env.valueFrom,
                                    "value": env.value,
                                    "is_secret": env.isSecret,
                                    "depend_on": env.dependOn
                                }
                            }
                        }
                        console.log('callSecrets: ', callSecretApi);
                        if (callSecretApi) {
                            console.log("Saving in payload: ", env);
                            if (env.isSecret) {
                                payload.push(myObj);
                            }
                        }
                        else {
                            if (!env.isSecret) {
                                payload.push(myObj);
                            }
                        }
                    }

                    return true;  // helps in continue loop
                });

                return true;  // helps in continue loop
            }
            else {
                return true;
            }
        });

        console.log("foundEmpty :", foundEmpty);
        console.log("injectFilled :", injectFilled);
        console.log("injectValue :", injectValue);
        console.log("hasChanges :", hasChanges);
        console.log("payload :", payload);

        if (foundEmpty) {
            setCurrentProjectDep({ ...currentProjectDep, tasks: [...currentProjectDep.tasks] });
            toggleAlert("error", "Error", "Some Values are empty. Please fill them first.");
        }
        // else if (!injectValue || !injectFilled) {
        //     console.log("inside if injectValue :", injectValue);
        //     setCurrentProject({ ...currentProject, tasks: [...currentProject.tasks] });
        //     toggleAlert("error", "Error", "Please select one from Build Time and Run Time.");
        //     // setIsValidated(true);
        // }
        else if (payload.length > 0) {
            console.log("payload :", selectedAppId, payload.find(val => val.id === currentProjectDep.tasks.find(val => val.id === selectedAppId)?.serviceId));
            function clearVars() {
                api.put(`${process.env.REACT_APP_API_URI}/api/project_env/projects/${currentProjectDep.id}/services-envs/`, [{
                    "id": currentProjectDep.tasks.find(val => val.id === selectedAppId)?.serviceId,
                    // "pass_at_build_time": temp.injectValue === "Build Time" ? true : false,
                    "is_secret_manager": currentProjectDep.accountDetails?.account_id ? true : false,
                    "secret_details": currentProjectDep.accountDetails?.account_id ? {
                        "account_id": currentProjectDep.accountDetails?.account_id,
                        "region": currentProjectDep.accountDetails?.region,
                        "secret_name": (currentProjectDep.tasks.find(val => val.id === selectedAppId)?.secret_name !== "" ? currentProjectDep.tasks.find(val => val.id === selectedAppId)?.secret_name : currentProjectDep.name + '_' + currentProjectDep.name),

                    } : undefined,
                    "env_var": {}
                }]).then((res) => {
                    let data = res.data;
                    if (data.status) {
                        toggleAlert("success", "Success", "Successfully saved Environment Variables.");
                    }
                    else {
                        toggleAlert("error", "Error", "Something went wrong. Please try again.");
                    }
                }).catch((err) => {
                    console.log("Error");
                    toggleAlert("error", "Error", err);
                });
            }
            callSecretApi && api.put(`${process.env.REACT_APP_API_URI}/api/project_env/secrets`, payload.find(val => val.id === currentProjectDep.tasks.find(val => val.id === selectedAppId)?.serviceId))
                    .then((res) => {
                        let data = res.data;
                        console.log("repo/list data :", data);
                        if (data.status) {
                            toggleAlert("success", "Success", "Successfully saved Environment Variables.");
                            // changeService();
                            setHasChanges(false);
                            clearVars();
                            // setIsValidated(false);
                            // localStorage.removeItem("Project");
                            // navigate("/all-projects");
                        }
                        else {
                            let err = formatErrors(data.msg);
                            toggleAlert("error", "Error", err);
                        }
                    })
                    .catch((err) => {
                        console.log("err :", err);
                        toggleAlert("error", "Error", "Something went wrong. Please try again.");
                    });
        }
    }

    return (
        <>
            {allOptions.envVars && <div style={serviceSelected.isActive ? { ...styles.wisgetPageStyle, padding: "0", cursor: "default" } : { ...styles.wisgetPageStyle, opacity: "0.5", pointerEvents: "none", cursor: "default" }} onClick={(e) => {
                e.stopPropagation();
            }}>
                {console.log("My envs: ", dataSource, currentProjectDep.tasks)}
                <IsoWidgetBox style={{ border: "transparent", padding: "0" }}>
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", padding: "0 20px 20px 0" }}>
                        {mutiSelectedRows.length == 0 ? <>
                            {/* <Button className="customButtonActive" style={{ padding: "7px 25px", height: "fit-content", marginRight: "20px" }} onClick={handelPlusIcon}>
                                Add
                            </Button>
                            <Button className="customButtonActive" style={{ background: "#132F44", borderRadius: "8px", color: "#FFFFFF", padding: "7px 25px", height: "fit-content" }} onClick={handleImportEnvVars}>
                                Import
                            </Button> */}
                            <Popover
                                overlayStyle={{
                                    width: "150px"
                                }}
                                placement="bottomLeft"
                                content={MoreActions()}
                                open={openPopup}
                                //open={row.isOpen}
                                onOpenChange={(newOpen) => {
                                    handleOpenChange(newOpen);
                                }}
                                trigger="click"
                            >
                                <img src={optionIcon} style={{ cursor: "pointer" }} />
                            </Popover>
                        </>
                            :
                            <Button className="customButtonActive" style={{ padding: "7px 35px", height: "fit-content", background: "#CA0606", color: "#FFF", border: "none" }} onClick={handelDeleteButton}>
                                Delete
                            </Button>
                        }
                    </div>
                    <div style={{ height: "90%" }}>
                        {/* <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
                            <img src={addItemIcon} alt="search" style={{ cursor: "pointer" }} onClick={handelPlusIcon} />
                        </div> */}
                        {console.log("Inside env vars: ", dataSource)}
                        <div style={{ height: "inherit", display: "flex", justifyContent: "center", alignItems: "flex-start" }}>
                            <TableWrapper
                                rowKey="key"
                                className="env-var-table"
                                columns={columns}
                                //rowSelection={rowSelection}
                                bordered={true}
                                dataSource={dataSource}
                                loading={false}
                                style={{ width: "98.5%", maxHeight: "500px", overflow: "auto" }}
                                pagination={false}
                                onRow={(record, idx) => {
                                    console.log("Row value: ", record);
                                    return {
                                        onClick: (e) => handleRecord('update', record)
                                    }
                                }}
                                rowClassName={(record, idx) => {
                                    if (idx % 2 === 0) {
                                        return 'even-rows';
                                    }
                                    else {
                                        return 'odd-rows';
                                    }
                                }}
                            // scroll={{y: '68%'}}
                            />
                        </div>
                        {/* {hasChanges && <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "17px" }}>

                            <Button className="customButtonActive"
                                style={{ background: "#132F44", borderRadius: "8px", color: "#FFFFFF", opacity: ((appList.length > 0) && appList[appList.length - 1].id === selectedAppId && !hasChanges) ? "0.3" : "1", cursor: ((appList.length > 0) && appList[appList.length - 1].id === selectedAppId && !hasChanges) ? "not-allowed" : "pointer" }}
                                onClick={() => {
                                    handelSaveEnvVar(currentProjectDep.importDetails.importOption === "Secrets");
                                }}
                            >
                                Save
                            </Button>
                        </div>} */}
                        {appData.environments.length > 0 && <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Box
                                    title={<div style={{ display: "flex" }}>
                                        <p style={{ ...innerTitleStyle, marginRight: "10px" }}>When do we pass ENV Variables to Service?</p>
                                        <CustomInfoTooltip title="This is application specific, for example for a PHP application, variables would be injected at run time" placement='bottomLeft' />
                                    </div>}
                                    titleStyle={innerTitleStyle}
                                    subtitle={"This will pass the env variables either at Build time in CI or during the CD at runtime."}
                                    subTitleStyle={{ ...innerSubtitleStyle, width: "350px" }}
                                    style={{ border: "transparent", width: "425px" }}
                                />
                                <Radio.Group style={{ opacity: appData.basicDetails.sourceType === "Container Reegistry" ? "0.7" : "1", pointerEvents: appData.basicDetails.sourceType === "Container Registry" && "none", display: "flex", height: "fit-content", marginTop: "20px" }} onChange={onRadioChange} value={currentProjectDep.tasks.find(val => val.id === selectedAppId)?.pass_env_vars_at_build_time ? "Build Time" : "Run Time"}>
                                    <Radio value={"Build Time"} style={{ border: "1px solid", padding: "10px", borderRadius: "5px", marginRight: "15px", color: "#132f44 !important" }}>Build Time</Radio>
                                    <Radio value={"Run Time"} style={{ border: "1px solid", padding: "10px", borderRadius: "5px" }}>Run Time</Radio>
                                    {/* <Radio value={3}>C</Radio>
                                        <Radio value={4}>D</Radio> */}
                                </Radio.Group>
                            </div>
                        </div>}
                    </div>
                </IsoWidgetBox>
            </div>}
        </>
    );
};
