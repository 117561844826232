import React from 'react';
import { Form, Field } from 'formik';
import { Row, Col, Tooltip } from 'antd';
import {
  AntDatePicker,
  AntSelect,
  AntTextArea,
  AntInput,
} from '@iso/components/ScrumBoard/AntFields';
import { dateFormat } from '@iso/components/ScrumBoard/FieldFormats';
// import CreateTaskHeader from '../CreateTaskHeader/CreateTaskHeader';
import HeadingWithIcon from '@iso/components/ScrumBoard/HeadingWithIcon/HeadingWithIcon';
import rabbitmqIcon from '@iso/assets/images/icon/rabbitmq-icon.png';
import kafkaIcon from '@iso/assets/images/icon/kafka-icon.png';
import sqsIcon from '@iso/assets/images/icon/sqs-icon.png';
import amazonmqIcon from '@iso/assets/images/icon/amazonmq-icon.png';
import CreateTaskHeader from '../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import Checkbox, { CheckboxGroup } from '@iso/components/uielements/checkbox';
import Box from '../../components/utility/box';
import { useState } from 'react';
import { useContext } from 'react';
import { AppContext } from '../../context/ContextProvider';
import { useLocation } from 'react-router';
import api from '../../api';
import { formatErrors } from '../../context/helper';
import Alert from '@iso/components/Feedback/Alert';
import { AlertContext } from '../../context/alertContext';
import CreateTaskFooter from '../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';

const marginsBot = {
  position: 'fixed',
  opacity: '100%',
  transition: 'all 1s ease',
  top: "7vw",
  right: "0",
  zIndex: '1000',
  width: "25%",
  padding: '15px'
};

const fadeMarginBot = {
  position: 'fixed',
  opacity: '0',
  transition: 'all 1s ease',
  top: "7vw",
  right: "0",
  zIndex: '-1',
  width: "25%",
  padding: '15px'
};

let divStyle = {
  padding: "0 20px",
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'flex-start',
  overflow: 'hidden',
}

let boxStyles = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: 'space-between',
  width: "150px",
  height: "150px",
  padding: "10px",
  position: "relative",
  margin: "10px 10px"
}


export default ({
  // handleSubmit,
  columnId,
  values,
  submitCount,
  onCancel,
  onDelete,
  onSave,
  onEditCancel,
}) => {

  let { convertToFrontend, convertToBackend, currentProject, setCurrentProject, openRightDrawer, setOpenRightDrawer, currentColumns, setCurrentColumns, currentTasks, setCurrentTasks } = useContext(AppContext);
  const { alertOptions, set_alertOptions } = useContext(AlertContext);

  const location = useLocation();

  const [error, setError] = useState({
    status: false,
    msg: "Success"
  });

  const [myQueueSources, setMyQueueSources] = useState({
    rabbitmq: {
      name: 'RabbitMQ',
      key: 'rabbitmq',
      icon: rabbitmqIcon,
      status: false
    },
    kafka: {
      name: "Kafka",
      key: "kafka",
      icon: kafkaIcon,
      status: false
    },
    sqs: {
      name: "SQS",
      key: "sqs",
      icon: sqsIcon,
      status: false
    },
    amazonmq: {
      name: "AmazonMQ",
      key: "amazonmq",
      icon: amazonmqIcon,
      status: false
    }
  });

  const handelClick = () => {
    setOpenRightDrawer({
      drawerType: false,
      drawerProps: {
        data: "",
        setData: "",
        columnId: "",
        tabSelected: "",
      },
    });
  }

  const getServiceCategoryId = (name) => {
    let serviceCategoryId = null;
    currentProject.serviceCategories.map((temp, idx) => {
      if (temp.name.toLowerCase() === name.toLowerCase()) {
        serviceCategoryId = temp.id
      }
    });

    console.log('serviceCategoryId :', serviceCategoryId);
    return serviceCategoryId;
  }

  const saveService = () => {
    console.log('save myQueueSources :', myQueueSources);
    Object.keys(myQueueSources).map((temp, ind) => {
      if (myQueueSources[temp].status) {
        let payload = {
          "name": myQueueSources[temp].name,
          "description": null,
          // "service_type": convertToBackend[columnId],
          "service_category": getServiceCategoryId(myQueueSources[temp].name),
          "service_icon": null,
          "config": {
            ...myQueueSources[temp],
            version: "",
          },
          "project": currentProject.id
        }

        delete payload.config.name;
        delete payload.config.status;
        delete payload.config.serviceId;
        delete payload.config.icon;
        delete payload.config.title;
        delete payload.config.serviceCategory;
        delete payload.config.created_at;
        delete payload.config.updated_at;
        console.log('payload in updateService :', payload);

        api.post(`${process.env.REACT_APP_API_URI}/api/project_env/services/`, payload)
          .then((res) => {
            let data = res.data;
            console.log("create dataSource data :", data);
            if (data.status) {
              // update column
              currentProject.columns[columnId].task_orders.push(currentProject.tasks.length);
              // update tasks
              currentProject.tasks.push({
                id: currentProject.tasks.length,
                name: data.data.name,
                title: data.data.name,
                columnId: convertToFrontend[data.data.type],
                serviceId: data.data.id,
                ...data.data.configuration
              });

              setCurrentProject({ ...currentProject, columns: currentProject.columns, tasks: [...currentProject.tasks] });
              localStorage.setItem("Project", JSON.stringify({ ...currentProject, columns: currentProject.columns, tasks: [...currentProject.tasks] }));
              handelClick();
              toggleAlert("success", "Success", "Successfully saved service");
            }
            else {
              let err = formatErrors(data.error_details);
              console.log("Error :", err);
              toggleAlert("error", "Error", err);
            }
          })
          .catch((err) => {
            console.log("err :", err);
            toggleAlert("error", "Error", "Something went wrong. Please try again.");
          });
      }
    });
  }

  function checkNameExists(temp) {
    let myList = currentProject.tasks.map((item, idx) => {
      return item && item.name;
    });

    if (myList.includes(myQueueSources[temp].name)) {
      return true;
    }
    else {
      return false;
    }
  }

  const saveTasks = () => {
    console.log('myQueueSources :', myQueueSources);
    Object.keys(myQueueSources).map((temp, ind) => {
      let data = {
        id: '',
        name: "AmazonMQ",
        key: "amazonmq",
        version: "",
        columnId: 'queues',
        editing: false,
        created_at: new Date(),
        updated_at: '',
      }

      if (temp && myQueueSources[temp].status) {
        let alreadyExist = checkNameExists(temp);
        console.log('alreadyExist :', alreadyExist);

        if (!alreadyExist) {
          let id = currentProject.tasks.length;
          console.log('id :', id);
          data.id = id;
          data.name = myQueueSources[temp].name;
          data.key = myQueueSources[temp].key;
          console.log('data :', data);

          // update column
          currentProject.columns[columnId].task_orders.push(id);
          // update tasks
          currentProject.tasks.push({ ...data });
        }
        else {
          toggleAlert("error", "Error", "Service already exists");
        }
      }
    });

    setCurrentProject({ ...currentProject, columns: { ...currentProject.columns } });
    setCurrentProject({ ...currentProject, tasks: currentProject.tasks });
    localStorage.setItem("Project", JSON.stringify({ ...currentProject, tasks: currentProject.tasks }));
    handelClick();
  }

  const handelBoxSelect = (e) => {
    console.log('e.target.id :', e.target.id);
    myQueueSources[e.target.id].status = !myQueueSources[e.target.id].status;
    setMyQueueSources({ ...myQueueSources });
  }

  const handelSaveTasks = () => {
    if (location.pathname.includes("/all-projects/project/")) {
      saveService();
    }
    else {
      saveTasks();
    }
  }

  function toggleAlert(type, message, description) {
    set_alertOptions(alertOptions => ({
      status: true,
      type: type,
      message: message,
      description: description,
    }));

    // opacity=0 after 10sec
    setTimeout(() => {
      set_alertOptions(alertOptions => ({
        status: false,
        type: type,
        message: message,
        description: description,
      }));
    }, 5 * 1000);
  }


  return (
    <>
      {/* <Alert
        message={alertOptions.message}
        description={alertOptions.description}
        type={alertOptions.type}
        showIcon
        style={alertOptions.status ? marginsBot : fadeMarginBot}
      /> */}

      <Form style={{ marginTop: "100px" }}>
        <CreateTaskHeader
          buttonContent="queues"
          values={values}
          onCancel={onCancel}
          onDelete={onDelete}
          onEditCancel={onEditCancel}
          onSave={handelSaveTasks}
        />
        <div style={divStyle}>
          {Object.keys(myQueueSources).map((temp, ind) => (
            <Box
              style={boxStyles}
              id={myQueueSources[temp].key}
              key={`DataSource-${ind}`}
              onClick={handelBoxSelect}
            >
              <Checkbox id={temp} style={{ position: "absolute", right: "0", margin: "0 15px" }} checked={myQueueSources[temp].status} />
              <img src={myQueueSources[temp].icon} id={myQueueSources[temp].key} alt="aws" style={{ width: "auto", height: "70%" }} />
              <span id={myQueueSources[temp].key} style={{ justifyContent: "center", alignItems: "center", paddingTop: "10px", fontWeight: "500" }}>{myQueueSources[temp].name}</span>
            </Box>
          ))}
        </div>

        <CreateTaskFooter buttonContent={"Queues"} onCancel={onCancel} onSave={handelSaveTasks} onDelete={onDelete} />
      </Form>
    </>
  )
};
