export let clusterHelpChecks = {
    cluster: {
        account: "Specify the integrated account where resources will be deployed. Ensure you have the necessary permissions in this account",
        environment: "Choose the environment production or non-production for your cluster. This affects resource allocation and access controls.",
        kubernetesVersion: "Select the Kubernetes version for your cluster. Choose a stable version that supports your application's requirements.",
        vpcCidr: "Enter the IP address range (CIDR notation) for your VPC. This defines the private network space for your resources.",
        vpcFlowLogs: "Enable to log all network traffic in your cluster and VPC. Useful for security audits and troubleshooting network issues.",
        logRetention: "Define how long to retain cluster logs (in days). Longer periods are useful for compliance and deeper analysis but incur more storage costs.",
        vpcLogRetention: "Set the duration (in days) to keep VPC flow logs. Balances the need for historical data against storage costs.",
        vpcMaxAggregation: "Choose the maximum interval for aggregating VPC flow logs. Shorter intervals provide more granular data but increase the volume of logs.",
    },
    pglAddon: {
        hostName: "Specify the hostname for the Grafana dashboard. This will be the URL through which users can access the dashboard.",
        cloudWatchCollector: "Enable to collect logs and metrics from AWS CloudWatch, for integrated monitoring.",
        dashboardRefreshInterval: "Set the interval at which the Grafana dashboard data refreshes automatically. Shorter intervals provide more up-to-date information but may increase load.",
        objectExpiration: "Define the period (in days) after which old logs and metrics are automatically deleted to manage storage efficiently."
    },
    nodegroups: {
        amiType: "Select the Amazon Machine Image (AMI) type for your nodes. Choose between standard, optimized for GPU, or ARM-based instances.",
        nodegroupType: "Choose the node group management type: 'Karpenter Manager' for dynamic provisioning based on workload or 'AWS Managed' for traditional, fixed-capacity node groups managed by AWS.",
        capacityType: "Choose 'On-Demand' for stable capacity or 'Spot' for cost savings with potential interruption. Affects node availability and pricing.",
        diskSize: "Define the disk size in GB for each node. Larger sizes are beneficial for storage-intensive applications",
        minNodes: "Set the minimum number of nodes in the nodegroup to ensure the desired base capacity for your application",
        maxNodes: "Specify the maximum number of nodes allowed to scale up in the nodegroup, providing flexibility during peak loads."
    }
};