import React, { useEffect, useState } from 'react';
import meta from './assets/meta.json';

function withClearCache(Component) {
    function ClearCacheComponent(props) {
        const [callComponent, setCallComponent] = useState(false);
        // fetch('/meta.json')
        //     .then((response) => response.json())
        //     .then((meta) => {
        //         console.log("meta in ClearCache :", meta);
        //         console.log("global.appVersion in ClearCache :", global.appVersion);
        //         console.log("meta.version in ClearCache :", meta.version);
        //         if (meta.version !== global.appVersion) {
        //             // Clear cache and force hard reload
        //             window.location.reload(true);
        //         }
        //     })
        //     .catch((error) => {
        //         console.error('Error fetching meta.json:', error);
        //     });
        useEffect(() => {
            let storedVersion = localStorage.getItem('appVersion');
            // if(storedVersion && storedVersion !== "") {
            //     console.log("Entering: ", storedVersion, VERSION);
            //     if (storedVersion !== VERSION) {
            //         localStorage.clear();
            //         localStorage.setItem('appVersion', VERSION);
            //     }   
            // }
            // else {
            //     localStorage.setItem('appVersion', VERSION);
            // }            
            console.log("meta in ClearCache :", meta);
            console.log("meta.version in ClearCache :", meta.version, storedVersion);
            if (meta.version !== storedVersion) {
                // Clear cache and force hard reload
                console.log("Clear cache and forcing reload");
                localStorage.clear();
                localStorage.setItem('appVersion', meta.version);
                //window.location.reload(true);
            }
            console.log("Clear cache and forcing reload",  localStorage.getItem('appVersion'));
            setCallComponent(true);
        }, []);
        return <>
            {callComponent && <Component {...props} />}
        </>;
    }

    return ClearCacheComponent;
}

export default withClearCache;