import React, { useState } from 'react';
const AlertContext = React.createContext();

const AlertProvider = ({ children }) => {
    const [alertOptions, set_alertOptions] = useState({
        status: false,
        type: "success",
        message: "Success",
        description: "Invited Successfully",
    });
    
    const [alerts, setAlerts] = useState({
        status: false,
        type: "success",
        message: "Success",
        description: "Invited Successfully",
    });

    const handleAlerts = (type, message, description) => {
        console.log("I am inside handleAlerts...");
        setAlerts({
            status: true,
            type: description,
            message: type,
            description: message,
        });
        console.log("I am going for setTimeout inside handleAlerts...");

        setTimeout(() => {
            console.log("I am inside handleAlerts setTimeout...");
            setAlerts({
                status: true,
                type: description,
                message: type,
                description: message,
            });
        }, 5 * 1000);
    }


    return (
        <AlertContext.Provider value={{alertOptions, set_alertOptions, alerts, setAlerts, handleAlerts }}>
            {children}
        </AlertContext.Provider>
    );
}

export { AlertContext, AlertProvider };