import React, { useEffect, useState } from 'react';
import { Form, Field } from 'formik';
// import { Row, Col, Tooltip, InputNumber } from 'antd';
import { Spin, Tooltip, Space } from 'antd';
import Box from '@iso/components/utility/box';
import ContentHolder from '@iso/components/utility/contentHolder';
import Select, { SelectOption } from '@iso/components/uielements/select';
// import { HrBar, CardFooter, CardBody, TaskDescription } from '../Task.style';
import RemoveIcon from '@iso/assets/images/deleteIcon.svg';
import rightArrowIconBlue from '@iso/assets/images/rightArrowIcon-blue.svg';
import workflowEnvIcon from '@iso/assets/images/workflow-env-icon.svg';
import UserIcon from '@iso/assets/images/user-icon.svg';
import {
    AntDatePicker,
    AntSelect,
    AntTextArea,
    AntInput,
} from '@iso/components/ScrumBoard/AntFields';
import Input, {
    InputSearch,
    InputGroup,
    Textarea,
} from '@iso/components/uielements/input';
import { dateFormat } from '@iso/components/ScrumBoard/FieldFormats';
// import CreateTaskHeader from '../CreateTaskHeader/CreateTaskHeader';
import HeadingWithIcon from '@iso/components/ScrumBoard/HeadingWithIcon/HeadingWithIcon';
import TitleIcon from '@iso/assets/images/icon/05-icon.svg';
import DescriptionIcon from '@iso/assets/images/icon/06-icon.svg';
import AttachmentIcon from '@iso/assets/images/icon/01-icon.svg';
import plusIcon from '@iso/assets/images/plusIcon.svg';
import refreshIcon from '@iso/assets/images/refresh.svg';
import CreateTaskHeader from '../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskHeader/CreateTaskHeader';
import Button from '@iso/components/uielements/button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/ContextProvider';
import { useContext } from 'react';
import IntlMessages from '../../components/utility/intlMessages';
import axios from 'axios';
import api from '../../api';
import { customFilterOption, customSortOptions, formatErrors } from '../../context/helper';
import { AlertContext } from '../../context/alertContext';
import Alert from '@iso/components/Feedback/Alert';
import CreateTaskFooter from '../ScrumBoard/Task/TaskCreateOrUpdate/CreateTaskFooter/CreateTaskFooter';
import { useRef } from 'react';
import { DeploymentContext } from '../../context/deploymentContext';
import { HrBar, TaskDescription } from '../ScrumBoard/Task/Task.style';
import { LoadingOutlined, CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import retriggerIcon from '@iso/assets/images/retriggerIcon.svg';
import CustomInfoTooltip from '../Dashboard/CustomComponents/CustomInfoTooltip';
import { CustomModal } from '../Dashboard/CustomComponents/PageLoader.style';


const Option = SelectOption;

let innerTitleStyle = {
    color: "#1F1F1F",
    fontFamily: "Poppins",
    fontType: "medium",
    fontWeight: "500",
    fontSize: "14px"
};

let innerSubtitleStyle = {
    fontSize: "12px",
    color: "#9B9B9B",
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontType: "regular",
}

const marginsBot = {
    position: 'fixed',
    opacity: '100%',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '1000',
    width: "25%",
    padding: '15px'
};

const fadeMarginBot = {
    position: 'fixed',
    opacity: '0',
    transition: 'all 1s ease',
    top: "7vw",
    right: "0",
    zIndex: '-1',
    width: "25%",
    padding: '15px'
};

let boxStyle = {
    border: '0',
    padding: "0",
    width: "100%"
}

let titleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontSize: "16px",
    fontWeight: "500"
}

let subtitleStyle = {
    fontSize: "14px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontType: "regular",
    fontWeight: "400"
}

let inputStyle = {
    padding: '4px 11px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontSize: '14px',
    borderRadius: '6px',
    height: "40px"
}

let textAreaStyle = {
    padding: '4px 11px',
    color: 'rgba(0, 0, 0, 0.88)',
    fontSize: '14px',
    borderRadius: '6px',
    height: "100px"
}

let imgStyle = {
    width: '16px',
    height: '20px',
    marginRight: '10px'
}

const CustomAlert = styled(Alert)`
    .ant-alert-action {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 10px;
    }
`;



export default ({
    // handleSubmit,
    values,
    data,
    editing,
    submitCount,
    columnId,
    onCancel,
    onDelete,
    onSave,
    deployment,
    onEditCancel,
}) => {

    const { convertToBackend, convertToFrontend, currentProject, setCurrentProject, setOpenModel, setOpenRightDrawer, tempNodeData, tempEdgesData, setCurrentWorkflowData, setWorkflowData, setWorkflowNodeData, setWorkflowEdgesData, initialWorkflowData, setInitialWorkflowData, refreshProjectData, setRefreshProjectData } = useContext(AppContext);
    const { alertOptions, set_alertOptions } = useContext(AlertContext);
    const { deploymentData, setDeploymentData, setSelectedProject } = useContext(DeploymentContext);
    const [fetching, setFetching] = useState(false);
    const [componentLoading, setComponentLoading] = useState(false);
    const [sources, setSources] = useState([]);
    const [accountsConnected, setAccountsConnected] = useState();
    const [sourceFetching, setSourceFetching] = useState(true);
    const [openAppSourceDropdown, setOpenAppSourceDropdown] = useState(false);
    const [openSource, setOpenSource] = useState(false);
    const [repoList, setRepoList] = useState();
    const [branchList, setBranchList] = useState();
    const [myTask, setMyTask] = useState({ ...data });
    const [imageRepoList, setImageRepoList] = useState([]);
    const [containerRegistryList, setContainerRegistryList] = useState([]);
    const [containerRegs, setContainerRegs] = useState([]);
    const [sourceList, setSourceList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [error, setError] = useState({
        status: false,
        msg: "Success"
    });
    const location = useLocation();
    const navigate = useNavigate();
    const targetRef = useRef(null);

    const repoUrlRegex = /(?:https?:\/\/)?(?:www\.)?(?:github\.com|gitlab\.com|\@bitbucket\.org)?\/([^/\s]+)\/([^/\s]+)/i;
    // https://github.com/Gautam-SquareOps/test.git
    // "https://squareops_test@bitbucket.org/atmosly/dev.git"
    const serviceNameRegex = /^[a-z0-9]([a-z0-9-]*[a-z0-9])?(?:\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/;

    const [myAppData, setMyAppData] = useState(editing ? data : {
        id: "",
        serviceId: "",
        serviceCategory: "",
        columnId: columnId,
        name: "",
        isPrivate: false,
        isOwner: true,
        accountId: "",
        accountName: "",
        sourceType: "",
        containerRegistry: {},
        imageRepo: "",
        source: "",
        envs: [],
        repoUrl: "",
        repo: "",
        branch: "",
        dockerFilePath: "",
        description: '',
        cpu: '',
        ram: '',
        workflows: [],
        editing: false,
        created_at: new Date(),
        updated_at: new Date(),
    });

    const [imageRepoLoading, setImageRepoLoading] = useState(true);
    const [envList, setEnvList] = useState([]);
    const [validatingRepo, setValidatingRepo] = useState(false);
    const [showError, setShowError] = useState("");

    console.log("Data inside: ", data);

    // to hide Add new workflow if no env is present
    useEffect(() => {
        if (data.serviceId) {
            console.log("currentProject in deployClickRightDrawer :", currentProject);
            let query = `?service_id=${data.serviceId}`;    // &account_id=${data.accountId}
            console.log("query in useEffect :", query);

            api.get(process.env.REACT_APP_API_URI + `/api/project_env/env/create${query}`)
                .then((res) => {
                    let data = res.data;
                    console.log("env list :", data);
                    if (data.status) {
                        let myEnvs = [];

                        let arr = data.data.sort((a, b) => (a.name.localeCompare(b.name)));

                        arr.map((temp, idx) => {
                            // Only active and updating phase env's
                            if (temp.phase === 3 || temp.phase === 2 || temp.phase === 1) {
                                myEnvs.push(temp);
                            }
                        });

                        setEnvList(myEnvs);
                    }
                    else if (!data.status) {
                        let err = formatErrors(data.error_details);
                        console.log("err in project_env/create :", err);
                    }
                }).catch((err) => {
                    console.log("err :", err);
                });
        }
    }, []);

    useEffect(() => {
        if (editing) {
            console.log("myAppData initially :", myAppData);
            if (myAppData.source) {
                setMyAppData({
                    ...myAppData,
                    source: myAppData.source,
                    // source: `${myAppData.source.toUpperCase()}${myAppData.account_name ? ` (${myAppData.account_name})` : ""}`
                });
            }
            else {
                setMyAppData({
                    ...myAppData,
                    source: "public"
                })
            }
        }
    }, []);

    useEffect(() => {
        let sourceTypes = ["Git Repositories", "Container Registry"];
        let sourceOptions = [];
        sourceTypes.forEach((val) => {
            sourceOptions.push(
                <Option key={val} value={val} name="sourceType">{val}</Option>
            );
        });
        setSourceList(sourceOptions);
    }, []);

    useEffect(() => {
        if (myAppData.source && (myAppData.accountId || myAppData.accountName)) {
            setFetching(true);
            getRepos(myAppData.accountId);
        }
        else if (myAppData.source && myAppData.source === "public") {
            setRepoList();
            setBranchList();
        }
    }, [myAppData.source, myAppData.accountId, myAppData.accountName]);

    useEffect(() => {
        if (sourceFetching) {
            api.post(process.env.REACT_APP_API_URI + "/api/integration/account/list/", {
                "category_id": 2
            })
                .then((res) => {
                    let data = res.data;
                    console.log("integration/list data :", data);
                    if (data.status) {
                        const options = { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
                        // let dataSource = [];
                        let appSource = [];
                        // Public on top of appSource
                        appSource.push(
                            <Option key={appSource.length - 1} name="source" data-alias="" type="public">
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
                                    <span>PUBLIC</span>
                                </div>
                            </Option>
                        );

                        data.data.map((temp, ind) => {
                            if (temp.status) {
                                appSource.push(
                                    <Option key={ind} name="source" data-key={temp.id} type={temp.integration_type} data-alias={temp.alias}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
                                            <span>{`${temp.integration_type.toUpperCase()}${temp.alias ? ` (${temp.alias})` : ""}`}</span>
                                        </div>
                                    </Option>
                                );
                            }
                        });

                        // Integrate New Account on bottom of appSource
                        appSource.push(
                            <Option key={appSource.length} name="source" data-alias="" type="integration" style={{ color: "white", background: "#1677ff" }}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
                                    <span>Integrate New Account</span>
                                </div>
                            </Option>
                        );

                        console.log('appSource :', appSource);
                        setAccountsConnected(appSource);
                        setSourceFetching(false);
                    }
                    else {
                        let err = formatErrors(data.error_details);
                        console.log("Err :", err);
                        setSourceFetching(false);
                    }
                })
                .catch((err) => {
                    console.log("err :", err);
                    setSourceFetching(false);
                    // toggleAlert("error", "Error", "No Data Found. Please try again.");
                });
        }
    }, [sourceFetching]);

    // Auto focus error
    useEffect(() => {
        if (error.status) {
            scrollToElement();
        }
    }, [error.status]);

    useEffect(() => {
        //if (myAppData.sourceType === "Container Registry") {
        if (myAppData.sourceType === "Container Registry") {
            let regOptions = [];
            api.get('/api/integration/container-registries/').then((res) => {
                let data = res.data;
                let arr = data.data;
                arr.push("PUBLIC");
                if (data.status) {
                    arr.forEach((child, ind) => {
                        if (child !== "PUBLIC") {
                            regOptions.push(
                                <Option key={child.alias + ind + '!'} name={"containerRegistry"} data-id={child.id} data-region={child.region} value={!child.account_id ? `${child.alias} - ${child.integration_type}` : `${child.account_id}${child.alias && `(${child.alias})`} / ${child.region}`}>
                                    <div style={{ width: "100%", display: "flex" }}>
                                        <p> {!child.account_id ? `${child.alias} - ${child.integration_type}` : `${child.account_id}${child.alias && `(${child.alias})`} / ${child.region}`} </p>
                                    </div>
                                </Option>);
                        }
                        else {
                            regOptions.push(
                                <Option key={child} name={"containerRegistry"} data-id="" data-region={null} value={child}>
                                    <div style={{ width: "100%", display: "flex" }}>
                                        <p> {child} </p>
                                    </div>
                                </Option>);
                        }
                    });

                    setContainerRegistryList(regOptions);
                    setContainerRegs(arr);
                }
                else {
                    console.log("Error");
                }
            }).catch((err) => {
                console.log("Error");
            })
        }
        //}
    }, [myAppData.sourceType]);


    useEffect(() => {
        const fetchImageRepos = () => {
            if (myAppData.containerRegistry && Object.keys(myAppData.containerRegistry).length > 0 && myAppData.containerRegistry !== "PUBLIC") {
                let imageRepos = [];
                api.get(`/api/integration/repositories/${myAppData.containerRegistry.id}/`).then((res) => {
                    let data = res.data;
                    if (data.status) {
                        data.data.forEach((val) => {
                            imageRepos.push(
                                <Option key={val} value={val} name="imageRepo">{val}</Option>
                            );
                        });
                        setImageRepoList(imageRepos);
                        setImageRepoLoading(false);
                    }
                    else {
                        console.log("Error");
                        setImageRepoLoading(false);
                    }
                }).catch((err) => {
                    console.log("Error");
                    setImageRepoLoading(false);
                });
            }
        }
        let myRepoTimeout = setTimeout(fetchImageRepos, 2000);
        return (() => clearTimeout(myRepoTimeout))
    }, [myAppData.containerRegistry]);

    const scrollToElement = () => {
        console.log("Target ref: ", targetRef);
        targetRef.current.scrollIntoView({
            behavior: 'smooth',
            block: "center",
            inline: "center"
        });
    };

    const getRepos = (accountId) => {
        console.log('Going to get repos');
        let payload = {
            "account_id": accountId
        }

        api.post(process.env.REACT_APP_API_URI + "/api/integration/vcs/repos/", payload)
            .then((res) => {
                let data = res.data;
                console.log("repo/list data :", data);
                if (data.status) {
                    let repos = [];

                    data.data.map((temp, ind) => {
                        repos.push(
                            <Option key={ind} name="repo" type={temp.name} value={temp.name} data-isprivate={temp.private} data-url={temp.repo_url} is-owner={temp.owner}>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px" }}>
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <p>{temp.name}</p>
                                        <p style={{
                                            fontFamily: "Nunito Sans",
                                            fontSize: "10px",
                                            color: "lightgray"
                                        }}>Atmosly requires you to have repo ownership otherwise some functionalities may not work.</p>
                                    </div>
                                    <span>{temp.private ? "Private" : "Public"}</span>
                                </div>
                            </Option>
                        );
                    });

                    console.log('repos :', repos);
                    setRepoList(repos);
                    setFetching(false);
                }
                else {
                    let err = formatErrors(data.error_details);
                    console.log("Error :", err);
                    setFetching(false);
                }
            })
            .catch((err) => {
                console.log("err :", err);
                setFetching(false);
                if (err === "Try without login") {
                }
                else {
                    toggleAlert("error", "Error", "Something went wrong. Please try again.");
                }
            });
    }

    const handelClick = () => {
        setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
                data: "",
                setData: "",
                columnId: "",
                tabSelected: "",
            },
        });
    }

    let myTitle = (title, required = true) => (
        <div>
            {required && <span style={{ marginRight: "2px", color: "red" }}>*</span>}
            {/* <img src={TitleIcon} style={imgStyle} alt="image" /> */}
            <span>{title}</span>
        </div>
    );

    const getServiceCategoryId = (type) => {
        let serviceCategoryId = null;
        if (currentProject.serviceCategories) {
            currentProject.serviceCategories.map((temp, idx) => {
                if (temp.type === type) {
                    serviceCategoryId = temp.id
                }
            });

            console.log('serviceCategoryId :', serviceCategoryId);
            return serviceCategoryId;
        }
        else {
            return myAppData.serviceCategory;
        }
    }

    const saveService = () => {
        console.log('myAppData in addService :', myAppData);
        console.log('currentProject in addService :', currentProject);

        let payload = {
            "name": myAppData.name,
            "description": myAppData.description,
            // "service_type": convertToBackend[myAppData.columnId],
            "service_category": getServiceCategoryId(convertToBackend[myAppData.columnId]),
            "service_icon": null,
            "config": {
                ...myAppData,
                account_id: myAppData.accountId,
                repo_url: myAppData.repoUrl,
                repo_name: myAppData.repo,
                is_private: myAppData.isPrivate,
                cpu: myAppData.cpu === "" ? null : parseInt(myAppData.cpu),
                ram: myAppData.ram === "" ? null : parseInt(myAppData.ram),
                is_owner: myAppData.isOwner ? myAppData.isOwner : false,

                app_source_type: myAppData.sourceType === "Git Repositories" ? 1 : 0,
                container_registry_id: myAppData.sourceType === "Git Repositories" ? null : myAppData.containerRegistry.id ? myAppData.containerRegistry.id : null,
                container_registry_type: myAppData.containerRegistry === "PUBLIC" ? 0 : 1,
                repository_name: myAppData.imageRepo,
            },
            "project": currentProject.id
        }

        delete payload.config.id;
        delete payload.config.title;
        delete payload.config.columnId;
        delete payload.config.description;
        delete payload.config.serviceId;
        delete payload.config.serviceCategory;
        delete payload.config.accountId;
        delete payload.config.accountName;
        delete payload.config.repoUrl;
        delete payload.config.repo;
        delete payload.config.isPrivate;
        delete payload.config.created_at;
        delete payload.config.updated_at;
        delete payload.config.isOwner;
        delete payload.config.sourceType;
        delete payload.config.containerRegistry;
        delete payload.config.imageRepo;
        console.log('payload in updateService :', payload);

        // let formData = new FormData();
        // formData.append('data', JSON.stringify(payload));

        api.post(`${process.env.REACT_APP_API_URI}/api/project_env/services/`, payload, /*{
            'Content-Type': 'multipart/form-data',
        }*/)
            .then((res) => {
                let data = res.data;
                console.log("create services data :", data);
                if (data.status) {
                    // update column
                    currentProject.columns[columnId].task_orders.push(currentProject.tasks.length);

                    let myTask = {
                        ...myAppData,
                        id: currentProject.tasks.length,
                        name: data.data.name,
                        title: data.data.name,
                        description: data.data.description,
                        columnId: convertToFrontend[data.data.type],
                        serviceId: data.data.id,
                        serviceCategory: data.data.service_category,
                        accountId: data.data.configuration["account_id"],
                        accountName: data.data.configuration["account_name"],
                        repoUrl: data.data.configuration["repo_url"],
                        repo: data.data.configuration["repo_name"],
                        isOwner: data.data.configuration["is_owner"],
                        isPrivate: data.data.configuration["is_private"],
                        webhook_info: data.data.webhook_info,
                        ...data.data.configuration
                    }

                    delete myTask.account_id;
                    delete myTask.account_name;
                    delete myTask.repo_url;
                    delete myTask.repo_name;
                    delete myTask.is_private;
                    // update tasks
                    currentProject.tasks.push(myTask);

                    setCurrentProject({ ...currentProject, columns: currentProject.columns, tasks: [...currentProject.tasks] });
                    localStorage.setItem("Project", JSON.stringify({ ...currentProject, columns: currentProject.columns, tasks: [...currentProject.tasks] }));
                    let depData = JSON.parse(localStorage.getItem("deploymentData"));
                    if (depData && depData.projectId === currentProject.id) {
                        setDeploymentData({ ...depData, projectId: 0, projectName: "" });
                        localStorage.setItem("deploymentData", JSON.stringify({ ...depData, projectId: 0, projectName: "" }));
                        setSelectedProject({});
                        localStorage.removeItem("selectedProject");
                    }
                    handelClick();
                    toggleAlert("success", "Success", "Saved Service Successfully.");
                    setRefreshProjectData(refreshProjectData => !refreshProjectData);
                }
                else {
                    let err = formatErrors(data.error_details);
                    console.log("Error :", err);
                    toggleAlert("error", "Error", err);
                }
            })
            .catch((err) => {
                console.log("err :", err);
                toggleAlert("error", "Error", "Something went wrong. Please try again.");
            });
    }

    const updateService = () => {
        console.log('myAppData in updateService :', myAppData);
        let payload = {
            "name": myAppData.name,
            // "service_type": convertToBackend[myAppData.columnId],
            "service_category": getServiceCategoryId(convertToBackend[myAppData.columnId]),
            "description": myAppData.description,
            "config": {
                ...myAppData,
                account_id: myAppData.accountId,
                repo_url: myAppData.repoUrl,
                repo_name: myAppData.repo,
                is_private: myAppData.isPrivate,
                cpu: myAppData.cpu === "" ? null : parseInt(myAppData.cpu),
                ram: myAppData.ram === "" ? null : parseInt(myAppData.ram),
                is_owner: myAppData.isOwner ? myAppData.isOwner : false,

                app_source_type: myAppData.sourceType === "Git Repositories" ? 1 : 0,
                container_registry_id: myAppData.sourceType === "Git Repositories" ? undefined : myAppData.containerRegistry.id ? myAppData.containerRegistry.id : null,
                container_registry_type: myAppData.sourceType === "Container Registry" ? myAppData.containerRegistry === "PUBLIC" ? 0 : 1 : undefined,
                repository_name: myAppData.sourceType === "Git Repositories" ? undefined : myAppData.imageRepo,
            }
        }

        console.log("Ram and cpu: ", payload);

        delete payload.config.id;
        delete payload.config.name;
        delete payload.config.columnId;
        delete payload.config.description;
        delete payload.config.serviceId;
        delete payload.config.serviceCategory;
        delete payload.config.accountId;
        delete payload.config.accountName;
        delete payload.config.repoUrl;
        delete payload.config.repo;
        delete payload.config.isPrivate;
        delete payload.config.created_at;
        delete payload.config.updated_at;
        delete payload.config.isOwner;
        delete payload.config.sourceType;
        delete payload.config.containerRegistry;
        delete payload.config.imageRepo;
        console.log('payload in updateService :', payload);

        // let formData = new FormData();
        // formData.append('data', JSON.stringify(payload));
        api.patch(`${process.env.REACT_APP_API_URI}/api/project_env/services/${myAppData.serviceId}/`, payload, {
            'Content-Type': 'multipart/form-data',
        })
            .then((res) => {
                let data = res.data;
                console.log("update in app data :", data);
                if (data.status) {
                    currentProject.tasks.forEach((temp, idx) => {
                        if (temp && (temp.serviceId === myAppData.serviceId)) {
                            currentProject.tasks[idx] = { ...myAppData, webhook_info: data.data.webhook_info };
                        }
                    });
                    console.log('currentProject :', currentProject);
                    setCurrentProject({ ...currentProject });
                    localStorage.setItem("Project", JSON.stringify({ ...currentProject }));
                    let depData = JSON.parse(localStorage.getItem("deploymentData"));
                    if (depData && depData.projectId === currentProject.id) {
                        setDeploymentData({ ...depData, projectId: 0, projectName: "" });
                        localStorage.setItem("deploymentData", JSON.stringify({ ...depData, projectId: 0, projectName: "" }));
                        setSelectedProject({});
                        localStorage.removeItem("selectedProject");
                    }
                    handelClick();
                    toggleAlert("success", "Success", "Updated service successfully.");
                    setRefreshProjectData(refreshProjectData => !refreshProjectData);
                }
                else {
                    let err = formatErrors(data.error_details);
                    console.log("Error :", err);
                    setError({
                        status: true,
                        msg: err
                    });
                    toggleAlert("error", "Error", err);
                }
            })
            .catch((err) => {
                console.log("err :", err);
                if (err === "Try without login") {
                }
                else {
                    // handelClick();
                    setError({
                        status: true,
                        msg: "Something went wrong. Please try again."
                    });
                    toggleAlert("error", "Error", "Something went wrong. Please try again.");
                }
            });
    }

    const saveTasks = () => {
        // console.log('myAppData :', myAppData);
        let id = currentProject.tasks.length;
        myAppData.id = id;
        console.log('id :', id);

        // update column
        currentProject.columns[columnId].task_orders.push(id);
        setCurrentProject({ ...currentProject, columns: currentProject.columns });
        // update tasks
        currentProject.tasks.push(myAppData);
        setCurrentProject({ ...currentProject, tasks: [...currentProject.tasks] });
        localStorage.setItem("Project", JSON.stringify({ ...currentProject, tasks: [...currentProject.tasks] }));
        handelClick();
    }

    const updateTasks = () => {
        currentProject.tasks.forEach((temp, idx) => {
            if (temp && (temp.id === myAppData.id)) {
                currentProject.tasks[idx] = myAppData;
            }
        });
        console.log('currentProject :', currentProject);
        setCurrentProject({ ...currentProject });
        localStorage.setItem("Project", JSON.stringify({ ...currentProject }));
        handelClick();
    }

    function checkNameExists() {
        let listOfName = [];

        currentProject.tasks.map((temp) => {
            if (temp && temp.id != myAppData.id) {
                listOfName.push(temp.name);
            }
        });

        // currentProject.serviceCategories.map((temp) => {
        //     listOfName.push(temp.name);
        // });

        console.log("listOfName :", listOfName);

        if (listOfName.includes(myAppData.name)) {
            return true;
        }
        else {
            return false;
        }
    }

    const handelSaveTask = () => {
        if (showError === "" && !validatingRepo) {
            if (checkNameExists()) {
                console.log("Service Name should be unique. Please Provide another Name.");
                setError({
                    status: true,
                    msg: "Service Name should be unique. Please Provide another Name."
                });
            }
            else if (!serviceNameRegex.test(myAppData.name)) {
                console.log("App Name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character")
                setError({
                    status: true,
                    msg: "App Name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character"
                });
            }
            else if (!myAppData.sourceType || myAppData.sourceType === "") {
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                })
            }
            else if (!myAppData.name || (!myAppData.source && myAppData.sourceType === "Git Repositories")) {
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                })
            }
            else if (myAppData.sourceType === "Container Registry" && Object.keys(myAppData.containerRegistry).length === 0) {
                console.log("Enters1");
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                })
            }
            else if (myAppData.sourceType === "Container Registry" && (myAppData.imageRepo === "" || !myAppData.imageRepo)) {
                console.log("Enters2");
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                })
            }
            else if ((myAppData.source === "public" && myAppData.sourceType === "Git Repositories") && !myAppData.repoUrl) {
                console.log("Enters3");
                console.log("Not repo url...");
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                });
            }
            else if ((myAppData.source !== "public" && myAppData.sourceType === "Git Repositories") && !myAppData.repo) {
                console.log("Enters4");
                console.log("Not repo....", myAppData.source);
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                });
            }
            else if ((myAppData.source == "public" && myAppData.sourceType === "Git Repositories") && (!myAppData.repoUrl.endsWith(".git"))) {
                console.log("Enters5");
                setError({
                    status: true,
                    msg: "Please provide correct Repo Url"
                });
            }
            else if ((myAppData.source == "public" && myAppData.sourceType === "Git Repositories") && (!repoUrlRegex.test(myAppData.repoUrl))) {
                console.log("Enters6");
                setError({
                    status: true,
                    msg: "Please provide correct Repo Url"
                });
            }
            else if (myAppData.source === "public" && myAppData.sourceType === "Git Repositories" && (myAppData.repoUrl.startsWith(" ") || myAppData.repoUrl.endsWith(" "))) {
                console.log("Enters7");
                setError({
                    status: true,
                    msg: "Please remove any leading or trailing spaces from Repo Url"
                });
            }
            else if (myAppData.cpu && /\/d*/.test(myAppData.cpu)) {
                setError({
                    status: true,
                    msg: "Please provide correct CPU."
                });
            }
            else if (myAppData.ram && /\/d*/.test(myAppData.ram)) {
                setError({
                    status: true,
                    msg: "Please provide correct RAM."
                });
            }
            else if (location.pathname.includes("/all-projects/project/")) {
                saveService();
            }
            else {
                saveTasks();
            }
        }
    }

    const handelUpdateTask = () => {
        if (showError === "" && !validatingRepo) {
            // console.log("regex :", serviceNameRegex.test(myAppData.name));
            console.log("myAppData in handelUpdateTask :", myAppData);

            if (checkNameExists()) {
                console.log("Service Name should be unique. Please Provide another Name.");
                setError({
                    status: true,
                    msg: "Service Name should be unique. Please Provide another Name."
                });
            }
            else if (!serviceNameRegex.test(myAppData.name)) {
                console.log("App Name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character")
                setError({
                    status: true,
                    msg: "App Name must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character"
                });
            }
            else if (!myAppData.sourceType || myAppData.sourceType === "") {
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                })
            }
            else if (!myAppData.name || (!myAppData.source && myAppData.sourceType === "Git Repositories")) {
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                })
            }
            else if (myAppData.sourceType === "Container Registry" && Object.keys(myAppData.containerRegistry).length === 0) {
                console.log("Enters1");
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                })
            }
            else if (myAppData.sourceType === "Container Registry" && (myAppData.imageRepo === "" || !myAppData.imageRepo)) {
                console.log("Enters2");
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                })
            }
            else if ((myAppData.source === "public" && myAppData.sourceType === "Git Repositories") && !myAppData.repoUrl) {
                console.log("Enters3");
                console.log("Not repo url...");
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                });
            }
            else if ((myAppData.source !== "public" && myAppData.sourceType === "Git Repositories") && !myAppData.repo) {
                console.log("Enters4");
                console.log("Not repo....", myAppData.source);
                setError({
                    status: true,
                    msg: "Please fill all Fields marked with *"
                });
            }
            else if ((myAppData.source == "public" && myAppData.sourceType === "Git Repositories") && (!myAppData.repoUrl.endsWith(".git"))) {
                console.log("Enters5");
                setError({
                    status: true,
                    msg: "Please provide correct Repo Url"
                });
            }
            else if ((myAppData.source == "public" && myAppData.sourceType === "Git Repositories") && (!repoUrlRegex.test(myAppData.repoUrl))) {
                console.log("Enters6");
                setError({
                    status: true,
                    msg: "Please provide correct Repo Url"
                });
            }
            else if (myAppData.source === "public" && myAppData.sourceType === "Git Repositories" && (myAppData.repoUrl.startsWith(" ") || myAppData.repoUrl.endsWith(" "))) {
                console.log("Enters7");
                setError({
                    status: true,
                    msg: "Please remove any leading or trailing spaces from Repo Url"
                });
            }
            else if (myAppData.cpu && /\/d*/.test(myAppData.cpu)) {
                setError({
                    status: true,
                    msg: "Please provide correct CPU."
                });
            }
            else if (myAppData.ram && /\/d*/.test(myAppData.ram)) {
                setError({
                    status: true,
                    msg: "Please provide correct RAM."
                });
            }
            else if (location.pathname.includes("/all-projects/project/")) {
                updateService();
            }
            else {
                updateTasks();
            }
        }
    }

    const handelChange = (e) => {
        // console.log('e.target.value :', e.target.value);
        // https://github.com/Gautam-SquareOps/test.git
        let val = e.target.value;
        // console.log("e.target.value :", e.target.value);

        if (e.target.name === "cpu" && (val[0] === 0 || e.target.value < 0 || val.length > 10)) {
            return;
        }
        else if (e.target.name === "ram" && (e.target.value < 0 || val.length > 10 || val[0] === 0)) {
            return;
        }
        else {
            setMyAppData({ ...myAppData, [e.target.name]: e.target.value });
            setError({
                status: false,
                msg: ""
            });
        }
    }

    const handleSelectChange = (value, event) => {
        // myClusterData.prod[event.name] = value;
        console.log("event :", event);
        console.log("value :", value);
        if (event.name === "source" && event["data-key"]) {
            setMyAppData({ ...myAppData, [event.name]: event.type, repo: "", repoUrl: "", dockerFilePath: "", accountId: event["data-key"], accountName: event["data-alias"], isPrivate: false });
            // setSelectedAccountId(event["data-key"]);
            // setFetching(true);
            // getRepos(event["data-key"]);
        }
        else if ((event.name === "source") && (event.type === "public")) {
            setMyAppData({ ...myAppData, [event.name]: event.type, repo: "", repoUrl: "", dockerFilePath: "", accountId: "", accountName: "", branch: "", isPrivate: false });
            // setRepoList();
            // setBranchList();
            return;
        }
        else if (event.name === "repo") {
            // getBranches(event.type);
            console.log('event["data-url"] :', event["data-url"]);
            console.log('event["data-isprivate"] :', event["data-isprivate"]);
            setMyAppData({ ...myAppData, [event.name]: event.type, repoUrl: event["data-url"], isPrivate: event["data-isprivate"], isOwner: event["is-owner"] });
        }
        else if (event.type === "integration") {
            handelClick();
            window.open(`${process.env.REACT_APP_GOOGLE_REDIRECT_URI}/integrations`, "_blank");
            return;
        }
        else if (event.name === "sourceType" || event.name === "imageRepo") {
            if (event.name === "sourceType") {
                myAppData.source = "";
                myAppData.containerRegistry = {};
                myAppData.imageRepo = "";
                myAppData.repoUrl = "";
            }
            console.log("Enters sourceType: ", event.name, value);
            setMyAppData({ ...myAppData, [event.name]: value });
        }
        else {
            if (event.name === "containerRegistry") {
                myAppData.imageRepo = "";
            }
            setMyAppData({ ...myAppData, [event.name]: event.type });
        }

        setError({
            status: false,
            msg: ""
        });
    };

    // const handleNumberChange = name => value => {
    //     console.log("name :", name);
    //     console.log("value :", value);
    // }

    const handleSource = (event, value) => {
        console.log("handleSource event.target :", event.target);
        console.log("handleSource event.target.tagName :", event.target.tagName);
        console.log("handleSource :", value);
        // if(event.target?.tagName !== "INPUT"){
        if (!value) {
            setTimeout(() => {
                setOpenAppSourceDropdown(value);
            }, 300);
            // setOpenSource(false);
        }
        else {
            setOpenAppSourceDropdown(!openAppSourceDropdown);
            // setOpenSource(!openSource);
        }
        // }
    }

    const handleAppSourceRefresh = () => {
        console.log("handleAppSourceRefresh :", sourceFetching);
        setSourceFetching(true);
        // setOpenAppSourceDropdown(false);
    }

    const handleAddWorkflow = e => {
        console.log("inside handleAddWorkflow e.target :", e.target);
        console.log("inside handleAddWorkflow data :", data);

        navigate(`/all-projects/project/${currentProject.id}/service/${myAppData.serviceId}/workflow/add`);

        // localStorage.setItem("serviceSelected", JSON.stringify(myAppData.id));
    }

    const onDeleteWorkflow = (data) => {
        setComponentLoading(true);
        setOpenModel({
            modalType: false,
            modalProps: {
                data: null,
            },
        });

        api.delete(`${process.env.REACT_APP_API_URI}/api/pipelines/workflows/delete/${data.id}/`)
            .then((res) => {
                let data = res.data;
                console.log("update in app data :", data);
                if (data.status) {
                    toggleAlert("success", "Success", "Workflow destroyed successfully.");
                    setRefreshProjectData(!refreshProjectData);
                    // set component loading to false after 1s as Project data is taking time.
                    setTimeout(() => {
                        setComponentLoading(false);
                        handelClick();
                    }, 1000);
                }
                else {
                    let err = formatErrors(data.error_details);
                    console.log("Error :", err);
                    toggleAlert("error", "Error", err);
                    setComponentLoading(false);
                    handelClick();
                }
            })
            .catch((err) => {
                console.log("error in deleting workflow :", err);
                toggleAlert("error", "Error", "Something went wrong. Please try again.");
                setComponentLoading(false);
                handelClick();
            });
    }

    const deleteWorkflow = (e, data) => {
        e.stopPropagation();
        console.log("inside deleteWorkflow :", data);
        if((data.environments && data.environments.length > 0 && myAppData.canDeleteWorkflow) || !data.environments || data.environments.length <= 0){
            console.log("inside deleteWorkflow :", data);
            data.title = data.workflowName;
            data.name = data.workflowName;
    
            setOpenModel({
                modalType: 'DELETE_TASKS',
                modalProps: {
                    data: data,
                    title: "Workflow",
                    subTitle: "",
                    subTitle2: `Please provide the name of the workflow to be destroyed`,
                    subTitle3: `PLEASE NOTE: All related workflow data from Atmosly will be destroyed after 7 days, and the workflow record won't be visible in the workflow list.`,
                    onDelete: onDeleteWorkflow,
                    columnId: null,
                },
            });
        }
    }


    function toggleAlert(type, message, description) {
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        // opacity=0 after 10sec
        setTimeout(() => {
            set_alertOptions(alertOptions => ({
                status: false,
                type: type,
                message: message,
                description: description,
            }));
        }, 5 * 1000);
    }

    const filterOption = (input, option) => {
        if (option.key) {
            return (option?.props.type.toLowerCase() ?? '').includes(input.toLowerCase())
        }
    };


    const handleClick = (e, temp) => {
        console.log("handleClick...");
        handelClick();
        navigate(`/all-projects/project/${currentProject.id}/service/${myAppData.serviceId}/workflow/${temp.id}`, {
            state: {
                data: {
                    ...temp,
                    serviceId: myAppData.serviceId
                },
                from: "renderCreateAppForm"
            }
        });
    };

    const validateRepo = () => {
        setValidatingRepo(true);
        setShowError("");
        api.post(process.env.REACT_APP_API_URI + "/api/project_env/projects/repo_url", [{
            "app_name": myAppData.name,
            "repo_url": myAppData.repoUrl
        }]).then((res) => {
            let data = res.data;
            if (data.status) {
                setShowError("");
            }
            else {
                setShowError(data.error_details[myAppData.name]);
            }
            setValidatingRepo(false);
        }).catch((err) => {
            console.log("Error");
        });
    }

    useEffect(() => {
        let timeout;
        if (myAppData.repoUrl !== "" && myAppData.name !== "" && repoUrlRegex.test(myAppData.repoUrl) && myAppData.source === "public") {
            timeout = setTimeout(() => {
                validateRepo();
            }, 500);

            return (() => {
                clearTimeout(timeout);
            });
        }
    }, [myAppData.repoUrl, myAppData.name])

    const retriggerWebhook = () => {
        setOpenModel({
            modalType: "CONFIRM_ACTION",
            modalProps: {
                title: "Confirm Webhook Rebuild ?",
                subTitle: "Are you sure you want to rebuild this webhook? This action will regenerate the webhook, which may affect any active integrations.",
                cancelButton: "Cancel",
                saveButton: "Save",
                onConfirm: () => {
                    api.post(process.env.REACT_APP_API_URI + `/api/project_env/create-webhook/`, {
                        "repo_name": data.source !== "gitlab" ? data.repo : data.repo_id,
                        "vcs_account_id": data.accountId,
                        "project_service_id": data.serviceId
                    }).then((res) => {
                        let data = res.data;
                        if (data.status) {
                            if (data.data.status) {
                                toggleAlert("success", "Success", data.data.message);
                                currentProject.tasks.forEach((temp, idx) => {
                                    if (temp && (temp.serviceId === myAppData.serviceId)) {
                                        currentProject.tasks[idx] = { ...myAppData, webhook_info: "true" };
                                    }
                                });
                                setCurrentProject({ ...currentProject });
                                localStorage.setItem("Project", JSON.stringify({ ...currentProject }))
                            }
                            else {
                                toggleAlert("error", "Error", data.data.message);
                            }
                        }
                        else {
                            toggleAlert("error", "Error", "Something went wrong. Please try again.");
                        }
                    }).catch((err) => {
                        toggleAlert("error", "Error", "Something went wrong. Please try again.");
                    }).finally(() => {
                        handelClick();
                        setOpenModel({
                            modalType: '',
                            modalProps: {
                                data: null,
                                columnId: null,
                            },
                        });
                    });
                },
                onCancel: () => {
                    setOpenModel({
                        modalType: '',
                        modalProps: {
                            data: null,
                            columnId: null,
                        },
                    });
                }
            }
        });
    }


    return (
        <>
            {/* <Alert
                message={alertOptions.message}
                description={alertOptions.description}
                type={alertOptions.type}
                showIcon
                style={alertOptions.status ? marginsBot : fadeMarginBot}
            /> */}
            <CustomModal
                visible={componentLoading}
                //onCancel={hideModal}
                footer={null}
                closable={false}
                centered
                maskClosable={false}
                style={{ border: "none", boxShadow: "none", zIndex: componentLoading ? 1000 : -1 }}
            >
                <div style={{ textAlign: 'center', background: "transparent", border: "none" }}>
                    <Spin size="large" />
                </div>
            </CustomModal>

            <Form style={{ marginTop: "100px" }}>
                <CreateTaskHeader
                    buttonContent="Apps"
                    editing={editing}
                    values={values}
                    onCancel={onCancel}
                    onDelete={onDelete}
                    onEditCancel={onEditCancel}
                    onSave={handelSaveTask}
                    onUpdate={handelUpdateTask}
                />
                {editing && !data.webhook_info && data.app_source_type === 1 && data.source !== "PUBLIC" && <CustomAlert
                    showIcon
                    type="warning"
                    message="Attention"
                    description={
                        <div style={{ display: "flex", flexDirection: "column", height: "60px" }}>
                            <p>CI/CD for automatic triggers wont work for this service. Click below to recreate webhook.</p>
                            <div style={{ position: "absolute", top: "80px", right: "20px" }}>
                                <Button style={{
                                    border: "1px solid #163247",
                                    background: "transparent",
                                    color: "#163247",
                                    height: "30px",
                                    width: "101px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }} onClick={() => {
                                    retriggerWebhook();
                                }}>
                                    <img src={retriggerIcon} style={{ height: "14px", marginRight: "5px" }} />
                                    <span>Recreate</span>
                                </Button>
                            </div>
                        </div>}
                    closable
                    style={{ margin: "20px" }}
                />}
                <div style={{ padding: "0 20px" }}>
                    <Box
                        title={"App Name*"}
                        subtitle={"Enter the name of the application service (e.g., Frontend, Backend)."}
                        style={boxStyle}
                        titleStyle={titleStyle}
                        subtitleStyle={subtitleStyle}
                        imgStyle={imgStyle}
                    >
                        <Input
                            name="name"
                            value={myAppData.name}
                            onChange={handelChange}
                            placeholder="App Name"
                            style={inputStyle}
                        />
                    </Box>

                    <Box
                        title={"Application Source Type*"}
                        subtitle={"Specify the source or origin of the application service code (e.g., Git repository URL)."}
                        style={{ ...boxStyle, position: "relative" }}
                        titleStyle={titleStyle}
                        subtitleStyle={subtitleStyle}
                    >
                        <Select
                            className="customSelectDataSource"
                            style={{ width: '100%' }}
                            value={myAppData.sourceType}
                            // open={openSource}
                            onChange={handleSelectChange}
                        // onClick={(e) => handleSource(e, true)}
                        // onBlur={(e) => handleSource(e, false)}
                        >
                            {sourceList}
                        </Select>
                    </Box>

                    {myAppData.sourceType === "Git Repositories" && <Box
                        title={"Git Source*"}
                        subtitle={"Select git source here"}
                        style={{ ...boxStyle, position: "relative" }}
                        titleStyle={titleStyle}
                        subtitleStyle={subtitleStyle}
                        imgStyle={imgStyle}
                    >
                        {console.log("My app data: ", myAppData)}
                        <Select
                            className="customSelectDataSource"
                            style={{ width: '100%' }}
                            value={myAppData.source ?
                                `${myAppData.source.toUpperCase()}${myAppData.accountName ? ` (${myAppData.accountName})` : ""}`
                                :
                                ""
                            }
                            // open={openSource}
                            onChange={handleSelectChange}
                            onClick={(e) => handleSource(e, true)}
                            onBlur={(e) => handleSource(e, false)}
                        >
                            {sourceFetching ?
                                <Option>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                        <Spin size="small" />
                                    </div>
                                </Option>
                                :
                                accountsConnected
                            }
                        </Select>
                        {openAppSourceDropdown && <img src={refreshIcon} style={{ position: "absolute", top: "10px", right: "0", cursor: "pointer" }} onClick={handleAppSourceRefresh} />}
                    </Box>}

                    {myAppData.sourceType === "Container Registry" &&
                        <>
                            <Box
                                title={"Container Registry*"}
                                subtitle={"Provide container registry here"}
                                style={{ ...boxStyle, position: "relative" }}
                                titleStyle={titleStyle}
                                subtitleStyle={subtitleStyle}
                            >
                                <Select
                                    className="customSelectDataSource"
                                    style={{ width: '100%' }}
                                    value={myAppData.containerRegistry === "PUBLIC" ? myAppData.containerRegistry : ((!myAppData.containerRegistry || Object.keys(myAppData.containerRegistry).length === 0) ? null : !myAppData.containerRegistry.account_id ? `${myAppData.containerRegistry.alias} - ${myAppData.containerRegistry.integration_type}` : `${myAppData.containerRegistry.account_id}${myAppData.containerRegistry.alias ? `(${myAppData.containerRegistry.alias})` : ``} / ${myAppData.containerRegistry.region}`)}
                                    // open={openSource}
                                    onChange={(value, event) => {
                                        console.log('value :', value);
                                        if (value !== "PUBLIC") {
                                            containerRegs.map((val) => {
                                                console.log('val :', val);
                                                console.log('val.alias :', val.alias);
                                                console.log('value :', value);
                                                console.log('event[data-id] :', event["data-id"]);
                                                if (val.id && event["data-id"] === val.id) {
                                                    // console.log('val :', val);
                                                    setMyAppData(myAppData => ({
                                                        ...myAppData,
                                                        containerRegistry: val,
                                                        imageRepo: ""
                                                    }));
                                                    setImageRepoLoading(true);
                                                }
                                            })
                                        }
                                        else {
                                            setMyAppData(myAppData => ({
                                                ...myAppData,
                                                containerRegistry: value,
                                                imageRepo: ""
                                            }));
                                        }
                                    }}                                // onClick={(e) => handleSource(e, true)}
                                // onBlur={(e) => handleSource(e, false)}
                                >
                                    {containerRegistryList}
                                </Select>
                            </Box>
                            {Object.keys(myAppData.containerRegistry).length > 0 && <Box
                                title={"Image Repo*"}
                                subtitle={"Provide repo here"}
                                style={{ ...boxStyle, position: "relative" }}
                                titleStyle={titleStyle}
                                subtitleStyle={subtitleStyle}
                            >
                                {myAppData.containerRegistry !== "PUBLIC" ? <Select
                                    className="customSelectDataSource"
                                    style={{ width: '100%' }}
                                    value={myAppData.imageRepo}
                                    showSearch
                                    filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                    filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                    onSearch={(value) => {
                                        console.log("Enters search", value);
                                        setSearchValue(value);
                                    }}
                                    // open={openSource}
                                    onChange={handleSelectChange}
                                    notFoundContent={imageRepoLoading && <Spin spinning={imageRepoLoading} />}
                                // onClick={(e) => handleSource(e, true)}
                                // onBlur={(e) => handleSource(e, false)}
                                >
                                    {imageRepoList}
                                </Select> :

                                    <Input name="imageRepo" value={myAppData.imageRepo} onChange={handelChange} />}
                            </Box>}
                        </>}

                    {myAppData.source && <>
                        {myAppData.source === "public" && myAppData.sourceType === "Git Repositories" && <Box
                            title={
                                <div style={{ display: "flex" }}>
                                    <span>Public Repo Url*</span>
                                    <CustomInfoTooltip title={"If a monrepo please add each of your application service individually with the same repo."} placement={"bottomLeft"} style={{ marginLeft: "10px" }} />
                                </div>
                            }
                            subtitle={"Repo Url Details"}
                            style={boxStyle}
                            titleStyle={titleStyle}
                            subtitleStyle={subtitleStyle}
                            imgStyle={imgStyle}
                        >
                            <Input
                                name="repoUrl"
                                value={myAppData.repoUrl}
                                onChange={handelChange}
                                placeholder="Repo Url"
                                style={inputStyle}
                                suffix={validatingRepo && <span style={{ marginRight: "15px" }}><LoadingOutlined /></span>}
                            // onBlur={getPublicBranches}
                            />
                            {showError !== "" && <p className='isoHelperText'>{showError}</p>}
                        </Box>}

                        {myAppData.source != "public" && myAppData.sourceType === "Git Repositories" && <Box
                            title={"Repository*"}
                            subtitle={"Provide the URL or details of the code repository for the application service."}
                            style={boxStyle}
                            titleStyle={titleStyle}
                            subtitleStyle={subtitleStyle}
                            imgStyle={imgStyle}
                        >
                            <Select
                                showSearch
                                filterSort={(optionA, optionB) => customSortOptions(optionA, optionB, searchValue)} // Custom sorting function
                                filterOption={(inputValue, option) => customFilterOption(inputValue, option)} // Custom filtering function
                                onSearch={(value) => {
                                    console.log("Enters search", value);
                                    setSearchValue(value);
                                }}
                                style={{ width: '100%' }}
                                value={myAppData.repo}
                                className="customSelectDataSource"
                                onChange={handleSelectChange}
                                optionLabelProp={myAppData.repo}
                            //filterOption={filterOption}
                            >
                                {fetching ?
                                    <Option>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                            <Spin size="small" />
                                        </div>
                                    </Option>
                                    :
                                    repoList
                                }
                            </Select>
                        </Box>}
                    </>}

                    <Box
                        title={"Min. CPU"}
                        subtitle={"Define the minimum CPU resources required for the application service."}
                        style={boxStyle}
                        titleStyle={titleStyle}
                        subtitleStyle={subtitleStyle}
                        imgStyle={imgStyle}
                    >
                        <Input
                            type="number"
                            name="cpu"
                            className="customInputBoxOnlyNumbers"
                            value={myAppData.cpu}
                            onChange={handelChange}
                            placeholder="100"
                            style={inputStyle}
                            suffix={"m"}
                        />
                    </Box>

                    <Box
                        title={"Min. RAM"}
                        subtitle={"Define the minimum RAM resources required for the application service"}
                        style={boxStyle}
                        titleStyle={titleStyle}
                        subtitleStyle={subtitleStyle}
                        imgStyle={imgStyle}
                    >
                        {/* <InputNumber
                            name="ram"
                            min={0}
                            max={100000}
                            placeholder="500"
                            style={{ ...inputStyle, padding: "4px 0", verticalAlign: "center", width: "100%" }}
                            value={myAppData.ram}
                            onChange={handleNumberChange}
                        /> */}
                        <Input
                            type="number"
                            name="ram"
                            className="customInputBoxOnlyNumbers"
                            value={myAppData.ram}
                            onChange={handelChange}
                            placeholder="500"
                            style={inputStyle}
                            suffix={"mi"}
                        />
                    </Box>

                    <Box
                        title={"Description"}
                        subtitle={"Provide a detailed description of the application service."}
                        style={{ ...boxStyle, marginBottom: data.serviceId ? "10px" : "150px" }}
                        titleStyle={titleStyle}
                        subtitleStyle={subtitleStyle}
                        imgStyle={imgStyle}
                    >
                        <Textarea
                            name="description"
                            value={myAppData.description}
                            onChange={handelChange}
                            placeholder="Add a more detailed description..."
                            style={textAreaStyle}
                        />

                        {error.status && <p ref={targetRef} className="isoHelperText">
                            <span>{error.msg}</span>
                        </p>}
                    </Box>

                    {data.serviceId && <>
                        <HrBar style={{ height: "2px", borderColor: "#ebebeb", marginTop: "50px", marginBottom: "34px" }} />

                        <Box
                            title={"Workflows list"}
                            // subtitle={"Click a workflow to see more details"}
                            style={{ ...boxStyle, marginBottom: "10px" }}
                            titleStyle={titleStyle}
                            subtitleStyle={subtitleStyle}
                            imgStyle={imgStyle}
                        />

                        <TaskDescription style={{ display: "inline-grid", gridTemplateColumns: "auto auto", gap: "30px", marginTop: "10px", marginBottom: "10px", width: "100%", marginBottom: "150px" }}>
                        {envList.length > 0 &&
                                <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0", position: "relative", height: "fit-content", margin: "0", width: "290px", height: "132px" }} onClick={handleAddWorkflow}>
                                    <ContentHolder
                                        style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "20px 16px" }}
                                    >
                                        <Box
                                            title=""
                                            subtitle=""
                                            style={{ border: "transparent", padding: "0", marginBottom: "0" }}
                                            titleStyle={innerTitleStyle}
                                            subTitleStyle={innerSubtitleStyle}
                                        >
                                            <div className='addWorkflow' style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%", height: "100%", padding: "25px 0px" }}>
                                                <img src={plusIcon} style={{ cursor: "pointer", width: "25px", height: "25px" }} alt="add" />
                                                <span
                                                    style={{
                                                        fontFamily: "Poppins",
                                                        fontSize: "12px",
                                                        fontWeight: "500px",
                                                        color: "#132f44",
                                                        cursor: "pointer",
                                                        marginTop: "6px"
                                                    }}
                                                // onClick={handleAddWorkflow}
                                                >Add Workflow</span>
                                            </div>
                                        </Box>
                                    </ContentHolder>
                                </Box>
                            }
                            {myAppData?.workflows?.map((temp, idx) => (
                                // <div>{temp.workflowName}</div>
                                <Box style={{ borderRadius: "10px", cursor: "pointer", padding: "0", position: "relative", height: "fit-content", margin: "0", width: "290px", height: "132px" }} id={idx} onClick={(e) => handleClick(e, temp)}>
                                    <ContentHolder
                                        style={{ display: "flex", alignItems: "start", cursor: "pointer", marginTop: "0", position: "relative", padding: "20px 16px" }}
                                        id={idx}
                                    >
                                        <Box
                                            title={temp.workflowName ? temp.workflowName : "-"}
                                            subtitle=""
                                            style={{ border: "transparent", padding: "0", paddingLeft: "15px", marginBottom: "0" }}
                                            titleStyle={innerTitleStyle}
                                            subTitleStyle={innerSubtitleStyle}
                                            id={idx}
                                        >
                                            <div style={{ display: "flex", justifyContent: "start", alignItems: "center", marginTop: "23px", fontSize: "12px", fontFamily: "Nunito Sans", color: "#c5c5c5" }}>
                                                <img src={workflowEnvIcon} style={{ marginRight: "6px", width: "23px", height: "23px" }} alt="env_name" />
                                                <span>{temp.environments ? (temp.environments[0]?.environment_name ? temp.environments[0].environment_name : "-") : "-"}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "start", alignItems: "center", marginTop: "5px", fontSize: "12px", fontFamily: "Nunito Sans", color: "#c5c5c5" }}>
                                                <img src={UserIcon} style={{ marginRight: "6px", width: "23px", height: "23px" }} alt="created_by" />
                                                <span>{temp.createdBy ? temp.createdBy : "-"}</span>
                                            </div>
                                        </Box>
                                        <img src={RemoveIcon} style={{ cursor: "pointer", height: "26px", transform: "translate(0px, -6px)" }} alt="details" onClick={(e) => { deleteWorkflow(e, temp) }} />
                                    </ContentHolder>
                                </Box>
                            ))}
                        </TaskDescription>
                    </>}

                    {myAppData && myAppData.workflows && myAppData.workflows.length > 0 && envList.length <= 0 && <HrBar style={{ height: "10px", borderColor: "none", margin: "70px 0px" }} />}


                </div>

                <CreateTaskFooter buttonContent={"Apps"} onCancel={onCancel} editing={editing} onSave={handelSaveTask} onUpdate={handelUpdateTask} onDelete={onDelete} disabled={showError !== "" || validatingRepo} />
            </Form>
        </>
    )
};
