import React from 'react';
import BoxTitleWrapper from './boxTitle';
import { BoxWrapper } from './box.style';

export default props => (
  <BoxWrapper
    className={`${props.className ? props.className : ''} isoBoxWrapper`}
    style={props.style}
    onClick={props.onClick && props.onClick}
    id={props.id && props.id}
  >
    <BoxTitleWrapper id={props.id && props.id} onClick={props.onClick && props.onClick} className={props.tooltip ? "tooltip" : ""} title={props.title} subtitle={props.subtitle} subtitle2={props.subtitle2}  titleStyle={props.titleStyle} subTitleStyle={props.subTitleStyle} subTitle2Style={props.subTitle2Style} tootipText={props.tooltip ? "tooltiptext" : ""} />
    {props.children}
  </BoxWrapper>
);
