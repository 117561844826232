import React, { Fragment, useState } from 'react';
// import { connect } from 'react-redux';
// import { Badge, Row, Col, Tooltip } from 'antd';
// import moment from 'moment';
// import Comments from '@iso/components/ScrumBoard/Comments/Comments';
import HeadingWithIcon from '@iso/components/ScrumBoard/HeadingWithIcon/HeadingWithIcon';
// import { IconSvg } from '@iso/components/ScrumBoard/IconSvg/IconSvg';
import CardDetailsHeader from './TaskDetailsHeader';
import Select, { SelectOption } from '@iso/components/uielements/select';
import {
  CardDetailsWrapper,
  AttachmentWrapper,
  TaskName,
  TaskDescription,
  ClockIcon,
} from '../Task.style';
// import PlusIcon from '@iso/assets/images/icon/24.svg';
import TitleIcon from '@iso/assets/images/icon/05-icon.svg';
import DescriptionIcon from '@iso/assets/images/icon/06-icon.svg';
// import AttachmentIcon from '@iso/assets/images/icon/01-icon.svg';
// import Clock from '@iso/assets/images/icon/17.svg';
// import scrumBoardActions from '@iso/redux/scrumBoard/actions';
import { useContext } from 'react';
import { AppContext } from '../../../../context/ContextProvider';
import initialData from '../../../../redux/scrumBoard/data';
import { useLocation } from 'react-router';
import api from '../../../../api';
import { DeploymentContext } from '../../../../context/deploymentContext';

const Option = SelectOption;


const TaskDetials = ({
  data,
  editing,
  deleteTaskWatcher,
  editTask,
  closeDrawer,
  openDrawer,
  columnId,
}) => {

  let { columns } = initialData;
  let { convertToBackend, currentProject, setCurrentProject, setOpenModel, openRightDrawer, setOpenRightDrawer, currentColumns, setCurrentColumns, currentTasks, setCurrentTasks } = useContext(AppContext);

  const deleteName = (columnId === "app") ? "Application" : (columnId === "dataSource") ? "Data Source" : (columnId === "queues") ? "Queue" : "Third Party";
  const { deploymentData, setDeploymentData, setSelectedProject } = useContext(DeploymentContext);

  const [myTask, setMyTask] = useState({ ...data });
  const location = useLocation();

  const children = ["1.2", "1.3", "1.4", "1.5"];
  children.map((child, ind) => {
    children.push(<Option key={child} name="version" value={child}>{child}</Option>);
  });

  const handelSelectChange = (value, event) => {
    myTask[event.name] = value;
    setMyTask({ ...myTask });
  };


  // const deleteTask = () => {
  //   const index = currentProject.columns[columnId].task_orders.indexOf(data.id);
  //   console.log('index :', index);
  //   if (index > -1) { // only splice array when item is found
  //     currentProject.columns[columnId].task_orders.splice(index, 1); // 2nd parameter means remove one item only
  //     delete currentProject.tasks[data.id];
  //     setCurrentProject({...currentProject});
  //     setCurrentProject({...currentProject});
  //   }

  //   setOpenRightDrawer({
  //     drawerType: false,
  //     drawerProps: {
  //       initials: "",
  //       columnId: "",
  //     },
  //   });

  //   setOpenModel({
  //     modalType: false,
  //     modalProps: {
  //       data: ""
  //     },
  //   });
  // }

  const updateService = () => {
    console.log('myTask in updateService :', myTask);
    let payload = {
      "name": myTask.name,
      "service_type": convertToBackend[columnId],
      "config": {
        ...myTask
      }
    }

    delete payload.config.id;
    delete payload.config.name;
    delete payload.config.columnId;
    delete payload.config.description;
    delete payload.config.serviceId;
    console.log('payload in updateService :', payload);

    api.patch(`${process.env.REACT_APP_API_URI}/api/project_env/services/${myTask.serviceId}/`, payload)
      .then(async (res) => {
        let data = await res.json();
        console.log("update dataSource/queues :", data);
        console.log("currentProject.tasks :", currentProject.tasks);
        if (data.status) {
          currentProject.tasks.forEach((temp, idx) => {
            if (temp && (temp.serviceId === data.data.id)) {
              currentProject.tasks[idx] = myTask;
            }
          });
          console.log('currentProject :', currentProject);
          let depData = JSON.parse(localStorage.getItem("deploymentData"));
          if (depData && depData.projectName === depData.name) {
            setDeploymentData({ ...depData, projectId: 0, projectName: "" });
            localStorage.setItem("deploymentData", JSON.stringify({ ...depData, projectId: 0, projectName: "" }));
            setSelectedProject({});
            localStorage.removeItem("selectedProject");
          }
          setCurrentProject({ ...currentProject });

          setOpenRightDrawer({
            drawerType: false,
            drawerProps: {
              initials: "",
              columnId: "",
            },
          });
        }
        else {
          if (typeof data.msg === 'object') {
            data.msg = data.msg[Object.keys(data.msg)[0]][0];
            console.log('data.msg :', data.msg);
          }
          alert(`Error : ${data.msg}`);
        }
      })
      .catch((err) => {
        console.log("err :", err);
        alert(err.msg);
      });
  }

  const updateTask = () => {
    currentProject.tasks.forEach((temp, idx) => {
      if (temp && (temp.id === myTask.id)) {
        currentProject.tasks[idx] = myTask;
      }
    });
    console.log('currentProject :', currentProject);
    setCurrentProject({ ...currentProject });

    setOpenRightDrawer({
      drawerType: false,
      drawerProps: {
        initials: "",
        columnId: "",
      },
    });
  }

  const handelUpdateTask = () => {
    if (location.pathname.includes("/all-projects/project/")) {
      updateService();
    }
    else {
      updateTask();
    }
  }

  return (
    <CardDetailsWrapper>
      <CardDetailsHeader
        editing={editing}
        onBtnClick={handelUpdateTask}
        onIconClick={closeDrawer}
        columnId={columnId}
        // onDelete={deleteTask}
        openDeleteModel={() => {
          setOpenModel({
            modalType: 'DELETE_TASKS',
            modalProps: {
              data: data,
              title: deleteName.toUpperCase(),
              subTitle: `Please provide the name of the ${deleteName} to be deleted`,
              // onDelete: deleteTask,
              columnId: columnId,
            },
          });
        }}
      />
      <div style={{ padding: "0 20px", marginTop: "30px" }}>
        <HeadingWithIcon heading="App Name" iconSrc={TitleIcon} />
        <TaskName>{data.name}</TaskName>

        {columnId === "app" && <>
          {/* <div style={{ clear: 'both', paddingTop: '30px' }}>
            <HeadingWithIcon heading="Docker File Path" iconSrc={DescriptionIcon} />
            <TaskDescription>{data.dockerFilePath}</TaskDescription>
          </div> */}
          <div style={{ clear: 'both', paddingTop: '30px' }}>
            <HeadingWithIcon heading="App Source" iconSrc={DescriptionIcon} />
            <TaskDescription>{data.source}</TaskDescription>
          </div>
          <div style={{ clear: 'both', paddingTop: '30px' }}>
            <HeadingWithIcon heading="Description" iconSrc={DescriptionIcon} />
            <TaskDescription>{data.description}</TaskDescription>
          </div>
        </>}
        {columnId === "dataSource" && <>
          <div style={{ clear: 'both', paddingTop: '30px' }}>
            <HeadingWithIcon heading="Version" iconSrc={DescriptionIcon} />
            <Select
              style={{ width: '400px' }}
              defaultValue={data.version}
              onChange={handelSelectChange}
            >
              {children}
            </Select>
          </div>
        </>}
        {columnId === "queues" && <>
          <div style={{ clear: 'both', paddingTop: '30px' }}>
            <HeadingWithIcon heading="Version" iconSrc={DescriptionIcon} />
            <Select
              style={{ width: '400px' }}
              defaultValue={data.version}
              onChange={handelSelectChange}
            >
              {children}
            </Select>
          </div>
        </>}
        {columnId === "thirdParties" && <>
          {/* <div style={{ clear: 'both', paddingTop: '30px' }}>
            <HeadingWithIcon heading="Third Party Name" iconSrc={DescriptionIcon} />
            <TaskDescription>{data.tpName}</TaskDescription>
          </div> */}
          <div style={{ clear: 'both', paddingTop: '30px' }}>
            <HeadingWithIcon heading="Url" iconSrc={DescriptionIcon} />
            <TaskDescription>{data.url}</TaskDescription>
          </div>
          <div style={{ clear: 'both', paddingTop: '30px' }}>
            <HeadingWithIcon heading="Description" iconSrc={DescriptionIcon} />
            <TaskDescription>{data.description}</TaskDescription>
          </div>
        </>}
      </div>
    </CardDetailsWrapper>
  );
};

export default TaskDetials;
