const initialData = {
  tasks: [
    {
      id: 0,
      name: "App-1",
      branch: "",
      description: 'Take Out the garbage 1',
      source: "GitHub",
      dockerFilePath: "docker/file/path",
      repoUrl: "https://github.com",
      columnId: 'app',
      editing: false,
      created_at: '2019-01-01',
      updated_at: '',
    },
    {
      id: 1,
      name: "Mysql",
      dataSource: "Mysql",
      version: "",
      serviceType: "",
      instanceType: "",
      // dockerFilePath: "docker/file/path",
      // description: 'Let Fix The Task manager using Redux 2',
      columnId: 'dataSource',
      editing: false,
      created_at: '2019-01-01',
      updated_at: '',
    },
    {
      id: 2,
      name: "Redis",
      dataSource: "Redis",
      version: "",
      serviceType: "",
      instanceType: "",
      columnId: 'dataSource',
      editing: false,
      created_at: '2019-01-01',
      updated_at: '',
    },
    {
      id: 3,
      name: "Elastic Search",
      dataSource: "Elastic Search",
      version: "",
      serviceType: "",
      instanceType: "",
      columnId: 'dataSource',
      editing: false,
      created_at: '2019-01-01',
      updated_at: '',
    },
    {
      id: 4,
      name: "Kafka",
      queue: "Kafka",
      columnId: 'queues',
      editing: false,
      created_at: '2019-01-01',
      updated_at: '',
    },
    {
      id: 5,
      name: "RabitMq",
      queue: "RabitMq",
      columnId: 'queues',
      editing: false,
      created_at: '2019-01-01',
      updated_at: '',
    },
    {
      id: 6,
      name: "Google Analitics",
      // tpName: "Google Analitics",
      url: "http://google.com/",
      description: "This is my third party description",
      columnId: 'thirdParties',
      editing: false,
      created_at: '2019-01-01',
      updated_at: '',
    },
  ],
  columns: {
    'app': {
      id: 'app',
      board_id: 'board-1',
      task_orders: [0],
      title: 'APPLICATIONS',
      editing: false,
    },
    'dataSource': {
      id: 'dataSource',
      board_id: 'board-1',
      task_orders: [3, 1, 2],
      title: 'DATA SOURCES',
      editing: false,
    },
    // 'queues': {
    //   id: 'queues',
    //   board_id: 'board-2',
    //   task_orders: [4, 5],
    //   title: 'QUEUES',
    //   editing: false,
    // },
    // 'thirdParties': {
    //   id: 'thirdParties',
    //   board_id: 'board-1',
    //   task_orders: [6],
    //   title: 'THIRD PARTIES',
    //   editing: false,
    // },
  },
  boards: {
    'board-1': {
      id: 'board-1',
      column_orders: ['column-1', 'column-3'],
      title: 'Isomorphic',
      category: 'Software',
      progress: 50,
      thumb: '',
      open_to_members: false,
      open_to_company: false,
      estimated_time: '',
      editing: false,
      created_at: '2019-01-10',
      updated_at: '',
    },
    'board-2': {
      id: 'board-2',
      column_orders: ['column-2'],
      title: 'Headless',
      category: 'OPs',
      progress: 70,
      thumb: '',
      open_to_members: true,
      open_to_company: true,
      estimated_time: '',
      editing: false,
      created_at: '2019-02-01',
      updated_at: '',
    },
    'board-3': {
      id: 'board-3',
      column_orders: [],
      title: 'React Next Landing',
      category: 'Service Desk',
      progress: 30,
      thumb: '',
      open_to_members: false,
      open_to_company: true,
      estimated_time: '',
      editing: false,
      created_at: '2020-12-03',
      updated_at: '',
    },
  },
  labels: {
    'label-1': {
      id: 'label-1',
      title: 'default',
      color: 'red',
      created_at: '',
      updated_at: '',
      editing: false,
    },
    'label-2': {
      id: 'label-2',
      title: 'processing',
      color: 'red',
      created_at: '',
      updated_at: '',
      editing: false,
    },
    'label-3': {
      id: 'label-3',
      title: 'warning',
      color: 'red',
      created_at: '',
      updated_at: '',
      editing: false,
    },
    'label-4': {
      id: 'label-4',
      title: 'success',
      color: 'red',
      created_at: '',
      updated_at: '',
      editing: false,
    },
    'label-5': {
      id: 'label-5',
      title: 'error',
      color: 'red',
      created_at: '',
      updated_at: '',
      editing: false,
    },
  },
  clusters: [
    {
      id: 1,
      title: "My First Cluster",
      accountId: "8851895743",
      alias: "8851895743",
      accountType: "aws",
      region: "us-east-1",
      prod: {
        tags: [{ name: "", value: "" }],
        naz: [],
        vpcCidr: "",
        flowLogMax: "60",
        logRetention: "60",
        clusterLogRetention: "90",
        eks: "",
        serverInsType: "",
        backups: false,
        natGatway: true,
        vnps: true,
        vpcFlowLogs: true,
      },
      dev: {
        backups: true
      },
      "non-prod": {
        backups: true
      }
    }
  ],
  regions: {
    "aws": {
      "us-east-1": {
        name: "US East (N. Virginia)",
        azs: [
          "us-east-1a",
          "us-east-1b",
          "us-east-1c",
          "us-east-1d",
          "us-east-1e",
          "us-east-1f",
        ]
      },
      "us-east-2": {
        name: "US East (Ohio)",
        azs: [
          "us-east-2a",
          "us-east-2b",
          "us-east-2c",
          "us-east-2d",
          "us-east-2e",
        ]
      },
      "us-west-1": {
        name: "US West (N. California)",
        azs: [
          "us-west-1a",
          "us-west-1b",
          "us-west-1c",
        ]
      },
      "us-west-2": {
        name: "US West (Oregon)",
        azs: [
          "us-west-2a",
          "us-west-2b",
          "us-west-2c",
          "us-west-2d",
          "us-west-2e",
          "us-west-2f",
        ]
      },
      "af-south-1": {
        name: "Africa (Cape Town)",
        azs: [
          "af-south-1a",
          "af-south-1b",
          "af-south-1c"
        ]
      },
      "ap-east-1": {
        name: "Asia Pacific (Hong Kong)",
        azs: [
          "ap-east-1a",
          "ap-east-1b",
          "ap-east-1c",
        ]
      },
      "ap-south-1": {
        name: "Asia Pacific (Mumbai)",
        azs: [
          "ap-south-1a",
          "ap-south-1b",
          "ap-south-1c",
        ]
      },
      "ap-south-2": {
        name: "Asia Pacific (Hyderabad)",
        azs: [
          "ap-south-2a",
        ]
      },
      "ap-southeast-3": {
        name: "Asia Pacific (Jakarta)",
        azs: [
          "ap-southeast-3a",
          "ap-southeast-3b",
          "ap-southeast-3c",
        ]
      },
      "ap-southeast-4": {
        name: "Asia Pacific (Melbourne)",
        azs: [
          "ap-southeast-4a",
          "ap-southeast-4b",
          "ap-southeast-4c",
        ]
      },
      "ap-northeast-1": {
        name: "Asia Pacific (Tokyo)",
        azs: [
          "ap-northeast-1a",
          "ap-northeast-1b",
          "ap-northeast-1c",
          "ap-northeast-1d",
        ]
      },
      "ap-northeast-2": {
        name: "Asia Pacific (Seoul)",
        azs: [
          "ap-northeast-2a",
          "ap-northeast-2b",
          "ap-northeast-2c",
          "ap-northeast-2d",
        ]
      },
      "ap-northeast-3": {
        name: "Asia Pacific (Osaka)",
        azs: [
          "ap-northeast-3a",
          "ap-northeast-3b",
          "ap-northeast-3c",
        ]
      },
      "ap-southeast-1": {
        name: "Asia Pacific (Singapore)",
        azs: [
          "ap-southeast-1a",
          "ap-southeast-1b",
          "ap-southeast-1c",
        ]
      },
      "ap-southeast-2": {
        name: "Asia Pacific (Sydney)",
        azs: [
          "ap-southeast-2a",
          "ap-southeast-2b",
          "ap-southeast-2c",
        ]
      },
      "ca-central-1": {
        name: "Canada (Central)",
        azs: [
          "ca-central-1a",
          "ca-central-1b",
          "ca-central-1c",
        ]
      },
      "eu-central-1": {
        name: "Europe (Frankfurt)",
        azs: [
          "eu-central-1a",
          "eu-central-1b",
          "eu-central-1c",
        ]
      },
      "eu-central-2": {
        name: "Europe (Zurich)",
        azs: [
          "eu-central-2a",
          "eu-central-2b",
          "eu-central-2c",
        ]
      },
      "eu-west-1": {
        name: "Europe (Ireland)",
        azs: [
          "eu-west-1a",
          "eu-west-1b",
          "eu-west-1c",
        ]
      },
      "eu-west-2": {
        name: "Europe (London)",
        azs: [
          "eu-west-2a",
          "eu-west-2b",
          "eu-west-2c",
        ]
      },
      "eu-west-3": {
        name: "Europe (Paris)",
        azs: [
          "eu-west-3a",
          "eu-west-3b",
          "eu-west-3c",
        ]
      },
      "eu-south-1": {
        name: "Europe (Milan)",
        azs: [
          "eu-south-1a",
          "eu-south-1b",
          "eu-south-1c",
        ]
      },
      "eu-south-2": {
        name: "Europe (Spain)",
        azs: [
          "eu-south-2a",
          "eu-south-2b",
          "eu-south-2c",
        ]
      },
      "eu-north-1": {
        name: "Europe (Stockholm)",
        azs: [
          "eu-north-1a",
          "eu-north-1b",
          "eu-north-1c",
        ]
      },
      "il-central-1": {
        name: "Israel (Tel Aviv)",
        azs: [
          "il-central-1a",
          "il-central-1b",
        ]
      },
      "me-south-1": {
        name: "Middle East (Bahrain)",
        azs: [
          "me-south-1a",
          "me-south-1b",
          "me-south-1c",
        ]
      },
      "me-central-1": {
        name: "Middle East (UAE)",
        azs: [
          "me-central-1a",
          "me-central-1b",
          "me-central-1c",
        ]
      },
      "sa-east-1": {
        name: "South America (São Paulo)",
        azs: [
          "sa-east-1a",
          "sa-east-1b",
          "sa-east-1c",
        ]
      },
      // "cn-north-1": [
      //   "cn-north-1a",
      //   "cn-north-1b",
      //   "cn-north-1c",
      // ],
      // "cn-northwest-1": [
      //   "cn-northwest-1a",
      //   "cn-northwest-1b",
      //   "cn-northwest-1c",
      // ],
      // "us-gov-west-1": [
      //   "us-gov-west-1a",
      //   "us-gov-west-1b",
      //   "us-gov-west-1c",
      // ],
      // "us-gov-east-1": [
      //   "us-gov-east-1a",
      //   "us-gov-east-1b",
      //   "us-gov-east-1c",
      // ]
    },
    "azure": [
      // "eastus2",
      // "southcentralus",
      // "westus2",
      // "westus3",
      // "australiaeast",
      "Brazil South",
      "France Central",
      "Qatar Central",
      "South Africa North",
      "Australia East",
      "Canada Central",
      "Italy North",
      "UAE North",
      "Central India",
      "Central US",
      "Germany West Central",
      "Israel Central",
      "Japan East",
      "East US",
      "Norway East",
      "Korea Central",
      "East US 2",
      "North Europe",
      "Southeast Asia",
      "South Central US",
      "UK South",
      "East Asia",
      "US Gov Virginia",
      "West Europe",
      "China North 3",
      "West US 2",
      "Sweden Central",
      "West US 3",
      "Switzerland North",
      "Poland Central"
    ],
    "gcp": {
      "asia-east1": {
        name: "Changhua County, Taiwan, APAC",
        azs: [
          "asia-east1-a",
          "asia-east1-b",
          "asia-east1-c"
        ]
      },
      "asia-east2": {
        name: "Hong Kong, APAC",
        azs: [
          "asia-east2-a",
          "asia-east2-b",
          "asia-east2-c"
        ]
      },
      "asia-northeast1": {
        name: "Tokyo, Japan, APAC",
        azs: [
          "asia-northeast1-a",
          "asia-northeast1-b",
          "asia-northeast1-c"
        ]
      },
      "asia-northeast2": {
        name: "Osaka, Japan, APAC",
        azs: [
          "asia-northeast2-a",
          "asia-northeast2-b",
          "asia-northeast2-c"
        ]
      },
      "asia-northeast3": {
        name: "Seoul, South Korea, APAC",
        azs: [
          "asia-northeast3-a",
          "asia-northeast3-b",
          "asia-northeast3-c"
        ]
      },
      "asia-south1": {
        name: "Mumbai, India, APAC",
        azs: [
          "asia-south1-a",
          "asia-south1-b",
          "asia-south1-c"
        ]
      },
      "asia-south2": {
        name: "Delhi, India, APAC",
        azs: [
          "asia-south2-a",
          "asia-south2-b",
          "asia-south2-c"
        ]
      },
      "asia-southeast1": {
        name: "Jurong West, Singapore, APAC",
        azs: [
          "asia-southeast1-a",
          "asia-southeast1-b",
          "asia-southeast1-c"
        ]
      },
      "asia-southeast2": {
        name: "Jakarta, Indonesia, APAC",
        azs: [
          "asia-southeast2-a",
          "asia-southeast2-b",
          "asia-southeast2-c"
        ]
      },
      "australia-southeast1": {
        name: "Sydney, Australia, APAC",
        azs: [
          "australia-southeast1-a",
          "australia-southeast1-b",
          "australia-southeast1-c"
        ]
      },
      "australia-southeast2": {
        name: "Melbourne, Australia, APAC",
        azs: [
          "australia-southeast2-a",
          "australia-southeast2-b",
          "australia-southeast2-c"
        ]
      },
      "europe-central2": {
        name: "Warsaw, Poland, Europe",
        azs: [
          "europe-central2-a",
          "europe-central2-b",
          "europe-central2-c"
        ]
      },
      "europe-north1": {
        name: "Hamina, Finland, Europe",
        azs: [
          "europe-north1-a",
          "europe-north1-b",
          "europe-north1-c"
        ]
      },
      "europe-southwest1": {
        name: "Madrid, Spain, Europe",
        azs: [
          "europe-southwest1-a",
          "europe-southwest1-b",
          "europe-southwest1-c"
        ]
      },
      "europe-west1": {
        name: "St. Ghislain, Belgium, Europe",
        azs: [
          "europe-west1-b",
          "europe-west1-c",
          "europe-west1-d",
        ]
      },
      "europe-west10": {
        name: "Berlin, Germany, Europe",
        azs: [
          "europe-west10-a",
          "europe-west10-b",
          "europe-west10-c",
        ]
      },
      "europe-west12": {
        name: "Turin, Italy, Europe",
        azs: [
          "europe-west12-a",
          "europe-west12-b",
          "europe-west12-c",
        ]
      },
      "europe-west2": {
        name: "London, England, Europe",
        azs: [
          "europe-west2-a",
          "europe-west2-b",
          "europe-west2-c",
        ]
      },
      "europe-west3": {
        name: "Frankfurt, Germany, Europe",
        azs: [
          "europe-west3-a",
          "europe-west3-b",
          "europe-west3-c",
        ]
      },
      "europe-west4": {
        name: "Eemshaven, Netherlands, Europe",
        azs: [
          "europe-west4-a",
          "europe-west4-b",
          "europe-west4-c",
        ]
      },
      "europe-west6": {
        name: "Zurich, Switzerland, Europe",
        azs: [
          "europe-west6-a",
          "europe-west6-b",
          "europe-west6-c",
        ]
      },
      "europe-west8": {
        name: "Milan, Italy, Europe",
        azs: [
          "europe-west8-a",
          "europe-west8-b",
          "europe-west8-c",
        ]
      },
      "europe-west9": {
        name: "Paris, France, Europe",
        azs: [
          "europe-west9-a",
          "europe-west9-b",
          "europe-west9-c",
        ]
      },
      "me-central1": {
        name: "Doha, Qatar, Middle East",
        azs: [
          "me-central1-a",
          "me-central1-b",
          "me-central1-c",
        ]
      },
      "me-west1": {
        name: "Tel Aviv, Israel, Middle East",
        azs: [
          "me-west1-a",
          "me-west1-b",
          "me-west1-c",
        ]
      },
      "northamerica-northeast1": {
        name: "Montréal, Québec, North America",
        azs: [
          "northamerica-northeast1-a",
          "northamerica-northeast1-b",
          "northamerica-northeast1-c",
        ]
      },
      "northamerica-northeast2": {
        name: "Toronto, Ontario, North America",
        azs: [
          "northamerica-northeast2-a",
          "northamerica-northeast2-b",
          "northamerica-northeast2-c",
        ]
      },
      "southamerica-east1": {
        name: "Osasco, São Paulo, Brazil, South America",
        azs: [
          "southamerica-east1-a",
          "southamerica-east1-b",
          "southamerica-east1-c",
        ]
      },
      "southamerica-west1": {
        name: "Santiago, Chile, South America",
        azs: [
          "southamerica-west1-a",
          "southamerica-west1-b",
          "southamerica-west1-c",
        ]
      },
      "us-central1": {
        name: "Council Bluffs, Iowa, North America",
        azs: [
          "us-central1-a",
          "us-central1-b",
          "us-central1-c",
          "us-central1-f",
        ]
      },
      "us-east1": {
        name: "Moncks Corner, South Carolina, North America",
        azs: [
          "us-east1-b",
          "us-east1-c",
          "us-east1-d",
        ]
      },
      "us-east4": {
        name: "Ashburn, Virginia, North America",
        azs: [
          "us-east4-a",
          "us-east4-b",
          "us-east4-c",
        ]
      },
      "us-east5": {
        name: "Columbus, Ohio, North America",
        azs: [
          "us-east5-a",
          "us-east5-b",
          "us-east5-c",
        ]
      },
      "us-south1": {
        name: "Dallas, Texas, North America",
        azs: [
          "us-south1-a",
          "us-south1-b",
          "us-south1-c",
        ]
      },
      "us-west1": {
        name: "The Dalles, Oregon, North America",
        azs: [
          "us-west1-a",
          "us-west1-b",
          "us-west1-c",
        ]
      },
      "us-west2": {
        name: "Los Angeles, California, North America",
        azs: [
          "us-west2-a",
          "us-west2-b",
          "us-west2-c",
        ]
      },
      "us-west3": {
        name: "Salt Lake City, Utah, North America",
        azs: [
          "us-west3-a",
          "us-west3-b",
          "us-west3-c",
        ]
      },
      "us-west4": {
        name: "Las Vegas, Nevada, North America",
        azs: [
          "us-west4-a",
          "us-west4-b",
          "us-west4-c",
        ]
      },
    },
  },
  retentionPeriodInDays: [
    "0", "1", "3", "5", "7", "14", "30", "60", "90", "120", "150", "180", "365", "400", "545", "731", "1096", "1827", "2192", "2557", "2922", "3288", "3653"
  ],
  flowLogMaxInSec: [
    "60", "600"
  ],
};

export default initialData;
