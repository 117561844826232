import styled from 'styled-components';
import { Table } from 'antd';
import { palette } from 'styled-theme';
import {
  transition,
  borderRadius,
  boxShadow,
} from '@iso/lib/helpers/style_utils';

export const TableWrapper = styled(Table)`


.ant-table-body {
overflow-y: auto !important;
    overflow-x: hidden !important;
    max-height: 600px;
}

.ant-table-container {
  height: 650px;
  position: relative;
}

.ant-table-wrapper {
  border-radius: 20px;
}

.ant-table-thead > tr > td {
  background-color: #ffffff !important;
}

.ant-btn ant-btn-primary ant-btn-sm {
  background-color: #e6f7ff !important; /* Change the background color as desired */
  /* Add any other CSS styles you want */
}

.ant-table-filter-column {
  align-items: center;
}

.ant-table-wrapper .ant-table-filter-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-table-thead > tr > th {
  background-color: #ffffff;
  color: black;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 2px solid #f1f3f6;
}
.ant-table-tbody > tr > td {
  border: none !important;
}

.ant-table-container table>thead>tr:first-child >*:last-child {
  background: white !important;
  border-bottom: 2px solid #f1f3f6;
}

.ant-table-container table>thead>tr:first-child >*:first-child {
  background: white !important;
}

.blurred-row {
  opacity: 0.5;
  pointer-events: none;
}

.even-row.normal-row {
  cursor: pointer;
}

.even-rows {
  background-color: #fbfbfb;
}

.ant-table-bordered .ant-table-thead > tr > th {
  background-color: #ffffff;
  color: black;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}

// .ant-table-bordered .ant-table-tbody > tr:hover{
//   td.ant-table-cell-row-hover{
//     background: red;
//   }
// }

.ant-table-bordered .ant-table-tbody > tr > td {
  color: #292929;
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 500;
}
  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    white-space: normal;
    &.noWrapCell {
      white-space: nowrap;
    }

    // @media only screen and (max-width: 920px) {
    //   white-space: nowrap;
    // }
  }

  .customHeaderCss {
    .ant-table-bordered .ant-table-thead > tr > th{
        background-color: green;
    }
  }

  .myTableWrapper {
    .ant-table-bordered .ant-table-thead > tr > th{
      background-color: transparent;
    }
  }

  .isoImgWrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${palette('grayscale', 9)};
    ${borderRadius('50%')};

    img {
      height: 100%;
      object-fit: cover;
    }

    .userActivity {
      width: 10px;
      height: 10px;
      display: block;
      background-color: ${palette('color', 3)};
      position: absolute;
      bottom: 0;
      right: 3px;
      border: 1px solid #ffffff;
      ${borderRadius('50%')};
    }
  }
`;

export const SmallTableWrapper = styled(Table)`

.ant-table-wrapper {
  border-radius: 20px;
}

// .ant-table-container {
//   height: 620px;
//   position: relative;
// }

.ant-table-thead > tr > td {
  background-color: #ffffff !important;
}

.ant-btn ant-btn-primary ant-btn-sm {
  background-color: #e6f7ff !important; /* Change the background color as desired */
  /* Add any other CSS styles you want */
}
.ant-table-thead > tr > th {
  background-color: #ffffff;
  color: black;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 2px solid #f1f3f6;
}
.ant-table-tbody > tr > td {
  border: none !important;
}

.ant-table-container table>thead>tr:first-child >*:last-child {
  background: white !important;
  border-bottom: 2px solid #f1f3f6;
}

.ant-table-container table>thead>tr:first-child >*:first-child {
  background: white !important;
}

.even-rows.normal-row {
  cursor: pointer;
}

.odd-rows.normal-row {
  cursor: pointer;
}

.blurred-row {
  opacity: 0.5;
  pointer-events: none;
}

.even-rows {
  background-color: #fbfbfb;
}

.ant-table-bordered .ant-table-thead > tr > th {
  background-color: #ffffff;
  color: black;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
}

.ant-table-bordered .ant-table-tbody > tr > td {
  color: #292929;
  font-family: Nunito Sans;
  font-size: 12px;
  font-weight: 500;
}
  .ant-table-bordered .ant-table-thead > tr > th,
  .ant-table-bordered .ant-table-tbody > tr > td {
    white-space: normal;
    &.noWrapCell {
      white-space: nowrap;
    }

    // @media only screen and (max-width: 920px) {
    //   white-space: nowrap;
    // }
  }

  .customHeaderCss {
    .ant-table-bordered .ant-table-thead > tr > th{
        background-color: green;
    }
  }

  .myTableWrapper {
    .ant-table-bordered .ant-table-thead > tr > th{
      background-color: transparent;
    }
  }

  .isoImgWrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: ${palette('grayscale', 9)};
    ${borderRadius('50%')};

    img {
      height: 100%;
      object-fit: cover;
    }

    .userActivity {
      width: 10px;
      height: 10px;
      display: block;
      background-color: ${palette('color', 3)};
      position: absolute;
      bottom: 0;
      right: 3px;
      border: 1px solid #ffffff;
      ${borderRadius('50%')};
    }
  }
`;

export const ProjectInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 16px;
  margin-left: 25px;
  flex-shrink: 0;
`;

export const InfoWrapper = styled.div`
  font-family: 'Poppins';
  font-weight: 500;
`;
export const Title = styled.h2`
  font-size: 16px;
  color: #323332;
  margin-bottom: 3px;
`;
export const CreatedAt = styled.p`
  font-size: 13px;
  color: #838b9d;
  margin: 0;
`;

export const MoreActionWrapper = styled.div`
  p {
    cursor: pointer;
    font-size: 14px;
    color: #788195;
    text-transform: capitalize;
    padding: 4px 0;
    font-weight: 500;
    transition: color 0.3s ease-out;
    &:hover {
      color: #323332;
    }
  }
`;
