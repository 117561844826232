import React, { useContext, useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { Button, Popconfirm, Tooltip } from 'antd';
import { IconSvg } from '@iso/components/ScrumBoard/IconSvg/IconSvg';
import RemoveIcon from '@iso/assets/images/icon/02-icon.svg';
import ShareIcon from '@iso/assets/images/icon/03-icon.svg';
import CloseIcon from '@iso/assets/images/icon/07-icon.svg';
import Box from '../../../../../components/utility/box';
import { ContextProvider } from '../../../../../context/ContextProvider';
import { AppContext } from '../../../../../context/ContextProvider';
import { BreadcrumbContext } from '../../../../../context/breadcrumbContext';
import { hasPermission, permissionReducer } from '../../../../../context/helper';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  background-color: #ffffff;
  border-top: 1px solid #e9e9e9;

  .contentDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .contentCencelButton, .contentSaveButton{

  }

  .contentCencelButton{
    font-size: 16px;
    font-family: "Nunito Sans";
    font-weight: 600;
    color: rgb(19, 47, 68);
    width: 130px;
    height: 44px;
    text-align: center;
    margin-right: 15px;

    :hover{
      color: rgb(19, 47, 68);
    }
  }

  .contentSaveButton{
    background-color: rgb(19, 47, 68);
    font-size: 16px;
    font-family: "Nunito Sans";
    font-weight: 600;
    width: 170px;
    height: 44px;

    :hover{
      background-color: rgb(19, 47, 68);
    }
  }

`;

const ActionButtons = styled.div`
  > img {
    margin-right: 15px;
    border: none;
    width: 40px;
    margin-left: -5px;
  }
`;

const IconButtons = styled.div`
  > img {
    margin-left: 15px;
    margin-right: 0;
  }
`;

export default function CreateTaskFooter({ buttonContent, givenButtonFunction1, givenButtonFunction2, openDrawer, editing, onSave, onUpdate, onDelete, onCancel, disabled }) {
  let { currentCluster, clusterTabSelected, isUpdate, updateCluster, oneClickCluster, isOneClick } = useContext(AppContext);
  const { pageHeading } = useContext(BreadcrumbContext);
  const [permission, dispatch] = useReducer(permissionReducer, "");

  useEffect(() => {
    if(pageHeading && pageHeading.title !== ""){
      if(editing){
        dispatch({ title: pageHeading.title, type: "UPDATE" });
      }
      else{
        dispatch({ title: pageHeading.title, type: "CREATE" });
      }
    }
  }, [pageHeading]);
  // console.log("In footer: ", currentCluster);
  return (
    <Container style={{ position: 'absolute', zIndex: '10', width: '98%', bottom: '0', display: "flex", justifyContent: "center" }}>
      {(buttonContent === "Scan Now" || buttonContent === "Schedule Later" || buttonContent === "One-click cluster") &&
        <div style={{ display: "flex" }}>
          <Button style={{ marginTop: "-20px", position: "absolute", transform: buttonContent === "One-click cluster" ? "translateX(-120px)" : "translateX(-100px)", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", color: "#132f44", borderColor: "#132f44", height: "44px", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={() => {
            givenButtonFunction2();
            }}> {buttonContent === "One-click cluster" ? "Customise" : "Clear All"} </Button>
          <Button type="primary" onClick={() => {
            console.log("Enterss");
            givenButtonFunction1();
            //openDrawer();
          }} style={{ position: "absolute", backgroundColor: "#132f44", marginTop: "-20px", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", height: "44px", display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "10px" }}>
            {buttonContent === "One-click cluster" ? "Create" : buttonContent}
          </Button>
        </div>
      }
      {["Apps", "Data Sources", "Queues"].includes(buttonContent) &&
        <div className="contentDiv">
          <Button className="contentCencelButton" onClick={onCancel}> Cancel </Button>
          <Button className="contentSaveButton" type="primary" onClick={() => {
            if(!editing){
              onSave();
            }
            else {
              if(hasPermission(permission)){
                onUpdate();
              }
            }
          }} style={{opacity: hasPermission(permission) ? disabled ? "0.5" : "1" : "0.5", disabled: disabled, cursor: hasPermission(permission) ? "pointer" : "not-allowed"}} title={!hasPermission(permission) && "You don't have permission to perform this action."}>
            {`${!editing ? "Save" : "Update"}`}
          </Button>
        </div>
      }
      {buttonContent === "Cluster" &&
        <div style={{ display: "flex" }}>
          <Button style={{ marginTop: "-20px", position: "absolute", transform: "translateX(-100px)", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", color: "#132f44" }} onClick={openDrawer}> Cancel</Button>
          <Button type="primary" onClick={() => {
            givenButtonFunction1(isUpdate ? updateCluster : isOneClick ? oneClickCluster : currentCluster, clusterTabSelected);
            openDrawer();
          }} style={{ position: "absolute", backgroundColor: "#132f44", marginTop: "-20px", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600" }}>
            Save & Next
          </Button>
        </div>
      }
      {buttonContent === "GCPIntegration" &&
        <div style={{ display: "flex" }}>
          <Button type="primary" onClick={givenButtonFunction1} style={{ marginTop: "-20px", position: "absolute", transform: "translateX(-100px)", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", backgroundColor: "#132f44" }}>
            Connect
          </Button>
          <Button type="primary" onClick={givenButtonFunction2} style={{ position: "absolute", backgroundColor: "white", color: "#132f44", marginTop: "-20px", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", border: "1px solid #132f44", marginLeft: "10px" }}>
            Clear
          </Button>
        </div>
      }
      {buttonContent === "CloudflareIntegration" &&
        <div style={{ display: "flex" }}>
          <Button type="primary" onClick={givenButtonFunction1} style={{ marginTop: "-20px", position: "absolute", transform: "translateX(-100px)", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", backgroundColor: "#132f44" }}>
            Connect
          </Button>
          <Button type="primary" onClick={givenButtonFunction2} style={{ position: "absolute", backgroundColor: "white", color: "#132f44", marginTop: "-20px", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", border: "1px solid #132f44", marginLeft: "10px" }}>
            Cancel
          </Button>
        </div>
      }
      {["Source Config", "Build Config", "Deploy Config"].includes(buttonContent) &&
        <div style={{ display: "flex" }}>
          <Button type="primary" onClick={givenButtonFunction2} style={{ position: "absolute", transform: "translateX(-120px)", backgroundColor: "white", color: "#132f44", marginTop: "-20px", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "500", border: "1px solid #132f44", padding: "5px 40px", height: "auto" }}>
            Cancel
          </Button>
          <Button type="primary" onClick={givenButtonFunction1} style={{ marginTop: "-20px", position: "absolute", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "500", backgroundColor: "#132f44", padding: "5px 40px", height: "auto", marginLeft: "30px" }}>
            Save
          </Button>
        </div>
      }
      {["PGL Stack", "Custom Script"].includes(buttonContent) &&
        <div style={{ display: "flex" }}>
          <Button type="primary" onClick={givenButtonFunction2} style={{ position: "absolute", transform: "translateX(-150px)", backgroundColor: "white", color: "#132f44", marginTop: "-20px", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "500", border: "1px solid #132f44", padding: "5px 40px", height: "auto" }}>
            Cancel
          </Button>
          <Button type="primary" onClick={givenButtonFunction1} style={{ marginTop: "-20px", position: "absolute", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "500", backgroundColor: "#132f44", padding: "5px 40px", height: "auto" }}>
            Save
          </Button>
        </div>
      }
      {/* {(buttonContent === "Configure Action" || buttonContent === "Configure Condition" || buttonContent === "Add Condition" || buttonContent === "Configure Frequency") &&
        <div style={{ display: "flex" }}>
          <Button type="primary" onClick={givenButtonFunction2} style={{ position: "absolute", transform: "translateX(-100px)", backgroundColor: "white", color: "#132f44", marginTop: "-20px", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", border: "1px solid #132f44" }}>
            Cancel
          </Button>
          <Button type="primary" onClick={givenButtonFunction1} style={{ marginTop: "-20px", position: "absolute", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600", backgroundColor: "#132f44" }}>
            Save
          </Button>
        </div>
      } */}
    </Container>
  );
}