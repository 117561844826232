import styled from 'styled-components';

export const RefreshLoader = styled("img")`
    animation: rotate 1s linear infinite;
    @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
`;

export const AddonsLoader = styled("div")`
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 3px solid #f3f3f3;
      animation: rotate 2s linear infinite;
      border-top: 3px solid black;
      border-left: 3px solid black;
      border-right: 3px solid black;


      @keyframes rotate {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
`;