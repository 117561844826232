import React, { useState, useEffect } from 'react'
import IntlMessages from '@iso/components/utility/intlMessages';
import InputBox from '@iso/containers/Ecommerce/Checkout/InputBox.js';
import Select, { SelectOption } from '@iso/components/uielements/select';
import Button from '@iso/components/uielements/button';
import { BillingFormWrapper, InputBoxWrapper } from '../Profile/ProfileForm.styles';
import DrawerRoot from '../ScrumBoard/rootDrawer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../context/ContextProvider';
import { BreadcrumbContext } from '../../context/breadcrumbContext';
import Box from '../../components/utility/box';
import api from '../../api';
import { AlertContext } from '../../context/alertContext';
import { formatErrors } from '../../context/helper';
import { CustomModal } from '../Dashboard/CustomComponents/PageLoader.style';
import { Form, Spin } from 'antd';
import ContentHolder from '@iso/components/utility/contentHolder';
import { useForm } from 'antd/es/form/Form';



const Option = SelectOption;

let innerTitleStyle = {
    color: "#1f1f1f",
    fontFamily: "Poppins",
    fontType: "medium",
    fontSize: "16px",
    fontWeight: "500"
};

let innerSubtitleStyle = {
    fontSize: "14px",
    color: "#9b9b9b",
    fontFamily: "Nunito Sans",
    fontType: "regular",
    fontWeight: "400"
}

export default function Project() {

    const navigate = useNavigate();
    const location = useLocation();

    const styleButton = {
        background: '#323332',
        padding: '10px 15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "auto"
    };

    const { allProjects, setAllProjects, initialProject, setOpenModel, currentProject, setCurrentProject, openRightDrawer, setOpenRightDrawer, refreshBoardList, setRefreshBoardList } = useContext(AppContext);
    let { pageHeading, setPageHeading, pageHeadingsObj, setPageHeadingsObj, breadData, setBreadData, breadcrumbObj, setBreadcrumbObj } = useContext(BreadcrumbContext);

    const { set_alertOptions } = useContext(AlertContext);
    const [myProjectData, setMyProjectData] = useState(currentProject);

    const [error, setError] = useState({
        status: false,
        msg: ""
    });
    const [fetching, setFetching] = useState(false);
    const [form] = useForm();

    // useEffect(() => {
    //     setBreadData(breadData => ([
    //         ...breadData,
    //         {
    //             name: breadcrumbObj.projects.projects.name,
    //             path: breadcrumbObj.projects.projects.path,
    //             isDropdown: true, image: breadcrumbObj.projects.projects.image,
    //             apiEndPoint: breadcrumbObj.projects.projects.apiEndPoint,
    //             redirectUrl: breadcrumbObj.projects.projects.redirectUrl
    //         },
    //         {
    //             name: breadcrumbObj.projects.createProject.name,
    //             isDropdown: false
    //         }
    //     ]));
    //     setPageHeading({
    //         title: pageHeadingsObj.projects.createProject.createProject.title,
    //         subTitle: pageHeadingsObj.projects.createProject.createProject.subTitle,
    //     });
    //     return (() => {
    //         setBreadData([]);
    //     });
    // }, []);

    // useEffect(() => {

    // }, []);

    const handelFieldChanges = (e) => {
        console.log('e.target.value :', e.target.value)
        if (e.target.name === "name") {
            myProjectData.title = e.target.value;
            myProjectData.id = allProjects.length;
        }
        setMyProjectData({ ...myProjectData, [e.target.name]: e.target.value });
        setCurrentProject({ ...myProjectData, [e.target.name]: e.target.value });
        setError({
            status: false,
            msg: ""
        });
    }

    const handelSaveAndNext = (e) => {
        let allName = allProjects.map((temp, ind) => {
            return temp.name
        });
        console.log('allName :', allName);

        if (!myProjectData.name) {
            console.log('Please provide name');
            setError({
                status: true,
                msg: "Please provide project name."
            });
        }
        else if (myProjectData.name.startsWith(" ") || myProjectData.name.endsWith(" ")) {
            console.log('Please provide name');
            setError({
                status: true,
                msg: "Invalid project name. Please remove any leading or trailing spaces."
            });
        }
        else if (allName.includes(myProjectData.name)) {
            console.log('Please user a different name');
            setError({
                status: true,
                msg: "Name already exist. Please provide different name."
            });
        }
        else {
            localStorage.setItem("Project", JSON.stringify(myProjectData));
            navigate("/all-projects/create/services");
        }
    }

    const handleCancelButton = () => {
        // navigate('/all-projects');
        setOpenModel({
            modalType: "",
            modalProps: {
                data: ""
            }
        });
    }

    function toggleAlert(type, message, description) {
        set_alertOptions(alertOptions => ({
            status: true,
            type: type,
            message: message,
            description: description,
        }));

        // opacity=0 after 10sec
        setTimeout(() => {
            set_alertOptions(alertOptions => ({
                status: false,
                type: type,
                message: message,
                description: description,
            }));
        }, 5 * 1000);
    }

    const saveProjectApi = async () => {
        try {
            await form.validateFields();
            console.log('currentProject.tasks :', currentProject.tasks);
            let services = [];
            let goToEnv = false;
            let myFiles = [];

            currentProject.tasks.map((temp, ind) => {
                if (temp && temp.columnId != "thirdParties") {
                    let conf = { ...temp };
                    delete conf.id;
                    delete conf.name;
                    delete conf.columnId;
                    delete conf.description;
                    delete conf.serviceId;
                    delete conf.serviceCategory;

                    // if (temp.columnId === "app") {
                    //   conf["account_id"] = conf.accountId;
                    //   conf["repo_url"] = conf.repoUrl;
                    //   conf["repo_name"] = conf.repo;
                    //   conf["is_private"] = conf.isPrivate;
                    //   conf["cpu"] = conf.cpu !== "" ? parseInt(conf.cpu) : null;
                    //   conf["ram"] = conf.ram !== "" ? parseInt(conf.ram) : null;
                    //   conf["is_owner"] = conf.isOwner;
                    //   conf["app_source_type"] = conf.sourceType === "Git Repositories" ? 1 : 0;
                    //   conf["container_registry_id"] = conf.sourceType === "Git Repositories" ? null : conf.containerRegistry === "PUBLIC" ? null : conf.containerRegistry.id;
                    //   conf["container_registry_type"] = conf.sourceType === "Git Repositories" ? null : conf.containerRegistry === "PUBLIC" ? 0 : 1;
                    //   conf["repository_name"] = conf.imageRepo;


                    //   delete conf.accountId;
                    //   delete conf.repoUrl;
                    //   delete conf.repo;
                    //   delete conf.isPrivate;
                    //   delete conf.isOwner;
                    //   delete conf.sourceType;
                    //   delete conf.containerRegistry;
                    //   delete conf.imageRepo;

                    //   services.push({
                    //     "name": temp.name,
                    //     // "service_type": convertToBackend[temp.columnId],
                    //     "service_category": getServiceCategoryId(temp.name, convertToBackend[temp.columnId]),
                    //     "description": temp.description ? temp.description : null,
                    //     "service_icon": null,
                    //     "config": {
                    //       ...conf
                    //     }
                    //   });


                    //   goToEnv = true;
                    // }
                    // else {
                    //   console.log("conf.file: ", conf);
                    //   conf["is_file"] = conf.file ? true : false;
                    //   conf["file_name"] = (conf.file && Object.keys(conf.file).length > 0) ? /*temp.name + '-' +*/ conf.file.name : ""
                    //   conf["dump_url"] = conf.dumpUrl ? conf.dumpUrl : "";
                    //   conf["s3_key"] = conf.s3Key ? conf.s3Key : null;
                    //   temp.file && myFiles.push({
                    //     file: temp.file,
                    //     serviceName: temp.name
                    //   });
                    //   delete conf.file;
                    //   delete conf.dumpUrl;
                    //   delete conf.presignedUrl;
                    //   delete conf.editing;
                    //   delete conf.source;
                    //   delete conf.version;
                    //   delete conf.is_file;
                    //   delete conf.s3Key;
                    //   services.push({
                    //     "name": temp.name,
                    //     // "service_type": convertToBackend[temp.columnId],
                    //     "service_category": getServiceCategoryId(temp.source, convertToBackend[temp.columnId]),
                    //     "description": temp.description ? temp.description : null,
                    //     "service_icon": null,
                    //     "config": { ...conf }
                    //   });
                    // }
                }
            });

            let payload = {
                "name": currentProject.name,
                "description": currentProject.description ? currentProject.description : null,
                "app_icon": null,
                "services": services
            }

            console.log("Payload: ", payload);

            // const formData = new FormData();
            // formData.append('project_data', JSON.stringify(payload));
            // if (myFiles.length > 0) {
            //   console.log("My files: ", myFiles);
            //   myFiles.map((val) => {
            //     console.log("File: ", val.file);
            //     if(val.file){
            //       formData.append(val.serviceName + '-' + val.file.name, new File([val.file], { type: 'application/sql,application/gzip'}), val.file.name);
            //     }
            //   });
            // }
            // console.log('formData :', formData);
            setFetching(true);
            api.post(process.env.REACT_APP_API_URI + "/api/project_env/projects/", payload,/* {
              headers: {
                'Content-Type': 'multipart/form-data',
              }
            }*/)
                .then((res) => {
                    let data = res.data;
                    console.log("data in projects :", data);
                    if (data.status) {
                        console.log('Success');
                        // currentProject.tasks = [];
                        // Object.keys(currentProject.columns).map((key, ind) => {
                        //   currentProject.columns[key].task_orders = [];
                        // });
                        // setCurrentProject({...currentProject});
                        // setCurrentTasks([]);
                        //   if (pageHeading.title === "Project Services" && currentProject.columns.app.task_orders.length > 0) {
                        //     toggleAlert("info", "Info", "Successfully created project. Now you can add environment variable for your services.");
                        //   }
                        //   else {
                        //     toggleAlert("success", "Success", "Project created successfully.");
                        //   }
                        toggleAlert("success", "Success", "Project created successfully.");
                        navigate(`/all-projects/project/${data.data.id}`);
                        /*This will now be commented as we will directly redirect to edit project services */
                        //setRefreshBoardList(!refreshBoardList);

                        //   if (goToEnv) {
                        //     navigate(`/all-projects/create/environmentvariables/${data.data.id}`, {
                        //       state: {
                        //         data: currentProject,
                        //         from: "create-project"
                        //       }
                        //     })
                        //   }
                        //   else {
                        //     localStorage.removeItem("Project");
                        //     navigate(`/all-projects`);
                        //   }
                    }
                    else {
                        let err = formatErrors(data.error_details);
                        console.log("err in cluster :", err);
                        toggleAlert("error", "Error", err);
                        // toggleAlert("error", "Error", "Error in Creating Project. Please try Again after checking all the reuired fields");
                    }
                    setOpenModel({
                        modalType: "",
                        modalProps: {
                            data: ""
                        }
                    });
                    setFetching(false);
                })
                .catch((err) => {
                    console.log("err :", err);
                    toggleAlert("error", "Error", "Something went wrong. Please try again.");
                    setOpenModel({
                        modalType: "",
                        modalProps: {
                            data: ""
                        }
                    });
                    setFetching(false);
                });
        }
        catch (err) {
            console.log("Please provide project name");
            // toggleAlert("error", "Error", "Something went wrong. Please try again.");
        }
    }

    return (
        <BillingFormWrapper className="isoBillingForm" style={{ width: "100%", padding: "0" }}>
            <Form form={form}>
                {/* <Box className="project-name" style={{ }}> */}
                <CustomModal
                    visible={fetching}
                    //onCancel={hideModal}
                    footer={null}
                    closable={false}
                    centered
                    maskClosable={false}
                    style={{ border: "none", boxShadow: "none" }}
                >
                    <div style={{ textAlign: 'center', background: "transparent", border: "none" }}>
                        <Spin size="large" />
                    </div>
                </CustomModal>
                <Box
                    style={{ border: "0", margin: "0", paddingBottom: "0", paddingLeft: "0" }}
                    title="Add Project"
                    subtitle="Please provide a name of the project blueprint"
                    titleStyle={{
                        flexGrow: "0",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "1.25",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#1f1f1f",
                    }}
                    subTitleStyle={{
                        flexGrow: 0,
                        fontFamily: "Nunito Sans",
                        fontSize: "14px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "1.43",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#9b9b9b",
                    }}
                />
                <div style={{ display: "flex", flexDirection: "column",/* height: "620px",*/ justifyContent: "space-between" }}>
                    <div style={{ display: "flex",/* width: "75%", justifyContent: "space-between",*/ }}>
                        {/* <Box
                            title={<p><IntlMessages id="cluster.projectname" />*</p>}
                            titleStyle={innerTitleStyle}
                            subtitle={"Enter name for your project"}
                            subTitleStyle={innerSubtitleStyle}
                            style={{ border: "transparent", width: "fit-content" }}
                        /> */}
                        <div style={{ width: "100%" }}>
                            <Form.Item name="name"
                                rules={[
                                    {
                                        required: "true",
                                        message: "Please enter project name."
                                    }
                                ]}
                            >
                                <InputBox
                                    name='name'
                                    placeholder="Project Name"
                                    value={myProjectData.name}
                                    onChange={handelFieldChanges}
                                    style={{ height: "44px" }}
                                />
                            </Form.Item>
                        </div>
                    </div>

                    {/* <div style={{ display: "flex", justifyContent: "space-between", marginRight: "20px", marginTop: "30px" }}>
                        {error && error.status && <p className="isoHelperText" style={{width: "50%"}}>  {error.msg} </p>}
                        <div style={{ display: "flex", justifyContent: "right", alignItems: "flex-end", width: "100%" }}>
                            <Button name="cancel" size="large" style={{ marginRight: "10px", borderRadius: "10px", border: "1px solid #132f44", color: "#12f44", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600" }} onClick={handleCancelButton}>
                                Cancel
                            </Button>
                            <Button name="proceed" size="large" onClick={saveProjectApi} style={{ backgroundColor: "#132f44", borderRadius: "10px", color: "white", border: "1px solid #132f44", fontSize: "16px", fontFamily: "Nunito Sans", fontWeight: "600" }}>
                                Create
                            </Button>
                        </div>
                    </div> */}

                    <ContentHolder style={{ width: "100%", display: "flex", justifyContent: 'end', alignItems: 'center', margin: "0", marginTop: "20px", marginRight: "20px" }}>
                        <Button className="customButtonActive" style={{ marginRight: "20px", width: "160px", borderColor: "#132f44", color: "#132f44" }} onClick={handleCancelButton}>
                            {"Cancel"}
                        </Button>
                        <Button className="customButtonActive" style={!currentProject?.accountDetails?.account_id ? { background: "#132F44", borderRadius: "8px", color: "#FFFFFF", width: "200px" } : { borderColor: "#CB444A", background: "#CB444A", color: "white", marginRight: "10px", height: "32px", width: "200px" }} onClick={saveProjectApi}>
                            {"Create"}
                        </Button>
                    </ContentHolder>
                </div>
                {/* </Box> */}
            </Form>
        </BillingFormWrapper>
    )
};