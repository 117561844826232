import gcpStep2 from '../../../assets/images/sampleImg.png';
// import gcpStep2 from '../../../assets/images/gcpStep-2.svg';
import gcpStep3 from '../../../assets/images/gcpStep-3.svg';
import gcpStep4 from '../../../assets/images/gcpStep-4.svg';
import gcpSubStep1 from '../../../assets/images/gcpSubImg-1.png';
import gcpSubStep2 from '../../../assets/images/gcpSubImg-2.png';
import gcpSubStep41 from '../../../assets/images/gcpStep-4p1.png';
import gcpSubStep42 from '../../../assets/images/gcpStep-4p2.png';
export const gcpContent = [
    {
        boxTitle1: "Step 1",
        boxTitle2: "Create a GCP Account",
        boxSubtitle: <span>If you don't have a GCP account, go to the <a href="https://cloud.google.com/" target='_blank'> GCP website </a> and sign up for a new account.</span>,
        //image: gcpStep1,
        isOpen: false,
    },
    {
        boxTitle1: "Step 2",
        boxTitle2: "Create a Project",
        boxSubtitle: "Once you have a GCP account, create a new project in the GCP Console. The project acts as a container for your GCP resources, and it's where you'll manage and organize everything related to your application.",
        image: gcpStep2,
        isOpen: false,
    },
    {
        boxTitle1: "Step 3",
        boxTitle2: "Create a Service Account",
        boxSubtitle: "After selecting or creating project, you can create service account by selecting IAM & Admin tab and then select service accounts",
        boxSubtitleDetails: [{
            title: "Click on create service account and provide following details",
            image: gcpSubStep1,
            isOpen: false,
        }, {
            title: "Assign Service Account Owner role  and click on done",
            image: gcpSubStep2,
            isOpen: false
        }],
        //image: gcpStep3,
        isOpen: false
    },
    {
        boxTitle1: "Step 4",
        boxTitle2: "Create and Download SA JSON",
        boxSubtitle: "Select created service account, click on add key and create Key in Json format",
        image: gcpSubStep41,
        image2: gcpSubStep42,
        isOpen: false
    }
];