import Dropzone from 'react-dropzone-component';
import 'dropzone/dist/min/dropzone.min.css';
import 'react-dropzone-component/styles/filepicker.css';
import styled from 'styled-components';

const CustomDropzone = styled(Dropzone)`
  &.filepicker.dropzone {
    height: 200px;
    justify-content: center;
    display: flex;
    align-items: center;
  }

`;

export default CustomDropzone;
