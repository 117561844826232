import CryptoJS from 'crypto-js';

export default function useProject() {
    const staticToken = "";

    const encryptSecrets = (data) => {
        // const salt = CryptoJS.lib.WordArray.random(16);
        // const key = CryptoJS.PBKDF2(staticToken, salt, {
        //   keySize: 256 / 32,
        //   iterations: 100000,
        //   hasher: CryptoJS.algo.SHA256,
        // });

        // const iv = CryptoJS.lib.WordArray.random(12);
        // const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.GCM });
        // // const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), 'jXYmOTG1NSGUg-HJIB9STOPncWAuDDJJRSHh92de-Yo=').toString();

        // console.log("IV and encrypted: ", encrypted);

        // // const combined = salt.concat(iv).concat(encrypted.ciphertext);
        // // return CryptoJS.enc.Base64.stringify(combined);
        // return CryptoJS.enc.Base64.stringify(encrypted);

        const salt = CryptoJS.lib.WordArray.random(16);
        const key = CryptoJS.PBKDF2(staticToken, salt, {
            keySize: 256 / 32,
            iterations: 100000,
            hasher: CryptoJS.algo.SHA256,
        });

        const iv = CryptoJS.lib.WordArray.random(16); // AES-CBC requires 16-byte IV
        const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC });

        const combined = salt.concat(iv).concat(encrypted.ciphertext);
        return CryptoJS.enc.Base64.stringify(combined);

    }

    const decryptSecrets = (encryptedData) => {
        // const key = CryptoJS.PBKDF2(staticToken, CryptoJS.lib.WordArray.random(16), {
        //     keySize: 256 / 32,
        //     iterations: 1000
        // });
        // const iv = CryptoJS.lib.WordArray.random(12);
        // const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
        //     iv: iv,
        //     mode: CryptoJS.mode.GCM,
        //     padding: CryptoJS.pad.Pkcs7
        // });
        // return CryptoJS.enc.Utf8.stringify(decrypted);

        const combined = CryptoJS.enc.Base64.parse(encryptedData);
        const salt = CryptoJS.lib.WordArray.create(combined.words.slice(0, 4));
        const iv = CryptoJS.lib.WordArray.create(combined.words.slice(4, 8));
        const ciphertext = CryptoJS.lib.WordArray.create(combined.words.slice(8));

        const key = CryptoJS.PBKDF2(staticToken, salt, {
            keySize: 256 / 32,
            iterations: 100000,
            hasher: CryptoJS.algo.SHA256,
        });

        const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, { iv: iv, mode: CryptoJS.mode.CBC });
        return CryptoJS.enc.Utf8.stringify(decrypted);
    }

    return { encryptSecrets, decryptSecrets };
}