import { useContext } from "react";
import { AppContext } from "../../../context/ContextProvider";
import { AlertContext } from "../../../context/alertContext";
import { GuardrailsContext } from "../../../context/guardrailsContext";

let conditionContentStyle = {
  width: "68px",
  height: "52px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 27px 0 0",
  padding: "6px 8px",
  borderRadius: "10px",
  backgroundColor: "#4d99ef",
};
let actionContentStyle = {
  opacity: "0.6",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: "500",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#4d99ef",
  margin: "0 27px 0 0",
  width: "98px"
};
let operationContentStyle = {
  height: "19px",
  width: "68%",
  flexGrow: "0",
  opacity: "0.6",
  fontFamily: "Nunito Sans",
  paddingRight: "8px",
  fontSize: "14px",
  fontWeight: "600",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#1f1f1f",
};

const labelStyle = {
  flexGrow: "0",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  color: "#fff",
};
export default function ModuleBox({ moduleName }) {
  const { setOpenRightDrawer } = useContext(AppContext);
  const { set_alertOptions } = useContext(AlertContext);
  const { selectedGuardrailInfo } = useContext(GuardrailsContext);
  function toggleAlert(type, message, description) {
    console.log("Toggle alert called: ");
    set_alertOptions(alertOptions => ({
        status: true,
        type: type,
        message: message,
        description: description,
    }));

    // opacity=0 after 10sec
    setTimeout(() => {
        set_alertOptions(alertOptions => ({
            status: false,
            type: type,
            message: message,
            description: description,
        }));
    }, 5 * 1000);
  }
  
  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} onClick={() => {
      // if(!selectedGuardrailInfo.view){
      //   if(!moduleName){
      //     toggleAlert("error", "Error", "Please select an action in order to proceed.");
      //   }
      // }
    }}>
      <div style={conditionContentStyle}><p style={labelStyle}>Where</p></div>
      <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
        <span style={actionContentStyle}>Module</span>
        {<span style={operationContentStyle}>{moduleName ? moduleName[0].toUpperCase() + moduleName.substring(1) : "Select Module"}</span>}
      </div>
    </div>
  );
}